import { PureComponent } from 'react';

import { UIContext } from 'contexts/UIProvider.jsx';
import uploadService from 'services/uploadService';

import Gallery from './Gallery.jsx';

class GalleryContainer extends PureComponent {
    static contextType = UIContext;

    drop = el => {
        const { spinnerPush, spinnerPop } = this.context;

        if (this.props.disabled) {
            return;
        }
        spinnerPush();

        Promise.all(el.map(e => uploadService.upload(e, 'photo')))
            .then(res => {
                spinnerPop();
                this.props.onChange([
                    ...this.props.photos,
                    ...res.map(({ data: { url } }) => url),
                ]);
            })
            .catch(() => {
                spinnerPop();
            });
    };

    star = url => {
        this.props.onStarChange(url);
    };

    delete = url => {
        this.props.onChange(this.props.photos.filter(u => u !== url));
    };

    render() {
        const props = {
            photos: this.props.photos,
            starredPhoto: this.props.starredPhoto,
            onDelete: this.delete,
            onStar: this.props.onStarChange ? this.star : undefined,
            onDrop: this.drop,
            disabled: this.props.disabled,
            multiple: this.props.multiple,
            size: this.props.size,
        };

        return <Gallery {...props} />;
    }
}

GalleryContainer.defaultProps = {
    photos: [],
    multiple: false,
    disabled: false,
    size: 'medium',
};

export default GalleryContainer;
