import { useState } from 'react';

import { Button, Column, Row } from 'ui';

import useUI from '../../../../contexts/useUI.js';
import PillBar from '../../../../ui/PillBar/index.js';

import CalendarAgenda from './CalendarAgenda.jsx';
import CalendarWeek from './CalendarWeek.jsx';

const Calendar = () => {
    const [mode, setMode] = useState('week');

    const { modalOpen } = useUI();

    return (
        <Column gap="m">
            <Row alignContent="space-between">
                <PillBar
                    fieldId={'venue-calendar-mode'}
                    value={mode}
                    onChange={setMode}
                    options={[
                        {
                            label: 'Tydzień',
                            value: 'week',
                        },
                        {
                            label: 'Agenda',
                            value: 'agenda',
                        },
                    ]}
                />
                <Button
                    size="xl"
                    color={'green'}
                    onClick={() => modalOpen('addEvent')}>
                    Dodaj wydarzenie
                </Button>
            </Row>
            {mode === 'week' && <CalendarWeek />}
            {mode === 'agenda' && <CalendarAgenda />}
        </Column>
    );
};

export default Calendar;
