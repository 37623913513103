import { useMutationNewsDelete } from 'queries/news.mutations.js';
import { Button, ModalActions, ModalContent, ModalHeader, ModalHOC } from 'ui';

const DeleteNews = ({ data: { id }, modalClose }) => {
    const mutation = useMutationNewsDelete({
        onSuccess: () => {
            modalClose();
        },
    });

    const deleteNews = () => {
        mutation.mutate(id);
    };
    return (
        <>
            <ModalHeader>Usuń</ModalHeader>
            <ModalContent>Czy na pewno chcesz usunąć wpis?</ModalContent>
            <ModalActions>
                <Button color="red" onClick={deleteNews}>
                    Usuń
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DeleteNews, {
    id: 'deleteNews',
    size: 'small',
});
