import { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { formatDateFull } from 'utils/formatters';

import Context from '../Context/index.js';
import { Icon } from '../index.js';

import DatePickerContext from './DatePickerContext';

import styles from './InputDate.module.scss';

const InputDate = ({
    value,
    error,
    isFocused,
    isTouched,
    onChange,
    onBlur,
    onFocus,
    disabled,
}) => {
    const isErrorVisible = useMemo(
        () => error && isTouched && !isFocused,
        [error, isTouched, isFocused],
    );

    return (
        <Context
            onOpen={onFocus}
            onClose={onBlur}
            position={'bottom-center'}
            context={
                <DatePickerContext
                    value={value || new Date()}
                    onChange={onChange}
                />
            }>
            <div
                className={cx(
                    styles.root,
                    isFocused && styles.focused,
                    isErrorVisible && styles.error,
                    disabled && styles.disabled,
                )}>
                <div className={styles.value}>
                    {value ? formatDateFull(value) : ''}
                </div>
                <Icon size="s" icon="calendar" color="grey-500" />
                {isErrorVisible && <div className={styles.error}>{error}</div>}
            </div>
        </Context>
    );
};

InputDate.propTypes = {
    type: PropTypes.oneOf(['text']),
    value: PropTypes.object,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

InputDate.defaultProps = {
    type: 'text',
    value: undefined,
    placeholder: undefined,
    error: undefined,
    onChange: undefined,
    onBlur: undefined,
    onFocus: undefined,
};

export default InputDate;
