import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
    useMutationVenueAdd,
    useMutationVenuePatch,
} from 'queries/venues.mutations.js';
import { useVenueQuery } from 'queries/venues.queries.js';
import {
    Button,
    Column,
    Header,
    Input,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Row,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util';

const AddEditVenueModal = ({ data: { id }, modalClose }) => {
    const history = useHistory();
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        name: {
            value: '',
            validators: [validators.isRequired()],
        },
        desc: {
            value: '',
        },
        'address.street': {
            value: '',
        },
        'address.city': {
            value: '',
        },
        'address.postal': {
            value: '',
        },
    });

    const { data } = useVenueQuery(id);

    useEffect(() => {
        if (data) {
            form.name.setValue(data.name);
            form.desc.setValue(data.desc);
            form['address.street'].setValue(data.address?.street);
            form['address.city'].setValue(data.address?.city);
            form['address.postal'].setValue(data.address?.postal);
        }
    }, [data]);

    const mutationAdd = useMutationVenueAdd({
        onSuccess: ({ id }) => {
            modalClose();
            history.push(`/venues/${id}`);
        },
    });
    const mutationPatch = useMutationVenuePatch({ onSuccess: modalClose });

    const add = () => {
        if (!isFormValid) return;
        mutationAdd.mutate(getFormValue());
    };

    const edit = () => {
        if (!isFormValid) return;
        mutationPatch.mutate({ id, delta: getFormValue() });
    };

    return (
        <>
            <ModalHeader>{id ? 'Edytuj obiekt' : 'Nowy obiekt'}</ModalHeader>
            <ModalContent>
                <Row gap={'m'}>
                    <Column gap={'xs'}>
                        <Header title="Informacje podstawowe" size="s" />
                        <div>
                            <Label>Nazwa miejsca</Label>
                            <Input type="text" {...form.name} />
                        </div>
                        <div>
                            <Label>Opis miejsca</Label>
                            <Input type="text" {...form.desc} />
                        </div>
                    </Column>
                    <Column gap={'xs'}>
                        <Header title="Informacje adresowe" size="s" />
                        <div>
                            <Label>Ulica</Label>
                            <Input type="text" {...form['address.street']} />
                        </div>
                        <div>
                            <Label>Miasto</Label>
                            <Input type="text" {...form['address.city']} />
                        </div>
                        <div>
                            <Label>Kod pocztowy</Label>
                            <Input type="text" {...form['address.postal']} />
                        </div>
                    </Column>
                </Row>
            </ModalContent>
            <ModalActions>
                {id ? (
                    <Button
                        color="green"
                        disabled={!isFormValid}
                        onClick={edit}
                        onDisabledClick={touchForm}>
                        Zapisz
                    </Button>
                ) : (
                    <Button
                        color="green"
                        disabled={!isFormValid}
                        onClick={add}
                        onDisabledClick={touchForm}>
                        Utwórz
                    </Button>
                )}

                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AddEditVenueModal, {
    id: 'addEditVenue',
    title: 'Dodaj nowy obiekt',
    size: 'medium',
});
