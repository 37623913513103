import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import styles from './FilterTabs.module.scss';

const FilterTabs = ({ tabs, filters, setFilters }) => (
    <div className={styles.tabs}>
        {tabs.map(t => (
            <div
                className={styles.tab}
                onClick={() => setFilters(t.filters)}
                key={t.label}>
                <span>{t.label}</span>
                {JSON.stringify(filters) === JSON.stringify(t.filters) && (
                    <motion.div className={styles.pill} layoutId={`filters`} />
                )}
            </div>
        ))}
    </div>
);

FilterTabs.propTypes = {
    tabs: PropTypes.array,
};

FilterTabs.defaultProps = {
    tabs: [],
};

export default FilterTabs;
