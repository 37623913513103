import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { Card, Column, Html, Row, Tag } from 'ui';
import useACL from 'utils/useACL';

import GroupTag from '../../../ui/GroupTag/index.js';

import styles from './InfoCard.module.scss';

const InfoCard = ({
    id,
    data: { title, text },
    author: { id: authorId },
    groups,
    editable,
}) => {
    const { isMe, isRole } = useACL();
    const { modalOpen } = useUI();
    const openEdit = () => modalOpen('addInfo', { id });
    const isEditable =
        editable && (isRole([ROLES.OWNER, ROLES.ADMIN]) || isMe(authorId));
    return (
        <Card onEdit={isEditable ? openEdit : null}>
            <Column gap="xs">
                <Row>
                    <Tag size="m" color="green">
                        Ogłoszenie
                    </Tag>
                </Row>
                {title && (
                    <Row>
                        <div className={styles.title}>{title}</div>
                    </Row>
                )}
                {groups.length ? (
                    <Row gap={'xs'}>
                        {groups.map(g => (
                            <GroupTag group={g} size="s" key={g.id} />
                        ))}
                    </Row>
                ) : null}
                {text && (
                    <Row>
                        <Html className={styles.text}>{text}</Html>
                    </Row>
                )}
            </Column>
        </Card>
    );
};

export default InfoCard;
