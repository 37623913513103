import fetch from 'utils/fetch';

export default {
    get: query => {
        if (!query) {
            return Promise.resolve({
                coaches: [],
                groups: [],
                parents: [],
                players: [],
            });
        }
        return fetch
            .get('search', {
                params: {
                    query,
                },
            })
            .then(r => r.data);
    },

    getUsers: (query, roles) =>
        new Promise((resolve, reject) => {
            fetch
                .get('search/users', {
                    params: {
                        query,
                        roles,
                    },
                })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(reject);
        }),
};
