import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { AnimatedTransition, Column, Container, Header, Tabs } from 'ui';

import { ROLES } from '../../constants/logic';
import ViewHOC from '../ViewHOC';

import Administrators from './Administrators';
import SchoolSettings from './SchoolSettings';

import styles from './Settings.module.scss';

const Settings = () => {
    const location = useLocation();
    const history = useHistory();
    return (
        <div className={styles.view}>
            <Container>
                <Column gap={'m'}>
                    <Header title="Ustawienia szkoły" size={'xl'} />
                    <Tabs
                        tabs={[
                            {
                                label: 'Dane szkoły i ustawienia',
                                main: true,
                                onClick: () => {
                                    history.push('/settings/main');
                                },
                                active: [
                                    '/settings',
                                    '/settings/main',
                                ].includes(location.pathname),
                            },
                            {
                                label: 'Administratorzy',
                                onClick: () => {
                                    history.push('/settings/administrators');
                                },
                                active:
                                    location.pathname ===
                                    '/settings/administrators',
                            },
                        ]}
                    />
                    <AnimatedTransition k={location.pathname}>
                        <Switch location={location}>
                            <Route
                                exact
                                path="/settings/main"
                                component={SchoolSettings}
                            />
                            <Route
                                exact
                                path="/settings/administrators"
                                component={Administrators}
                            />

                            <Route component={SchoolSettings} />
                        </Switch>
                    </AnimatedTransition>
                </Column>
            </Container>
        </div>
    );
};

export default ViewHOC(Settings, {
    menu: true,
    topbar: true,
    auth: {
        roles: [ROLES.OWNER, ROLES.ADMIN],
    },
});
