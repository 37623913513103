import { useHistory } from 'react-router-dom';

import useAuth from 'contexts/useAuth';
import { Button, Column, Header, Input, Label } from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util';

import styles from './CreateSchool.module.scss';

const CreateSchool = () => {
    const [form, { isFormValid, touchForm }] = useForm({
        name: {
            value: '',
            validators: [validators.isRequired()],
        },
    });
    const { createSchool } = useAuth();
    const history = useHistory();

    const createSchoolFunc = () => {
        if (!isFormValid) return false;

        createSchool(form.name.value).then(() => {
            history.push(localStorage.getItem('entryPoint') || '/dashboard');
            localStorage.removeItem('entryPoint');
        });
    };

    return (
        <div className={styles.reset}>
            <div className={styles.card}>
                <Column gap="xs">
                    <Header title={'Utwórz szkołę'} />
                    <div>
                        <Label>Nazwa szkoły</Label>
                        <Input type="text" {...form.name} />
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            shape="square"
                            href="/auth/signin"
                            icon="chevron-left"
                        />
                        <Button
                            disabled={!isFormValid}
                            color="green"
                            onClick={createSchoolFunc}
                            onDisabledClick={touchForm}>
                            Utwórz
                        </Button>
                    </div>
                </Column>
            </div>
        </div>
    );
};

export default CreateSchool;
