import cx from 'classnames';

import { Icon } from 'ui';

import { ReactComponent as Star } from './star.svg';

import styles from './Image.module.scss';

const Image = ({ url, isStarred, size, onStar, onDelete }) => {
    const thumbnailUrl = url.replace('.jpg', '.th.jpg');
    return (
        <div className={cx(styles.image, styles[`size-${size}`])}>
            {onStar && (
                <div
                    className={cx(
                        styles.button,
                        styles.topLeft,
                        isStarred && styles.active,
                    )}
                    onClick={() => onStar(url)}>
                    <Star />
                </div>
            )}
            {onDelete && (
                <div
                    className={cx(styles.button, styles.bottomLeft)}
                    onClick={() => onDelete(url)}>
                    <Icon icon="delete" size="s" />
                </div>
            )}
            <div
                className={styles.pic}
                style={{ background: `url(${thumbnailUrl})` }}
            />
        </div>
    );
};

export default Image;
