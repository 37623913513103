import fetch from 'utils/fetch';

export default {
    query: query => fetch.get('coaches', { params: query }).then(r => r.data),
    post: coach => fetch.post('coaches', coach).then(r => r.data),

    get: id => fetch.get(`coaches/${id}`).then(r => r.data),
    patch: (id, delta) => fetch.patch(`coaches/${id}`, delta).then(r => r.data),
    delete: id => fetch.delete(`coaches/${id}`).then(r => r.data),

    getGroups: id => fetch.get(`coaches/${id}/groups`).then(r => r.data),
    getEvents: (id, params) =>
        fetch.get(`coaches/${id}/events`, { params }).then(r => r.data),
};
