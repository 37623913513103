import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';

import CalendarAgendaView from 'components/CalendarAgendaView';
import { Column, Header, Spinner } from 'ui';

import { useGetVenueEventsQuery } from '../../../../queries/venues.queries.js';

const CalendarAgenda = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const now = useMemo(() => DateTime.now(), []);

    const { data, isFetched } = useGetVenueEventsQuery(id, {
        dateStart: { $gte: now.toString() },
    });

    if (!isFetched) return <Spinner active />;

    return (
        <Column gap={'m'}>
            <Header title="Najbliższe wydarzenia" size="l" />
            <CalendarAgendaView events={data} isFetching={!isFetched} />
        </Column>
    );
};

export default CalendarAgenda;
