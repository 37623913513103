import cx from 'classnames';
import PropTypes from 'prop-types';

import { Tooltip } from '../index.js';

import style from './PercentCircle.module.scss';

const PercentCircle = ({ percent, size }) => {
    if (isNaN(percent)) return;
    return (
        <Tooltip
            text={`Frekwencja: ${Math.round(100 * percent)}%`}
            display="flex">
            <div className={cx([style.root, style[`size-${size}`]])}>
                <svg
                    className={cx([style.percentChart, style[`size-${size}`]])}
                    style={{
                        '--percent': percent,
                        '--color':
                            percent > 0.75
                                ? 'var(--color-green-500)'
                                : percent > 0.5
                                ? 'var(--color-yellow-800)'
                                : 'var(--color-red)',
                    }}>
                    <circle className={style.track} />
                    <circle className={style.indicator} />
                </svg>
                {size === 'l' && (
                    <div className={style.percent}>
                        {Math.round(100 * percent)}%
                    </div>
                )}
            </div>
        </Tooltip>
    );
};

PercentCircle.propTypes = {
    percent: PropTypes.number,
    size: PropTypes.oneOf(['s', 'm', 'l']),
};

PercentCircle.defaultProps = {
    percent: 0,
    size: 'm',
};
export default PercentCircle;
