import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { Icon } from 'ui/index';
import { formatMonth, formatYear } from 'utils/formatters';

import Days from './Days';

import style from './MonthView.module.scss';

const MonthView = ({
    value,
    month,
    year,
    // direction,
    onNext,
    onPrev,
    onChange,
    openYear,
}) => {
    const props = {
        month,
        year,
        value,
        onChange,
    };
    return (
        <div className={style.root}>
            <div className={style.month}>
                <div className={style.button} onClick={onPrev}>
                    <Icon icon="circle-left" />
                </div>
                <div className={style.name} onClick={openYear}>
                    {formatMonth(new Date(year, month))}
                    <span className={style.year}>
                        {formatYear(new Date(year, month))}
                    </span>
                </div>
                <div
                    className={style.button}
                    onClick={onNext}
                    onKeyPress={onPrev}>
                    <Icon icon="circle-right" />
                </div>
            </div>
            <div className={style.weeknames}>
                <div>P</div>
                <div>W</div>
                <div>Ś</div>
                <div>C</div>
                <div>P</div>
                <div>S</div>
                <div>N</div>
            </div>
            <div className={style.view}>
                <AnimatePresence>
                    <motion.div
                        key={month}
                        initial={{
                            opacity: 0,
                        }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{
                            opacity: 0,
                        }}>
                        <Days {...props} />
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
};

MonthView.propTypes = {
    value: PropTypes.instanceOf(Date).isRequired,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    direction: PropTypes.string.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    openYear: PropTypes.func.isRequired,
};

export default MonthView;
