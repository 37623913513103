import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import ListRow from '../ListRow';

import style from './List.style.module.scss';

const List = ({ header, data, renderRow, emptyComponent }) => {
    if (!data.length) {
        return emptyComponent;
    }

    return (
        <div className={style.list}>
            {header && <div className={style.header}>{header}</div>}
            <div className={style.content}>
                <AnimatePresence>
                    {data.map(row => (
                        <ListRow
                            key={row.id}
                            data={row}
                            renderRow={renderRow}
                        />
                    ))}
                </AnimatePresence>
            </div>
        </div>
    );
};

List.propTypes = {
    emptyComponent: PropTypes.node,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

List.defaulfProps = {
    emptyComponent: null,
    children: [],
};

export default List;
