import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useAuth from 'contexts/useAuth';
import useUI from 'contexts/useUI';
import { Button, Column, Header, Input, Label } from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util';

import styles from './SignUp.module.scss';

const SignUp = () => {
    const [form, { isFormValid, touchForm }] = useForm(
        {
            name: {
                value: '',
                validators: [validators.isRequired()],
            },
            surname: {
                value: '',
                validators: [validators.isRequired()],
            },
            email: {
                value: '',
                validators: [validators.isRequired(), validators.isEmail()],
            },
            password: {
                value: '',
                validators: [validators.isRequired(), validators.isPassword()],
            },
            password2: {
                value: '',
                validators: [validators.isRequired(), validators.isPassword()],
            },
        },
        {
            validators: [
                form =>
                    form.password2.value !== form.password.value
                        ? 'Hasła nie pasują'
                        : null,
            ],
        },
    );

    const [disabledEmail, setDisabledEmail] = useState(false);
    const location = useLocation();
    const history = useHistory();

    const { signUp } = useAuth();
    const { modalOpen } = useUI();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const emailParam = params.get('email');
        const nameParam = params.get('name');
        const surnameParam = params.get('surname');
        if (emailParam) {
            form.email.setValue(emailParam);
            form.name.setValue(nameParam || '');
            form.surname.setValue(surnameParam || '');
            setDisabledEmail(true);
        }
    }, []);

    const signUpFunc = () => {
        if (!isFormValid) return false;
        signUp({
            name: form.name.value,
            surname: form.surname.value,
            email: form.email.value,
            password: form.password.value,
        })
            .then(() => {
                history.push('/auth/signin');
            })
            .catch(() => {});
    };

    return (
        <div className={styles.reset}>
            <div className={styles.card}>
                <Column gap={'s'}>
                    <Header title={'Tworzenie konta'} />
                    <Column gap={'xs'}>
                        <div>
                            <Label>Imię</Label>
                            <Input {...form.name} />
                        </div>
                        <div>
                            <Label>Nazwisko</Label>
                            <Input {...form.surname} />
                        </div>
                        <div>
                            <Label>Adres email</Label>
                            <Input {...form.email} disabled={disabledEmail} />
                        </div>
                        <div>
                            <Label>Hasło</Label>
                            <Input type="password" {...form.password} />
                        </div>
                        <div>
                            <Label>Powtórz hasło</Label>
                            <Input type="password" {...form.password2} />
                        </div>
                    </Column>
                    <div className={styles.text}>
                        Rejestrując się akceptujesz{' '}
                        <strong onClick={() => modalOpen('termsandconditions')}>
                            Warunki i zasady
                        </strong>{' '}
                        oraz{' '}
                        <strong onClick={() => modalOpen('privacypolicy')}>
                            Politykę prywatności
                        </strong>{' '}
                        Goaly
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            shape="square"
                            href="/auth/signin"
                            icon="chevron-left"
                        />
                        <Button
                            color="green"
                            onClick={signUpFunc}
                            disabled={!isFormValid}
                            onDisabledClick={touchForm}>
                            Zarejestruj
                        </Button>
                    </div>
                </Column>
            </div>
        </div>
    );
};

export default SignUp;
