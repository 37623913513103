import { useRouteMatch } from 'react-router-dom';

import EmptyCard from 'components/EmptyCard';
import useUI from 'contexts/useUI';
import { usePlayerMeasurementsQuery } from 'queries/players.queries.js';
import {
    Button,
    Column,
    Header,
    List,
    ListActions,
    ListCell,
    Spinner,
} from 'ui';

import ListDataCell from '../../../../components/ListDataCell/index.js';

// import BMIChart from './BMIChart';
// import HeightChart from './HeightChart';
// import WeightChart from './WeightChart';

const Measurements = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const { modalOpen } = useUI();
    const { data, isFetched } = usePlayerMeasurementsQuery(id);

    if (!isFetched) return <Spinner active />;
    return (
        <Column gap={'m'}>
            <Header title="Pomiary fizjonomiczne" size="l">
                <Button
                    icon="plus"
                    size={'l'}
                    color={'green'}
                    onClick={() =>
                        modalOpen('addMeasurement', { idPlayer: id })
                    }>
                    {/*Dodaj pomiar*/}
                </Button>
            </Header>
            {data && data.length > 1 ? (
                <>
                    {/*<WeightChart*/}
                    {/*    data={data.map(m => ({*/}
                    {/*        x: new Date(m.createdAt).toISOString().slice(0, 10),*/}
                    {/*        y: m.weight,*/}
                    {/*    }))}*/}
                    {/*/>*/}
                    {/*<HeightChart*/}
                    {/*    data={data.map(m => ({*/}
                    {/*        x: new Date(m.createdAt).toISOString().slice(0, 10),*/}
                    {/*        y: m.height,*/}
                    {/*    }))}*/}
                    {/*/>*/}
                    {/*<BMIChart*/}
                    {/*    data={data.map(m => ({*/}
                    {/*        x: new Date(m.createdAt).toISOString().slice(0, 10),*/}
                    {/*        y: m.bmi,*/}
                    {/*    }))}*/}
                    {/*/>*/}
                </>
            ) : undefined}
            <Header title="Historia pomiarów" size="m" />
            {!data.length ? (
                <EmptyCard
                    action={() => modalOpen('addMeasurement', { idPlayer: id })}
                    actionLabel="Dodaj pierwszy pomiar"
                />
            ) : (
                <List
                    header={
                        <>
                            <ListCell width={120} header>
                                Data
                            </ListCell>
                            <ListCell width={100} header>
                                Waga
                            </ListCell>
                            <ListCell width={100} header>
                                Wzrost
                            </ListCell>
                            <ListCell width={140} header>
                                BMI
                            </ListCell>
                            <ListCell header>Autor</ListCell>
                        </>
                    }
                    data={data || []}
                    renderRow={({ height, weight, bmi, createdAt, author }) => {
                        return (
                            <>
                                <ListDataCell.Date date={createdAt} />
                                <ListDataCell.Height height={height} />
                                <ListDataCell.Weight weight={weight} />
                                <ListDataCell.BMI bmi={bmi} />
                                <ListDataCell.User user={author} />
                                <ListActions actions={[]} />
                            </>
                        );
                    }}
                />
            )}
        </Column>
    );
};

export default Measurements;
