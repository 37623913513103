import { useMutation, useQueryClient } from '@tanstack/react-query';

import useUI from 'contexts/useUI';
import organizationsService from 'services/organizationsService.js';

export const useMutationOrganizationPatch = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ id, delta }) => organizationsService.patch(id, delta),
        {
            onSuccess: () => {
                toastPush({
                    title: 'Zmieniono organizację',
                });
                queryClient.invalidateQueries(['organization']);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationOrganizationPatchSettings = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ id, delta }) => organizationsService.patchSettings(id, delta),
        {
            onSuccess: () => {
                toastPush({
                    title: 'Zmieniono ustawienia organizacji',
                });
                queryClient.invalidateQueries(['organization']);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationOrganizationAddAdmin = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idOrganization, admin }) =>
            organizationsService.postAdmin(idOrganization, admin),
        {
            onSuccess: data => {
                toastPush({
                    title: 'Dodano administratora',
                    text: `${data.name} ${data.surname}`,
                });
                queryClient.invalidateQueries([
                    'organization',
                    'administrators',
                ]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationOrganizationDeleteAdmin = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idOrganization, idAdmin }) =>
            organizationsService.deleteAdmin(idOrganization, idAdmin),
        {
            onSuccess: () => {
                toastPush({
                    title: 'Usunięto administratora',
                });
                queryClient.invalidateQueries([
                    'organization',
                    'administrators',
                ]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};
