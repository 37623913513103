import { useRouteMatch } from 'react-router-dom';

import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useVenueQuery } from 'queries/venues.queries.js';
import { Card, Column, Header, LabeledValue, Map, Row, Spinner } from 'ui';
import useACL from 'utils/useACL';

const Info = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const { isRole } = useACL();

    const { modalOpen } = useUI();

    const { data: venue } = useVenueQuery(id);

    if (!venue) {
        return <Spinner active />;
    }

    const canEdit = isRole([ROLES.ADMIN, ROLES.OWNER]);
    return (
        <Column gap={'m'}>
            <Row gap={'m'}>
                <Card
                    onEdit={
                        canEdit ? () => modalOpen('addEditVenue', { id }) : null
                    }>
                    <Column gap={'xs'}>
                        <Header title="Informacje podstawowe" size="m" />
                        <Row gap={'xs'}>
                            <Column>
                                <LabeledValue
                                    label="Nazwa"
                                    value={venue.name}
                                />
                            </Column>
                            <Column>
                                <LabeledValue label="Opis" value={venue.desc} />
                            </Column>
                        </Row>
                    </Column>
                </Card>
                <Card
                    onEdit={
                        canEdit ? () => modalOpen('addEditVenue', { id }) : null
                    }>
                    <Column gap={'xs'}>
                        <Header title="Adres" size="m" />
                        <Row gap={'m'}>
                            <Column>
                                <LabeledValue
                                    label={'Ulica'}
                                    value={venue.address.street}
                                />
                            </Column>
                            <Column>
                                <LabeledValue
                                    label={'Miasto'}
                                    value={venue.address.city}
                                />
                            </Column>
                            <Column>
                                <LabeledValue
                                    label={'Kod pocztowy'}
                                    value={venue.address.postal}
                                />
                            </Column>
                        </Row>
                    </Column>
                </Card>
            </Row>
            {venue.location && (
                <Row>
                    <Column gap={'xs'}>
                        <Header title="Lokalizacja obiektu" size="m" />
                        <Map {...venue.location} />
                    </Column>
                </Row>
            )}
        </Column>
    );
};

export default Info;
