import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import EmptyCard from 'components/EmptyCard';
import { useCalendarEventQuery } from 'queries/events.queries.js';
import {
    Button,
    Column,
    Header,
    List,
    ListActions,
    ListCell,
    Row,
    Spinner,
} from 'ui';

import ListDataCell from '../../../../components/ListDataCell/index.js';
import PresenceSwitch from '../../../../components/PresenceSwitch/index.js';
import useUI from '../../../../contexts/useUI.js';
import {
    useMutationEventParticipantDelete,
    useMutationEventStatusesPatch,
} from '../../../../queries/events.mutations.js';
import Context from '../../../../ui/Context/index.js';

import ModalAddParticipant from './modals/ModalAddParticipant/index.js';
import AddSideNote from './AddSideNote.jsx';
import NotePill from './NotePill.jsx';

const Participants = () => {
    const {
        params: { id: idEvent },
    } = useRouteMatch();
    const { data, isFetched } = useCalendarEventQuery(idEvent);
    const { modalOpen } = useUI();

    const { mutate: updateParticipantsStatuses } =
        useMutationEventStatusesPatch();

    const { mutate: deleteParticipant } = useMutationEventParticipantDelete();

    const participants = useMemo(
        () =>
            data.participants.map(p => ({
                ...p,
                status: (data.participantsStatuses || {})[p.id],
                notes: (data.participantsNotes || {})[p.id],
            })),
        [data],
    );

    const setStatus = idUser => status => {
        updateParticipantsStatuses({ idEvent, idUser, status });
    };

    const removeParticipant = idParticipant => {
        deleteParticipant({ idEvent, idParticipant });
    };

    const addParticipant = () => {
        modalOpen('addParticipant', { idEvent });
    };

    if (!isFetched) {
        return <Spinner active />;
    }

    return (
        <Column gap="m">
            <Header title="Uczestnicy" size="l">
                <Button size="l" onClick={addParticipant}>
                    Zaproś
                </Button>
            </Header>
            {!data.participants.length ? (
                <EmptyCard />
            ) : (
                <List
                    header={
                        <>
                            <ListCell width={300} header>
                                Imię i nazwisko
                            </ListCell>
                            <ListCell width={160} header>
                                Rola
                            </ListCell>
                            <ListCell width={100} header>
                                Obecność
                            </ListCell>
                            <ListCell header>Uwagi</ListCell>
                        </>
                    }
                    data={participants || []}
                    renderRow={({
                        id,
                        name,
                        surname,
                        role,
                        avatar,
                        status,
                        notes,
                    }) => (
                        <>
                            <ListDataCell.User
                                user={{
                                    id,
                                    name,
                                    surname,
                                    avatar,
                                    role,
                                }}
                                status={status}
                                size="l"
                            />
                            <ListDataCell.UserRole role={role} width={160} />
                            <ListCell width={100}>
                                <PresenceSwitch
                                    value={status}
                                    onChange={setStatus(id)}
                                />
                            </ListCell>
                            <ListCell>
                                <Row gap="xxs">
                                    {(notes || []).map(id => (
                                        <NotePill key={id} noteId={id} />
                                    ))}
                                    <Context
                                        context={
                                            <AddSideNote
                                                idEvent={idEvent}
                                                idUser={id}
                                            />
                                        }>
                                        <Button
                                            size="l"
                                            icon="plus"
                                            shape="rect"
                                            color="grey"
                                        />
                                    </Context>
                                </Row>
                            </ListCell>
                            <ListActions
                                actions={[
                                    {
                                        title: 'remove',
                                        icon: 'delete',
                                        color: 'red',
                                        onClick: () => removeParticipant(id),
                                    },
                                ]}
                            />
                        </>
                    )}
                />
            )}
            <ModalAddParticipant />
        </Column>
    );
};

export default Participants;
