import cx from 'classnames';

import { ROLES } from 'constants/logic';
import useAuth from 'contexts/useAuth';
import useUI from 'contexts/useUI';
import { Button, Logo } from 'ui';

import { version } from '../../../../package.json';

import MenuItem from './MenuItem';

import style from './Menu.module.scss';

const Menu = () => {
    const { isMenu, tutorialOpen } = useUI();
    const { me } = useAuth();
    const role = me?.role;

    return (
        <div className={cx(style.menu, !isMenu && style.hidden)}>
            <div className={cx(style.top, 'headway')}>
                <Logo width={55} />
                <span className={style.version}>{version}</span>
            </div>
            <div className={style.center}>
                <MenuItem title="Tablica" icon="dashboard" link="/dashboard" />
                {[ROLES.OWNER, ROLES.ADMIN].includes(role) && (
                    <MenuItem title="Komunikacja" icon="news" link="/news" />
                )}
                {[ROLES.OWNER, ROLES.ADMIN, ROLES.COACH].includes(role) && (
                    <MenuItem
                        title="Kalendarz"
                        icon="calendar"
                        link="/calendar"
                    />
                )}
                {[ROLES.OWNER, ROLES.ADMIN, ROLES.COACH].includes(role) && (
                    <MenuItem title="Zawodnicy" icon="player" link="/players" />
                )}
                {[ROLES.OWNER, ROLES.ADMIN, ROLES.COACH].includes(role) && (
                    <MenuItem title="Grupy" icon="group" link="/groups" />
                )}
                {[ROLES.OWNER, ROLES.ADMIN, ROLES.COACH].includes(role) && (
                    <MenuItem
                        title="Rodzice"
                        icon="cheerleader"
                        link="/parents"
                    />
                )}
                {[ROLES.OWNER, ROLES.ADMIN, ROLES.COACH].includes(role) && (
                    <MenuItem title="Trenerzy" icon="whistle" link="/coaches" />
                )}
                {[ROLES.OWNER, ROLES.ADMIN, ROLES.COACH].includes(role) && (
                    <MenuItem title="Obiekty" icon="stadium" link="/venues" />
                )}
                {/* <MenuItem */}
                {/* title="Multimedia" */}
                {/* icon="media" */}
                {/* link="/multimedia" */}
                {/* /> */}
                {/* <MenuItem */}
                {/* title="Dokumenty" */}
                {/* icon="document" */}
                {/* link="/documents" */}
                {/* /> */}
                {/* <MenuItem */}
                {/* title="Testy" */}
                {/* icon="timer" */}
                {/* link="/tests" */}
                {/* /> */}
                {/* <MenuItem */}
                {/* title="Płatności" */}
                {/* icon="wallet" */}
                {/* link="/payments" */}
                {/* /> */}
            </div>
            <div className={style.bottom}>
                {[ROLES.OWNER, ROLES.ADMIN].includes(role) && (
                    <MenuItem
                        title="Ustawienia"
                        icon="settings"
                        link="/settings"
                    />
                )}
                <div className={style.help}>
                    <Button color={'black'} onClick={tutorialOpen}>
                        Potrzebna pomoc?
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Menu;
