import { useMutation, useQueryClient } from '@tanstack/react-query';

import useUI from 'contexts/useUI';
import venuesService from 'services/venuesService.js';

export const useMutationVenueAdd = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    return useMutation(venuesService.post, {
        onSuccess: data => {
            toastPush({
                title: 'Dodano nowy obiekt',
                text: data.name,
            });
            queryClient.invalidateQueries(['venues', 'list']);
            if (onSuccess) {
                onSuccess(data);
            }
        },
    });
};

export const useMutationVenuePatch = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    return useMutation(({ id, delta }) => venuesService.patch(id, delta), {
        onSuccess: (data, { id }) => {
            toastPush({
                title: 'Wyedytowano obiekt',
                text: data.name,
            });
            queryClient.invalidateQueries(['venues', 'list']);
            queryClient.invalidateQueries(['venues', 'single', id]);
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationVenueDelete = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    return useMutation(venuesService.delete, {
        onSuccess: data => {
            toastPush({
                title: 'Usunięto obiekt',
                text: data.name,
            });
            queryClient.invalidateQueries(['venues', 'list']);
            queryClient.invalidateQueries(['venues', 'single', data.id]);
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};
