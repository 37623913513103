import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Tooltip } from 'ui';

import POS from './list.js';

import styles from './Positions.module.scss';

const Positions = ({ value, onChange }) => {
    const [newValue, setNewValue] = useState([]);

    const onClick = label => {
        if (newValue.includes(label)) {
            setNewValue([...newValue.filter(i => i !== label)]);
        } else {
            setNewValue([...newValue, label]);
        }
    };

    useEffect(() => {
        if (JSON.stringify(value) !== JSON.stringify(newValue)) {
            setNewValue(value);
        }
    }, [value]);

    useDebounce(
        () => {
            if (JSON.stringify(value) !== JSON.stringify(newValue)) {
                onChange(newValue);
            }
        },
        2000,
        [newValue],
    );

    return (
        <div className={styles.root}>
            <div className={styles.leftField} />
            <div className={styles.rightField} />
            <div className={styles.centerLine} />
            <div className={styles.centerCircle} />
            {POS.map(p => (
                <div
                    key={p.label}
                    onClick={() => onClick(p.label)}
                    className={cx(
                        styles.position,
                        newValue.includes(p.label) && styles.active,
                    )}
                    style={{ left: `${p.x}%`, top: `${p.y}%` }}>
                    <Tooltip text={p.name}>
                        <div>{p.label}</div>
                    </Tooltip>
                </div>
            ))}
        </div>
    );
};

Positions.propTypes = {
    value: PropTypes.array,
    size: PropTypes.string,
    onChange: PropTypes.func,
};

Positions.defaultProps = {
    value: [],
    size: 'medium',
    onChange: undefined,
};

export default Positions;
