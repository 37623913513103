import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Auth from 'screens/Auth';
import CalendarList from 'screens/Calendar/CalendarList';
import CalendarSingle from 'screens/Calendar/CalendarSingle';
import AddEventDialog from 'screens/Calendar/modals/AddEvent';
import CoachesList from 'screens/Coaches/CoachesList';
import CoachesSingle from 'screens/Coaches/CoachesSingle';
import AddCoachDialog from 'screens/Coaches/modals/AddCoachDialog';
import Dashboard from 'screens/Dashboard';
import Documents from 'screens/Documents';
import GroupsList from 'screens/Groups/GroupsList';
import GroupsSingle from 'screens/Groups/GroupsSingle';
import Multimedia from 'screens/Multimedia';
import News from 'screens/News';
import AddGallery from 'screens/News/modals/AddGallery';
import AddInfo from 'screens/News/modals/AddInfo';
import AddNews from 'screens/News/modals/AddNews';
import DeleteNews from 'screens/News/modals/DeleteNews';
import GalleryDialog from 'screens/News/modals/Gallery';
import NewsDialog from 'screens/News/modals/News';
import AddParentDialog from 'screens/Parents/modals/AddParentDialog';
import ParentsList from 'screens/Parents/ParentsList';
import ParentsSingle from 'screens/Parents/ParentsSingle';
import Payments from 'screens/Payments';
import AddPlayerDialog from 'screens/Players/modals/AddPlayerDialog';
import PlayersList from 'screens/Players/PlayersList';
import PlayersSingle from 'screens/Players/PlayersSingle';
import Settings from 'screens/Settings';
import Tests from 'screens/Tests';
import Venues from 'screens/Venues/VenuesList';
import VenuesSingle from 'screens/Venues/VenuesSingle';

import ErrorBoundary from '../ErrorBoundary';

const Routing = ({ location }) => (
    <ErrorBoundary>
        <Switch location={location}>
            <Route path="/auth" component={Auth} />
            <Route path="/calendar" exact component={CalendarList} />
            <Route path="/calendar/:id" component={CalendarSingle} />
            <Route path="/coaches" exact component={CoachesList} />
            <Route path="/coaches/:id" component={CoachesSingle} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/documents" component={Documents} />
            <Route path="/groups" exact component={GroupsList} />
            <Route path="/groups/:id" component={GroupsSingle} />
            <Route path="/multimedia" component={Multimedia} />
            <Route path="/news" component={News} />
            <Route path="/parents" exact component={ParentsList} />
            <Route path="/parents/:id" component={ParentsSingle} />
            <Route path="/payments" component={Payments} />
            <Route path="/players" exact component={PlayersList} />
            <Route path="/players/:id" component={PlayersSingle} />
            <Route path="/settings" component={Settings} />
            <Route path="/tests" component={Tests} />
            <Route path="/venues" exact component={Venues} />
            <Route path="/venues/:id" component={VenuesSingle} />

            <Route component={Auth} />
        </Switch>
        <AddEventDialog />
        <NewsDialog />
        <GalleryDialog />
        <AddNews />
        <AddInfo />
        <AddGallery />
        <DeleteNews />
        <AddParentDialog />
        <AddPlayerDialog />
        <AddCoachDialog />
    </ErrorBoundary>
);

Routing.propTypes = {
    location: PropTypes.object.isRequired,
};

export default React.memo(Routing);
