import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DecadeView from './DecadeView';

class DecadeViewContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            decade:
                props.value.getFullYear() - (props.value.getFullYear() % 10),
            direction: 'center',
        };
        document.addEventListener('keyup', this.keyupHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.keyupHandler);
    }

    changeHandler(date) {
        this.props.onChange(date);
    }

    keyupHandler = e => {
        if ((e.keyCode || e.which) === 37) {
            this.prevYear();
        } else if ((e.keyCode || e.which) === 38) {
            return;
        } else if ((e.keyCode || e.which) === 39) {
            this.nextYear();
        } else if ((e.keyCode || e.which) === 40) {
            return;
        }
    };

    nextDecade = () => {
        this.setState(state => ({
            decade: state.decade + 10,
            direction: 'left',
        }));
    };

    prevDecade = () => {
        this.setState(state => ({
            decade: state.decade - 10,
            direction: 'right',
        }));
    };

    openYear = year => {
        this.props.setMode('year', new Date(year, 0));
    };

    render() {
        const props = {
            value: this.props.value,
            decade: this.state.decade,
            direction: this.state.direction,
            onNext: this.nextDecade,
            onPrev: this.prevDecade,
            openYear: this.openYear,
        };

        return <DecadeView {...props} />;
    }
}

DecadeViewContainer.propTypes = {
    onChange: PropTypes.func,
};

DecadeViewContainer.defaultProps = {
    onChange: undefined,
};

export default DecadeViewContainer;
