import { useQuery } from '@tanstack/react-query';

import eventsService from 'services/eventsService.js';

export const useCalendarListQuery = (params = {}) => {
    return useQuery(
        ['events', 'list', params],
        () => eventsService.query({ ...params, offset: 0, limit: 100 }),
        {
            initialData: [],
        },
    );
};

export const useCalendarEventQuery = id => {
    return useQuery(['events', 'event', id], () => eventsService.get(id), {
        initialData: null,
    });
};

export const useCalendarEventCyclicalQuery = id => {
    return useQuery(
        ['events', 'event-cyclical', id],
        () => eventsService.cyclicalGet(id),
        {
            initialData: null,
        },
    );
};
