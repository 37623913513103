import { useMutation, useQueryClient } from '@tanstack/react-query';

import useUI from 'contexts/useUI';
import groupsService from 'services/groupsService.js';

export const useMutationGroupAdd = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(groupsService.post, {
        onSuccess: data => {
            toastPush({
                title: 'Dodano grupę',
                text: `${data.name}`,
            });

            queryClient.invalidateQueries(['groups', 'list']);
            queryClient.invalidateQueries(['groups', 'single', data.id]);
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationGroupPatch = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(({ id, delta }) => groupsService.patch(id, delta), {
        onSuccess: (data, { id }) => {
            toastPush({
                title: 'Wyedytowano grupę',
                text: `${data.name}`,
            });

            queryClient.invalidateQueries(['groups', 'list']);
            queryClient.invalidateQueries(['groups', 'single', id]);
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationGroupDelete = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(groupsService.delete, {
        onSuccess: data => {
            toastPush({
                title: 'Usunięto grupę',
            });

            queryClient.invalidateQueries(['groups', 'list']);
            queryClient.invalidateQueries(['groups', 'single', data.id]);
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationGroupAddPlayer = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idGroup, idPlayer }) => groupsService.addPlayer(idGroup, idPlayer),
        {
            onSuccess: (data, { idGroup, idPlayer }) => {
                toastPush({
                    title: 'Dodano zawodnika do grupy',
                });

                queryClient.invalidateQueries(['players', 'list']);
                queryClient.invalidateQueries(['players', 'single', idPlayer]);
                queryClient.invalidateQueries(['groups', 'list']);
                queryClient.invalidateQueries(['groups', 'single', idGroup]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationGroupRemovePlayer = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idGroup, idPlayer }) =>
            groupsService.removePlayer(idGroup, idPlayer),
        {
            onSuccess: (data, { idGroup, idPlayer }) => {
                toastPush({
                    title: 'Usunięto zawodnika z grupy',
                });

                queryClient.invalidateQueries(['players', 'list']);
                queryClient.invalidateQueries(['players', 'single', idPlayer]);
                queryClient.invalidateQueries(['groups', 'list']);
                queryClient.invalidateQueries(['groups', 'single', idGroup]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationGroupAddCoach = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idGroup, idCoach }) => groupsService.addCoach(idGroup, idCoach),
        {
            onSuccess: (data, { idGroup, idCoach }) => {
                toastPush({
                    title: 'Dodano trenera do grupy',
                });

                queryClient.invalidateQueries(['coaches', 'list']);
                queryClient.invalidateQueries(['coaches', 'single', idCoach]);
                queryClient.invalidateQueries(['groups', 'list']);
                queryClient.invalidateQueries(['groups', 'single', idGroup]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationGroupRemoveCoach = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idGroup, idCoach }) => groupsService.removeCoach(idGroup, idCoach),
        {
            onSuccess: (data, { idGroup, idCoach }) => {
                toastPush({
                    title: 'Usunięto trenera z grupy',
                });

                queryClient.invalidateQueries(['coaches', 'list']);
                queryClient.invalidateQueries(['coaches', 'single', idCoach]);
                queryClient.invalidateQueries(['groups', 'list']);
                queryClient.invalidateQueries(['groups', 'single', idGroup]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};
