import { NOTES_MAP } from '../../../../constants/notes.js';

import styles from './AddSideNote.module.scss';
const NotePill = ({ noteId, onClick }) => (
    <div className={styles.notePill} onClick={onClick}>
        <span>{NOTES_MAP[noteId].emoji}</span>
        {NOTES_MAP[noteId].label}
    </div>
);
export default NotePill;
