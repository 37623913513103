import { useHistory } from 'react-router-dom';

import useAuth from 'contexts/useAuth';
import { Avatar, Column, Icon, RoleTag } from 'ui';

import styles from './RoleSelection.module.scss';

const Role = ({ id, name, surname, avatar, email, role }) => {
    const { selectRole } = useAuth();
    const history = useHistory();

    const selectRoleFunc = async () => {
        await selectRole(id);
        history.push(localStorage.getItem('entryPoint') || '/dashboard');
    };

    return (
        <div className={styles.role} onClick={selectRoleFunc}>
            <div className={styles.left}>
                <div className={styles.avatarTransform}>
                    <Avatar
                        text={`${name} ${surname}`}
                        picture={avatar}
                        size="xl"
                    />
                </div>
            </div>
            <div className={styles.center}>
                {name || surname ? (
                    <div className={styles.name}>
                        {name}
                        <strong>{surname}</strong>
                    </div>
                ) : (
                    <div className={styles.name}>{email}</div>
                )}
                <div className={styles.sub}>
                    <RoleTag role={role} size="s" />
                </div>
            </div>
            <div className={styles.right}>
                <Icon icon="circle-right" size="m" />
            </div>
        </div>
    );
};

const School = ({ name, avatar, roles }) => (
    <div className={styles.school}>
        <div className={styles.schoolHeader}>
            <div className={styles.avatar}>
                <Avatar text={name} shape="square" size="m" picture={avatar} />
            </div>
            {name}
        </div>
        <div className={styles.card}>
            {roles.map(role => (
                <Role {...role} key={role.id} />
            ))}
        </div>
    </div>
);

const RoleSelection = () => {
    const { schools } = useAuth();

    return (
        <div className={styles.view}>
            <div className={styles.header}>Zaloguj jako:</div>
            <div className={styles.list}>
                <Column gap="m">
                    {schools.map(school => (
                        <School {...school} key={school._id} />
                    ))}
                </Column>
            </div>
        </div>
    );
};

export default RoleSelection;
