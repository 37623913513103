import { useEffect } from 'react';

import { ROLES } from 'constants/logic';
import useAuth from 'contexts/useAuth';
import ViewHOC from 'screens/ViewHOC';

import { version } from '../../../package.json';
import useUI from '../../contexts/useUI.js';

import DashboardAdmin from './DashboardAdmin';
import DashboardCoach from './DashboardCoach';

const Dashboard = () => {
    const { me } = useAuth();
    const { modalOpen } = useUI();

    useEffect(() => {
        const lastVersion = localStorage.getItem('lastVersion');

        if (lastVersion !== version) {
            localStorage.setItem('lastVersion', version);
            modalOpen('newVersion');
        }
    }, []);

    switch (me.role) {
        case ROLES.OWNER: {
            return <DashboardAdmin />;
        }
        case ROLES.ADMIN: {
            return <DashboardAdmin />;
        }
        case ROLES.COACH: {
            return <DashboardCoach />;
        }
        default: {
            return <DashboardAdmin />;
        }
    }
};

export default ViewHOC(Dashboard, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
