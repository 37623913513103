import { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip/index.js';

import styles from './Input.module.scss';

const Input = ({
    type,
    error,
    value,
    onChange,
    onBlur,
    onFocus,
    autocomplete,
    disabled,
    isFocused,
    isTouched,
}) => {
    // const [passwordVisibility, setPasswordVisibility] = useState(false);

    const changeHandler = ({ target: { value } }) => {
        if (onChange) {
            onChange(value);
        }
    };

    // const showPassword = () => {
    //     setPasswordVisibility(t => !t);
    // };

    const isErrorVisible = useMemo(
        () => error && isTouched && !isFocused,
        [error, isTouched, isFocused],
    );

    return (
        <Tooltip text={error} position="bottom">
            <div
                className={cx(
                    styles.input,
                    isFocused && styles.focused,
                    isErrorVisible && styles.error,
                    disabled && styles.disabled,
                )}>
                <input
                    type={type}
                    value={value}
                    onChange={changeHandler}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoComplete={autocomplete ? autocomplete.join(' ') : ''}
                />
                {isErrorVisible && <div className={styles.error}>{error}</div>}
            </div>
        </Tooltip>
    );
};

Input.propTypes = {
    type: PropTypes.oneOf(['text', 'password']),
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
    autocomplete: PropTypes.array,
};

Input.defaultProps = {
    type: 'text',
    value: '',
    placeholder: undefined,
    error: undefined,
    onChange: undefined,
    onBlur: undefined,
    onFocus: undefined,
    disabled: false,
    autocomplete: undefined,
};

export default Input;
