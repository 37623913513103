import useAuth from 'contexts/useAuth';

const useACL = () => {
    const { me } = useAuth();

    const isMe = id => {
        return id === me.id;
    };

    const isRole = roles => {
        return roles.includes(me.role);
    };

    const isMyGroup = id => {
        if (me.role !== 'coach') return false;
        return !!me.groups.includes(id);
    };

    const isIncludingMyGroup = groups => {
        if (me.role !== 'coach') return false;
        return !!me.groups.find(value => groups.indexOf(value) > -1);
    };

    const isLastDays = (date, days) => {
        const now = new Date();
        const oldDate = new Date(date);
        return now - oldDate < days * 24 * 60 * 60 * 1000;
    };

    return {
        isMe,
        isRole,
        isMyGroup,
        isIncludingMyGroup,
        isLastDays,
    };
};

export default useACL;
