import { useNewsQuery } from 'queries/news.queries.js';
import {
    Column,
    Html,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Row,
    Spinner,
} from 'ui';
import { formatDateFullWithDay } from 'utils/formatters';

import GroupTag from '../../../../ui/GroupTag/index.js';

import styles from './News.module.scss';

const News = ({ data }) => {
    const { data: news } = useNewsQuery(data.id);

    if (!news) {
        return <Spinner active />;
    }

    const {
        data: { picture, title, shortText, longText },
        date,
        groups,
    } = news;

    const pic = !picture.includes('gorniklibiaz.pl')
        ? picture.replace('.jpg', '.large.jpg')
        : picture;
    return (
        <>
            <ModalHeader>{title}</ModalHeader>
            <ModalContent>
                <Column gap="xs">
                    {pic && (
                        <div
                            className={styles.picture}
                            style={{ backgroundImage: `url(${pic})` }}
                        />
                    )}
                    <div className={styles.date}>
                        {formatDateFullWithDay(date)}
                    </div>
                    <Html className={styles.shortText}>{shortText}</Html>
                    <Html className={styles.longText}>{longText}</Html>
                    {groups.length ? (
                        <Row gap={'s'}>
                            {groups.map(g => (
                                <GroupTag group={g} size="m" key={g.id} />
                            ))}
                        </Row>
                    ) : null}
                </Column>
            </ModalContent>
        </>
    );
};

export default ModalHOC(News, {
    id: 'news',
    size: 'medium',
    padding: false,
});
