import { useMemo } from 'react';

import useUI from 'contexts/useUI.js';
import { useMutationEventAdd } from 'queries/events.mutations.js';
import { useGroupsAllQuery } from 'queries/groups.queries.js';
import { useVenuesAllQuery } from 'queries/venues.queries.js';
import {
    Button,
    Column,
    Header,
    Input,
    InputDate,
    InputSelect,
    InputText,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Row,
    Warning,
} from 'ui';
import useForm from 'utils/useForm';
import validatorsUtil from 'utils/validators.util.js';

import {
    generateNiceTitle,
    hoursOptions,
    inviteOptions,
    typeOptions,
} from '../../../calendar.utils.js';

const AddEventSingle = ({
    data: { groupIds, date, timeStart, timeEnd, venueId },
    modalClose,
}) => {
    const { toastPush } = useUI();
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        title: {
            value: 'Trening',
            validators: [validatorsUtil.isRequired()],
        },
        desc: {
            value: '',
        },
        date: {
            value: date || new Date(),
        },
        type: {
            value: 'training',
        },
        timeStart: {
            value: timeStart || '15:0',
        },
        timeEnd: {
            value: timeEnd || '16:30',
        },
        venue: {
            value: venueId || null,
        },
        groups: {
            value: groupIds || [],
        },
        'invite.players': {
            value: 'groups',
        },
        'invite.parents': {
            value: 'none',
        },
        'invite.coaches': {
            value: 'groups',
        },
    });

    const setTypeF = v => {
        if (!form.title.isTouched) {
            const dayOfWeek = new Date(form.date.value).getDay();
            form.title.setValue(
                generateNiceTitle(v, dayOfWeek, form.timeStart.value),
            );
        }
        form.type.setValue(v);
    };

    const setDateF = v => {
        if (!form.title.isTouched) {
            const dayOfWeek = new Date(v).getDay();
            form.title.setValue(
                generateNiceTitle(
                    form.type.value,
                    dayOfWeek,
                    form.timeStart.value,
                ),
            );
        }
        form.date.setValue(v);
    };

    const setTimeStartF = v => {
        if (!form.title.isTouched) {
            const dayOfWeek = new Date(form.date.value).getDay();
            form.title.setValue(
                generateNiceTitle(form.type.value, dayOfWeek, v),
            );
        }
        form.timeStart.setValue(v);
    };

    const { data: groupsFetched } = useGroupsAllQuery();
    const { data: venuesFetched } = useVenuesAllQuery();

    const options = useMemo(
        () => ({
            groups: groupsFetched
                ? groupsFetched.map(v => ({ label: v.name, value: v.id }))
                : [],
            venues: venuesFetched
                ? venuesFetched.map(v => ({ label: v.name, value: v.id }))
                : [],
        }),
        [groupsFetched, venuesFetched],
    );

    const timeValidator = () => {
        const timeStart = Number(form.timeStart.value.replace(':', '.'));
        const timeEnd = Number(form.timeEnd.value.replace(':', '.'));

        if (timeStart > timeEnd) {
            toastPush({
                text: 'Godzina rozpoczęcia nie może być większa niż godzina zakończenia',
            });
            return false;
        }
        return true;
    };

    const mutation = useMutationEventAdd({
        onSuccess: modalClose,
    });

    const addEvent = () => {
        if (!isFormValid) return;

        const form = getFormValue();

        if (!timeValidator()) return;

        const dateStart = new Date(form.date.getTime());
        const dateEnd = new Date(form.date.getTime());

        const timeStartArray = form.timeStart.split(':');
        const timeEndArray = form.timeEnd.split(':');

        dateStart.setHours(Number(timeStartArray[0]));
        dateStart.setMinutes(Number(timeStartArray[1]));

        dateEnd.setHours(Number(timeEndArray[0]));
        dateEnd.setMinutes(Number(timeEndArray[1]));

        mutation.mutate({
            ...form,
            dateStart,
            dateEnd,
        });
    };

    return (
        <>
            <ModalHeader>Nowe wydarzenie pojedyncze</ModalHeader>
            <ModalContent>
                <Row gap="m" align="start">
                    <Column gap="m">
                        <Column gap="xs">
                            <Header title="Informacje o wydarzeniu" size="s" />
                            <div>
                                <Label>Tytuł wydarzenia</Label>
                                <Input {...form.title} />
                            </div>
                            <div>
                                <Label>Rodzaj wydarzenia</Label>
                                <InputSelect
                                    options={typeOptions}
                                    {...form.type}
                                    onChange={setTypeF}
                                />
                            </div>
                        </Column>
                        <Column gap="xs">
                            <Header title="Data i miejsce" size="s" />
                            <div>
                                <Label>Data</Label>
                                <InputDate {...form.date} onChange={setDateF} />
                            </div>
                            <Row gap={'m'}>
                                <Column>
                                    <Label>Godzina rozpoczęcia</Label>
                                    <InputSelect
                                        options={hoursOptions}
                                        {...form.timeStart}
                                        onChange={setTimeStartF}
                                    />
                                </Column>
                                <Column>
                                    <Label>Godzina zakończenia</Label>
                                    <InputSelect
                                        options={hoursOptions}
                                        {...form.timeEnd}
                                    />
                                </Column>
                            </Row>
                            <div>
                                <Label>Obiekt</Label>
                                <InputSelect
                                    options={options.venues}
                                    {...form.venue}
                                />
                            </div>
                            {!form.venue.value && (
                                <div>
                                    <Warning
                                        title="Nie wybrano miejsca wydarzenia"
                                        content="Czy aby na pewno chcesz dodać takie
                                            wydarzenie?"
                                    />
                                </div>
                            )}
                        </Column>
                    </Column>
                    <Column gap="m">
                        <Column gap="xs">
                            <Header title="Grupy" size="s" />
                            <div>
                                <Label>Dodaj do kalendarzy grup</Label>
                                <InputSelect
                                    options={options.groups}
                                    {...form.groups}
                                    multiselect
                                />
                            </div>

                            {!form.groups.value.length && (
                                <div>
                                    <Warning
                                        title="Nie wybrano żadnej grupy"
                                        content=" Czy aby na pewno chcesz dodać takie
                                            wydarzenie"
                                    />
                                </div>
                            )}
                        </Column>
                        <Column gap="xs">
                            <Header title="Uczestnicy" size="s" />
                            <div>
                                <Label>Zaproszeni zawodnicy</Label>
                                <InputSelect
                                    options={inviteOptions}
                                    {...form['invite.players']}
                                />
                            </div>
                            <div>
                                <Label>Zaproszeni rodzice</Label>
                                <InputSelect
                                    options={inviteOptions}
                                    {...form['invite.parents']}
                                />
                            </div>
                            <div>
                                <Label>Zaproszeni trenerzy</Label>
                                <InputSelect
                                    options={inviteOptions}
                                    {...form['invite.coaches']}
                                />
                            </div>
                        </Column>
                        <Column gap="xs">
                            <Header title="Dodatkowe informacje" size="s" />
                            <div>
                                <Label>Opis wydarzenia</Label>
                                <InputText {...form.desc} />
                            </div>
                        </Column>
                    </Column>
                </Row>
            </ModalContent>
            <ModalActions>
                <Button
                    color="green"
                    onClick={addEvent}
                    onDisabledClick={touchForm}
                    disabled={!isFormValid}>
                    Utwórz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AddEventSingle, {
    id: 'addEventSingle',
    size: 'large',
});
