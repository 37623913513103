import { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon, Row } from 'ui';

import style from './PresenceSwitch.module.scss';

const PresenceSwitch = ({ value, onChange }) => {
    const [val, setVal] = useState(value);

    const change = val => {
        setVal(val);
        onChange(val);
    };

    useEffect(() => {
        setVal(value);
    }, [value]);

    return (
        <div className={style.root}>
            <Row>
                <div className={style.option} onClick={() => change('absent')}>
                    <Icon
                        icon="close"
                        size="s"
                        color={val === 'absent' ? 'white' : 'grey-500'}
                    />
                </div>
                <div className={style.option} onClick={() => change(null)} />
                <div className={style.option} onClick={() => change('present')}>
                    <Icon
                        icon="ok"
                        size="s"
                        color={val === 'present' ? 'white' : 'grey-500'}
                    />
                </div>
            </Row>
            <div className={cx([style.pill, style[`value-${val}`]])} />
        </div>
    );
};

PresenceSwitch.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

PresenceSwitch.defaultProps = {
    value: null,
};

export default PresenceSwitch;
