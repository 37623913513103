import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import groupsService from '../services/groupsService.js';

export const useGroupsAllQuery = () => {
    return useQuery(['groups', 'list-all'], () => groupsService.query(), {
        initialData: [],
    });
};

export const useGroupQuery = id => {
    return useQuery(['groups', 'single', id], () => groupsService.get(id), {
        enabled: !!id,
        initialData: null,
    });
};

export const useGroupEventsQuery = (id, params) => {
    return useQuery(
        ['groups', 'single', id, 'events', params],
        () => groupsService.getEvents(id, params),
        {
            enabled: !!id,
            initialData: [],
        },
    );
};

export const useGroupEventsCyclicalQuery = id => {
    return useQuery(
        ['groups', 'single', id, 'events-cyclical'],
        () => groupsService.getEventsCyclical(id),
        {
            enabled: !!id,
            initialData: [],
        },
    );
};

export const useGroupCoachesQuery = id => {
    return useQuery(
        ['groups', 'single', id, 'coaches'],
        () => groupsService.getCoaches(id),
        {
            enabled: !!id,
            initialData: [],
        },
    );
};

export const useGroupPlayersQuery = id => {
    return useQuery(
        ['groups', 'single', id, 'players'],
        () => groupsService.getPlayers(id),
        {
            enabled: !!id,
            initialData: [],
        },
    );
};

export const useGroupSettingsQuery = id => {
    return useQuery(
        ['groups', 'single', id, 'settings'],
        () => groupsService.getSettings(id),
        {
            enabled: !!id,
            initialData: [],
        },
    );
};

export const useGroupFeedQuery = id => {
    return useInfiniteQuery(
        ['group', id, 'feed'],
        ({ pageParam }) => {
            return groupsService.getFeed(id, {
                limit: 10,
                offset: pageParam,
            });
        },
        {
            getNextPageParam: (a, b) => {
                if (!a.length) return undefined;
                return b.flat(1).length;
            },
        },
    );
};

export const useGroupGalleriesQuery = id => {
    return useInfiniteQuery(
        ['group', id, 'galleries'],
        ({ pageParam }) => {
            return groupsService.getGalleries(id, {
                limit: 10,
                offset: pageParam,
            });
        },
        {
            getNextPageParam: (a, b) => {
                if (!a.length) return undefined;
                return b.flat(1).length;
            },
        },
    );
};

export const useGroupsListQuery = () => {
    return useInfiniteQuery(
        ['groups', 'list'],
        ({ pageParam }) => {
            return groupsService.query({
                limit: 10,
                offset: pageParam,
            });
        },
        {
            getNextPageParam: (a, b) => {
                if (!a.length) return undefined;
                return b.flat(1).length;
            },
        },
    );
};
