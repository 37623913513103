import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { Button, Card, Column, Html, Row, Tag } from 'ui';
import useACL from 'utils/useACL';

import GroupTag from '../../../ui/GroupTag/index.js';

import styles from './NewsCard.module.scss';

const NewsCard = ({
    id,
    data: { title, shortText, picture },
    author: { id: authorId },
    groups,
    editable,
}) => {
    const { isMe, isRole } = useACL();
    const { modalOpen } = useUI();
    const open = () => {
        modalOpen('news', { id });
    };
    const openEdit = () => modalOpen('addNews', { id });
    const isEditable =
        editable && (isRole([ROLES.OWNER, ROLES.ADMIN]) || isMe(authorId));

    return (
        <Card onEdit={isEditable ? openEdit : null}>
            <Column gap="s">
                <Row>
                    <Tag size="m" color="green">
                        Aktualność
                    </Tag>
                </Row>
                {title && (
                    <Row>
                        <div className={styles.title}>{title}</div>
                    </Row>
                )}
                {groups.length ? (
                    <Row gap="xxs">
                        {groups.map(g => (
                            <GroupTag group={g} size="s" key={g.id} />
                        ))}
                    </Row>
                ) : null}
                {picture && (
                    <div
                        className={styles.picture}
                        onClick={open}
                        style={{
                            background: `url(${picture})`,
                        }}
                    />
                )}

                {shortText && (
                    <Row>
                        <Html className={styles.text}>{shortText}</Html>
                    </Row>
                )}

                <Row alignContent={'end'}>
                    <Button size="l" onClick={open} color="green">
                        Czytaj więcej
                    </Button>
                </Row>
            </Column>
        </Card>
    );
};

export default NewsCard;
