import { useRouteMatch } from 'react-router-dom';

import EmptyCard from 'components/EmptyCard';
import Feed from 'components/Feed';
import useUI from 'contexts/useUI';
import { useGroupGalleriesQuery } from 'queries/groups.queries.js';
import AddGallery from 'screens/News/modals/AddGallery';
import { Button, Column, Header, Spinner } from 'ui';

const GalleriesGroup = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const { modalOpen } = useUI();

    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetched,
    } = useGroupGalleriesQuery(id);

    if (!isFetched) return <Spinner active />;

    const galleries = data.pages.flat(1);
    return (
        <Column gap="m">
            <Header title="Galerie" size="l">
                <Button
                    size={'l'}
                    color={'green'}
                    onClick={() => modalOpen('addGallery', { groups: [id] })}>
                    Nowa galeria
                </Button>
            </Header>
            {!galleries.length && (
                <EmptyCard
                    action={() => modalOpen('addGallery', { groups: [id] })}
                    actionLabel="Dodaj pierwszą galerię"
                />
            )}
            <Feed
                items={galleries}
                load={fetchNextPage}
                isMore={!isFetchingNextPage && hasNextPage}
            />
            <AddGallery />
        </Column>
    );
};

export default GalleriesGroup;
