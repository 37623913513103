import { Column, InfiniteLoader } from 'ui';
import { formatDateFullWithDay } from 'utils/formatters';

import FeedCard from './FeedCard';

import styles from './Feed.module.scss';

const Feed = ({ items, load, editable, isMore }) => {
    let date = '';
    return (
        <Column gap="l">
            {items.map(i => {
                const dateString = i.date.slice(0, 10);
                if (dateString !== date) {
                    date = dateString;
                    return (
                        <Column gap={'s'} key={i.id}>
                            <div className={styles.date}>
                                {formatDateFullWithDay(i.date)}
                            </div>
                            <FeedCard editable={editable} {...i} />
                        </Column>
                    );
                }
                return <FeedCard key={i.id} editable={editable} {...i} />;
            })}
            {isMore && <InfiniteLoader onVisible={load} />}
        </Column>
    );
};

Feed.defaultProps = {
    editable: false,
    data: [],
};

export default Feed;
