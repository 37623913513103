import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { EVENTS_TYPES_MAP, ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useCalendarEventQuery } from 'queries/events.queries.js';
import ViewHOC from 'screens/ViewHOC';
import { AnimatedTransition, Column, Container, Head, Row, Spinner } from 'ui';
import { formatDateFullWithDay, formatHour } from 'utils/formatters';
import useACL from 'utils/useACL';

import GroupTag from '../../../ui/GroupTag/index.js';
import PercentCircle from '../../../ui/PercentCircle/index.js';
import DeleteEventDialog from '../modals/DeleteEventDialog';
import EditEventDialog from '../modals/EditEventDialog';

import Info from './Info';
import MapVenue from './MapVenue';
import Participants from './Participants';

const CalendarSingle = () => {
    const location = useLocation();
    const {
        params: { id },
    } = useRouteMatch();
    const { isRole, isIncludingMyGroup } = useACL();

    const { modalOpen } = useUI();

    const { data } = useCalendarEventQuery(id);

    if (!data) {
        return <Spinner active />;
    }

    const hasRights =
        isRole([ROLES.OWNER, ROLES.ADMIN]) ||
        isIncludingMyGroup(data.groups.map(g => g.id));

    return (
        <>
            <Container>
                <Column gap={'m'}>
                    <Head
                        title={data.title || EVENTS_TYPES_MAP[data.type].name}
                        subtitle={`${formatDateFullWithDay(
                            data.dateStart,
                        )}, ${formatHour(data.dateStart)}`}
                        avatar={{
                            text: '  ',
                        }}
                        tabs={[
                            {
                                content: 'Informacje',
                                link: `/calendar/${data.id}`,
                            },
                            {
                                content: (
                                    <Row align="center" gap="xxxs">
                                        <span>Uczestnicy</span>
                                        <PercentCircle
                                            percent={data.attendance}
                                            size="s"
                                        />
                                    </Row>
                                ),
                                link: `/calendar/${data.id}/participants`,
                            },
                            {
                                content: 'Mapa dojazdu',
                                link: `/calendar/${data.id}/map`,
                            },
                        ]}
                        actions={[
                            {
                                text: 'Edytuj wydarzenie',
                                icon: 'edit',
                                onClick: hasRights
                                    ? () => modalOpen('editEvent', { id })
                                    : null,
                            },
                            {
                                text: 'Usuń wydarzenie',
                                color: 'red',
                                icon: 'delete',
                                onClick: hasRights
                                    ? () => modalOpen('deleteEvent', { id })
                                    : null,
                            },
                        ]}
                        rightChildren={
                            data.groups && data.groups.length
                                ? data.groups.map(g => (
                                      <GroupTag group={g} size="m" key={g.id} />
                                  ))
                                : null
                        }
                    />
                    <AnimatedTransition k={location.pathname}>
                        <Switch location={location}>
                            <Route
                                exact
                                path="/calendar/:id/"
                                component={Info}
                            />
                            <Route
                                exact
                                path="/calendar/:id/participants"
                                component={Participants}
                            />
                            <Route
                                exact
                                path="/calendar/:id/map"
                                component={MapVenue}
                            />
                            <Route component={Info} />
                        </Switch>
                    </AnimatedTransition>
                </Column>
            </Container>
            <DeleteEventDialog />
            <EditEventDialog />
        </>
    );
};
export default ViewHOC(CalendarSingle, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
