import {Column, Header} from 'ui';

import NotReady from '../../../../components/NotReady/index.js';

// import {getGroupSettingsQuery} from "queries/groups.queries.js";

const Settings = () => {
    // const {
    //     params: { id },
    // } = useRouteMatch();
    // const { data: settings } = getGroupSettingsQuery(id);

    return (
        <Column gap="m">
            <Header title="Ustawienia grupy" size="l" />
            <NotReady />
        </Column>
    );
};

export default Settings;
