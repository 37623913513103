import { useRouteMatch } from 'react-router-dom';

import EmptyCard from 'components/EmptyCard';
import useUI from 'contexts/useUI';
import { useMutationParentRemovePlayer } from 'queries/parents.mutations.js';
import { useParentPlayersQuery } from 'queries/parents.queries.js';
import { Button, Column, Header, List, ListActions, Spinner } from 'ui';

import ListDataCell from '../../../../components/ListDataCell/index.js';

import AddPlayers from './modals/AddPlayers';

const Players = () => {
    const {
        params: { id },
    } = useRouteMatch();
    const { data: players, isFetched } = useParentPlayersQuery(id);
    const { modalOpen } = useUI();
    const addPlayersToParent = () =>
        modalOpen('addPlayersToParent', { idParent: id });
    const mutationRemovePlayer = useMutationParentRemovePlayer();
    const removePlayerFromParent = idPlayer => {
        mutationRemovePlayer.mutate({ idParent: id, idPlayer });
    };

    if (!isFetched) return <Spinner active />;

    return (
        <>
            <Column gap="m">
                <Header title="Podopieczni" size="l">
                    <Button
                        size={'l'}
                        color={'green'}
                        onClick={addPlayersToParent}>
                        Dodaj podopiecznego
                    </Button>
                </Header>
                {isFetched && !players.length ? (
                    <EmptyCard
                        action={addPlayersToParent}
                        actionLabel="Dodaj pierwszego podopiecznego"
                    />
                ) : (
                    <List
                        data={players || []}
                        renderRow={({
                            id: idPlayer,
                            name,
                            surname,
                            age,
                            avatar,
                            birthday,
                            groups,
                        }) => {
                            return (
                                <>
                                    <ListDataCell.User
                                        user={{
                                            name,
                                            surname,
                                            avatar,
                                            id: idPlayer,
                                            role: 'player',
                                        }}
                                    />
                                    <ListDataCell.Age
                                        age={age}
                                        date={birthday}
                                    />
                                    <ListDataCell.Groups groups={groups} />
                                    <ListActions
                                        actions={[
                                            {
                                                title: 'Usuń',
                                                icon: 'delete',
                                                color: 'red',
                                                onClick: () => {
                                                    removePlayerFromParent(
                                                        idPlayer,
                                                    );
                                                },
                                            },
                                        ]}
                                    />
                                </>
                            );
                        }}
                    />
                )}
            </Column>
            <AddPlayers />
        </>
    );
};

export default Players;
