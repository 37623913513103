import { Motion, spring } from 'react-motion';
import PropTypes from 'prop-types';

const TutorialBackground = ({ screenWidth, screenHeight, element }) => {
    const bgDefault = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
            width: 0,
            height: 0,
        },
        bgStyle = {
            x: spring(element.x),
            y: spring(element.y),
            width: spring(element.width),
            height: spring(element.height),
        };
    return (
        <Motion defaultStyle={bgDefault} style={bgStyle}>
            {({ x, y, width, height }) => (
                <svg width={screenWidth} height={screenHeight}>
                    <defs>
                        <mask id="hole">
                            <rect
                                width={screenWidth}
                                height={screenHeight}
                                fill="white"
                            />
                            <rect
                                width={width}
                                height={height}
                                x={x}
                                y={y}
                                strokeWidth="20"
                                strokeLinejoin="round"
                                stroke="black"
                                fill="black"
                            />
                        </mask>
                    </defs>
                    <rect
                        width={screenWidth}
                        height={screenHeight}
                        mask="url(#hole)"
                        fill="rgba(4, 102, 79, 0.66)"
                    />
                </svg>
            )}
        </Motion>
    );
};

TutorialBackground.propTypes = {
    screenWidth: PropTypes.number.isRequired,
    screenHeight: PropTypes.number.isRequired,
    element: PropTypes.object.isRequired,
};

export default TutorialBackground;
