import { useHistory, useLocation } from 'react-router-dom';

import { useMutationEventDelete } from 'queries/events.mutations.js';
import { Button, ModalActions, ModalContent, ModalHeader, ModalHOC } from 'ui';

const DeleteEventDialog = ({ data: { id }, modalClose }) => {
    const location = useLocation();
    const history = useHistory();

    const mutation = useMutationEventDelete({
        onSuccess: () => {
            modalClose();
            if (location.pathname.includes(`/calendar/${id}`)) {
                history.push('/calendar');
            }
        },
    });

    const deleteEvent = () => {
        mutation.mutate(id);
    };

    return (
        <>
            <ModalHeader>Usuń wydarzenie</ModalHeader>
            <ModalContent>Czy na pewno chcesz usunąć wydarzenie?</ModalContent>
            <ModalActions>
                <Button color="red" onClick={deleteEvent}>
                    Usuń
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DeleteEventDialog, {
    id: 'deleteEvent',
    size: 'small',
});
