import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Avatar, Row, Tooltip } from '../index.js';
import Tag from '../Tag';

const GroupTag = ({ group, size, fullName }) => {
    if (!group) return null;
    return (
        <Link to={`/groups/${group.id}`}>
            <Row gap={'xxxs'}>
                <Tooltip text={group.name}>
                    <Avatar
                        text={group.avatar.slice(7, 9)}
                        shape="shield"
                        size={size}
                        color={group.avatar.slice(0, 7)}
                    />
                </Tooltip>
                {fullName && <Tag text={group.name} size={size} />}
            </Row>
        </Link>
    );
};

GroupTag.propTypes = {
    role: PropTypes.string,
    fullName: PropTypes.bool,
};
GroupTag.defaultProps = {
    role: '',
    fullName: true,
};

export default GroupTag;
