import { useMutation, useQueryClient } from '@tanstack/react-query';

import useUI from 'contexts/useUI';
import eventsService from 'services/eventsService.js';

export const useMutationEventAdd = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(eventsService.post, {
        onSuccess: ({ name, groups, venue }) => {
            toastPush({
                title: 'Dodano wydarzenie',
                text: name,
            });

            queryClient.invalidateQueries(['events']);
            if (groups) {
                groups.forEach(idGroup => {
                    queryClient.invalidateQueries([
                        'groups',
                        'single',
                        idGroup,
                        'events',
                    ]);
                });
            }

            if (venue) {
                queryClient.invalidateQueries([
                    'venues',
                    'single',
                    venue,
                    'events',
                ]);
            }

            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationEventPatch = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(({ id, delta }) => eventsService.patch(id, delta), {
        onSuccess: data => {
            toastPush({
                title: 'Wyedytowano wydarzenie',
                text: data.name,
            });

            queryClient.invalidateQueries(['events']);
            if (data.groups) {
                data.groups.forEach(idGroup => {
                    queryClient.invalidateQueries([
                        'groups',
                        'single',
                        idGroup,
                        'events',
                    ]);
                });
            }

            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationEventDelete = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(eventsService.delete, {
        onSuccess: () => {
            toastPush({
                title: 'Usunięto wydarzenie',
            });

            queryClient.invalidateQueries(['events']);
            queryClient.invalidateQueries(['groups', 'single']);
            queryClient.invalidateQueries(['venues', 'single']);

            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationEventCyclicalAdd = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(eventsService.cyclicalPost, {
        onSuccess: ({ name, groups }) => {
            toastPush({
                title: 'Dodano wydarzenie cykliczne',
                text: name,
            });

            queryClient.invalidateQueries(['events']);
            if (groups) {
                groups.forEach(idGroup => {
                    queryClient.invalidateQueries([
                        'groups',
                        'single',
                        idGroup,
                        'events',
                    ]);
                    queryClient.invalidateQueries([
                        'groups',
                        'single',
                        idGroup,
                        'events-cyclical',
                    ]);
                });
            }

            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationEventCyclicalPatch = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ id, delta }) => eventsService.cyclicalPatch(id, delta),
        {
            onSuccess: data => {
                toastPush({
                    title: 'Wyedytowano wydarzenie cykliczne',
                    text: data.name,
                });

                queryClient.invalidateQueries(['events']);

                if (data.groups) {
                    data.groups.forEach(idGroup => {
                        queryClient.invalidateQueries([
                            'groups',
                            'single',
                            idGroup,
                            'events',
                        ]);
                        queryClient.invalidateQueries([
                            'groups',
                            'single',
                            idGroup,
                            'events-cyclical',
                        ]);
                    });
                }

                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationEventCyclicalDelete = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(eventsService.cyclicalDelete, {
        onSuccess: () => {
            toastPush({
                title: 'Usunięto wydarzenie cykliczne',
            });

            queryClient.invalidateQueries(['events']);
            queryClient.invalidateQueries(['groups', 'single']);

            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationEventStatusesPatch = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ idEvent, idUser, status }) => {
            if (status === 'present') {
                return eventsService.setStatusPresent({ idEvent, idUser });
            }
            if (status === 'absent') {
                return eventsService.setStatusAbsent({ idEvent, idUser });
            }
            return eventsService.deleteStatus({ idEvent, idUser });
        },
        {
            onSuccess: data => {
                queryClient.setQueryData(['events', 'event', data.id], d => {
                    return {
                        ...d,
                        participantsStatuses: data.participantsStatuses,
                    };
                });

                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationEventNotePut = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ idEvent, idUser, idNote }) =>
            eventsService.togleNote({ idEvent, idUser, idNote }),
        {
            onSuccess: data => {
                queryClient.setQueryData(['events', 'event', data.id], d => {
                    return {
                        ...d,
                        participantsNotes: data.participantsNotes,
                    };
                });

                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationEventParticipantAdd = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ idEvent, idParticipant }) =>
            eventsService.addParticipant(idEvent, idParticipant),
        {
            onSuccess: (_, { idEvent }) => {
                queryClient.invalidateQueries(['events', 'event', idEvent]);

                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationEventParticipantDelete = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ idEvent, idParticipant }) =>
            eventsService.deleteParticipant(idEvent, idParticipant),
        {
            onSuccess: (_, { idEvent }) => {
                queryClient.invalidateQueries(['events', 'event', idEvent]);

                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};
