import { useRouteMatch } from 'react-router-dom';

import CalendarAgendaView from 'components/CalendarAgendaView';
import { useParentEventsQuery } from 'queries/parents.queries.js';
import { Column, Header, Spinner } from 'ui';

const Calendar = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const { data, isFetched } = useParentEventsQuery(id);

    if (!isFetched) return <Spinner active />;

    return (
        <Column gap="m">
            <Header title="Kalendarz" size="l" />
            <CalendarAgendaView events={data} />
        </Column>
    );
};

export default Calendar;
