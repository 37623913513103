import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import { Icon } from 'ui';

import style from './MenuItem.module.scss';

const MenuItem = ({ title, icon, link }) => (
    <NavLink
        to={link}
        className={cx(style.menuItem)}
        activeClassName={style.active}>
        <div className={style.icon}>
            <Icon icon={icon} size="l" />
        </div>
        <div className={style.title}>
            <span>{title}</span>
        </div>
    </NavLink>
);

export default MenuItem;
