import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useGroupQuery } from 'queries/groups.queries.js';
import {
    AnimatedTransition,
    Column,
    Container,
    Head,
    Spinner,
    Tutorial,
} from 'ui';
import useACL from 'utils/useACL';

import ViewHOC from '../../ViewHOC';
import AddEditGroupDialog from '../modals/AddEditGroupDialog';
import DeleteGroupDialog from '../modals/DeleteGroupDialog';

import AvatarGroupDialog from './Info/modals/AvatarGroupDialog/index.js';
import Attendance from './Attendance';
import Calendar from './Calendar';
import Feed from './Feed';
import Galleries from './Galleries';
import tutorial from './GroupsSingle.tutorial.json';
import Info from './Info';
import Players from './Players';
import Settings from './Settings';

const GroupsSingle = () => {
    const location = useLocation();
    const {
        params: { id },
    } = useRouteMatch();

    const { isRole, isMyGroup } = useACL();

    const { modalOpen } = useUI();

    const openDeleteGroupDialog = () => {
        modalOpen('deleteGroup', { id });
    };

    const { data: group } = useGroupQuery(id);

    if (!group) {
        return <Spinner active />;
    }
    return (
        <>
            <Container>
                <Column gap={'m'}>
                    <Head
                        title={group.name}
                        subtitle={group.subname}
                        avatar={{
                            shape: 'shield',
                            text: group.avatar.slice(7, 9),
                            color: group.avatar.slice(0, 7),
                        }}
                        tabs={[
                            {
                                content: 'Informacje',
                                link: `/groups/${group.id}`,
                            },
                            {
                                content: 'Komunikacja',
                                link: `/groups/${group.id}/feed`,
                            },
                            {
                                content: 'Kalendarz',
                                link: `/groups/${group.id}/calendar`,
                            },
                            {
                                content: 'Zawodnicy',
                                link: `/groups/${group.id}/players`,
                                counter: group.players
                                    ? group.players.length
                                    : null,
                            },
                            {
                                content: 'Frekwencja',
                                link: `/groups/${group.id}/attendance`,
                            },
                            {
                                content: 'Galerie',
                                link: `/groups/${group.id}/galleries`,
                            },
                            {
                                content: 'Ustawienia',
                                link: `/groups/${group.id}/settings`,
                                disabled:
                                    !isRole([ROLES.OWNER, ROLES.ADMIN]) &&
                                    !isMyGroup(group.id),
                            },
                        ]}
                        actions={[
                            {
                                text: 'Edytuj avatar',
                                icon: 'edit',
                                onClick: isRole([ROLES.OWNER, ROLES.ADMIN])
                                    ? () => modalOpen('avatarGroup', { id })
                                    : null,
                            },
                            {
                                text: 'Usuń grupę',
                                color: 'red',
                                icon: 'delete',
                                onClick:
                                    isRole([ROLES.OWNER, ROLES.ADMIN]) &&
                                    openDeleteGroupDialog,
                            },
                        ]}
                    />
                    <AnimatedTransition k={location.pathname}>
                        <Switch location={location}>
                            <Route exact path="/groups/:id/" component={Info} />
                            <Route
                                exact
                                path="/groups/:id/players"
                                component={Players}
                            />
                            <Route
                                exact
                                path="/groups/:id/attendance"
                                component={Attendance}
                            />
                            <Route
                                exact
                                path="/groups/:id/calendar"
                                render={props => (
                                    <Calendar {...props} group={group} />
                                )}
                            />
                            <Route
                                exact
                                path="/groups/:id/feed"
                                component={Feed}
                            />
                            <Route
                                exact
                                path="/groups/:id/galleries"
                                component={Galleries}
                            />
                            <Route
                                exact
                                path="/groups/:id/settings"
                                component={Settings}
                            />
                            <Route component={Info} />
                        </Switch>
                    </AnimatedTransition>
                </Column>
            </Container>
            <DeleteGroupDialog />
            <AddEditGroupDialog />
            <AvatarGroupDialog />
            <Tutorial id="groups-single" steps={tutorial} />
        </>
    );
};

export default ViewHOC(GroupsSingle, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
