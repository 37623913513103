import { useMutationEventCyclicalDelete } from 'queries/events.mutations.js';
import { Button, ModalActions, ModalContent, ModalHeader, ModalHOC } from 'ui';

const DeleteEventCyclicalDialog = ({ data: { id }, modalClose }) => {
    const mutation = useMutationEventCyclicalDelete({
        onSuccess: () => {
            modalClose();
        },
    });

    const deleteEventCyclical = () => {
        mutation.mutate(id);
    };

    return (
        <>
            <ModalHeader>Usuń wydarzenie cykliczne</ModalHeader>
            <ModalContent>
                Czy na pewno chcesz usunąć wydarzenie cykliczne? Z kalendarza
                zostaną usunięte powiązane nadchodzące wydarzenia.
            </ModalContent>
            <ModalActions>
                <Button color="red" onClick={deleteEventCyclical}>
                    Usuń
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DeleteEventCyclicalDialog, {
    id: 'deleteEventCyclical',
});
