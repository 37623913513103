import { useRouteMatch } from 'react-router-dom';

import EmptyCard from 'components/EmptyCard';
import useUI from 'contexts/useUI';
import { useMutationGroupRemovePlayer } from 'queries/groups.mutations.js';
import { useGroupPlayersQuery } from 'queries/groups.queries.js';
import { Button, Column, Header, List, ListActions, Spinner } from 'ui';

import ListDataCell from '../../../../components/ListDataCell/index.js';

import AddPlayer from './modals/AddPlayer';

const Players = () => {
    const {
        params: { id },
    } = useRouteMatch();
    const { data: players, isFetched } = useGroupPlayersQuery(id);
    const { modalOpen } = useUI();
    const addPlayersToGroup = () =>
        modalOpen('addPlayersToGroup', { idGroup: id });
    const mutationRemovePlayer = useMutationGroupRemovePlayer();
    const removePlayer = idPlayer => {
        mutationRemovePlayer.mutate({ idGroup: id, idPlayer });
    };

    if (!isFetched) return <Spinner active />;

    return (
        <>
            <Column gap="m">
                <Header title="Zawodnicy" size="l">
                    <Button
                        size={'l'}
                        color={'green'}
                        onClick={addPlayersToGroup}>
                        Dodaj zawodników
                    </Button>
                </Header>
                {!players.length ? (
                    <EmptyCard
                        action={addPlayersToGroup}
                        actionLabel="Dodaj pierwszych zawodników"
                    />
                ) : (
                    <List
                        data={players || []}
                        renderRow={({
                            id: idPlayer,
                            name,
                            surname,
                            sex,
                            age,
                            avatar,
                            birthday,
                            parents,
                            medicalDate,
                            isMedicalValid,
                        }) => (
                            <>
                                <ListDataCell.User
                                    width={300}
                                    size="l"
                                    user={{
                                        id: idPlayer,
                                        name,
                                        surname,
                                        avatar,
                                        role: 'player',
                                    }}
                                />
                                <ListDataCell.Sex sex={sex} />
                                <ListDataCell.Age age={age} date={birthday} />
                                <ListDataCell.Medicals
                                    valid={isMedicalValid}
                                    date={medicalDate}
                                />
                                <ListDataCell.Users users={parents} />
                                <ListActions
                                    actions={[
                                        {
                                            title: 'Usuń',
                                            icon: 'delete',
                                            color: 'red',
                                            onClick: () => {
                                                removePlayer(idPlayer);
                                            },
                                        },
                                    ]}
                                />
                            </>
                        )}
                    />
                )}
            </Column>
            <AddPlayer />
        </>
    );
};

export default Players;
