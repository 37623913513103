import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useParentQuery } from 'queries/parents.queries.js';
import ViewHOC from 'screens/ViewHOC';
import {
    AnimatedTransition,
    Column,
    Container,
    Head,
    RoleTag,
    Spinner,
} from 'ui';
import { formatAddress } from 'utils/formatters';
import useACL from 'utils/useACL';

import DeleteParentDialog from '../modals/DeleteParentDialog';

import Calendar from './Calendar';
import Info from './Info';
import Players from './Players';

const ParentsSingle = () => {
    const location = useLocation();
    const {
        params: { id },
    } = useRouteMatch();

    const { isRole } = useACL();

    const { modalOpen } = useUI();

    const openDeleteParentDialog = () => {
        modalOpen('deleteParent', { id });
    };

    const { data: parent } = useParentQuery(id);

    if (!parent) {
        return <Spinner active />;
    }
    return (
        <Container>
            <Column gap={'m'}>
                <Head
                    title={`${parent.name} ${parent.surname}`}
                    subtitle={
                        <>
                            <span>{formatAddress(parent.address)}</span>
                            <RoleTag role={ROLES.PARENT} size="s" />
                        </>
                    }
                    avatar={{
                        text: `${parent.name} ${parent.surname}`,
                        picture: parent.avatar,
                    }}
                    tabs={[
                        {
                            content: 'Informacje',
                            link: `/parents/${parent.id}`,
                        },
                        {
                            content: 'Podopieczni',
                            link: `/parents/${parent.id}/players`,
                            counter: parent.players
                                ? parent.players.length
                                : undefined,
                        },
                        {
                            content: 'Kalendarz',
                            link: `/parents/${parent.id}/calendar`,
                        },
                    ]}
                    actions={[
                        {
                            text: 'Usuń rodzica',
                            color: 'red',
                            icon: 'delete',
                            onClick:
                                isRole([ROLES.OWNER, ROLES.ADMIN]) &&
                                openDeleteParentDialog,
                        },
                    ]}
                />
                <AnimatedTransition k={location.pathname}>
                    <Switch location={location}>
                        <Route exact path="/parents/:id/" component={Info} />
                        <Route
                            exact
                            path="/parents/:id/players"
                            component={Players}
                        />
                        <Route
                            exact
                            path="/parents/:id/calendar"
                            render={props => (
                                <Calendar {...props} parent={parent} />
                            )}
                        />
                        <Route component={Info} />
                    </Switch>
                </AnimatedTransition>
            </Column>
            <DeleteParentDialog />
        </Container>
    );
};

export default ViewHOC(ParentsSingle, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
