import cx from 'classnames';
import PropTypes from 'prop-types';

import Row from '../grid/Row.jsx';

import style from './Tag.module.scss';

const Tag = ({ text, color, type, size, children, onClick }) => {
    return (
        <div
            className={cx(
                style.tag,
                style[`size-${size}`],
                style[`type-${type}`],
                style[`color-${color}`],
                onClick && style.clickable,
            )}
            onClick={onClick}>
            <Row gap={'xxs'} align="center">
                {text && <span>{text}</span>}
                {children}
            </Row>
        </div>
    );
};

Tag.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['fill', 'border']),
    size: PropTypes.oneOf(['s', 'm', 'l']),
    color: PropTypes.oneOf(['grey', 'green', 'black']),
};

Tag.defaultProps = {
    color: 'grey',
    type: 'fill',
    size: 'm',
};

export default Tag;
