import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
    Avatar,
    ContactTag,
    Icon,
    ListCell,
    RoleTag,
    Row,
    Tag,
    Tooltip,
} from 'ui';
import {
    formatAge,
    formatDateFull,
    formatHour,
    formatYear,
} from 'utils/formatters.js';

import AvatarWithStatus from '../AvatarWithStatus';

const Age = ({ age, date, onClick, width }) => (
    <ListCell width={width || 150} onClick={onClick}>
        <Row gap="xxxs">
            <strong>{formatAge(age)}</strong>
            {date ? <span>({formatYear(date)})</span> : null}
        </Row>
    </ListCell>
);

const BMI = ({ bmi, onClick, width }) => (
    <ListCell width={width || 140} onClick={onClick}>
        <Row gap="xxs" align={'center'}>
            {bmi || null}
            {bmi > 24 ? <Tag text={'Nadwaga'} size="s" /> : null}
        </Row>
    </ListCell>
);

const DateCell = ({ date, onClick, width }) => (
    <ListCell width={width || 120} onClick={onClick}>
        {formatDateFull(date)}
    </ListCell>
);

const DateTime = ({ date, onClick, width }) => (
    <ListCell width={width || 170} onClick={onClick}>
        {`${formatDateFull(date)}, ${formatHour(date)}`}
    </ListCell>
);

const Email = ({ email, onClick, width }) => (
    <ListCell width={width || 150} onClick={onClick}>
        <a href={`mailto:${email}`}>{email}</a>
    </ListCell>
);

const Group = ({ group, onClick, size, width }) => (
    <ListCell width={width || 300} onClick={onClick}>
        <Link to={`/groups/${group.id}`}>
            <Row gap="xs" align="center">
                <Avatar
                    text={group.avatar.slice(7, 9)}
                    shape="shield"
                    size={size || 'l'}
                    color={group.avatar.slice(0, 7)}
                />
                <span>{group.name}</span>
            </Row>
        </Link>
    </ListCell>
);

const Groups = ({ groups, onClick, width }) => (
    <ListCell width={width || 200} onClick={onClick}>
        <Row gap="xxs">
            {groups.map(g => (
                <Tooltip key={g.id} text={g.name} display="inline">
                    <Link to={`/groups/${g.id}`}>
                        <Avatar
                            text={g.avatar.slice(7, 9)}
                            shape="shield"
                            size="m"
                            color={g.avatar.slice(0, 7)}
                        />
                    </Link>
                </Tooltip>
            ))}
        </Row>
    </ListCell>
);

const Height = ({ height, onClick, width }) => (
    <ListCell width={width || 100} onClick={onClick}>
        {height ? `${height.toFixed(0)}cm` : null}
    </ListCell>
);

const IconCell = ({ icon, tooltip, onClick, width }) => (
    <ListCell width={width || 24} onClick={onClick}>
        {icon && (
            <Tooltip text={tooltip} display="inline">
                <Icon icon={icon} color="green" />
            </Tooltip>
        )}
    </ListCell>
);

const Medicals = ({ date, width, onClick }) => (
    <ListCell width={width || 120} onClick={onClick}>
        <Row>
            <Tooltip text={date && formatDateFull(date)} display="inline">
                {new Date(date) > new Date('') ? (
                    <Tag text="Aktualne badania" color="green" size="s" />
                ) : (
                    <Tag text="Wygasłe badania" size="s" />
                )}
            </Tooltip>
        </Row>
    </ListCell>
);

const Phone = ({ phone, onClick, width }) => (
    <ListCell width={width || 200} onClick={onClick}>
        {phone && <ContactTag>{phone}</ContactTag>}
    </ListCell>
);

const Sex = ({ sex }) => (
    <ListCell width={20}>
        <Icon
            icon={sex}
            size="s"
            color={sex === 'male' ? '#10559A' : '#DB4C77'}
        />
    </ListCell>
);

const Text = ({ text, strong, onClick, width }) => (
    <ListCell width={width || 300} onClick={onClick}>
        {strong ? <strong>{text}</strong> : text}
    </ListCell>
);

const TimeFrame = ({ timeStart, timeEnd, onClick, width }) => (
    <ListCell width={width || 150} onClick={onClick}>
        <strong>
            {timeStart.length < 5 ? `${timeStart}0` : timeStart}-
            {timeEnd.length < 5 ? `${timeEnd}0` : timeEnd}
        </strong>
    </ListCell>
);

const User = ({ user, status, onClick, size, width }) => {
    const link = useMemo(() => {
        if (!user?.role || !user.id) return null;
        if (user.role === 'coach') return `/coaches/${user.id}`;

        return `/${user.role}s/${user.id}`;
    }, [user]);

    return (
        <ListCell width={width || 300} onClick={onClick}>
            <Link to={link || '#'}>
                <Row gap="xs" align="center">
                    <AvatarWithStatus
                        status={status}
                        text={`${user.name} ${user.surname}`}
                        size={size || 'm'}
                        picture={user.avatar}
                    />
                    <span>
                        {user.name} <strong>{user.surname}</strong>
                    </span>
                </Row>
            </Link>
        </ListCell>
    );
};

const UserAvatarCell = ({ user, onClick, size, width }) => (
    <ListCell width={width || 32} onClick={onClick}>
        <Link to={`/players/${user.id}`}>
            <Avatar
                text={`${user.name} ${user.surname}`}
                size={size || 'l'}
                picture={user.avatar}
            />
        </Link>
    </ListCell>
);

const UserRole = ({ role, size, onClick, width }) => (
    <ListCell width={width || 100} onClick={onClick}>
        <RoleTag role={role} size={size || 'm'} />
    </ListCell>
);

const Users = ({ users, onClick, width }) => (
    <ListCell width={width || 100} onClick={onClick}>
        <Row gap="xxxs">
            {(users || []).map(p => (
                <Tooltip
                    text={`${p.name} ${p.surname}`}
                    key={p.id}
                    display="inline">
                    {p.role ? (
                        <Link to={`/${p.role}s/${p.id}`} key={p.id}>
                            <Avatar
                                size="m"
                                text={`${p.name} ${p.surname}`}
                                picture={p.avatar}
                            />
                        </Link>
                    ) : (
                        <Avatar
                            key={p.id}
                            size="m"
                            text={`${p.name} ${p.surname}`}
                            picture={p.avatar}
                        />
                    )}
                </Tooltip>
            ))}
        </Row>
    </ListCell>
);

const Weight = ({ weight, onClick, width }) => (
    <ListCell width={width || 100} onClick={onClick}>
        {weight ? `${weight.toFixed(1)}kg` : null}
    </ListCell>
);

export default {
    Age,
    BMI,
    Date: DateCell,
    DateTime,
    Email,
    Group,
    Groups,
    Height,
    Icon: IconCell,
    Medicals,
    Phone,
    Sex,
    Text,
    TimeFrame,
    User,
    UserAvatar: UserAvatarCell,
    UserRole,
    Users,
    Weight,
};
