import cx from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { Button } from 'ui/index';

import style from './TutorialStep.style.module.scss';

const TutorialStep = ({ index, title, text, element, close, next, finish }) => {
    const styles = {};
    switch (element.position) {
        case 'topLeft':
            styles.top = `${element.y + element.height / 2 - 30}px`;
            styles.left = `${element.x + element.width + 30}px`;
            break;
        case 'top':
            styles.top = `${element.y + element.height + 30}px`;
            styles.left = `${element.x + element.width / 2 - 215}px`;
            break;
        case 'topRight':
            styles.top = `${element.y + element.height / 2 - 30}px`;
            styles.right = `${window.innerWidth - element.x + 30}px`;
            break;
        case 'bottomLeft':
            styles.bottom = `${window.innerHeight - element.y + 30}px`;
            styles.left = `${element.x + element.width + 30}px`;
            break;
        case 'bottom':
            styles.bottom = `${window.innerHeight - element.y + 30}px`;
            styles.left = `${element.x + element.width / 2 - 215}px`;
            break;
        case 'bottomRight':
            styles.bottom = `${window.innerHeight - element.y + 30}px`;
            styles.right = `${window.innerWidth - element.x + 30}px`;
            break;
        default:
            break;
    }

    return (
        <div
            className={cx(
                style.tutorialStep,
                !element.position ? style.centered : style.positioned,
            )}>
            <motion.div
                key={index}
                className={cx(
                    style.card,
                    element.position && style[`position-${element.position}`],
                )}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                style={styles}>
                <div className={style.title}>
                    {index && <div className={style.index}>{index}</div>}
                    {title}
                </div>
                <div className={style.content}>
                    <div className={style.text}>{text}</div>
                    <div className={style.buttons}>
                        {finish && (
                            <Button color="green" onClick={finish}>
                                Zakończ
                            </Button>
                        )}
                        {next && (
                            <Button color="green" onClick={next}>
                                Dalej
                            </Button>
                        )}
                        {close && (
                            <Button color={'black'} onClick={close}>
                                Zamknij
                            </Button>
                        )}
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

TutorialStep.propTypes = {
    index: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    element: PropTypes.object.isRequired,
    close: PropTypes.func,
    next: PropTypes.func,
    finish: PropTypes.func,
};

TutorialStep.defaultProps = {
    index: undefined,
    title: undefined,
    text: undefined,
    close: undefined,
    next: undefined,
    finish: undefined,
};

export default TutorialStep;
