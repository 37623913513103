import { PureComponent } from 'react';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Portal from '../Portal';

import style from './Tooltip.module.scss';

class Tooltip extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            coordinates: {},
        };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    getTooltipRef(el) {
        this.instance = el;
    }

    handleMouseEnter() {
        const elInfo = this.instance.getBoundingClientRect();

        if (this.props.position === 'bottom') {
            this.setState({
                coordinates: {
                    top: `${elInfo.bottom + window.pageYOffset - 5}px`,
                    left: `${elInfo.left - 100 + elInfo.width / 2}px`,
                },
                open: !!this.props.text,
            });
        } else {
            this.setState({
                coordinates: {
                    bottom: `${
                        document.body.clientHeight -
                        (elInfo.top + window.pageYOffset + 5)
                    }px`,
                    left: `${elInfo.left - 100 + elInfo.width / 2}px`,
                },
                open: !!this.props.text,
            });
        }
    }

    handleMouseLeave() {
        this.setState({
            open: false,
        });
    }

    render() {
        const { text, children, display } = this.props;
        const { open, coordinates } = this.state;

        return (
            <div
                className={cx(style.wrapper, style[`display-${display}`])}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                ref={el => this.getTooltipRef(el)}>
                {text && (
                    <Portal>
                        <AnimatePresence>
                            {open && (
                                <motion.div
                                    className={style.tooltip}
                                    style={coordinates}>
                                    <div className={style.content}>{text}</div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </Portal>
                )}
                {children}
            </div>
        );
    }
}

Tooltip.propTypes = {
    text: PropTypes.string,
    position: PropTypes.oneOf(['top', 'bottom', 'right']),
    display: PropTypes.oneOf(['block', 'inline', 'flex']),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

Tooltip.defaultProps = {
    text: '',
    display: 'block',
    position: 'top',
};

export default Tooltip;
