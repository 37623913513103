import { ROLES } from 'constants/logic';
import {
    Button,
    Column,
    Input,
    Label,
    List,
    ListActions,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';

import ListDataCell from '../../../../../../components/ListDataCell/index.js';
import {
    useMutationEventParticipantAdd,
    useMutationEventParticipantDelete,
} from '../../../../../../queries/events.mutations.js';
import { useCalendarEventQuery } from '../../../../../../queries/events.queries.js';
import { useSearchPeopleQuery } from '../../../../../../queries/search.queries.js';
import useForm from '../../../../../../utils/useForm.js';

const ModalAddParticipant = ({ data: { idEvent }, modalClose }) => {
    const {
        data: { participants },
    } = useCalendarEventQuery(idEvent);

    const [form] = useForm({
        search: {
            value: '',
        },
    });

    const { data: players } = useSearchPeopleQuery(form.search.value, [
        ROLES.PLAYER,
        ROLES.PARENT,
        ROLES.COACH,
    ]);

    const { mutate } = useMutationEventParticipantAdd();
    const { mutate: mutateDelete } = useMutationEventParticipantDelete();

    const addPlayer = idParticipant => {
        mutate({ idEvent, idParticipant });
    };

    const removePlayer = idParticipant => {
        mutateDelete({ idEvent, idParticipant });
    };

    return (
        <>
            <ModalHeader>Zaproś uczestnika</ModalHeader>
            <ModalContent>
                <Column gap="m">
                    <Column gap="xs">
                        <Column>
                            <Label>Wpisz imię lub nazwisko</Label>
                            <Input {...form.search} />
                        </Column>
                        <List
                            data={players.slice(0, 5)}
                            renderRow={({ id, name, surname, avatar }) => {
                                return (
                                    <>
                                        <ListDataCell.User
                                            width={350}
                                            user={{ name, surname, avatar }}
                                        />
                                        {participants.find(
                                            pp => pp.id === id,
                                        ) ? (
                                            <ListActions
                                                actions={[
                                                    {
                                                        icon: 'minus',
                                                        color: 'red',
                                                        onClick: () =>
                                                            removePlayer(id),
                                                    },
                                                ]}
                                            />
                                        ) : (
                                            <ListActions
                                                actions={[
                                                    {
                                                        icon: 'plus',
                                                        onClick: () =>
                                                            addPlayer(id),
                                                    },
                                                ]}
                                            />
                                        )}
                                    </>
                                );
                            }}
                        />
                    </Column>
                </Column>
            </ModalContent>
            <ModalActions>
                <Button color={'black'} onClick={modalClose}>
                    Zakończ
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(ModalAddParticipant, {
    id: 'addParticipant',
    size: 'small',
});
