import { useEffect, useMemo } from 'react';

import Gallery from 'components/Gallery';
import { useGroupsAllQuery } from 'queries/groups.queries.js';
import {
    useMutationNewsAdd,
    useMutationNewsPatch,
} from 'queries/news.mutations.js';
import { useNewsQuery } from 'queries/news.queries.js';
import {
    Button,
    Column,
    Header,
    Input,
    InputSelect,
    InputSwitch,
    InputText,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Row,
    Warning,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util.js';

const AddGallery = ({ data: { groups, id }, modalClose }) => {
    const { data: groupsFetched } = useGroupsAllQuery();
    const groupOptions = useMemo(
        () => groupsFetched.map(v => ({ label: v.name, value: v.id })),
        [groupsFetched],
    );

    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        title: {
            value: '',
            validators: [validators.isRequired()],
        },
        text: {
            value: '',
            validators: [validators.isRequired()],
        },
        photos: {
            value: [],
            validators: [validators.isRequired()],
        },
        isWholeOrganization: {
            value: false,
        },
        groups: {
            value: groups || [],
        },
        isWeb: {
            value: false,
        },
    });

    const { data } = useNewsQuery(id);

    useEffect(() => {
        if (data) {
            form.title.setValue(data.data?.title);
            form.text.setValue(data.data?.text);
            form.photos.setValue(data.data?.photos);
            form.groups.setValue(data.groups.map(g => g.id));
            form.isWeb.setValue(data.isWeb);
            form.isWholeOrganization.setValue(!data.groupsRule);
        }
    }, [data]);

    const mutationAdd = useMutationNewsAdd({ onSuccess: modalClose });
    const mutationPatch = useMutationNewsPatch({ onSuccess: modalClose });

    const add = () => {
        if (!isFormValid) return;
        mutationAdd.mutate({
            data: {
                ...getFormValue(),
            },
            ...getFormValue(),
            type: 'gallery',
            groupsRule: !form.isWholeOrganization.value,
            groups: form.isWholeOrganization.value ? [] : form.groups.value,
        });
    };

    const edit = () => {
        if (!isFormValid) return;
        mutationPatch.mutate({
            id,
            delta: {
                data: {
                    ...getFormValue(),
                },
                ...getFormValue(),
                type: 'gallery',
                groupsRule: !form.isWholeOrganization.value,
                groups: form.isWholeOrganization.value ? [] : form.groups.value,
            },
        });
    };

    return (
        <>
            <ModalHeader>{id ? 'Edytuj galerię' : 'Nowa galeria'}</ModalHeader>
            <ModalContent>
                <Row gap={'m'}>
                    <Column gap={'m'}>
                        <Column gap={'xs'}>
                            <Header title="Informacje podstawowe" size="s" />
                            <div>
                                <Label>Tytuł</Label>
                                <Input {...form.title} />
                            </div>
                            <div>
                                <Label>Opis</Label>
                                <InputText {...form.text} />
                            </div>
                        </Column>
                        <Column gap={'xs'}>
                            <Header title="Informacje dodatkowe" size="s" />
                            <div>
                                <InputSwitch {...form.isWholeOrganization}>
                                    Galeria dotyczy całej organizacji
                                </InputSwitch>
                            </div>
                            {!form.isWholeOrganization.value && (
                                <div>
                                    <Label>Przypisz grupy do galerii</Label>
                                    <InputSelect
                                        options={groupOptions}
                                        {...form.groups}
                                        multiselect
                                    />
                                </div>
                            )}
                            <div>
                                <InputSwitch {...form.isWeb}>
                                    Publikacja na stronie internetowej
                                </InputSwitch>
                                {!form.isWeb.value && (
                                    <Warning
                                        title={`Galeria nie zostanie upubliczniona
                                            na stronie www szkoły`}
                                        content=" Będzie widoczna tylko wewnątrz Goaly"
                                    />
                                )}
                            </div>
                        </Column>
                    </Column>
                    <Column gap={'xs'}>
                        <Header title="Zdjęcia" size="s" />
                        <Gallery
                            photos={form.photos.value}
                            onChange={ps => form.photos.setValue(ps)}
                            size="small"
                            multiple
                        />
                    </Column>
                </Row>
            </ModalContent>
            <ModalActions>
                {!id && (
                    <Button
                        disabled={!isFormValid}
                        color="green"
                        onClick={add}
                        onDisabledClick={touchForm}>
                        Utwórz
                    </Button>
                )}
                {id && (
                    <Button
                        disabled={!isFormValid}
                        color="green"
                        onClick={edit}>
                        Zapisz
                    </Button>
                )}
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AddGallery, {
    id: 'addGallery',
    title: 'Galeria',
    size: 'large',
});
