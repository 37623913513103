import fetch from 'utils/fetch';

export default {
    query: query => fetch.get('players', { params: query }).then(r => r.data),
    post: player => fetch.post('players', player).then(r => r.data),

    get: id => fetch.get(`players/${id}`).then(r => r.data),
    patch: (id, delta) => fetch.patch(`players/${id}`, delta).then(r => r.data),
    delete: id => fetch.delete(`players/${id}`).then(r => r.data),

    getGroups: id => fetch.get(`players/${id}/groups`).then(r => r.data),
    getEvents: id => fetch.get(`players/${id}/events`).then(r => r.data),
    getInsurances: id => fetch.get(`players/${id}/insurances`),

    getParents: id => fetch.get(`players/${id}/parents`).then(r => r.data),
    addParent: (id, idParent) => fetch.put(`players/${id}/parents/${idParent}`),
    removeParent: (id, idParent) =>
        fetch.delete(`players/${id}/parents/${idParent}`),

    getMedicals: id => fetch.get(`players/${id}/medicals`).then(r => r.data),
    postMedical: (id, medical) =>
        fetch.post(`players/${id}/medicals`, medical).then(r => r.data),

    getMeasurements: id =>
        fetch.get(`players/${id}/measurements`).then(r => r.data),
    postMeasurement: (id, measurement) =>
        fetch.post(`players/${id}/measurements`, measurement).then(r => r.data),
};
