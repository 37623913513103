import fetch from 'utils/fetch';

export default {
    query: query => fetch.get('venues', { params: query }).then(r => r.data),
    post: venue => fetch.post('venues', venue).then(r => r.data),

    get: id => fetch.get(`venues/${id}`).then(r => r.data),
    patch: (id, delta) => fetch.patch(`venues/${id}`, delta).then(r => r.data),
    delete: id => fetch.delete(`venues/${id}`).then(r => r.data),

    getEvents: (id, query) =>
        fetch.get(`venues/${id}/events`, { params: query }).then(r => r.data),
};
