import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './ListCell.style.module.scss';

const ListCell = ({ header, footer, width, align, children, onClick }) => (
    <div
        className={cx(
            style.listCell,
            header && style.header,
            footer && style.footer,
            style[`align-${align}`],
            onClick && style.clickable,
        )}
        style={{ flex: !width ? 1 : 0, maxWidth: width, minWidth: width }}
        onClick={onClick}>
        <div className={style.children}>{children}</div>
    </div>
);

ListCell.propTypes = {
    header: PropTypes.bool,
    footer: PropTypes.bool,
    width: PropTypes.number,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    onClick: PropTypes.func,
};

ListCell.defaultProps = {
    header: false,
    footer: false,
    width: undefined,
    align: 'left',
    children: undefined,
    onClick: undefined,
};

export default ListCell;
