import {
    useMutationPlayerAddGroup,
    useMutationPlayerRemoveGroup,
} from 'queries/players.mutations.js';
import { usePlayerGroupsQuery } from 'queries/players.queries.js';
import {
    Button,
    Column,
    List,
    ListActions,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';

import ListDataCell from '../../../../../components/ListDataCell/index.js';
import { useGroupsAllQuery } from '../../../../../queries/groups.queries.js';

const AddGroups = ({ data: { idPlayer }, modalClose }) => {
    const { data: playerGroups } = usePlayerGroupsQuery(idPlayer);
    const { data: groups } = useGroupsAllQuery(idPlayer);

    const mutationPlayerAddGroup = useMutationPlayerAddGroup();
    const mutationPlayerRemoveGroup = useMutationPlayerRemoveGroup();

    const addGroup = idGroup => {
        mutationPlayerAddGroup.mutate({ idPlayer, idGroup });
    };

    const removeGroup = idGroup => {
        mutationPlayerRemoveGroup.mutate({ idPlayer, idGroup });
    };

    if (!playerGroups || !groups) return <div />;

    return (
        <>
            <ModalHeader>Zarządzaj grupami zawodnika</ModalHeader>
            <ModalContent>
                <Column gap="xs">
                    <List
                        data={groups}
                        renderRow={({ id, name, avatar }) => {
                            return (
                                <>
                                    <ListDataCell.Group
                                        group={{ id, name, avatar }}
                                    />
                                    {playerGroups.find(pg => pg.id === id) ? (
                                        <ListActions
                                            actions={[
                                                {
                                                    icon: 'minus',
                                                    onClick: () =>
                                                        removeGroup(id),
                                                },
                                            ]}
                                        />
                                    ) : (
                                        <ListActions
                                            actions={[
                                                {
                                                    icon: 'plus',
                                                    onClick: () => addGroup(id),
                                                },
                                            ]}
                                        />
                                    )}
                                </>
                            );
                        }}
                    />
                </Column>
            </ModalContent>
            <ModalActions>
                <Button color={'black'} onClick={modalClose}>
                    Zakończ
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AddGroups, {
    id: 'addGroupsToPlayer',
    title: 'Zarządzanie grupami zawodnika',
    size: 'small',
});
