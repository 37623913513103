import { createDateTime } from 'utils/date';

export const formatDateFull = date =>
    date && createDateTime(date).toFormat('d LLLL y');
export const formatDateFullWithDay = date =>
    date && createDateTime(date).toFormat('cccc, d LLLL y');
export const formatMonth = date =>
    date && createDateTime(date).toFormat('LLLL');
export const formatYear = date => date && createDateTime(date).toFormat('y');
export const formatDay = date =>
    date && createDateTime(date).toFormat('yyyy-LL-dd');

export const formatHour = date => date && createDateTime(date).toFormat('T');
export const formatTime = minutes =>
    minutes && `${Math.floor(minutes / 60)}h ${minutes % 60}m`;

export const formatAddress = ({ addressStreet, addressCity }) => {
    if (addressStreet && addressCity) {
        return `${addressStreet}, ${addressCity}`;
    } else if (!addressStreet && addressCity) {
        return `${addressCity}`;
    } else if (addressStreet && !addressCity) {
        return `${addressCity}`;
    } else {
        return '';
    }
};

export const formatAge = age => {
    return !isNaN(age) ? `${age.toFixed(0)} lat` : null;
};
