import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';

import CalendarWeekView from 'components/CalendarWeekView/index.js';
import { useCalendarListQuery } from 'queries/events.queries.js';
import { Button, Header, Spinner } from 'ui';

const CalendarListWeek = () => {
    const now = useMemo(() => DateTime.now(), []);

    const [dateStart, setDateStart] = useState(now.startOf('week'));
    const [dateEnd, setDateEnd] = useState(now.endOf('week'));

    const { data: events, isFetched } = useCalendarListQuery({
        dateStart: { $gte: dateStart.toString(), $lte: dateEnd.toString() },
    });

    const nextWeek = () => {
        setDateStart(dateStart.plus({ weeks: 1 }));
        setDateEnd(dateEnd.plus({ weeks: 1 }));
    };

    const prevWeek = () => {
        setDateStart(dateStart.minus({ weeks: 1 }));
        setDateEnd(dateEnd.minus({ weeks: 1 }));
    };

    if (!isFetched) return <Spinner active />;

    return (
        <>
            <Header
                title={`${dateStart.toFormat('dd LLLL')} - ${dateEnd.toFormat(
                    'dd LLLL',
                )}`}
                size={'l'}>
                <Button icon="chevron-left" size={'l'} onClick={prevWeek} />
                <Button icon="chevron-right" size={'l'} onClick={nextWeek} />
            </Header>
            <CalendarWeekView
                events={events}
                dateStart={dateStart}
                dateEnd={dateEnd}
            />
        </>
    );
};

export default CalendarListWeek;
