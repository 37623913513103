import { useMutation, useQueryClient } from '@tanstack/react-query';

import useUI from 'contexts/useUI';
import groupsService from 'services/groupsService.js';
import playersService from 'services/playersService.js';

export const useMutationPlayerAdd = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    return useMutation(playersService.post, {
        onSuccess: async (data, variables) => {
            queryClient.invalidateQueries(['players', 'list']);
            toastPush({
                title: 'Dodano zawodnika',
                text: `${data.name} ${data.surname}`,
            });

            if (variables.addToParent) {
                queryClient.invalidateQueries([
                    'parents',
                    'single',
                    variables.addToParent,
                    'players',
                ]);
            }

            if (variables.addToGroup) {
                queryClient.invalidateQueries([
                    'groups',
                    'single',
                    variables.addToGroup,
                    'players',
                ]);
            }
            if (onSuccess) {
                onSuccess(data);
            }
        },
    });
};

export const useMutationPlayerPatch = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(({ id, delta }) => playersService.patch(id, delta), {
        onSuccess: (data, { id }) => {
            toastPush({
                title: 'Wyedytowano zawodnika',
                text: `${data.name} ${data.surname}`,
            });

            queryClient.invalidateQueries(['players', 'list']);
            queryClient.invalidateQueries(['players', 'single', id]);
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationPlayerDelete = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(playersService.delete, {
        onSuccess: data => {
            toastPush({
                title: 'Usunięto zawodnika',
            });

            queryClient.invalidateQueries(['players', 'list']);
            queryClient.invalidateQueries(['players', 'single', data.id]);
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationPlayerAddParent = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idPlayer, idParent }) =>
            playersService.addParent(idPlayer, idParent),
        {
            onSuccess: (data, { idPlayer, idParent }) => {
                toastPush({
                    title: 'Dodano rodzica',
                });

                queryClient.invalidateQueries(['players', 'list']);
                queryClient.invalidateQueries(['players', 'single', idPlayer]);
                queryClient.invalidateQueries(['parents', 'list']);
                queryClient.invalidateQueries(['parents', 'single', idParent]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationPlayerRemoveParent = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idPlayer, idParent }) =>
            playersService.removeParent(idPlayer, idParent),
        {
            onSuccess: (data, { idPlayer, idParent }) => {
                toastPush({
                    title: 'Usunięto administratora',
                });

                queryClient.invalidateQueries(['players', 'list']);
                queryClient.invalidateQueries(['players', 'single', idPlayer]);
                queryClient.invalidateQueries(['parents', 'list']);
                queryClient.invalidateQueries(['parents', 'single', idParent]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationPlayerAddGroup = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idPlayer, idGroup }) => groupsService.addPlayer(idGroup, idPlayer),
        {
            onSuccess: (data, { idGroup, idPlayer }) => {
                toastPush({
                    title: 'Dodano do grupy',
                });

                queryClient.invalidateQueries(['groups', 'list']);
                queryClient.invalidateQueries(['players', 'list']);
                queryClient.invalidateQueries(['groups', 'single', idGroup]);
                queryClient.invalidateQueries(['players', 'single', idPlayer]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationPlayerRemoveGroup = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idPlayer, idGroup }) =>
            groupsService.removePlayer(idGroup, idPlayer),
        {
            onSuccess: (data, { idPlayer, idGroup }) => {
                toastPush({
                    title: 'Usunięto z grupy',
                });

                queryClient.invalidateQueries(['groups', 'list']);
                queryClient.invalidateQueries(['players', 'list']);
                queryClient.invalidateQueries(['groups', 'single', idGroup]);
                queryClient.invalidateQueries(['players', 'single', idPlayer]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationPlayerMeasurementAdd = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idPlayer, measurement }) =>
            playersService.postMeasurement(idPlayer, measurement),
        {
            onSuccess: (data, { idPlayer }) => {
                toastPush({
                    title: 'Dodano pomiar',
                });

                queryClient.invalidateQueries([
                    'players',
                    'single',
                    idPlayer,
                    'measurements',
                ]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationPlayerMedicalAdd = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idPlayer, medical }) =>
            playersService.postMedical(idPlayer, medical),
        {
            onSuccess: (data, { idPlayer }) => {
                toastPush({
                    title: 'Dodano badanie medyczne',
                });

                queryClient.invalidateQueries([
                    'players',
                    'single',
                    idPlayer,
                    'medicals',
                ]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};
