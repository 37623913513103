export default [
    {
        label: 'GK',
        name: 'Bramkarz',
        x: 7,
        y: 50,
    },
    {
        label: 'DC',
        name: 'Środkowy obrońca',
        x: 20,
        y: 50,
    },
    {
        label: 'DL',
        name: 'Lewy obrońca',
        x: 20,
        y: 20,
    },
    {
        label: 'DR',
        name: 'Prawy obrońca',
        x: 20,
        y: 80,
    },
    {
        label: 'DMC',
        name: 'Środkowy defensywny pomocnik',
        x: 35,
        y: 50,
    },
    {
        label: 'DML',
        name: 'Lewy defensywny pomocnik',
        x: 35,
        y: 20,
    },
    {
        label: 'DMR',
        name: 'Prawy defensywny pomocnik',
        x: 35,
        y: 80,
    },
    {
        label: 'MC',
        name: 'Środkowy pomocnik',
        x: 50,
        y: 50,
    },
    {
        label: 'ML',
        name: 'Lewy pomocnik',
        x: 50,
        y: 20,
    },
    {
        label: 'MR',
        name: 'Prawy pomocnik',
        x: 50,
        y: 80,
    },
    {
        label: 'AMC',
        name: 'Środkowy ofensywny pomocnik',
        x: 65,
        y: 50,
    },
    {
        label: 'AML',
        name: 'Lewy ofensywny pomocnik',
        x: 65,
        y: 20,
    },
    {
        label: 'AMR',
        name: 'Prawy ofensywny pomocnik',
        x: 65,
        y: 80,
    },
    {
        label: 'ST',
        name: 'Napastnik',
        x: 80,
        y: 50,
    },
];
