import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { DateTime, Interval } from 'luxon';

import useUI from '../../contexts/useUI.js';
import { Avatar, Column, Icon, Row, Tooltip } from '../../ui/index.js';
import PercentCircle from '../../ui/PercentCircle/index.js';

import styles from './CalendarWeekView.module.scss';

const HOURS = [
    6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];

const CalendarWeekDay = ({ day, addEventParams }) => {
    const { modalOpen } = useUI();

    return (
        <div className={styles.day}>
            <div className={styles.dayName}>
                {day.toLocaleString({ weekday: 'short' }).toUpperCase()}
                <strong>{day.toFormat('dd')}</strong>
            </div>
            {HOURS.map(hour => (
                <div
                    className={cx([
                        styles.hour,
                        day.set({ hour }) < DateTime.now() && styles.past,
                    ])}
                    key={hour}>
                    <div
                        className={styles.add}
                        onClick={() =>
                            modalOpen('addEventSingle', {
                                ...addEventParams,
                                date: day.toJSDate(),
                                timeStart: `${hour}:0`,
                                timeEnd: `${hour + 1}:0`,
                            })
                        }>
                        <Icon icon={'plus'} color="grey-500" size="s" />
                    </div>
                </div>
            ))}
        </div>
    );
};

const CalendarWeekView = ({ events, dateStart, dateEnd, addEventParams }) => {
    const ref = useRef(null);

    const [cellWidth, setCellWidth] = useState(0);
    const [cellHeight, setCellHeight] = useState(0);

    useLayoutEffect(() => {
        setCellWidth((ref.current.offsetWidth - 60) / days.length);
        setCellHeight((ref.current.offsetHeight - 50) / HOURS.length);
    }, []);

    const days = useMemo(() => {
        const arr = [];
        for (let i = 0; i < 7; i++) {
            arr.push(dateStart.plus({ days: i }));
        }
        return arr;
    }, [dateStart, dateEnd]);
    return (
        <div className={styles.root} ref={ref}>
            <div className={styles.hours}>
                {HOURS.map(hour => (
                    <div className={styles.hour} key={hour}>
                        {hour}:00
                    </div>
                ))}
            </div>
            {days.map(day => (
                <CalendarWeekDay
                    key={day.toString()}
                    day={day}
                    addEventParams={addEventParams}
                />
            ))}
            {events.map(event => {
                const start = DateTime.fromISO(event.dateStart);
                const end = DateTime.fromISO(event.dateEnd);
                const i = Interval.fromDateTimes(start, end);

                return (
                    <Link to={`/calendar/${event.id}`} key={event.id}>
                        <div
                            className={styles.event}
                            style={{
                                width: cellWidth,
                                height: i.length('hours') * cellHeight,
                                top:
                                    50 +
                                    cellHeight * (start.hour - 6) +
                                    cellHeight * (start.minute / 60),
                                left:
                                    60 +
                                    cellWidth * (start.day - dateStart.day),
                                '--color-border':
                                    event?.groups.length &&
                                    event?.groups[0]?.avatar
                                        ? event?.groups[0]?.avatar.substr(0, 7)
                                        : 'var(--color-grey-500)',
                            }}>
                            <div className={styles.content}>
                                <Column>
                                    <Row alignContent="space-between" size={0}>
                                        <Row>
                                            <div className={styles.time}>
                                                {start.toLocaleString(
                                                    DateTime.TIME_24_SIMPLE,
                                                )}
                                                {' - '}
                                                {end.toLocaleString(
                                                    DateTime.TIME_24_SIMPLE,
                                                )}
                                            </div>
                                        </Row>
                                        <Row gap={'xxxs'} size={0}>
                                            {event.groups.map(
                                                ({ id, name, avatar }) => (
                                                    <Tooltip
                                                        key={id}
                                                        text={name}>
                                                        <Avatar
                                                            text={avatar.slice(
                                                                7,
                                                                9,
                                                            )}
                                                            shape="shield"
                                                            size="s"
                                                            color={avatar.slice(
                                                                0,
                                                                7,
                                                            )}
                                                        />
                                                    </Tooltip>
                                                ),
                                            )}
                                        </Row>
                                    </Row>
                                    <Row alignContent="space-between">
                                        <div className={styles.title}>
                                            {event.title}
                                        </div>
                                        {event.attendance > 0 && (
                                            <div className={styles.attendance}>
                                                <PercentCircle
                                                    percent={event.attendance}
                                                    size="s"
                                                />
                                            </div>
                                        )}
                                    </Row>
                                </Column>
                            </div>
                        </div>
                    </Link>
                );
            })}
        </div>
    );
};

export default CalendarWeekView;
