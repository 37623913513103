import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './InputSwitch.module.scss';

const InputSwitch = ({
    children,
    value,
    onChange,
    isFocused,
    onFocus,
    onBlur,
}) => {
    const onSwitch = () => {
        if (onFocus) {
            onFocus();
        }

        onChange(!value);
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <div
            className={cx(styles.inputSwitch, isFocused && styles.focused)}
            onClick={onSwitch}>
            <div className={styles.text}>{children}</div>
            <div className={cx(styles.switch, value && styles.active)}>
                <div className={styles.circle} />
            </div>
        </div>
    );
};

InputSwitch.propTypes = {
    children: PropTypes.any.isRequired,
    value: PropTypes.bool.isRequired,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

InputSwitch.defaultProps = {
    onChange: undefined,
    onBlur: undefined,
    onFocus: undefined,
};

export default InputSwitch;
