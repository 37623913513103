import fetch from 'utils/fetch';

export default {
    upload: (file, type) => {
        const formData = new FormData();
        formData.append('file', file);

        return fetch.post(`upload/${type}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};
