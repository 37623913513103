import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import dashboardService from '../services/dashboardService.js';

export const useDashboardCoachesQuery = () => {
    return useQuery(
        ['dashboard', 'coaches'],
        () => dashboardService.getCoaches(),
        {
            initialData: [],
        },
    );
};

export const useDashboardGroupsQuery = params => {
    return useQuery(
        ['dashboard', 'groups', params],
        () => dashboardService.getGroups(params),
        {
            initialData: [],
        },
    );
};

export const useDashboardVenuesQuery = () => {
    return useQuery(
        ['dashboard', 'venues'],
        () => dashboardService.getVenues(),
        {
            initialData: [],
        },
    );
};

export const useDashboardEventsQuery = () => {
    return useQuery(
        ['dashboard', 'events'],
        () => dashboardService.getEvents(),
        {
            initialData: [],
        },
    );
};

export const useDashboardFeedQuery = () => {
    return useInfiniteQuery(
        ['dashboard', 'feed'],
        ({ pageParam }) => {
            return dashboardService.getFeed({
                limit: 10,
                offset: pageParam,
            });
        },
        {
            getNextPageParam: (a, b) => {
                if (!a.length) return undefined;
                return b.flat(1).length;
            },
        },
    );
};
