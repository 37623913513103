import { useQuery } from '@tanstack/react-query';

import searchService from '../services/searchService.js';

export const useSearchQuery = searchPhrase => {
    return useQuery(
        ['search', 'total', searchPhrase],
        () => searchService.get(searchPhrase),
        {
            initialData: [],
        },
    );
};

export const useSearchPeopleQuery = (searchPhrase, roles) => {
    return useQuery(
        ['search', 'users', searchPhrase, roles],
        () => searchService.getUsers(searchPhrase, roles),
        {
            enabled: !!searchPhrase && searchPhrase.length > 2,
            initialData: [],
        },
    );
};
