import fetch from 'utils/fetch';

export default {
    query: query => fetch.get('events', { params: query }).then(r => r.data),
    post: event => fetch.post('events', event).then(r => r.data),

    get: id => fetch.get(`events/${id}`).then(r => r.data),
    patch: (id, delta) => fetch.patch(`events/${id}`, delta).then(r => r.data),
    delete: id => fetch.delete(`events/${id}`).then(r => r.data),

    getParticipants: id => fetch.get(`events/${id}/participants`),
    addParticipant: (idEvent, idParticipant) =>
        fetch.put(`events/${idEvent}/participants/${idParticipant}`),
    deleteParticipant: (idEvent, idParticipant) =>
        fetch.delete(`events/${idEvent}/participants/${idParticipant}`),

    cyclicalPost: event =>
        fetch.post('events-cyclical', event).then(r => r.data),
    cyclicalGet: id => fetch.get(`events-cyclical/${id}`).then(r => r.data),
    cyclicalPatch: (id, delta) =>
        fetch.patch(`events-cyclical/${id}`, delta).then(r => r.data),
    cyclicalDelete: id =>
        fetch.delete(`events-cyclical/${id}`).then(r => r.data),

    deleteStatus: ({ idEvent, idUser }) =>
        fetch
            .delete(`events/${idEvent}/participants/${idUser}/status`)
            .then(r => r.data),

    setStatusPresent: ({ idEvent, idUser }) =>
        fetch
            .put(`events/${idEvent}/participants/${idUser}/status/present`)
            .then(r => r.data),

    setStatusAbsent: ({ idEvent, idUser }) =>
        fetch
            .put(`events/${idEvent}/participants/${idUser}/status/absent`)
            .then(r => r.data),

    togleNote: ({ idEvent, idUser, idNote }) =>
        fetch
            .put(`events/${idEvent}/participants/${idUser}/notes/${idNote}`)
            .then(r => r.data),
};
