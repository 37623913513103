import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { Icon } from 'ui/index';

import Years from './Years';

import style from './DecadeView.module.scss';

const DecadeView = ({ decade, onNext, onPrev, openYear }) => {
    const props = {
        decade,
        openYear,
    };
    return (
        <div className={style.root}>
            <div className={style.decade}>
                <div
                    className={style.button}
                    onClick={onPrev}
                    onKeyPress={onPrev}>
                    <Icon icon="circle-left" />
                </div>
                <div className={style.name}>{`${decade}-${decade + 9}`}</div>
                <div
                    className={style.button}
                    onClick={onNext}
                    onKeyPress={onPrev}>
                    <Icon icon="circle-right" />
                </div>
            </div>
            <div className={style.view}>
                <AnimatePresence>
                    <motion.div
                        key={decade}
                        initial={{
                            opacity: 0,
                        }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{
                            opacity: 0,
                        }}>
                        <Years {...props} />
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
};

DecadeView.propTypes = {
    decade: PropTypes.number.isRequired,
    direction: PropTypes.string.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    openYear: PropTypes.func.isRequired,
};

export default DecadeView;
