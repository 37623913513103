import GroupCard from 'components/GroupCard';
import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useGroupsListQuery } from 'queries/groups.queries.js';
import { Button, Column, Container, Header, Row, Spinner } from 'ui';
import InfiniteLoader from 'ui/InfiniteLoader';
import useACL from 'utils/useACL';

import EmptyCard from '../../../components/EmptyCard/index.js';
import ViewHOC from '../../ViewHOC';
import AddGroupDialog from '../modals/AddEditGroupDialog';

const Groups = () => {
    const { modalOpen } = useUI();
    const { isRole } = useACL();

    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetched,
    } = useGroupsListQuery();

    const groups = data.pages.flat(1);

    const openAddGroup = () => {
        modalOpen('addEditGroup');
    };

    if (!isFetched) return <Spinner active />;

    return (
        <>
            <Container>
                <Column gap={'m'}>
                    <Header title="Grupy" size={'xl'}>
                        {isRole([ROLES.ADMIN, ROLES.OWNER]) && (
                            <Button color={'green'} onClick={openAddGroup}>
                                Nowa grupa
                            </Button>
                        )}
                    </Header>
                    {groups.length ? (
                        <Row gap={'m'} wrap="wrap">
                            {groups.map(group => (
                                <GroupCard {...group} key={group.id} />
                            ))}
                            {!isFetchingNextPage && hasNextPage && (
                                <InfiniteLoader onVisible={fetchNextPage} />
                            )}
                        </Row>
                    ) : (
                        <EmptyCard
                            action={openAddGroup}
                            actionLabel="Dodaj pierwszą grupę"
                        />
                    )}
                </Column>
            </Container>
            <AddGroupDialog />
        </>
    );
};

export default ViewHOC(Groups, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
