import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import TutorialBackground from './TutorialBackground';
import TutorialStep from './TutorialStep';

import style from './Tutorial.style.module.scss';

const Tutorial = ({ step, steps, element, next, close, finish }) => {
    const props = {
            ...steps[step],
            element,
            next,
            close,
            finish,
        },
        bgProps = {
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            element,
        };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={style.tutorial}>
            <AnimatePresence>
                <TutorialStep {...props} />
            </AnimatePresence>
            <TutorialBackground {...bgProps} />
        </motion.div>
    );
};

Tutorial.propTypes = {
    step: PropTypes.number,
    steps: PropTypes.array,
    element: PropTypes.object.isRequired,
    next: PropTypes.func,
    close: PropTypes.func,
    finish: PropTypes.func,
};

Tutorial.defaultProps = {
    step: 0,
    steps: [],
    next: undefined,
    close: undefined,
    finish: undefined,
};

export default Tutorial;
