import { PureComponent } from 'react';

import { ROLES } from 'constants/logic';
import ViewHOC from 'screens/ViewHOC';

import Multimedia from './Multimedia';

class MultimediaContainer extends PureComponent {
    render() {
        const props = {};

        return <Multimedia {...props} />;
    }
}

export default ViewHOC(MultimediaContainer, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
