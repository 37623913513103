import { useState } from 'react';

import { EVENTS_TYPES_MAP } from 'constants/logic';
import { Column, Icon, Row, Tooltip } from 'ui';
import { formatHour, formatTime } from 'utils/formatters';

import GroupTag from '../../../ui/GroupTag/index.js';
import PercentCircle from '../../../ui/PercentCircle/index.js';

import EventCardDetails from './EventCardDetails';

import styles from './EventCard.module.scss';
const EventCard = ({
    id,
    title,
    type,
    dateStart,
    dateEnd,
    minutes,
    venue,
    groups,
    desc,
    participants,
    attendance,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={styles.root} key={id}>
            <Row gap="s">
                <div className={styles.left}>
                    <div className={styles.top}>
                        {formatHour(dateStart)} - {formatHour(dateEnd)}
                    </div>
                    <div className={styles.bottom}>{formatTime(minutes)}</div>
                </div>
                <div className={styles.wrapper}>
                    {isOpen ? (
                        <EventCardDetails
                            id={id}
                            type={type}
                            title={title}
                            groups={groups}
                            dateStart={dateStart}
                            dateEnd={dateEnd}
                            minutes={minutes}
                            venue={venue}
                            desc={desc}
                            participants={participants}
                            attendance={attendance}
                        />
                    ) : (
                        <div className={styles.card}>
                            <Row gap="s" align="center">
                                <div>
                                    <Tooltip text={EVENTS_TYPES_MAP[type].name}>
                                        <div className={styles.icon}>
                                            <Icon
                                                icon={
                                                    EVENTS_TYPES_MAP[type].icon
                                                }
                                                color="grey"
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                                <Column gap="xxs">
                                    <div className={styles.main}>
                                        <Row align="center" gap="xxs">
                                            {title}
                                        </Row>
                                    </div>
                                    <Row align="center" gap="xxs">
                                        {groups.map(g => (
                                            <GroupTag
                                                group={g}
                                                size="s"
                                                key={g.id}
                                            />
                                        ))}
                                        <div className={styles.bottom}>
                                            {venue ? venue.name : ''}
                                        </div>
                                        <PercentCircle
                                            percent={attendance}
                                            size="s"
                                        />
                                    </Row>
                                </Column>
                            </Row>
                        </div>
                    )}
                    <a
                        className={styles.button}
                        onClick={() => {
                            setIsOpen(v => !v);
                        }}>
                        <Icon
                            size="xs"
                            icon={isOpen ? 'chevron-up' : 'chevron-down'}
                            color={'black-500'}
                        />
                    </a>
                </div>
            </Row>
        </div>
    );
};

export default EventCard;
