import cx from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import styles from './Header.module.scss';

const Header = ({ title, icon, size, children }) => (
    <div className={cx([styles.header, styles[`size-${size}`]])}>
        {icon && (
            <div className={styles.icon}>
                <Icon icon={icon} size={size} />
            </div>
        )}
        <span>{title}</span>
        {children && <div className={styles.children}>{children}</div>}
    </div>
);

Header.propTypes = {
    title: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
};

Header.defaultProps = {
    size: 'l',
};

export default Header;
