import { useMemo } from 'react';
import cx from 'classnames';

import useUI from 'contexts/useUI.js';
import { useMutationEventCyclicalAdd } from 'queries/events.mutations.js';
import { useGroupsAllQuery } from 'queries/groups.queries.js';
import { useVenuesAllQuery } from 'queries/venues.queries.js';
import {
    Button,
    Column,
    Header,
    Input,
    InputSelect,
    InputText,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Row,
    Warning,
} from 'ui';
import useForm from 'utils/useForm';
import validatorsUtil from 'utils/validators.util.js';

import {
    generateNiceTitle,
    hoursOptions,
    inviteOptions,
    typeOptions,
} from '../../../calendar.utils.js';

import styles from './AddEventCyclical.module.scss';

const WEEKDAYS = ['PN', 'WT', 'ŚR', 'CZ', 'PT', 'SB', 'ND'];

const AddEventCyclical = ({ data: { groupIds }, modalClose }) => {
    const { toastPush } = useUI();
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        title: {
            value: 'Trening',
            validators: [validatorsUtil.isRequired()],
        },
        desc: {
            value: '',
        },
        weekday: {
            value: 1,
        },
        type: {
            value: 'training',
        },
        timeStart: {
            value: '15:0',
        },
        timeEnd: {
            value: '16:30',
        },
        venue: {
            value: null,
        },
        groups: {
            value: groupIds || [],
        },
        'invite.players': {
            value: 'groups',
        },
        'invite.parents': {
            value: 'none',
        },
        'invite.coaches': {
            value: 'groups',
        },
    });

    const setTypeF = v => {
        if (!form.title.isTouched) {
            form.title.setValue(
                generateNiceTitle(v, form.weekday.value, form.timeStart.value),
            );
        }
        form.type.setValue(v);
    };

    const setWeekdayF = v => {
        if (!form.title.isTouched) {
            form.title.setValue(
                generateNiceTitle(form.type.value, v, form.timeStart.value),
            );
        }
        form.weekday.setValue(v);
    };

    const setTimeStartF = v => {
        if (!form.title.isTouched) {
            form.title.setValue(
                generateNiceTitle(form.type.value, form.weekday.value, v),
            );
        }
        form.timeStart.setValue(v);
    };

    const { data: groupsFetched } = useGroupsAllQuery();
    const { data: venuesFetched } = useVenuesAllQuery();

    const options = useMemo(
        () => ({
            groups: groupsFetched
                ? groupsFetched.map(v => ({ label: v.name, value: v.id }))
                : [],
            venues: venuesFetched
                ? venuesFetched.map(v => ({ label: v.name, value: v.id }))
                : [],
        }),
        [groupsFetched, venuesFetched],
    );

    const timeValidator = () => {
        const timeStart = Number(form.timeStart.value.replace(':', '.'));
        const timeEnd = Number(form.timeEnd.value.replace(':', '.'));

        if (timeStart > timeEnd) {
            toastPush({
                text: 'Godzina rozpoczęcia nie może być większa niż godzina zakończenia',
            });
            return false;
        }
        return true;
    };

    const mutation = useMutationEventCyclicalAdd({
        onSuccess: modalClose,
    });

    const addEvent = () => {
        if (!isFormValid) return;

        if (!timeValidator()) return;

        mutation.mutate(getFormValue());
    };

    return (
        <>
            <ModalHeader>Nowe wydarzenie cykliczne</ModalHeader>
            <ModalContent>
                <Row gap={'m'} align={'start'}>
                    <Column gap={'m'}>
                        <Column gap={'xs'}>
                            <Header title="Informacje o wydarzeniu" size="s" />
                            <div>
                                <Label>Tytuł wydarzenia</Label>
                                <Input {...form.title} />
                            </div>
                            <div>
                                <Label>Rodzaj wydarzenia</Label>
                                <InputSelect
                                    options={typeOptions}
                                    {...form.type}
                                    onChange={setTypeF}
                                />
                            </div>
                        </Column>
                        <Column gap={'xs'}>
                            <Header title="Data i miejsce" icon="" size="s" />
                            <div>
                                <Label>Dzień tygodnia</Label>
                                <div className={styles.week}>
                                    {WEEKDAYS.map((wd, i) => (
                                        <div
                                            key={wd}
                                            className={cx([
                                                styles.weekday,
                                                i + 1 === form.weekday.value &&
                                                    styles.active,
                                            ])}
                                            onClick={() => {
                                                setWeekdayF(i + 1);
                                            }}>
                                            {wd}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Row gap={'m'}>
                                <Column>
                                    <Label>Godzina rozpoczęcia</Label>
                                    <InputSelect
                                        options={hoursOptions}
                                        {...form.timeStart}
                                        onChange={setTimeStartF}
                                    />
                                </Column>
                                <Column>
                                    <Label>Godzina zakończenia</Label>
                                    <InputSelect
                                        options={hoursOptions}
                                        {...form.timeEnd}
                                    />
                                </Column>
                            </Row>
                            <div>
                                <Label>Obiekt</Label>
                                <InputSelect
                                    options={options.venues}
                                    {...form.venue}
                                />
                            </div>
                            {!form.venue.value && (
                                <div>
                                    <Warning
                                        title="Nie wybrano miejsca wydarzenia"
                                        content="Czy aby na pewno chcesz dodać takie
                                                wydarzenie"
                                    />
                                </div>
                            )}
                        </Column>
                    </Column>
                    <Column gap={'m'}>
                        <Column gap={'xs'}>
                            <Header title="Grupy" icon="" size="s" />
                            <div>
                                <Label>Dodaj do kalendarzy grup</Label>
                                <InputSelect
                                    options={options.groups}
                                    {...form.groups}
                                    multiselect
                                />
                            </div>
                            {!form.groups.value.length && (
                                <div>
                                    <Warning
                                        title="Nie wybrano żadnej grupy"
                                        content="Czy aby na pewno chcesz dodać takie
                                            wydarzenie"
                                    />
                                </div>
                            )}
                        </Column>
                        <Column gap={'xs'}>
                            <Header title="Uczestnicy" icon="" size="s" />
                            <div>
                                <Label>Zaproszeni zawodnicy</Label>
                                <InputSelect
                                    options={inviteOptions}
                                    {...form['invite.players']}
                                />
                            </div>
                            <div>
                                <Label>Zaproszeni rodzice</Label>
                                <InputSelect
                                    options={inviteOptions}
                                    {...form['invite.parents']}
                                />
                            </div>
                            <div>
                                <Label>Zaproszeni trenerzy</Label>
                                <InputSelect
                                    options={inviteOptions}
                                    {...form['invite.coaches']}
                                />
                            </div>
                        </Column>
                        <Column gap={'xs'}>
                            <Header
                                title="Dodatkowe informacje"
                                icon=""
                                size="s"
                            />
                            <div>
                                <Label>Opis wydarzenia</Label>
                                <InputText {...form.desc} />
                            </div>
                        </Column>
                    </Column>
                </Row>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid}
                    color="green"
                    onClick={addEvent}
                    onDisabledClick={touchForm}>
                    Utwórz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AddEventCyclical, {
    id: 'addEventCyclical',
    size: 'large',
});
