import cx from 'classnames';
import PropTypes from 'prop-types';

import COLORS from 'constants/colors';

import { ReactComponent as Calendar } from './svg/calendar.svg';
import { ReactComponent as Camera } from './svg/camera.svg';
import { ReactComponent as Chat } from './svg/chat.svg';
import { ReactComponent as Cheerleader } from './svg/cheerleader.svg';
import { ReactComponent as ChevronDown } from './svg/chevron-down.svg';
import { ReactComponent as ChevronLeft } from './svg/chevron-left.svg';
import { ReactComponent as ChevronRight } from './svg/chevron-right.svg';
import { ReactComponent as ChevronUp } from './svg/chevron-up.svg';
import { ReactComponent as CircleLeft } from './svg/circle-left.svg';
import { ReactComponent as CircleRight } from './svg/circle-right.svg';
import { ReactComponent as Clock } from './svg/clock.svg';
import { ReactComponent as CloseIcon } from './svg/close.svg';
import { ReactComponent as Dashboard } from './svg/dashboard.svg';
import { ReactComponent as Delete } from './svg/delete.svg';
import { ReactComponent as Document } from './svg/document.svg';
import { ReactComponent as Edit } from './svg/edit.svg';
import { ReactComponent as Female } from './svg/female.svg';
import { ReactComponent as Group } from './svg/group.svg';
import { ReactComponent as LockOff } from './svg/lockoff.svg';
import { ReactComponent as LockOn } from './svg/lockon.svg';
// import { ReactComponent as Mail } from './svg/mail.svg';
import { ReactComponent as Male } from './svg/male.svg';
import { ReactComponent as Marker } from './svg/marker.svg';
import { ReactComponent as Match } from './svg/match.svg';
// import { ReactComponent as Media } from './svg/media.svg';
import { ReactComponent as MenuIcon } from './svg/menu.svg';
import { ReactComponent as Minus } from './svg/minus.svg';
import { ReactComponent as News } from './svg/news.svg';
import { ReactComponent as OkIcon } from './svg/ok.svg';
import { ReactComponent as Phone } from './svg/phone.svg';
import { ReactComponent as Player } from './svg/player.svg';
import { ReactComponent as PlayerStanding } from './svg/player-standing.svg';
import { ReactComponent as PlusIcon } from './svg/plus.svg';
import { ReactComponent as Referee } from './svg/referee.svg';
import { ReactComponent as Search } from './svg/search.svg';
import { ReactComponent as Settings } from './svg/settings.svg';
import { ReactComponent as Signout } from './svg/signout.svg';
import { ReactComponent as Stadium } from './svg/stadium.svg';
import { ReactComponent as Star } from './svg/star.svg';
import { ReactComponent as Text } from './svg/text.svg';
// import { ReactComponent as Timer } from './svg/timer.svg';
import { ReactComponent as Tournament } from './svg/tournament.svg';
import { ReactComponent as UserIcon } from './svg/user.svg';
import { ReactComponent as UsersIcon } from './svg/users.svg';
// import { ReactComponent as Wallet } from './svg/wallet.svg';
import { ReactComponent as Whistle } from './svg/whistle.svg';

import style from './Icon.style.module.scss';

/** Component printing SVG icon inline. */
const icons = {
        // add: <Add />,
        calendar: <Calendar />,
        camera: <Camera />,
        chat: <Chat />,
        cheerleader: <Cheerleader />,
        'circle-left': <CircleLeft />,
        'circle-right': <CircleRight />,
        clock: <Clock />,
        dashboard: <Dashboard />,
        delete: <Delete />,
        document: <Document />,
        edit: <Edit />,
        female: <Female />,
        group: <Group />,
        'lock-off': <LockOff />,
        'lock-on': <LockOn />,
        // mail: <Mail />,
        male: <Male />,
        marker: <Marker />,
        match: <Match />,
        // media: <Media />,
        menu: <MenuIcon />,
        minus: <Minus />,
        news: <News />,
        phone: <Phone />,
        player: <Player />,
        'player-standing': <PlayerStanding />,
        referee: <Referee />,
        search: <Search />,
        settings: <Settings />,
        signout: <Signout />,
        stadium: <Stadium />,
        star: <Star />,
        text: <Text />,
        // timer: <Timer />,
        tournament: <Tournament />,
        // wallet: <Wallet />,
        whistle: <Whistle />,

        'chevron-left': <ChevronLeft />,
        'chevron-right': <ChevronRight />,
        'chevron-down': <ChevronDown />,
        'chevron-up': <ChevronUp />,
        close: <CloseIcon />,
        ok: <OkIcon />,
        user: <UserIcon />,
        users: <UsersIcon />,
        plus: <PlusIcon />,
    },
    Icon = ({ icon, color, size, styles }) => (
        <div
            className={cx(style.icon, style[`size-${size}`])}
            style={{ '--color': COLORS[color] || color, ...styles }}>
            {icons[icon]}
        </div>
    );

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xs']),
};

Icon.defaultProps = {
    color: 'black-500',
    size: 'm',
    style: {},
};

export default Icon;
