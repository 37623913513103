import { Button, Container, Header } from 'ui';

import styles from './Documents.module.scss';

const Documents = () => (
    <div className={styles.view}>
        <Container>
            <div>
                <Header title="Dokumenty" size={'xl'}>
                    <Button>Zuploaduj dokument</Button>
                </Header>
            </div>
            Sekcja niedostępna
        </Container>
    </div>
);

export default Documents;
