import { useCallback, useEffect } from 'react';
import cx from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import useUI from 'contexts/useUI';

import { ReactComponent as SVGRemove } from './svg/remove.svg';

import style from './Toast.style.module.scss';

const DEFAULT_DISPLAY_TIME = 5000;

const Toaster = ({ id, title, text, color, time }) => {
    const { toastPop } = useUI();

    const close = useCallback(() => {
        toastPop(id);
    }, [toastPop, id]);

    useEffect(() => {
        const timeout = setTimeout(close, time || DEFAULT_DISPLAY_TIME);
        return () => {
            clearTimeout(timeout);
        };
    }, [close, time]);

    return (
        <motion.div
            className={cx(style.toast, style[`color-${color}`])}
            initial={{ x: 5, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 5, opacity: 0 }}>
            <div className={style.content}>
                {title && <span className={style.title}>{title}</span>}
                {text && <span className={style.text}>{text}</span>}
                <SVGRemove
                    className={style.remove}
                    onClick={close}
                    onKeyPress={undefined}
                />
            </div>
        </motion.div>
    );
};

Toaster.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['black', 'red']),
    onClose: PropTypes.func,
    position: PropTypes.number,
};

Toaster.defaultProps = {
    color: 'black',
    position: 0,
};

export default Toaster;
