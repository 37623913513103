import { useContext } from 'react';

import { UIContext } from './UIProvider';

const useUI = () => {
    const {
        toasts,
        spinners,
        toastPush,
        toastPop,
        spinnerPush,
        spinnerPop,
        isMenu,
        setMenu,
        isTopBar,
        setTopBar,
        modal,
        modalOpen,
        modalClose,
        tutorialOpen,
    } = useContext(UIContext);

    return {
        toasts,
        spinners,
        toastPush,
        toastPop,
        spinnerPush,
        spinnerPop,
        isMenu,
        setMenu,
        isTopBar,
        setTopBar,
        modal,
        modalOpen,
        modalClose,
        tutorialOpen,
    };
};

export default useUI;
