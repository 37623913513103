import { useState } from 'react';

import EmptyCard from 'components/EmptyCard';
import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useNewsListQuery } from 'queries/news.queries.js';
import {
    Button,
    Column,
    Container,
    ContextMenu,
    FilterTabs,
    Header,
    Spinner,
} from 'ui';

import ViewHOC from '../ViewHOC';

import NewsList from './List';

const FILTERS = [
    {
        label: 'Wszystko',
        filters: {},
    },
    {
        label: 'Aktualności',
        filters: {
            type: 'news',
        },
    },
    {
        label: 'Ogłoszenia',
        filters: {
            type: 'info',
        },
    },
    {
        label: 'Galerie i multimedia',
        filters: {
            type: 'gallery',
        },
    },
];

const News = () => {
    const [filters, setFilters] = useState({});
    const { modalOpen } = useUI();

    const contextActions = [
        {
            text: 'Nowa aktualność',
            icon: 'news',
            main: true,
            onClick: () => modalOpen('addNews'),
        },
        {
            text: 'Nowe ogłoszenie',
            icon: 'whistle',
            onClick: () => modalOpen('addInfo'),
        },
        {
            text: 'Nowa galeria',
            icon: 'camera',
            onClick: () => modalOpen('addGallery'),
        },
    ];

    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetching,
        isFetched,
    } = useNewsListQuery(filters);

    const news = data.pages.flat(1);

    if (!isFetched) return <Spinner active />;

    return (
        <Container>
            <Column gap={'m'}>
                <Header title="Komunikacja" size="xl">
                    <ContextMenu actions={contextActions} large>
                        <Button color={'green'}>Utwórz</Button>
                    </ContextMenu>
                </Header>
                <FilterTabs
                    tabs={FILTERS}
                    filters={filters}
                    setFilters={setFilters}
                />
                {!isFetching && !news.length && <EmptyCard />}
                <NewsList
                    news={news}
                    isMore={!isFetchingNextPage && hasNextPage}
                    fetch={fetchNextPage}
                />
            </Column>
        </Container>
    );
};

const NewsScreen = ViewHOC(News, {
    menu: true,
    topbar: true,
    auth: {
        roles: [ROLES.OWNER, ROLES.ADMIN],
    },
});

export default NewsScreen;
