import { Link } from 'react-router-dom';

import { Avatar, Column, Row } from 'ui';

import GroupTag from '../../../ui/GroupTag/index.js';

import styles from './CoachCard.module.scss';

const CoachCard = ({ id, name, surname, avatar, groups }) => (
    <div className={styles.coachCard}>
        <Row gap="xs" align="center">
            <Link to={`/coaches/${id}`}>
                <Avatar size="l" text={`${name} ${surname}`} picture={avatar} />
            </Link>
            <Column gap="xxs">
                <Link className={styles.name} to={`/coaches/${id}`}>
                    {name}
                    <strong>{surname}</strong>
                </Link>
                <Row gap="xxs">
                    {groups.length
                        ? groups.map(g => (
                              <GroupTag group={g} size="s" key={g.id} />
                          ))
                        : '-'}
                </Row>
            </Column>
        </Row>
    </div>
);

export default CoachCard;
