import { useHistory, useLocation } from 'react-router-dom';

import { useMutationPlayerDelete } from 'queries/players.mutations.js';
import { Button, ModalActions, ModalContent, ModalHeader, ModalHOC } from 'ui';

const DeletePlayerDialog = ({ data: { id }, modalClose }) => {
    const location = useLocation();
    const history = useHistory();

    const mutation = useMutationPlayerDelete({
        onSuccess: () => {
            modalClose();
            if (location.pathname === `/players/${id}`) {
                history.push('/players');
            }
        },
    });

    const deletePlayer = () => {
        mutation.mutate(id);
    };

    return (
        <>
            <ModalHeader>Usuń zawodnika</ModalHeader>
            <ModalContent>Czy na pewno chcesz usunąć zawodnika?</ModalContent>
            <ModalActions>
                <Button color="red" onClick={deletePlayer}>
                    Usuń
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DeletePlayerDialog, {
    id: 'deletePlayer',
    size: 'small',
});
