import { useState } from 'react';
import { useDebounce } from 'react-use';
import cx from 'classnames';

import useAuth from 'contexts/useAuth';
import useUI from 'contexts/useUI';
import { useSearchQuery } from 'queries/search.queries.js';
import { Avatar, Icon, RoleTag, Row, Tooltip } from 'ui';

import Profile from './modals/Profile';
import Search from './Search';

import style from './TopBar.module.scss';

const TopBar = () => {
    const { isTopBar, modalOpen } = useUI();
    const { me, signOut } = useAuth();
    const [search, setSearch] = useState('');
    const [searchPhrase, setSearchPhrase] = useState('');

    const { data } = useSearchQuery(searchPhrase);

    const [, cancel] = useDebounce(
        () => {
            cancel();
            setSearchPhrase(search);
        },
        500,
        [search],
    );

    const openProfile = () => {
        modalOpen('profile');
    };

    return (
        <div className={cx(style.topbar, !isTopBar && style.hidden)}>
            <div>
                <div className={style.left}>
                    <div className={style.icon}>
                        <Icon icon="search" size="s" color="grey" />
                    </div>
                    <input
                        type="text"
                        placeholder="Wpisz by wyszukać w aplikacji"
                        onChange={e => setSearch(e.target.value)}
                        value={search}
                        onBlur={() => {
                            setTimeout(() => {
                                setSearch('');
                                setSearchPhrase('');
                            }, 100);
                        }}
                    />
                    <Search {...data} />
                </div>
                <div className={style.center} />
                <div className={style.right}>
                    <Row align="center">
                        <Row align="center" gap={'s'}>
                            <div className={style.user} onClick={openProfile}>
                                <Row gap={'xxs'} align="center">
                                    <Avatar
                                        text={`${me.name} ${me.surname}`}
                                        size="l"
                                        picture={me.avatar}
                                    />
                                    <div
                                        className={style.name}
                                        onClick={openProfile}>
                                        {me.name}
                                        <strong>{me.surname}</strong>
                                    </div>
                                </Row>
                            </div>
                            <div className={style.role}>
                                <RoleTag role={me.role} size="s" />
                            </div>
                            <Tooltip
                                text={me.organization.name}
                                position="bottom">
                                <div
                                    className={style.organization}
                                    style={{
                                        background: `url(${me.organization.avatar})`,
                                    }}
                                />
                            </Tooltip>
                        </Row>
                        <div className={style.button} onClick={openProfile}>
                            <Icon icon="settings" color="grey" />
                        </div>
                        <div className={style.button} onClick={signOut}>
                            <Icon icon="signout" color="grey" />
                        </div>
                    </Row>
                </div>
            </div>
            <Profile />
        </div>
    );
};

export default TopBar;
