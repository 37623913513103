import PropTypes from 'prop-types';

import Context from '../Context/index.js';

import OptionsView from './OptionsView';
import SelectView from './SelectView';

const Select = ({
    value,
    error,
    onChange,
    options,
    isTouched,
    isFocused,
    onFocus,
    onBlur,
    disabled,
    allselect,
    multiselect,
}) => {
    const selectHandler = val => {
        let newVal;
        if (multiselect) {
            if (value.find(v => v === val)) {
                newVal = value.filter(v => v !== val);
            } else {
                newVal = [...value, val];
            }
        } else {
            newVal = val;
        }

        if (newVal !== value) {
            onChange(newVal);
        }
    };

    const selectAllHandler = () => {
        onChange(options.map(o => o.value));
    };

    const propsOV = {
        options: options.map(o => ({
            ...o,
            selected: multiselect
                ? !!value.find(v => v === o.value)
                : value === o.value,
        })),
        onSelect: selectHandler,
        onSelectAll: allselect && multiselect ? selectAllHandler : undefined,
        multiselect: multiselect,
    };

    const propsSV = {
        error: error,
        values: multiselect
            ? options.filter(o => value.includes(o.value))
            : options.find(o => value === o.value),
        isAll: multiselect ? options.length === value.length : false,
        isMulti: multiselect,
        isTouched: isTouched,
        isFocused: isFocused,
        disabled: disabled,
    };

    return (
        <Context
            onOpen={onFocus}
            onClose={onBlur}
            position={'bottom-stretch'}
            context={<OptionsView {...propsOV} />}>
            <SelectView {...propsSV} />
        </Context>
    );
};

Select.propTypes = {
    value: PropTypes.any,
    error: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any.isRequired,
            labelText: PropTypes.any,
            value: PropTypes.any.isRequired,
        }),
    ),
    multiselect: PropTypes.bool,
    allselect: PropTypes.bool,
};

export default Select;
