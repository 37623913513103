import ListDataCell from 'components/ListDataCell';
import { NEWS_TYPES_MAP } from 'constants/logic';
import useUI from 'contexts/useUI';
import { Column, InfiniteLoader, List, ListActions, ListCell, Row } from 'ui';

const NewsList = ({ news, isMore, fetch }) => {
    const { modalOpen } = useUI();

    return (
        <Row>
            <Column size={1}>
                <List
                    header={
                        <>
                            <ListCell header width={24} />
                            <ListCell header width={170}>
                                Data
                            </ListCell>
                            <ListCell header width={300}>
                                <strong>Tytuł</strong>
                            </ListCell>

                            <ListCell width={200} header>
                                Autor
                            </ListCell>
                            <ListCell header>Grupy</ListCell>
                        </>
                    }
                    data={news}
                    renderRow={({ id, date, data, type, author, groups }) => {
                        const open = () =>
                            modalOpen(
                                `add${type[0].toUpperCase() + type.slice(1)}`,
                                {
                                    id,
                                },
                            );
                        const openDelete = () =>
                            modalOpen('deleteNews', { id });
                        return (
                            <>
                                <ListDataCell.Icon
                                    icon={NEWS_TYPES_MAP[type].icon}
                                    tooltip={NEWS_TYPES_MAP[type].name}
                                    onClick={open}
                                />
                                <ListDataCell.DateTime
                                    date={date}
                                    onClick={open}
                                />
                                <ListDataCell.Text
                                    text={data.title}
                                    width={300}
                                    onClick={open}
                                    strong
                                />
                                <ListDataCell.User user={author} width={200} />
                                <ListDataCell.Groups
                                    width={100}
                                    groups={groups}
                                    onClick={open}
                                />
                                <ListActions
                                    actions={[
                                        {
                                            title: 'usun',
                                            icon: 'delete',
                                            color: 'red',
                                            onClick: openDelete,
                                        },
                                        {
                                            title: 'edit',
                                            icon: 'edit',
                                            onClick: open,
                                        },
                                    ]}
                                />
                            </>
                        );
                    }}
                />
                {isMore && <InfiniteLoader onVisible={fetch} />}
            </Column>
        </Row>
    );
};

export default NewsList;
