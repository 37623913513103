import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import useAuth from 'contexts/useAuth.js';

import organizationsService from '../services/organizationsService.js';

export const useOrganizationInfoQuery = () => {
    const { me } = useAuth();
    return useQuery(
        ['organization', 'info'],
        () => organizationsService.get(me.organization._id),
        {
            initialData: null,
        },
    );
};

export const useOrganizationAdministratorsQuery = () => {
    const { me } = useAuth();
    return useInfiniteQuery(
        ['organization', 'administrators'],
        ({ pageParam }) => {
            return organizationsService.queryAdmins(me.organization._id, {
                limit: 10,
                offset: pageParam,
            });
        },
        {
            getNextPageParam: (a, b) => {
                if (!a.length) return undefined;
                return b.flat(1).length;
            },
        },
    );
};
