import React from 'react';
import cx from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import style from './PillBar.module.scss';
const PillBarOption = ({ label, fieldId, isActive }) => (
    <>
        <div className={style.label}>
            <span>{label}</span>
        </div>
        {isActive && (
            <motion.div
                className={style.pill}
                layoutId={`pillbar-pill-${fieldId}`}
            />
        )}
    </>
);

PillBarOption.propTypes = {
    label: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    fieldId: PropTypes.string.isRequired,
};

const PillBarOptionMemo = React.memo(PillBarOption);

const PillBar = ({ options, value, onChange, fieldId }) => (
    <div className={style.pillBar}>
        {options.map(o => (
            <button
                key={o.label}
                className={cx([
                    style.option,
                    o.value === value && style.active,
                ])}
                onClick={() => onChange(o.value)}>
                <PillBarOptionMemo
                    label={o.label}
                    isActive={o.value === value}
                    fieldId={fieldId}
                />
            </button>
        ))}
    </div>
);

PillBar.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        }),
    ).isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    fieldId: PropTypes.string,
};

export default React.memo(PillBar);
