import { useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';

import styles from './CalendarAgendaView.module.scss';

const DaysRibbonScroll = ({ children }) => {
    const scrollRef = useRef();
    const [translateX, setTranslateX] = useState(0);
    useGesture(
        {
            onDrag: ({ offset: [x], direction: [dx] }) => {
                if (dx) {
                    setTranslateX(x > 0 ? 0 : x);
                }
            },
        },
        { target: scrollRef },
    );

    return (
        <div ref={scrollRef}>
            <div
                className={styles.daysRibbonContent}
                style={{
                    transform: `translate(${translateX}px)`,
                }}>
                {children}
            </div>
        </div>
    );
};

export default DaysRibbonScroll;
