import cx from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import styles from './Tabs.module.scss';

const Tabs = ({ tabs }) => (
    <div className={cx(styles.tabs)}>
        {tabs.map(t => (
            <div
                className={cx(styles.tab, t.main && styles.main)}
                onClick={t.onClick}
                key={t.label}>
                <span>{t.label}</span>
                {t.active && (
                    <motion.div className={styles.pill} layoutId={`tabs`} />
                )}
            </div>
        ))}
    </div>
);

Tabs.propTypes = {
    tabs: PropTypes.array,
};

Tabs.defaultProps = {
    tabs: [],
};

export default Tabs;
