import { useMutationOrganizationDeleteAdmin } from 'queries/organization.mutations.js';
import {
    Button,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui/index.js';

const DeleteAdmin = ({ data: { idOrganization, idAdmin }, modalClose }) => {
    const mutation = useMutationOrganizationDeleteAdmin({
        onSuccess: () => {
            modalClose();
        },
    });

    const deletePlayer = () => {
        mutation.mutate({ idOrganization, idAdmin });
    };

    return (
        <>
            <ModalHeader>Usuń administratora</ModalHeader>
            <ModalContent>
                Czy na pewno chcesz usunąć administratora?
            </ModalContent>
            <ModalActions>
                <Button color="red" onClick={deletePlayer}>
                    Usuń
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DeleteAdmin, {
    id: 'deleteAdministrator',
    size: 'small',
});
