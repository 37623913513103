import { useEffect, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import useAuth from 'contexts/useAuth';
import { Toaster } from 'ui';

import NewVersion from '../Dashboard/modals/NewVersion.jsx';

import CookieAlert from './CookieAlert';
import Menu from './Menu';
import Routing from './Routing';
// import Spinner from './Spinner';
import TopBar from './TopBar';

import style from './App.module.scss';

const App = () => {
    const [isReady, setIsReady] = useState(false);
    const { restoreSession } = useAuth();

    useEffect(() => {
        smoothscroll.polyfill();
        restoreSession()
            .then(() => {
                if (this.props.match.path === '/') {
                    setTimeout(() => {
                        setIsReady(true);
                    }, 200);
                }
            })
            .catch(() => {
                setIsReady(true);
            });
    }, []);

    useEffect(() => {
        if (isReady) {
            document.getElementById('spinner').style.opacity = 0;

            setTimeout(() => {
                document.getElementById('spinner').remove();

                if (window.Headway && import.meta.env.VITE_HEADWAY_KEY) {
                    window.Headway.init({
                        selector: '.headway',
                        account: import.meta.env.VITE_HEADWAY_KEY,
                        translations: {
                            title: 'Co nowego w Goaly',
                            readMore: 'Czytaj więcej',
                            labels: {
                                new: 'Nowości',
                                improvement: 'Aktualizacje',
                                fix: 'Poprawki',
                            },
                            footer: 'Czytaj więcej',
                        },
                    });
                }
            }, 2000);
        }
    }, [isReady]);

    if (!isReady) {
        return <div />;
    }

    return (
        <div className={style.app}>
            <Menu />
            <TopBar />
            <Routing />
            <Toaster />
            {/*<Spinner />*/}
            <CookieAlert />
            <NewVersion />
            <div id="portal" />
        </div>
    );
};

export default App;
