import { Link, useHistory } from 'react-router-dom';

import useAuth from 'contexts/useAuth';
import { Button, Column, Header, Input, Label } from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util.js';

import useUI from '../../../contexts/useUI.js';

import { ReactComponent as FieldSVG } from './svg/field.svg';

import styles from './SignIn.module.module.scss';

const SignIn = () => {
    const { modalOpen } = useUI();

    const [form, { isFormValid, touchForm }] = useForm({
        email: {
            value: '',
            validators: [validators.isRequired(), validators.isEmail()],
        },
        password: {
            value: '',
            validators: [validators.isRequired()],
        },
    });
    const history = useHistory();

    const { signIn } = useAuth();

    const signInFunc = e => {
        e.preventDefault();

        if (!isFormValid) return false;

        signIn(form.email.value, form.password.value)
            .then(users => {
                if (users === 'only-parent') {
                    history.push('/auth/install-app');
                } else if (users === 0) {
                    history.push('/auth/create-school');
                } else if (users === 1) {
                    history.push(
                        localStorage.getItem('entryPoint') || '/dashboard',
                    );
                    localStorage.removeItem('entryPoint');
                } else {
                    history.push('/auth/role-selection');
                }
            })
            .catch(() => {});
    };

    const openAbout = () => {
        window.location = 'https://www.goaly.pl';
    };

    const openTerms = () => {
        modalOpen('termsandconditions');
    };

    const openPrivacy = () => {
        modalOpen('privacypolicy');
    };

    return (
        <div className={styles.singin}>
            <div className={styles.card}>
                <div className={styles.left}>
                    <Column gap={'m'}>
                        <Header title={'Zaloguj się'} />
                        {/*<div className={styles.header}>Logowanie</div>*/}
                        <form onSubmit={signInFunc}>
                            <Column gap={'xs'}>
                                <div>
                                    <Label>Adres email</Label>
                                    <Input {...form.email} />
                                </div>
                                <div>
                                    <Label>Hasło</Label>
                                    <Input
                                        type="password"
                                        {...form.password}
                                        autocomplete={['current-password']}
                                    />
                                    <button
                                        type="submit"
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </Column>
                        </form>

                        <Column gap={'xs'}>
                            <div>
                                <Link
                                    className={styles.forgot}
                                    to="/auth/reset-password">
                                    Zapomniałeś hasła?
                                </Link>
                            </div>
                            <div className={styles.text}>
                                Logując się akceptujesz{' '}
                                <strong onClick={openTerms}>Regulamin</strong>{' '}
                                oraz{' '}
                                <strong onClick={openPrivacy}>
                                    Politykę prywatności
                                </strong>{' '}
                                Goaly
                            </div>
                            <div>
                                <Button
                                    color="green"
                                    onClick={signInFunc}
                                    disabled={!isFormValid}
                                    onDisabledClick={touchForm}>
                                    Zaloguj
                                </Button>
                            </div>
                        </Column>
                    </Column>
                </div>
                <div className={styles.right}>
                    <FieldSVG />
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.left}>
                    <Column gap={'s'}>
                        <Header title={'Nie masz jeszcze konta?'} size="m" />
                        <div className={styles.text}>
                            Zarejestruj się już teraz. Skorzystaj z formularza i
                            ciesz się dostępem do wszystkich funkcjonalności
                            Goaly.
                        </div>
                        <div>
                            <Button href="/auth/signup">Rejestracja</Button>
                        </div>
                    </Column>
                </div>
                <div className={styles.right}>
                    <Column gap={'s'}>
                        <Header title={'Zainteresowany Goaly?'} size="m" />
                        <div className={styles.text}>
                            Zarządzasz szkołą sportową i jesteś ciekawy co
                            potrafi Goaly? Sprawdz nasze najważniejsze funkcje.
                        </div>
                        <div>
                            <Button onClick={openAbout}>
                                Zobacz na www.goaly.pl
                            </Button>
                        </div>
                    </Column>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
