import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import venuesService from '../services/venuesService.js';

export const useVenuesAllQuery = () => {
    return useQuery(['venues', 'list-all'], () => venuesService.query(), {
        initialData: [],
    });
};

export const useVenueQuery = id => {
    return useQuery(['venues', 'single', id], () => venuesService.get(id), {
        enabled: !!id,
        initialData: null,
    });
};

export const useGetVenueEventsQuery = (id, params) => {
    return useQuery(
        ['venues', 'single', id, 'events', params],
        () => venuesService.getEvents(id, params),
        {
            enabled: !!id,
            initialData: [],
        },
    );
};

export const useVenuesQuery = () => {
    return useInfiniteQuery(
        ['venues', 'list'],
        ({ pageParam }) => {
            return venuesService.query({
                limit: 20,
                offset: pageParam,
            });
        },
        {
            getNextPageParam: (a, b) => {
                if (!a.length) return undefined;
                return b.flat(1).length;
            },
        },
    );
};
