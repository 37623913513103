import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import Routing from './Routing';

class RoutingContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            key: '',
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { location } = props;
        const key =
            ((location.pathname === '/' ||
                location.pathname.substring(1, 5) === 'auth') &&
                'auth') ||
            (location.pathname.substring(1, 10) === 'calendar/' &&
                'calendar') ||
            (location.pathname.substring(1, 9) === 'coaches/' && 'coach') ||
            (location.pathname.substring(1, 8) === 'groups/' && 'group') ||
            (location.pathname.substring(1, 9) === 'players/' && 'player') ||
            (location.pathname.substring(1, 9) === 'parents/' && 'parent') ||
            (location.pathname.substring(1, 8) === 'venues/' && 'venues') ||
            (location.pathname.substring(1, 10) === 'settings/' &&
                'settings') ||
            location.pathname;

        if (key !== state.key) {
            return { key };
        }
        return null;
    }

    componentDidUpdate() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    render() {
        const props = {
            location: this.props.location,
            keyString: this.state.key,
        };
        return <Routing {...props} />;
    }
}

export default withRouter(RoutingContainer);
