import { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from 'ui';

import styles from './SelectView.module.scss';

const SelectView = ({
    values,
    error,
    isAll,
    isMulti,
    isTouched,
    isFocused,
    disabled,
}) => {
    let text;

    if (isMulti) {
        if (isAll) {
            if (values.length === 0) {
                text = '';
            } else {
                text = 'Wszystko';
            }
        } else if (values.length === 1) {
            text = values[0].label;
        } else if (values.length > 1) {
            text = `${values.length} wybranych`;
        } else if (isTouched && values.length === 0) {
            text = '0 wybranych';
        } else if (!isTouched && values.length === 0) {
            text = '';
        }
    } else {
        text = values && values.label ? values.label : '';
    }

    const isErrorVisible = useMemo(
        () => error && isTouched && !isFocused,
        [error, isTouched, isFocused],
    );

    return (
        <div
            className={cx(
                styles.root,
                isFocused && styles.focused,
                isErrorVisible && styles.error,
                disabled && styles.disabled,
            )}>
            <div className={styles.value}>{text}</div>
            <Icon size="s" icon="chevron-down" color="grey-500" />
            {isErrorVisible && <div className={styles.error}>{error}</div>}
        </div>
    );
};

SelectView.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    values: PropTypes.any,
    isAll: PropTypes.bool,
    isMulti: PropTypes.bool,
    isTouched: PropTypes.bool,
    errorVisibility: PropTypes.bool,
};

SelectView.defaultProps = {
    error: '',
    values: undefined,
    isAll: false,
    isMulti: false,
    isTouched: false,
    errorVisibility: false,
};

export default SelectView;
