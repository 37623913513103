import { useRouteMatch } from 'react-router-dom';

import EmptyCard from 'components/EmptyCard';
import GroupCard from 'components/GroupCard';
import useUI from 'contexts/useUI';
import { useCoachGroupsQuery } from 'queries/coaches.queries.js';
import { Button, Column, Header, Row, Spinner } from 'ui';

import AddGroups from './modals/AddGroups';

const Groups = () => {
    const {
        params: { id },
    } = useRouteMatch();
    const { data: groups, isFetched } = useCoachGroupsQuery(id);
    const { modalOpen } = useUI();
    const addGroupsToCoach = () =>
        modalOpen('addGroupsToCoach', { idCoach: id });

    if (!isFetched) return <Spinner active />;

    return (
        <Column gap={'m'}>
            <Header title="Grupy" size="l">
                <Button color={'green'} size={'l'} onClick={addGroupsToCoach}>
                    Zarządzaj grupami
                </Button>
            </Header>
            {!groups?.length && (
                <EmptyCard
                    action={addGroupsToCoach}
                    actionLabel="Dodaj pierwszą grupę"
                />
            )}
            <Row gap={'s'} wrap="wrap">
                {groups.map(group => (
                    <GroupCard {...group} key={group.id} onClick={undefined} />
                ))}
            </Row>
            <AddGroups />
        </Column>
    );
};

export default Groups;
