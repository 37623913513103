import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';

import CalendarAgendaView from 'components/CalendarAgendaView';
import useUI from 'contexts/useUI';
import {
    useGroupEventsCyclicalQuery,
    useGroupEventsQuery,
} from 'queries/groups.queries.js';
import { Column, Header, List, ListActions, ListCell, Spinner } from 'ui';

import ListDataCell from '../../../../components/ListDataCell/index.js';

const WEEKDAY_NAMES = {
    1: 'W każdy podziedziałek',
    2: 'W każdy wtorek',
    3: 'W każdą środę',
    4: 'W każdy czwartek',
    5: 'W każdy piątek',
    6: 'W każdą sobotę',
    7: 'W każdą niedzielę',
};

const CalendarAgenda = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const now = useMemo(() => DateTime.now(), []);

    const { modalOpen } = useUI();

    const { data, isFetched } = useGroupEventsQuery(id, {
        dateStart: { $gte: now.toString() },
    });

    const { data: dataC, isFetched: isFetchedC } =
        useGroupEventsCyclicalQuery(id);

    if (!isFetchedC || !isFetched) return <Spinner active />;

    return (
        <Column gap={'m'}>
            {isFetchedC && dataC?.length > 0 && (
                <Column gap={'m'}>
                    <Header title="Wydarzenia cykliczne" size="l" />
                    <List
                        header={
                            <>
                                <ListCell header width={200}>
                                    Kiedy
                                </ListCell>
                                <ListCell width={100}>Godziny</ListCell>
                                <ListCell width={250} header>
                                    Tytuł
                                </ListCell>
                                <ListCell header>Obiekt</ListCell>
                            </>
                        }
                        data={dataC}
                        renderRow={({
                            id: idEventCyclical,
                            title,
                            weekday,
                            venue,
                            timeEnd,
                            timeStart,
                        }) => {
                            return (
                                <>
                                    <ListDataCell.Text
                                        width={200}
                                        text={WEEKDAY_NAMES[weekday]}
                                    />
                                    <ListDataCell.TimeFrame
                                        width={100}
                                        timeStart={timeStart}
                                        timeEnd={timeEnd}
                                        text={WEEKDAY_NAMES[weekday]}
                                    />
                                    <ListDataCell.Text
                                        width={250}
                                        text={title}
                                    />
                                    <ListDataCell.Text text={venue?.name} />
                                    <ListActions
                                        actions={[
                                            {
                                                title: 'Usuń',
                                                icon: 'delete',
                                                color: 'red',
                                                onClick: () =>
                                                    modalOpen(
                                                        'deleteEventCyclical',
                                                        {
                                                            id: idEventCyclical,
                                                        },
                                                    ),
                                            },
                                            {
                                                title: 'Edytuj',
                                                icon: 'edit',
                                                onClick: () =>
                                                    modalOpen(
                                                        'editEventCyclical',
                                                        {
                                                            id: idEventCyclical,
                                                        },
                                                    ),
                                            },
                                        ]}
                                    />
                                </>
                            );
                        }}
                    />
                </Column>
            )}
            <Column gap={'m'}>
                <Header title="Najbliższe wydarzenia" size="l" />
                <CalendarAgendaView events={data} isFetching={!isFetched} />
            </Column>
        </Column>
    );
};

export default CalendarAgenda;
