import { Column, Row } from '..';

import style from './Warning.module.scss';

const Warning = ({ title, content, children }) => (
    <div className={style.warning}>
        <Row gap="xs">
            <Column size={0}>
                <div className={style.icon}>
                    <span>!</span>
                </div>
            </Column>
            <Column gap="xxs">
                <div className={style.title}>{title}</div>
                <div className={style.content}>{content}</div>
                {children}
            </Column>
        </Row>
    </div>
);
export default Warning;
