import cx from 'classnames';
import PropTypes from 'prop-types';

import Context from '../Context';
import Icon from '../Icon';

import style from './ContextMenu.module.scss';

const ContextMenu = ({ children, actions }) => (
    <Context
        context={
            <div className={style.contextMenu}>
                <div className={style.content}>
                    {actions.map(a => (
                        <div
                            key={a.text}
                            className={cx(
                                style.option,
                                !a.onClick && style.disabled,
                                style[`color-${a.color}`],
                            )}
                            onClick={a.onClick}>
                            {a.icon && (
                                <Icon icon={a.icon} color={a.color} size="s" />
                            )}
                            <span className={style.optionText}>{a.text}</span>
                        </div>
                    ))}
                </div>
            </div>
        }>
        {children}
    </Context>
);

ContextMenu.propTypes = {
    actions: PropTypes.array,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

ContextMenu.defaultProps = {
    actions: [],
};

export default ContextMenu;
