import fetch from 'utils/fetch';

export default {
    query: query => fetch.get('parents', { params: query }).then(r => r.data),
    post: parent => fetch.post('parents', parent).then(r => r.data),

    get: id => fetch.get(`parents/${id}`).then(r => r.data),
    patch: (id, delta) => fetch.patch(`parents/${id}`, delta).then(r => r.data),
    delete: id => fetch.delete(`parents/${id}`).then(r => r.data),

    getPlayers: id => fetch.get(`parents/${id}/players`).then(r => r.data),
    getEvents: id => fetch.get(`parents/${id}/events`).then(r => r.data),
};
