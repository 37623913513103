import { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Space.module.scss';

const SIZES = {
    xxl: 64,
    xl: 48,
    l: 32,
    m: 24,
    s: 16,
    xs: 12,
    xxs: 8,
};

const Space = ({
    children,
    direction,
    className,
    style,
    onClick,
    ...sizeProps
}) => {
    const spaceStyle = useMemo(() => {
        const mt = SIZES[sizeProps.mt || sizeProps.mv || sizeProps.m] || 0;
        const mb = SIZES[sizeProps.mb || sizeProps.mv || sizeProps.m] || 0;
        const mr = SIZES[sizeProps.mr || sizeProps.mh || sizeProps.m] || 0;
        const ml = SIZES[sizeProps.ml || sizeProps.mh || sizeProps.m] || 0;

        const pt = SIZES[sizeProps.pt || sizeProps.pv || sizeProps.p] || 0;
        const pb = SIZES[sizeProps.pb || sizeProps.pv || sizeProps.p] || 0;
        const pr = SIZES[sizeProps.pr || sizeProps.ph || sizeProps.p] || 0;
        const pl = SIZES[sizeProps.pl || sizeProps.ph || sizeProps.p] || 0;
        return {
            margin: `${mt}px ${mr}px ${mb}px ${ml}px`,
            padding: `${pt}px ${pr}px ${pb}px ${pl}px`,
        };
    }, [sizeProps]);
    return (
        <div
            className={cx([
                styles.space,
                styles[`direction-${direction}`],
                className,
            ])}
            style={{
                ...spaceStyle,
                ...style,
            }}
            onClick={onClick}>
            {children}
        </div>
    );
};

const SizeProp = PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl']);

Space.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    direction: PropTypes.oneOf(['row', 'column']),
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    m: SizeProp,
    mv: SizeProp,
    mh: SizeProp,
    mt: SizeProp,
    mr: SizeProp,
    mb: SizeProp,
    ml: SizeProp,
    p: SizeProp,
    pv: SizeProp,
    ph: SizeProp,
    pt: SizeProp,
    pr: SizeProp,
    pb: SizeProp,
    pl: SizeProp,
};

Space.defaultProps = {
    direction: 'column',
    onClick: null,
};

export default Space;
