import { useHistory, useLocation } from 'react-router-dom';

import { useMutationCoachDelete } from 'queries/coaches.mutations.js';
import { Button, ModalActions, ModalContent, ModalHeader, ModalHOC } from 'ui';

const DeleteCoachDialog = ({ data: { id }, modalClose }) => {
    const location = useLocation();
    const history = useHistory();

    const mutation = useMutationCoachDelete({
        onSuccess: () => {
            modalClose();
            if (location.pathname.includes(`/coaches/${id}`)) {
                history.push('/coaches');
            }
        },
    });

    const deleteCoach = () => {
        mutation.mutate(id);
    };

    return (
        <>
            <ModalHeader>Usuń trenera</ModalHeader>
            <ModalContent>Czy na pewno chcesz usunąć trenera?</ModalContent>
            <ModalActions>
                <Button color="red" onClick={deleteCoach}>
                    Usuń
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DeleteCoachDialog, {
    id: 'deleteCoach',
    size: 'medium',
});
