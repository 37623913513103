import { ModalContent, ModalHeader, ModalHOC } from 'ui';

const DialogTerms = () => (
    <>
        <ModalHeader>
            Regulamin świadczenia usług dostępnych w ramach Goaly
        </ModalHeader>
        <ModalContent>
            <div>
                <p>Dział I. Postanowienia ogólne</p>
                <p>
                    Niniejszy regulamin świadczenia usług dostępnych w ramach
                    Goaly określa zasady i warunki techniczne świadczenia przez
                    Usługodawcę Usług drogą elektroniczną, o których mowa w
                    Dziale II.
                </p>
                <p>Użyte w Regulaminie pojęcia oznaczają:</p>
                <p>
                    Usługodawca – oznacza Kroppa Piotr Polus z siedzibą przy ul.
                    Konopnickiej 27, 32-590 Libiąż, REGON: 368959192, NIP:
                    6282119205.
                </p>
                <p>
                    Usługobiorca – oznacza osobę prawną, jednostkę organizacyjną
                    nieposiadającą osobowości prawnej albo osobę fizyczną
                    prowadzącą działalność gospodarczą, która korzysta z Usług
                    udostępnianych przez Goaly na mocy niniejszego Regulaminu.
                </p>
                <p>
                    Usługi – oznacza usługi w rozumieniu ustawy z dnia 18 lipca
                    2002 r. o świadczeniu usług drogą elektroniczną świadczone
                    przez Usługodawcę.
                </p>
                <p>
                    Cennik – należy przez to rozumieć ofertę przedstawiającą
                    oplaty za korzystanie z uslug platnych.
                </p>
                <p>
                    Formularz rejestracyjny – dokument elektroniczny, którego
                    wypełnienie przez Usługobiorcę stanowi jeden z warunków
                    korzystania z Usług świadczonych drogą elektroniczną przez
                    Goaly.
                </p>
                <p>
                    Hasło – ciąg znaków literowych, cyfrowych oraz znaków
                    specjalnych, znany jedynie osobie uprawnionej do pracy w
                    Systemie.
                </p>
                <p>
                    Konto – dokument elektroniczny przypisany do Usługobiorcy,
                    powstały w wyniku wypełnienia Formularza rejestracyjnego
                    oraz spełnienia innych wymogów niezbędnych, by rozpocząć i
                    kontynuować pracę w Systemie, o których mowa w niniejszym
                    Regulaminie.
                </p>
                <p>
                    Regulamin – niniejszy dokument, określający warunki
                    korzystania z Usług świadczonych przez Usługodawcę drogą
                    elektroniczną.
                </p>
                <p>
                    Opłata – łączne miesięczne wynagrodzenie z tytułu posiadania
                    Konta, w wysokości określonej w Cenniku, a w przypadku Usług
                    świadczonych przez okres dłuższy niż jeden miesiąc, suma
                    opłat miesięcznych z tytułu posiadania Konta za cały okres
                    świadczenia Usług w Okresie Rozliczeniowym;
                </p>
                <p>
                    System – oprogramowanie zainstalowane na serwerach
                    wykorzystywanych przez Usługodawcę, udostępniane
                    Usługobiorcy na komputerze Usługobiorcy poprzez przeglądarkę
                    internetową, dostępne przez protokół HTTPS po zalogowaniu
                    przy użyciu loginu i hasła.
                </p>
                <p>
                    Okres rozliczeniowy – okres świadczenia Usługi dostępu do
                    Konta, za który należna jest Opłata określona w Cenniku,
                    wynoszący miesiąc kalendarzowy lub jego wielokrotność.
                </p>
                <p>
                    Dział II. Rodzaje i zakres usług świadczonych w oparciu o
                    Regulamin
                </p>
                <p>Usługodawca świadczy usługi płatne i bezpłatne.</p>
                <p>
                    W ramach usług bezpłatnych Usługobiorca, po dokonaniu
                    rejestracji, może korzystać z 30-dniowej darmowej, testowej
                    wersji Systemu, zwanej dalej okresem bezpłatnym. W tym
                    okresie Usługobiorca ma możliwość korzystania ze wszystkich
                    modułów Systemu.
                </p>
                <p>
                    W ramach Usług płatnych Usługobiorca, po dokonaniu
                    rejestracji (lub po wygaśnięciu darmowego okresu
                    bezpłatnego). Ceny Usług zostały określone w Cenniku
                    dostępnym pod adresem www.goaly.com.
                </p>
                <p>
                    Zasady dotyczące płatności zostały opisane w Dziale V
                    niniejszego Regulaminu.
                </p>
                <p>
                    Zakazane jest dostarczanie przez Usługobiorcę treści o
                    charakterze bezprawnym. W razie otrzymania przez Usługodawcę
                    urzędowego zawiadomienia lub wiarygodnej wiadomości o
                    bezprawnym charakterze przechowywanych danych przez
                    Usługobiorcę, Usługodawca będzie uprawniony uniemożliwić
                    dostęp do Konta Usługobiorcy lub rozwiązać umowę o
                    świadczenie usług objętych niniejszym Regulaminem. W
                    przypadku uzyskania wiarygodnej wiadomości o bezprawnym
                    charakterze danych, Usługodawca zawiadomi, za pośrednictwem
                    poczty elektronicznej (na adres e-mail podany w formularzu
                    rejestracyjnym), Usługobiorcę o zamiarze uniemożliwienia mu
                    dostępu do Konta do czasu wyjaśnienia sprawy lub o
                    rozwiązaniu umowy w trybie natychmiastowym.
                </p>
                <p>Dział III. Warunki świadczenia usług</p>
                <p>
                    Wymagania techniczne dotyczące korzystania z Usług, o
                    których mowa w Dziale II Regulaminu są następujące:
                </p>
                <p>Połączenie z Internetem;</p>
                <p>
                    Przeglądarka internetowa o standardzie odpowiadającym
                    aplikacji:
                </p>
                <p>
                    Chrome w wersji nie starszej niż 3 ostatnie wersje liczone
                    od wersji major (aktualne informacje:
                    https://en.wikipedia.org/wiki/Google_Chrome_version_history)
                </p>
                <p>
                    FireFox w wersji nie starszej niż 3 ostatnie wersje liczone
                    od wersji major (aktualne informacje:
                    https://en.wikipedia.org/wiki/Firefox_version_history)
                </p>
                <p>
                    Safari – aktualną stabilną wersję nie starszą niż rok
                    (aktualne informacje:
                    https://en.wikipedia.org/wiki/Safari_version_history )
                </p>
                <p>
                    Microsoft Edge w wersji nie starszej niż 3 numery wersji
                    major wstecz w gałęzi stable, nie starsze niż 1 rok
                    (aktualne informacje
                    https://en.wikipedia.org/wiki/Microsoft_Edge
                </p>
                <p>Konto pocztowe e-mail.</p>
                <p>
                    Usługobiorca przyjmuje do wiadomości, że do prawidłowego
                    wyświetlania treści na Brand Journal’u wymagana jest
                    przeglądarka, która spełnia następujące wymagania
                    techniczne:
                </p>
                <p>
                    Wspiera aktualne standardy zabezpieczeń HTTP – Transport
                    Layer Security (TLS), od wersji 1.0, w połączeniu z tzw.
                    mocnym szyfrowaniem (aktualne informacje
                    (https://en.wikipedia.org/wiki/Transport_Layer_Security
                </p>
                <p>
                    Wspiera CSS Flexible Box Layout Module (aktualne informacje:
                    https://caniuse.com/#search=flex)
                </p>
                <p>
                    Usługodawca informuje, a Usługobiorca przyjmuje do
                    wiadomości, że korzystanie z sieci Internet może wiązać się
                    z ryzykiem w postaci szkody, którą Usługobiorca może ponieść
                    na skutek zagrożeń występujących w sieci Internet, a w
                    szczególności włamania do systemu informatycznego
                    Usługobiorcy, przejęcia haseł przez osoby trzecie,
                    zainfekowania systemu informatycznego Usługobiorcy wirusami.
                </p>
                <p>
                    Usługodawca oświadcza, że będzie się starał w miarę
                    możliwości informować Usługobiorców o czasowej
                    niedostępności Usług z wyprzedzeniem.
                </p>
                <p>
                    Usługodawca zastrzega sobie prawo odmówienia lub
                    zaprzestania świadczenia usługi w przypadku, gdy dane
                    Usługobiorcy wskazane w formularzu rejestracyjnym są
                    nieprawdziwe albo zachodzi ryzyko, że dane te mogą być
                    nieprawdziwe albo niekompletne. Usługodawca zastrzega sobie
                    możliwość żądania dostarczenia przez Usługobiorcę stosownych
                    dokumentów potwierdzających prawdziwość i kompletność danych
                    podanych w formularzu rejestracyjnym.
                </p>
                <p>
                    Usługobiorca zobowiązuje się do przestrzegania wszystkich
                    postanowień Regulaminu.
                </p>
                <p>
                    Usługobiorca zobowiązuje się powstrzymać od wszelkich
                    działań mogących utrudnić bądź destabilizować funkcjonowanie
                    Systemu lub korzystanie z Usług.
                </p>
                <p>
                    Usługobiorca ponosi całkowitą i wyłączną odpowiedzialność za
                    wprowadzane przez siebie do Systemu treści, w szczególności
                    dane osobowe, w tym potwierdza, że pozyskał je w sposób
                    legalny i posiada: zgody na przetwarzanie danych osobowych
                    od dysponentów tych danych na cele realizacji przesyłania
                    informacji handlowych drogą elektroniczną z wykorzystaniem
                    Serwisu oraz Usług;
                </p>
                <p>
                    W razie otrzymania przez Usługodawcę urzędowego
                    zawiadomienia lub wiarygodnej wiadomości o bezprawnym
                    charakterze przechowywanych danych przez Usługobiorcę,
                    Usługodawca będzie uprawniony uniemożliwić dostęp do Konta
                    Usługobiorcy.
                </p>
                <p>
                    Zakazane jest dostarczanie przez Usługobiorcę treści o
                    charakterze bezprawnym. W razie otrzymania przez Usługodawcę
                    urzędowego zawiadomienia lub wiarygodnej wiadomości o
                    bezprawnym charakterze przechowywanych danych przez
                    Usługobiorcę, Usługodawca będzie uprawniony uniemożliwić
                    dostęp do Konta Usługobiorcy lub rozwiązać umowę o
                    świadczenie usług objętych niniejszym Regulaminem. W
                    przypadku uzyskania wiarygodnej wiadomości o bezprawnym
                    charakterze danych Usługodawca zawiadomi, za pośrednictwem
                    poczty elektronicznej (na adres e-mail podany w formularzu
                    rejestracyjnym), Usługobiorcę o zamiarze uniemożliwienia mu
                    dostępu do Konta lub o rozwiązaniu umowy w trybie
                    natychmiastowym.
                </p>
                <p>DZIAŁ IV. Zawieranie i rozwiązywanie umowy</p>
                <p>
                    Rozpoczęcie korzystania przez Usługobiorcę z Usług o
                    charakterze płatnym możliwe jest jedynie w przypadku
                    uprzedniego zapoznania się i akceptacji postanowień
                    zawartych w Regulaminie, a także po dokonaniu rejestracji i
                    uzyskaniu dostępu do indywidualnego Konta. Ponadto, dostęp
                    do Usług, o których mowa w niniejszym Regulaminie, możliwy
                    jest dopiero po uiszczeniu opłaty, zgodnie z Cennikiem,
                    chyba że odrębna umowa o współpracy zawarta między
                    Usługodawcą, a Usługobiorcą wprowadza inne zapisy. Warunkiem
                    rozpoczęcia pracy w Systemie jest także zawarcie umowy
                    powierzenia przetwarzania danych z Usługodawcą, o której
                    mowa w Dziale VII. Łączne spełnienie warunków opisanych w
                    niniejszym punkcie jest równoznaczne z zawarciem umowy o
                    świadczenie usług drogą elektroniczną, której przedmiotem
                    jest korzystanie z Systemu.
                </p>
                <p>
                    Rozpoczęcie korzystania przez Usługobiorcę z Usług o
                    charakterze bezpłatnym możliwe jest jedynie w przypadku
                    uprzedniego zapoznania się i akceptacji postanowień
                    zawartych w Regulaminie, a także po dokonaniu rejestracji i
                    uzyskaniu dostępu do indywidualnego Konta. Rozpoczęcie
                    korzystania z opisanych tu usług jest jednoznaczne z
                    zawarciem umowy o świadczenie usług drogą elektroniczną.
                </p>
                <p>
                    Usługodawca zastrzega sobie prawo odmówienia lub
                    zaprzestania świadczenia Usługi w przypadku, gdy dane
                    Usługobiorcy wskazane w formularzu rejestracyjnym są
                    nieprawdziwe albo zachodzi ryzyko, że dane te mogą być
                    nieprawdziwe albo niekompletne. Usługodawca zastrzega sobie
                    możliwość żądania dostarczenia przez Usługobiorcę stosownych
                    dokumentów potwierdzających prawdziwość i kompletność danych
                    podanych w formularzu rejestracyjnym.
                </p>
                <p>
                    Usługodawca ma prawo wypowiedzieć umowę o świadczenie usług
                    bez zachowania terminu wypowiedzenia i zaprzestać
                    świadczenia Usług w następujących przypadkach:
                </p>
                <p>
                    naruszenia przez Usługobiorcę istotnych postanowień
                    Regulaminu,
                </p>
                <p>
                    umieszczania przez Usługobiorcę treści niezgodnych z
                    obowiązującymi przepisami prawa lub dobrymi obyczajami,
                </p>
                <p>
                    wykorzystywania przez Usługobiorcę Usług niezgodnie ze jej
                    przeznaczeniem.
                </p>
                <p>
                    Umowa o świadczenie usług wygasa z chwilą zakończenia
                    Okresu, na jaki Usługa została wykupiona przez Usługobiorcę.
                </p>
                <p>
                    Rozwiązanie umowy o świadczenie Usług następuje z chwilą
                    zaistnienia jednego z poniższych zdarzeń:
                </p>
                <p>
                    W razie dokonywania Opłat z wykorzystaniem karty płatniczej
                    lub karty kredytowej, z upływem Okresu Rozliczeniowego, za
                    który Usługobiorca uiścił Opłatę, o ile w pierwszym dniu
                    nowego okresu rozliczeniowego Usługobiorca nie uiści Opłaty
                    na kolejny Okres Rozliczeniowy;
                </p>
                <p>
                    W razie dokonywania Opłat z pośrednictwem tradycyjnego
                    przelewu, istnieje możliwość wypowiedzenia umowy o
                    świadczenie usług z zachowaniem 1 miesięcznego okresu
                    wypowiedzenia.
                </p>
                <p>
                    Termin wypowiedzenia, o którym mowa w ust. 6 pkt. 6.2.,
                    liczony jest od ostatniego dnia okresu rozliczeniowego, w
                    którym zostało dokonane wypowiedzenie.
                </p>
                <p>
                    Wypowiedzenie o którym mowa w ust. 4 powyżej następuje drogą
                    pisemną pod rygorem nieważności na adresy Stron umowy.
                </p>
                <p>
                    Pod rygorem nieważności, wypowiedzenie o którym mowa w ust.
                    6 powyżej następuje drogą pisemną na adresy Stron umowy lub
                    drogą mailową, wysłane na adres contact@goaly.pl
                </p>
                <p>DZIAŁ V. Płatności</p>
                <p>
                    Usługobiorca, korzystający z usług o charakterze płatnym, ma
                    możliwość ich go w następujący sposób:
                </p>
                <p>
                    tradycyjnym przelewem na podstawie wystawionej faktury VAT
                    na podany w niej rachunek bankowy, przesłanej na wskazany
                    przez Usługobiorcę adres email.
                </p>
                <p>
                    Opłaty wnoszone są z góry za kolejne Okresy rozliczeniowe,
                    chyba że odrębna umowa o współpracy zawarta między
                    Usługodawcą, a Usługobiorcą wprowadza inny tryb opłat.
                </p>
                <p>
                    Okres rozliczeniowy kończy się w dniu poprzedzającym datę,
                    która odpowiada dacie rozpoczęcia korzystania z platformy
                    przez Usługobiorcę w kolejnym miesiącu świadczenia Usług
                    (bądź jego wielokrotności – w zależności od okresu
                    obowiązywania umowy o świadczenie Usług), a gdyby takiego
                    dnia w kolejnym miesiącu nie było – w ostatnim dniu danego
                    miesiąca.
                </p>
                <p>
                    Po zaksięgowaniu płatności, Usługobiorca otrzyma fakturę VAT
                    poczta elektroniczną na wskazany adres. Na życzenie
                    Usługobiorcy, faktura VAT może zostać mu przesłana również
                    pocztą tradycyjną na wskazany adres. Usługodawca wystawi ją
                    wówczas w ciągu 7 dni od dnia złożenia życzenia i wyśle na
                    adres podany podczas rejestracji.
                </p>
                <p>
                    W przypadku nieopłacenia faktury/ niewniesienia Opłaty, po
                    upływie wskazanego na fakturze terminu płatności,
                    Usługodawca zastrzega sobie prawo do zaprzestania
                    świadczenia Usług i zablokowania dostępu do nich.
                </p>
                <p>DZIAŁ VI. Reklamacje</p>
                <p>
                    Wszelkie ewentualne reklamacje Usługobiorca bądź inna osoba
                    odwiedzająca stronę www.goaly.pl winni zgłaszać na piśmie na
                    adres wskazany w oznaczeniu Usługodawcy, bądź za
                    pośrednictwem poczty elektronicznej na adres e-mail:
                    contact@goaly.pl
                </p>
                <p>
                    Zgłoszenie reklamacyjne winno zawierać szczegółowy opis
                    zdarzenia uzasadniającego zgłoszenie reklamacji, imię i
                    nazwisko oraz adres e-mail Usługobiorcy bądź innej osoby
                    odwiedzającej stronę www.goaly.pl.
                </p>
                <p>
                    Usługodawca rozpatrzy zgłoszenie reklamacyjne w terminie 14
                    dni od dnia otrzymania zgłoszenia.
                </p>
                <p>
                    Odpowiedź na reklamację wysyłana jest wyłącznie na adres
                    e-mail podany w zgłoszeniu reklamacyjnym.
                </p>
                <p>DZIAŁ VII. Przetwarzanie i ochrona danych osobowych</p>
                <p>
                    Administrator zapewnia poufność i ochronę danych osobowych
                    wskazanych w ust. 3 i ust. 12 poniżej na zasadach
                    określonych w niniejszym Regulaminie oraz w Polityce Ochrony
                    Danych Osobowych.
                </p>
                <p>
                    Administratorem danych, o których mowa w ust. 3 poniżej,
                    które są podawane przez Usługobiorcę podczas rejestracji w
                    Systemie, jest Usługodawca.
                </p>
                <p>
                    Usługodawca jest administratorem danych osobowych podawanych
                    przez Usługobiorcę, tj. następujących kategorii danych:
                </p>
                <p>
                    imiona i nazwiska przedsiębiorców prowadzących działalność
                    gospodarczą;
                </p>
                <p>
                    imiona i nazwiska osób kontaktowych z ramienia Usługobiorcy;
                </p>
                <p>
                    adresy e-mail i numery telefonów komórkowych osób wskazanych
                    w pkt. 3.1. i 3.2. powyżej, a także ich numery IP i dane
                    cookies;
                </p>
                <p>
                    Dane wskazane w ust. 3 powyżej będą przetwarzane przez
                    Usługodawcę wyłącznie w następujących celach:
                </p>
                <p>
                    związanych z wykonaniem umowy o świadczenie Usług drogą
                    elektroniczną, tj. w związku z realizacją Usług objętych
                    umową o świadczenie usług drogą elektroniczną (w tym
                    związanych z rozpatrywaniem reklamacji, prowadzenia
                    statystyk),
                </p>
                <p>
                    związanych z marketingiem bezpośrednim własnych produktów i
                    usług. Informacje marketingowe dotyczące produktów i usług
                    Usługodawcy będą przesyłane do czasu wniesienia przez
                    Usługobiorcę sprzeciwu wobec przetwarzania dotyczących go
                    danych osobowych na potrzeby ww. marketingu, w tym
                    profilowania, w zakresie, w jakim przetwarzanie będzie
                    związane z marketingiem bezpośrednim Usługodawcy, a także
                </p>
                <p>
                    pod warunkiem wyrażenia zgody – na adres poczty
                    elektronicznej wskazany w formularzu zapisu na newsletter
                    Usługodawca będzie przekazywał informacje handlowe oraz
                    przesyłał treści marketingowe odnoszące się do podmiotów
                    trzecich.
                </p>
                <p>
                    Podanie danych ma charakter dobrowolny, przy czym
                    przetwarzanie danych osobowych przez Usługodawcę na cele
                    wskazane w pkt. 4.1. powyżej jest niezbędne do wykonania
                    umowy o świadczenie Usług drogą elektroniczną.
                </p>
                <p>
                    Osoba, której dane dotyczą ma prawo w dowolnym momencie
                    wycofać wyrażone zgody. Wycofanie zgody nie wpływa jednak na
                    zgodność z prawem przetwarzania, którego dokonano na
                    podstawie zgody przed wycofaniem.
                </p>
                <p>
                    Wycofanie wyrażonych zgód na przetwarzanie danych osobowych
                    przez Usługodawcę może nastąpić poprzez złożenie
                    oświadczenia w jakiejkolwiek formie, w tym także drogą
                    elektroniczną na adres e-mail Usługodawcy tj. na adres
                    e-mail contact@goaly.pl.
                </p>
                <p>
                    Każda osoba ma prawo dostępu do swoich danych osobowych
                    przetwarzanych przez Usługodawcę oraz ma możliwość ich
                    sprostowania oraz usuwania.
                </p>
                <p>
                    Podczas rejestracji Usługobiorcy w Systemie, Usługodawca
                    udostępni mu wszelkie informacje niezbędne w związku ze
                    zbieraniem danych osobowych przez Usługodawcę, a także
                    informacje niezbędne do zapewnienia rzetelności i
                    przejrzystości przetwarzania.
                </p>
                <p>
                    Usługodawca może publikować bannery i linki do innych stron,
                    i portalów internetowych. Korzystając z bannera lub linku,
                    Usługobiorca przechodzi na stronę należącą do innego
                    właściciela.
                </p>
                <p>
                    W stosunku do danych osobowych wprowadzanych przez
                    Usługobiorcę podczas korzystania z poszczególnych
                    funkcjonalności Systemu, innych niż dane osobowe należące do
                    użytkowników Konta, administratorem danych jest
                    Usługobiorca.
                </p>
                <p>
                    Usługobiorca, na potrzeby realizacji Usług powierza
                    Usługodawcy przetwarzanie danych osobowych wprowadzanych do
                    Systemu przez Usługobiorcę. Powierzone Usługodawcy dane
                    osobowe obejmują w szczególności, ale nie wyłącznie
                    następujące kategorie danych, których treść jest uzależniona
                    od kategorii osób dla których dedykowane są treści przez
                    Usługobiorcę:
                </p>
                <p>
                    dane osobowe trenerów (w tym imiona, nazwiska, adresy email,
                    numery telefonów),
                </p>
                <p>
                    dane osobowe rodziców (w tym imiona, nazwiska, adresy email,
                    numery telefonów, adresy fizyczne),
                </p>
                <p>
                    dane osobowe zawodników (w tym imiona, nazwiska, adresy
                    email, numery telefonów, adresy fizyczne, dane
                    biometryczne).
                </p>
                <p>
                    Celem przetwarzania powierzonych Usługodawcy danych
                    osobowych, o których mowa w ust. 12 powyżej jest realizacja
                    Usług, tj. przesyłanie informacji handlowych za
                    pośrednictwem Serwisu w zakresie wynikającym z zawartej z
                    Usługobiorcą umowy o świadczeniu Usług drogą elektroniczną.
                </p>
                <p>
                    Przetwarzanie powierzonych Usługodawcy danych osobowych
                    odbywać się będzie na podstawie umowy zawartej pomiędzy
                    Usługobiorcą a Usługodawcą w formie pisemnej. Usługobiorca
                    otrzyma od Usługodawcy w chwili rejestracji do Systemu na
                    jego adres e-mail umowę powierzenia danych osobowych.
                    Zawarcie umowy następować będzie poprzez podpisanie przez
                    obie strony umowy powierzenia danych osobowych.
                </p>
                <p>
                    Usługodawca, w związku z przetwarzaniem powierzonych mu
                    danych osobowych przez Usługobiorcę korzysta z zewnątrznych
                    usług wyszczególnionych w dokumencie Polityka Prywantości
                    dostępnym na www.goaly.pl, na co Usługobiorca wyraża zgodę.
                </p>
                <p>
                    Usługodawca oświadcza, że stosuje środki techniczne i
                    organizacyjne mające na celu zabezpieczenie danych
                    osobowych, których jest administratorem, a także
                    powierzonych mu danych osobowych. Usługodawca oświadcza, że
                    opracował i wdrożył Politykę Ochrony Danych Osobowych oraz
                    instrukcję zarządzania systemem informatycznym służącym do
                    przetwarzania danych, o których mowa w rozporządzeniu
                    Ministra Spraw Wewnętrznych i Administracji z dnia 29
                    kwietnia 2004 r. w sprawie dokumentacji przetwarzania danych
                    osobowych oraz warunków technicznych i organizacyjnych,
                    jakim powinny odpowiadać urządzenia i systemy informatyczne
                    służące do przetwarzania danych osobowych.
                </p>
                <p>
                    Usługodawca oświadcza, że każda osoba (np. pracownik
                    etatowy, osoba świadcząca czynności na podstawie umów
                    cywilnoprawnych, inne osoby pracujące na rzecz Usługodawcy),
                    która zostanie dopuszczona do przetwarzania powierzonych
                    przez Usługobiorcę danych osobowych zostanie zobowiązana do
                    zachowania tych danych w tajemnicy. Tajemnica ta obejmuje
                    również wszelkie informacje dotyczące sposobów
                    zabezpieczenia powierzonych do przetwarzania danych
                    osobowych.
                </p>
                <p>
                    Umowa powierzenia przetwarzania danych, o której mowa w
                    niniejszym Dziale, zawierana jest na czas określony,
                    powiązany z czasem świadczenia Usług wykupionych przez
                    Usługobiorcę. Rozwiązanie umowy świadczenia drogą
                    elektroniczną Usług udostępnianych w ramach Systemu jest
                    jednoznaczne z rozwiązaniem umowy powierzenia przetwarzania
                    danych.
                </p>
                <p>
                    Po rozwiązaniu umowy świadczenia Usług drogą elektroniczną
                    wszystkie dane osobowe przekazane przez Usługobiorcę na
                    rzecz Usługodawcy ulegają zniszczeniu. Na życzenie
                    Usługobiorcy, Usługodawca dostarczy protokół zniszczenia
                    powierzonych danych. Protokół taki zostanie przekazany
                    Usługobiorcy w terminie 14 dni kalendarzowych liczonych od
                    daty skierowania takiego życzenia do Usługodawcy.
                </p>
                <p>
                    Dane osobowe są przetwarzane z uwzględnieniem zasady:
                    rozliczalności, adekwatności oraz rzetelności.
                </p>
                <p>
                    Usługodawca zastrzega sobie prawo udostępniania danych
                    urzędom i organom uprawnionym do przetwarzania danych na
                    podstawie obowiązujących przepisów prawa np. organom
                    ścigania, sądom.
                </p>
                <p>
                    DZIAŁ VIII. Zasady odpowiedzialności i ochrona praw
                    własności intelektualnej
                </p>
                <p>
                    Usługodawca zwraca uwagę, że w związku ze świadczeniem Usług
                    udostępnia Usługobiorcy treści chronione prawem własności
                    intelektualnej, w szczególności utwory chronione prawem
                    autorskim (np. wybór i układ treści, loga/logotypy, nazwy,
                    multimedia, zdjęcia, etc.).
                </p>
                <p>
                    Usługobiorca zobowiązany jest przestrzegać obowiązujących
                    przepisów prawa. Wszelkie kopiowanie, wprowadzanie zmian, a
                    także publiczne odtwarzanie udostępnianych treści bez zgody
                    Usługodawcy jest zakazane, o ile nie wynika to z odrębnych
                    umów zawartych przez Usługobiorcę z Usługodawcą lub
                    bezwzględnie obowiązujących przepisów prawa.
                </p>
                <p>DZIAŁ IX. Przepisy końcowe</p>
                <p>
                    Usługodawca ma prawo do opublikowania „nazwy” i adresu
                    strony internetowej Usługobiorcy na swojej liście
                    referencyjnej, jeżeli Usługobiorca nie zgłosi w tej kwestii
                    zastrzeżenia.
                </p>
                <p>
                    Niniejszy Regulamin obowiązuje od dnia 17 kwietnia 2018
                    roku.
                </p>
                <p>
                    Regulamin jest udostępniony Usługobiorcy nieodpłatnie za
                    pośrednictwem witryny internetowej, znajdującej się pod tym
                    http://goaly.pl/regulamin.pdf, która umożliwia Usługobiorcy
                    pobranie, utrwalenie i wydrukowanie Regulaminu.
                </p>
                <p>
                    Usługodawca zastrzega sobie prawo zmiany Regulaminu i
                    Cennika, przy czym zmiany Cennika nie mają wpływu na
                    świadczenie Usług już opłaconych. Usługobiorca jest związany
                    nową treścią Regulaminu w przypadku wyrażenia zgody na jego
                    treść. W przypadku, gdy Usługobiorca nie wyrazi zgody na
                    nową treść Regulaminu, Umowa ulega rozwiązaniu z dniem
                    ostatniego w pełni opłaconego Okresu rozliczeniowego.
                </p>
                <p>Prawem właściwym jest prawo polskie.</p>
            </div>
        </ModalContent>
    </>
);

export default ModalHOC(DialogTerms, {
    id: 'termsandconditions',
    size: 'medium',
});
