import { useMutation, useQueryClient } from '@tanstack/react-query';

import useUI from 'contexts/useUI';
import parentsService from 'services/parentsService.js';

import playersService from '../services/playersService.js';

export const useMutationParentAdd = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(parentsService.post, {
        onSuccess: (data, variables) => {
            toastPush({
                title: 'Dodano rodzica',
                text: `${data.name} ${data.surname}`,
            });

            queryClient.invalidateQueries(['parents', 'list']);
            queryClient.invalidateQueries(['parents', 'single', data.id]);

            if (variables.addToPlayer) {
                queryClient.invalidateQueries([
                    'players',
                    'single',
                    variables.addToPlayer,
                ]);
            }

            if (onSuccess) {
                onSuccess(data);
            }
        },
    });
};

export const useMutationParentPatch = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(({ id, delta }) => parentsService.patch(id, delta), {
        onSuccess: (data, { id }) => {
            toastPush({
                title: 'Wyedytowano rodzica',
                text: `${data.name} ${data.surname}`,
            });

            queryClient.invalidateQueries(['parents', 'list']);
            queryClient.invalidateQueries(['parents', 'single', id]);
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationParentDelete = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(parentsService.delete, {
        onSuccess: (data, { id }) => {
            toastPush({
                title: 'Usunięto rodzica',
            });

            queryClient.invalidateQueries(['parents', 'list']);
            queryClient.invalidateQueries(['parents', 'single', id]);
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationParentAddPlayer = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idParent, idPlayer }) =>
            playersService.addParent(idPlayer, idParent),
        {
            onSuccess: (data, { idParent, idPlayer }) => {
                toastPush({
                    title: 'Dodano zawodnika',
                });

                queryClient.invalidateQueries(['parents', 'list']);
                queryClient.invalidateQueries(['parents', 'single', idParent]);
                queryClient.invalidateQueries(['players', 'single', idPlayer]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationParentRemovePlayer = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idParent, idPlayer }) =>
            playersService.removeParent(idPlayer, idParent),
        {
            onSuccess: (data, { idParent, idPlayer }) => {
                toastPush({
                    title: 'Usunięto zawodnika',
                    text: `${data.name} ${data.surname}`,
                });

                queryClient.invalidateQueries(['parents', 'list']);
                queryClient.invalidateQueries(['parents', 'single', idParent]);
                queryClient.invalidateQueries(['players', 'single', idPlayer]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};
