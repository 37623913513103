import { useHistory, useLocation } from 'react-router-dom';

import { useMutationVenueDelete } from 'queries/venues.mutations.js';
import {
    Button,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui/index.js';

const DeleteVenueModal = ({ data: { id }, modalClose }) => {
    const location = useLocation();
    const history = useHistory();

    const mutation = useMutationVenueDelete({
        onSuccess: () => {
            modalClose();
            if (location.pathname.includes(`/venues/${id}`)) {
                history.push('/venues');
            }
        },
    });

    const deleteVenue = () => {
        mutation.mutate(id);
    };

    return (
        <>
            <ModalHeader>Usuń obiekt</ModalHeader>
            <ModalContent>Czy na pewno chcesz usunąć obiekt?</ModalContent>
            <ModalActions>
                <Button color="red" onClick={deleteVenue}>
                    Usuń
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DeleteVenueModal, {
    id: 'deleteVenue',
    size: 'small',
});
