import { Link } from 'react-router-dom';

import { EVENTS_TYPES_MAP } from 'constants/logic';
import { Button, Column, Counter, Html, Icon, Row, Tag, Tooltip } from 'ui';
import {
    formatAddress,
    formatDateFullWithDay,
    formatHour,
    formatTime,
} from 'utils/formatters';

import GroupTag from '../../../../ui/GroupTag/index.js';
import PercentCircle from '../../../../ui/PercentCircle/index.js';
import AvatarWithStatus from '../../../AvatarWithStatus/index.js';

import styles from './EventCardDetails.module.scss';

const EventCardDetails = ({
    id,
    type,
    title,
    groups,
    dateStart,
    dateEnd,
    minutes,
    venue,
    desc,
    participants = [],
    attendance,
}) => {
    const players = participants.filter(({ role }) => role === 'player');
    const parents = participants.filter(({ role }) => role === 'parent');
    const coaches = participants.filter(({ role }) => role === 'coach');
    return (
        <Row className={styles.card}>
            <Column gap="s">
                <Row gap="xxs">
                    <Icon icon={EVENTS_TYPES_MAP[type].icon} color="grey" />
                    <Tag text={EVENTS_TYPES_MAP[type].name} color="green" />
                </Row>
                <div className={styles.title}>{title}</div>
                {groups?.length > 0 && (
                    <Row gap="xxs">
                        {groups.map(g => (
                            <GroupTag group={g} size="s" key={g.id} />
                        ))}
                    </Row>
                )}
                <Row gap="xs" align={'start'}>
                    <div className={styles.left}>
                        <Icon icon="calendar" color="grey" />
                    </div>
                    <div className={styles.right}>
                        <div className={styles.bold}>
                            {formatDateFullWithDay(dateStart)}
                        </div>
                        <div className={styles.regular}>
                            {formatHour(dateStart)} - {formatHour(dateEnd)}{' '}
                            <Counter size={'s'} text={formatTime(minutes)} />
                        </div>
                    </div>
                </Row>
                {venue && (
                    <Row gap="xs" align={'start'}>
                        <div className={styles.left}>
                            <Icon icon="marker" color="grey" />
                        </div>
                        <div className={styles.right}>
                            <div className={styles.bold}>{venue.name}</div>
                            <div className={styles.regular}>
                                {formatAddress(venue.address)}
                            </div>
                        </div>
                    </Row>
                )}
                {desc && (
                    <Row gap="xs" align={'start'}>
                        <div className={styles.left}>
                            <Icon icon="text" color="grey" />
                        </div>
                        <div>
                            <div className={styles.small}>
                                <Html>{desc}</Html>
                            </div>
                        </div>
                    </Row>
                )}
                {coaches.length || players.length || parents.length ? (
                    <Row gap="xs" align={'start'}>
                        <div className={styles.left}>
                            <Icon icon="player-standing" color="grey" />
                        </div>
                        <Column gap="xs">
                            {coaches.length ? (
                                <Column gap="xxs">
                                    <div className={styles.participantsHeader}>
                                        <span>Trenerzy</span>
                                        <Counter
                                            size={'s'}
                                            text={coaches.length}
                                        />
                                    </div>
                                    <Row gap="xxxs" wrap="wrap">
                                        {coaches.map(c => (
                                            <Tooltip
                                                text={`${c.name} ${c.surname}`}
                                                key={c.id}
                                                display="inline">
                                                <Link
                                                    to={`/coaches/${c.id}`}
                                                    key={c.id}>
                                                    <AvatarWithStatus
                                                        size="m"
                                                        text={`${c.name} ${c.surname}`}
                                                        picture={c.avatar}
                                                        status={c.status}
                                                    />
                                                </Link>
                                            </Tooltip>
                                        ))}
                                    </Row>
                                </Column>
                            ) : undefined}
                            {players.length ? (
                                <Column gap="xxs">
                                    <div className={styles.participantsHeader}>
                                        <span>Zawodnicy</span>
                                        <Counter
                                            size={'s'}
                                            text={players.length}
                                        />
                                    </div>
                                    <Row gap="xxxs" wrap="wrap">
                                        {players.map(c => (
                                            <Tooltip
                                                text={`${c.name} ${c.surname}`}
                                                key={c.id}
                                                display="inline">
                                                <Link
                                                    to={`/players/${c.id}`}
                                                    key={c.id}>
                                                    <AvatarWithStatus
                                                        size="m"
                                                        text={`${c.name} ${c.surname}`}
                                                        picture={c.avatar}
                                                        status={c.status}
                                                    />
                                                </Link>
                                            </Tooltip>
                                        ))}
                                    </Row>
                                </Column>
                            ) : undefined}
                            {parents.length ? (
                                <Column gap="xxs">
                                    <div className={styles.participantsHeader}>
                                        <span>Rodzice</span>
                                        <Counter
                                            size={'s'}
                                            text={parents.length}
                                        />
                                    </div>
                                    <Row gap="xxxs" wrap="wrap">
                                        {parents.map(c => (
                                            <Tooltip
                                                text={`${c.name} ${c.surname}`}
                                                key={c.id}
                                                display="inline">
                                                <Link
                                                    to={`/parents/${c.id}`}
                                                    key={c.id}>
                                                    <AvatarWithStatus
                                                        size="m"
                                                        text={`${c.name} ${c.surname}`}
                                                        picture={c.avatar}
                                                        status={c.status}
                                                    />
                                                </Link>
                                            </Tooltip>
                                        ))}
                                    </Row>
                                </Column>
                            ) : undefined}
                        </Column>
                    </Row>
                ) : null}
                <Row align="end">
                    <Button href={`/calendar/${id}`} size="l" color="green">
                        Zobacz szczegóły
                    </Button>
                </Row>
                {attendance > 0 && (
                    <div className={styles.attendance}>
                        <PercentCircle percent={attendance} size="m" />
                    </div>
                )}
            </Column>
        </Row>
    );
};

export default EventCardDetails;
