import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import coachesService from '../services/coachesService.js';

export const useCoachQuery = id => {
    return useQuery(['coaches', 'single', id], () => coachesService.get(id), {
        initialData: null,
    });
};

export const useCoachEventsQuery = (id, params) => {
    return useQuery(
        ['coaches', 'single', id, 'events', params],
        () => coachesService.getEvents(id, params),
        {
            initialData: [],
        },
    );
};

export const useCoachGroupsQuery = id => {
    return useQuery(
        ['coaches', 'single', id, 'groups'],
        () => coachesService.getGroups(id),
        {
            initialData: [],
        },
    );
};

export const useCoachesListQuery = () => {
    return useInfiniteQuery(
        ['coaches', 'list'],
        ({ pageParam }) => {
            return coachesService.query({
                limit: 10,
                offset: pageParam,
            });
        },
        {
            getNextPageParam: (a, b) => {
                if (!a.length) return undefined;
                return b.flat(1).length;
            },
        },
    );
};
