import { Fragment } from 'react';

import EventCard from 'components/CalendarAgendaView/EventCard';
import Feed from 'components/Feed';
import {
    useDashboardCoachesQuery,
    useDashboardEventsQuery,
    useDashboardFeedQuery,
    useDashboardGroupsQuery,
} from 'queries/dashboard.queries.js';
import { Column, Container, Header, Row, Tutorial } from 'ui';
import { formatDateFullWithDay } from 'utils/formatters';

import CoachCard from '../CoachCard';
import GroupCard from '../GroupCard';

import tutorial from './Dashboard.tutorial.json';

import styles from './Dashboard.module.scss';

const Dashboard = () => {
    const { data: coaches } = useDashboardCoachesQuery();
    const { data: groups } = useDashboardGroupsQuery();
    const { data: events } = useDashboardEventsQuery();

    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useDashboardFeedQuery();
    const feed = data.pages.flat(1);

    return (
        <>
            <Container>
                <Column gap={'m'}>
                    <Row>
                        <Header title="Tablica administratora" size={'xl'} />
                    </Row>
                    <Row>
                        <Column gap="xs">
                            <Header title="Grupy w szkole" size="m" />
                            <Row gap="xs" wrap="wrap">
                                {groups.map(g => (
                                    <GroupCard {...g} key={g.id} />
                                ))}
                            </Row>
                        </Column>
                    </Row>
                    <Row gap="m" align="start">
                        <Column gap="xs">
                            <Header title="Wiadomości ze szkoły" size="m" />
                            <Feed
                                items={feed}
                                load={fetchNextPage}
                                isMore={!isFetchingNextPage && hasNextPage}
                            />
                        </Column>
                        <Column gap="m">
                            <Column gap="xs">
                                <Header title="Kalendarz wydarzeń" size="m" />
                                <Column gap="xs">
                                    {events.map((e, i) => {
                                        if (
                                            i === 0 ||
                                            new Date(e.dateStart).getDay() !==
                                                new Date(
                                                    events[i - 1].dateStart,
                                                ).getDay()
                                        ) {
                                            return (
                                                <Fragment key={e.id}>
                                                    <div
                                                        className={
                                                            styles.dayName
                                                        }>
                                                        {formatDateFullWithDay(
                                                            e.dateStart,
                                                        )}
                                                    </div>
                                                    <EventCard {...e} />
                                                </Fragment>
                                            );
                                        }
                                        return <EventCard key={e.id} {...e} />;
                                    })}
                                </Column>
                            </Column>
                            <Column gap="xs">
                                <Header title="Trenerzy" size="m" />
                                <Column gap={'xs'}>
                                    {coaches.map(c => (
                                        <CoachCard key={c.id} {...c} />
                                    ))}
                                </Column>
                            </Column>
                        </Column>
                    </Row>
                </Column>
            </Container>
            <Tutorial id="dashboard" steps={tutorial} />
        </>
    );
};

export default Dashboard;
