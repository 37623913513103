import PropTypes from 'prop-types';

import { Avatar, Dot } from 'ui';

import style from './AvatarWithStatus.module.scss';

const AvatarWithStatus = ({ status, ...props }) => {
    return (
        <div className={style.userAvatar}>
            <div className={status === 'absent' ? style.transparent : null}>
                <Avatar {...props} />
            </div>

            {status && (
                <div className={style.status}>
                    <Dot
                        color={status === 'present' ? 'green' : 'red'}
                        size={props.size === 'l' ? 'xs' : 'xxs'}
                    />
                </div>
            )}
        </div>
    );
};

AvatarWithStatus.propTypes = {
    ...Avatar.propTypes,
    status: PropTypes.oneOf(['absent', 'present']),
};

AvatarWithStatus.defaultProps = {
    ...Avatar.defaultProps,
    status: null,
};

export default AvatarWithStatus;
