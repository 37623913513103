import { Button, Container } from 'ui';

import styles from './Error404.module.scss';

const CreateSchool = () => (
    <div className={styles.error404}>
        <Container>
            <div className={styles.header}>Ooops!</div>
            <div className={styles.text}>Wystąpił nieoczekiwany błąd</div>
            <div className={styles.buttons}>
                <Button color="green" onClick={() => location.reload()}>
                    Przeładuj stronę
                </Button>
            </div>
        </Container>
    </div>
);

export default CreateSchool;
