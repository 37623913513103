import { useHistory } from 'react-router-dom';

import EmptyCard from 'components/EmptyCard';
import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useVenuesQuery } from 'queries/venues.queries.js';
import {
    Button,
    Column,
    Container,
    Header,
    List,
    ListActions,
    ListCell,
    Spinner,
} from 'ui';
import InfiniteLoader from 'ui/InfiniteLoader';
import useACL from 'utils/useACL';

import ListDataCell from '../../../components/ListDataCell/index.js';
import ViewHOC from '../../ViewHOC';
import AddVenueDialog from '../modals/AddEditVenueModal';

import styles from './Venues.module.scss';

const Venues = () => {
    const { modalOpen } = useUI();
    const history = useHistory();
    const { isRole } = useACL();

    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isFetched,
    } = useVenuesQuery();

    const venues = data.pages.flat(1);

    if (!isFetched) return <Spinner active />;

    return (
        <div className={styles.view}>
            <Container>
                <Column gap={'m'}>
                    <Header title="Obiekty" size={'xl'}>
                        {isRole([ROLES.ADMIN, ROLES.OWNER]) && (
                            <Button
                                color={'green'}
                                onClick={() => modalOpen('addEditVenue')}>
                                Nowy obiekt
                            </Button>
                        )}
                    </Header>
                    {!isFetching && !venues.length && (
                        <EmptyCard
                            action={() => modalOpen('addEditVenue')}
                            actionLabel="Dodaj pierwszy obiekt"
                        />
                    )}
                    <List
                        header={
                            <>
                                <ListCell width={400} header>
                                    Nazwa
                                </ListCell>
                                <ListCell header>Miasto</ListCell>
                            </>
                        }
                        data={venues}
                        renderRow={({ id, name, address }) => {
                            const open = () => {
                                history.push(`/venues/${id}`);
                            };
                            return (
                                <>
                                    <ListDataCell.Text
                                        width={400}
                                        text={name}
                                        strong
                                        onClick={open}
                                    />
                                    <ListDataCell.Text
                                        text={address.city}
                                        onClick={open}
                                    />
                                    <ListActions
                                        actions={[
                                            {
                                                title: 'edit',
                                                icon: 'edit',
                                                onClick: open,
                                            },
                                        ]}
                                    />
                                </>
                            );
                        }}
                    />
                    {!isFetchingNextPage && hasNextPage && (
                        <InfiniteLoader onVisible={fetchNextPage} />
                    )}
                </Column>
            </Container>
            <AddVenueDialog />
        </div>
    );
};

export default ViewHOC(Venues, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
