import { Container, Header } from 'ui';

import styles from './Payments.module.scss';

const Payments = () => (
    <div className={styles.view}>
        <Container>
            <div>
                <Header title="Platnosci" size={'xl'} />
            </div>
            Sekcja niedostępna
        </Container>
    </div>
);

export default Payments;
