export default {
    isRequired: error => val => {
        if (Array.isArray(val) && !val.length) {
            return error || 'Pole wymagane';
        }
        if (!val) {
            return error || 'Pole wymagane';
        }
        return null;
    },

    minLength: (min, error) => val => {
        if (val.length < min) {
            return error || `Pole powinno zawierac min. ${min} znaków`;
        }
        return null;
    },

    maxLength: (max, error) => val => {
        if (val.length > max) {
            return error || `Pole powinno zawierac max. ${max} znaków`;
        }
        return null;
    },
    isNumber: error => val => {
        if (isNaN(val)) {
            return error || `Nieprawidłowa liczba`;
        }
        return null;
    },
    minNumber: (min, error) => val => {
        if (val < min) {
            return error || `Wartość nie może być niższa niż ${min}`;
        }
        return null;
    },
    maxNumber: (max, error) => val => {
        if (val < max) {
            return error || `Wartość nie może być niższa niż ${max}`;
        }
        return null;
    },
    isEmail: error => value => {
        const emailRegEx =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (Array.isArray(value)) {
            return value.find(v => !emailRegEx.test(v))
                ? 'Niepoprawny adres e-mail'
                : null;
        } else {
            if (!emailRegEx.test(value)) {
                return error || 'Niepoprawny adres e-mail';
            }
        }

        return null;
    },
    isPassword: error => val => {
        if (val.length < 8) {
            return error || 'Hasło powinno zawierać conajmniej 8 znaków';
        }

        if (!/\d/.test(val)) {
            return error || 'Hasło powinno zawierać conajmniej 1 cyfrę';
        }

        if (!/[A-Z]/.test(val)) {
            return error || 'Hasło powinno zawierać conajmniej 1 wielką literę';
        }
    },
};
