import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import qs from 'qs';

const defaultOptions = {
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'brackets' }),
};
const Fetch = axios.create(defaultOptions);

Fetch.interceptors.request.use(config => {
    const newConfig = { ...config };
    const token = window.localStorage.getItem(
        import.meta.env.VITE_AUTHTOKEN_NAME,
    );

    if (token) {
        newConfig.headers.Authorization = `JWT ${token}`;
        return newConfig;
    }

    return config;
});

Fetch.interceptors.response.use(
    response => response,
    error => {
        if (
            error &&
            error.response &&
            ![401, 403, 404].includes(error.response.status)
        ) {
            const headers = {
                ...error.config.headers,
            };

            Bugsnag.notify(error, {
                context: error.config.url,
                metaData: {
                    response: error.response,
                },
                request: {
                    url: error.config.url,
                    method: error.config.method,
                    headers,
                    data: error.config.data,
                },
            });
        }

        return Promise.reject(error);
    },
);

export default Fetch;
