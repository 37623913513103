import { DateTime, Settings } from 'luxon';

Settings.defaultLocale = 'pl';

export const createDateTime = date => {
    if (date instanceof DateTime) {
        return date;
    } else if (date instanceof Date) {
        return DateTime.fromJSDate(date);
    } else if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date)) {
        return DateTime.fromISO(date);
    } else if (/\d{4}-\d{2}-\d{2}/.test(date)) {
        return DateTime.fromFormat(date, 'yyyy-LL-dd');
    } else {
        return DateTime.fromString(date);
    }
};

const DAY_MS = 86400000;
const MINUTE_MS = 60000;
const STARTMS = new Date(2018, 0, 1);
const isToday = date => {
    const today = DateTime.now();
    return date.toISODate() === today.toISODate();
};
const addDays = (date, days) => {
    const newDate = new Date(date.getTime() + days * DAY_MS);
    const TZOffset = newDate.getTimezoneOffset() - date.getTimezoneOffset();
    if (TZOffset === 0) {
        return newDate;
    }
    return new Date(newDate.getTime() + TZOffset * MINUTE_MS);
};
const setWeekday = (date, weekday) => {
    const day = date.getDay();
    const daysInterval = day ? day - weekday : 7 - weekday;
    return addDays(new Date(date.setHours(0, 0, 0, 0)), -daysInterval);
};

const dayToDate = day => new Date(2018, 0, day);
const dateToDay = date => Math.ceil((new Date(date) - STARTMS + 1) / DAY_MS);

export { addDays, dateToDay, dayToDate, isToday, setWeekday };
