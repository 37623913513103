import { useEffect } from 'react';

import { useMutationParentPatch } from 'queries/parents.mutations.js';
import { useParentQuery } from 'queries/parents.queries.js';
import {
    Button,
    Column,
    Input,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';
import useForm from 'utils/useForm';

const DialogEditAddress = ({ data: { idParent }, modalClose }) => {
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        'address.street': {
            value: '',
        },
        'address.city': {
            value: '',
        },
        'address.postal': {
            value: '',
        },
    });

    const { data } = useParentQuery(idParent);

    useEffect(() => {
        if (data) {
            form['address.street'].setValue(data.address?.street);
            form['address.city'].setValue(data.address?.city);
            form['address.postal'].setValue(data.address?.postal);
        }
    }, [data]);

    const mutation = useMutationParentPatch({
        onSuccess: modalClose,
    });

    const save = () => {
        if (!isFormValid) return;
        mutation.mutate({ id: idParent, delta: getFormValue() });
    };
    return (
        <>
            <ModalHeader>Edytuj informacje adresowe</ModalHeader>
            <ModalContent>
                <Column gap={'xs'}>
                    <div>
                        <Label>Ulica</Label>
                        <Input {...form['address.street']} />
                    </div>
                    <div>
                        <Label>Miasto</Label>
                        <Input {...form['address.city']} />
                    </div>
                    <div>
                        <Label>Kod pocztowy</Label>
                        <Input {...form['address.postal']} />
                    </div>
                </Column>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid}
                    color="green"
                    onClick={save}
                    onDisabledClick={touchForm}>
                    Zapisz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DialogEditAddress, {
    id: 'editAddress',
    title: 'Edytuj informacje adresowe',
    size: 'small',
});
