import useAuth from 'contexts/useAuth.js';
import { useMutationOrganizationAddAdmin } from 'queries/organization.mutations.js';
import {
    Button,
    Column,
    Input,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui/index.js';
import useForm from 'utils/useForm.js';
import validatorsUtil from 'utils/validators.util.js';

const DialogAddAdmin = ({ modalClose }) => {
    const { me } = useAuth();
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        name: {
            value: '',
            validators: [validatorsUtil.isRequired()],
        },
        surname: {
            value: '',
            validators: [validatorsUtil.isRequired()],
        },
        email: {
            value: '',
            validators: [validatorsUtil.isRequired(), validatorsUtil.isEmail()],
        },
    });

    const mutation = useMutationOrganizationAddAdmin({
        onSuccess: modalClose,
    });

    const addAdmin = () => {
        if (!isFormValid) return;
        mutation.mutate({
            idOrganization: me.organization._id,
            admin: getFormValue(),
        });
    };

    return (
        <>
            <ModalHeader>Nowy administrator</ModalHeader>
            <ModalContent>
                <Column gap={'xs'}>
                    <div>
                        <Label>Imię</Label>
                        <Input type="text" {...form.name} />
                    </div>
                    <div>
                        <Label>Nazwisko</Label>
                        <Input type="text" {...form.surname} />
                    </div>
                    <div>
                        <Label>Adres email</Label>
                        <Input type="text" {...form.email} />
                    </div>
                </Column>
            </ModalContent>
            <ModalActions>
                <Button
                    color="green"
                    onClick={addAdmin}
                    disabled={!isFormValid}
                    onDisabledClick={touchForm}>
                    Utwórz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DialogAddAdmin, {
    id: 'addAdministrator',
    title: 'Dodaj nowego administratora',
    size: 'small',
});
