import { Button, ModalContent, ModalHeader, ModalHOC } from 'ui';

import cyclicalImg from './cycle.png';
import singleImg from './single.png';

import styles from './AddEventChoose.module.scss';

const AddEventChoose = ({ data, modalOpen }) => (
    <>
        <ModalHeader>Nowe wydarzenie</ModalHeader>
        <ModalContent>
            <div className={styles.root}>
                <div
                    className={styles.card}
                    onClick={() => modalOpen('addEventSingle', data)}>
                    <div className={styles.header}>Wydarzenie pojedyncze</div>
                    <div>
                        <img
                            className={styles.image}
                            src={singleImg}
                            alt="Pojedyncze wydarzenie"
                        />
                    </div>
                    <div className={styles.desc}>
                        Wydarzenie odbywające się jeden raz. Odpowiednie
                        ustawienia dla pojedynczych treningów.
                    </div>

                    <div className={styles.tags}>
                        <Button color="green">Utwórz</Button>
                    </div>
                </div>
                <div
                    className={styles.card}
                    onClick={() => modalOpen('addEventCyclical', data)}>
                    <div className={styles.header}>Wydarzenie cykliczne</div>
                    <div>
                        <img
                            className={styles.image}
                            src={cyclicalImg}
                            alt="Cykliczne wydarzenie"
                        />
                    </div>
                    <div className={styles.desc}>
                        Wydarzenie odbywające się co tydzień o tej samej porze.
                        Odpowiednie dla regularnych treningów.
                    </div>

                    <div className={styles.tags}>
                        <Button color="green">Utwórz</Button>
                    </div>
                </div>
            </div>
        </ModalContent>
    </>
);

export default ModalHOC(AddEventChoose, {
    id: 'addEvent',
    size: 'medium',
});
