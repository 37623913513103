import {
    GoogleMap,
    Marker,
    withGoogleMap,
    withScriptjs,
} from 'react-google-maps';
import PropTypes from 'prop-types';

import styles from './Map.module.scss';

const MapComponent = withScriptjs(
    withGoogleMap(({ lat, lng }) => (
        <GoogleMap defaultZoom={16} defaultCenter={{ lat, lng }}>
            <Marker position={{ lat, lng }} />
        </GoogleMap>
    )),
);

MapComponent.propTypes = {
    lat: PropTypes.string.isRequired,
    lng: PropTypes.number.isRequired,
};

const Map = props => (
    <MapComponent
        {...props}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD2wmOXdB-9dUg3Q3n6ulwjGL5_GA0hf_M&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className={styles.map} />}
        mapElement={<div style={{ height: `100%` }} />}
    />
);

export default Map;
