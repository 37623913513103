import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './Counter.module.scss';

const Counter = ({ text, size, styles }) => {
    return (
        <div
            className={cx(style.counter, style[`size-${size}`])}
            style={styles}>
            {text}
        </div>
    );
};

Counter.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOf(['s', 'm']),
    styles: PropTypes.object,
};

Counter.defaultProps = {
    size: 'm',
    styles: undefined,
};

export default Counter;
