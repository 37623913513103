import { useState } from 'react';

import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { Button, Column, Container, Header } from 'ui';

import PillBar from '../../../ui/PillBar/index.js';
import ViewHOC from '../../ViewHOC';

import CalendarListAgenda from './CalendarListAgenda.jsx';
import CalendarListWeek from './CalendarListWeek.jsx';

const CalendarList = () => {
    const { modalOpen } = useUI();
    const [mode, setMode] = useState('week');

    return (
        <Container>
            <Column gap={'m'}>
                <Header title="Kalendarz" size="xl">
                    <PillBar
                        fieldId={'calendar-list-mode'}
                        value={mode}
                        onChange={setMode}
                        options={[
                            {
                                label: 'Tydzień',
                                value: 'week',
                            },
                            {
                                label: 'Agenda',
                                value: 'agenda',
                            },
                        ]}
                    />
                    <Button
                        color={'green'}
                        onClick={() => modalOpen('addEvent')}>
                        Nowe wydarzenie
                    </Button>
                </Header>
                {mode === 'week' && <CalendarListWeek />}
                {mode === 'agenda' && <CalendarListAgenda />}
            </Column>
        </Container>
    );
};

export default ViewHOC(CalendarList, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
