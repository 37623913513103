import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { DateTime } from 'luxon';

import { EVENTS_TYPES_MAP } from '../../../../../constants/logic.js';
import {
    Avatar,
    Icon,
    RoleTag,
    Row,
    Tooltip,
} from '../../../../../ui/index.js';
import PercentCircle from '../../../../../ui/PercentCircle/index.js';

import styles from './PunchCard.module.scss';

const PunchCardStatus = ({ status }) => {
    if (!status) {
        return <div className={styles.noStatus} />;
    }
    if (status === 'absent') {
        return (
            <div className={styles.statusAbsent}>
                <Icon icon="close" size="s" color={'white'} />
            </div>
        );
    }
    if (status === 'present') {
        return (
            <div className={styles.statusPresent}>
                <Icon icon="ok" size="s" color={'white'} />
            </div>
        );
    }
};
const PunchCard = ({ events, users }) => {
    const history = useHistory();

    const eventsSorted = useMemo(() => {
        if (!events) return [];
        return events.sort(
            (a, b) => new Date(b.dateStart) - new Date(a.dateStart),
        );
    }, [events]);

    const openEvent = id => {
        history.push(`/calendar/${id}`);
    };

    return (
        <div className={styles.punchCard}>
            <div className={styles.header}>
                <div className={styles.placeholder} />
                {eventsSorted.map(event => (
                    <div
                        className={styles.event}
                        key={event.id}
                        onClick={() => openEvent(event.id)}>
                        <div className={styles.date}>
                            <strong>
                                {DateTime.fromISO(event.dateStart).toFormat(
                                    'dd MMM',
                                )}
                            </strong>
                        </div>
                        <Tooltip text={event.title}>
                            <Icon
                                icon={EVENTS_TYPES_MAP[event.type].icon}
                                color="grey"
                            />
                        </Tooltip>
                        <div className={styles.attendance}>
                            <PercentCircle percent={event.attendance} />
                            <span>
                                {Math.round(event.attendance * 100)}{' '}
                                <strong>%</strong>
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            {users.map(user => {
                const attendance =
                    events.filter(
                        event =>
                            event.participantsStatuses &&
                            event.participantsStatuses[user.id] === 'present',
                    ).length / events.length;
                return (
                    <div
                        key={user.id}
                        className={cx([styles.row, styles.user])}>
                        <div className={styles.username}>
                            <Row gap="xs" align="center">
                                <Avatar
                                    text={`${user.name} ${user.surname}`}
                                    size={'l'}
                                    picture={user.avatar}
                                />
                                <span>
                                    {user.name} <strong>{user.surname}</strong>
                                </span>
                                <RoleTag role={user.role} size={'s'} />
                            </Row>
                        </div>
                        <div className={styles.sum}>
                            <Row
                                gap="xxxs"
                                align="center"
                                alignContent={'center'}>
                                <PercentCircle percent={attendance} />
                                <span>
                                    {Math.round(attendance * 100)}{' '}
                                    <strong>%</strong>
                                </span>
                            </Row>
                        </div>
                        {events.map(event => (
                            <div className={styles.entry} key={event.id}>
                                <PunchCardStatus
                                    status={
                                        event.participantsStatuses &&
                                        event.participantsStatuses[user.id]
                                    }
                                />
                            </div>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

export default PunchCard;
