import { NOTES } from '../../../../constants/notes.js';
import { useMutationEventNotePut } from '../../../../queries/events.mutations.js';
import { Row } from '../../../../ui/index.js';

import NotePill from './NotePill.jsx';

import styles from './AddSideNote.module.scss';
const AddSideNote = ({ idEvent, idUser }) => {
    const { mutate } = useMutationEventNotePut();
    const toggleNote = idNote => {
        mutate({ idEvent, idUser, idNote });
    };

    return (
        <div className={styles.addSideNote}>
            <Row wrap="wrap" gap="xxs">
                {NOTES.map(noteId => (
                    <NotePill
                        key={noteId}
                        noteId={noteId}
                        onClick={() => toggleNote(noteId)}
                    />
                ))}
            </Row>
        </div>
    );
};

export default AddSideNote;
