import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import packageJson from '../../../package.json';
import Error404 from '../Error404';

Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_APIKEY,
    releaseStage: import.meta.env.VITE_INSTANCE_NAME,
    appVersion: packageJson.version,
    enabledReleaseStages: ['prod', 'dev'],
    redactedKeys: ['Authorization', /^password$/i],
    plugins: [new BugsnagPluginReact()],
});

const BugsnagErrorBoundary =
    Bugsnag.getPlugin('react').createErrorBoundary(React);

const ErrorBoundary = ({ children }) => (
    <BugsnagErrorBoundary FallbackComponent={Error404}>
        {children}
    </BugsnagErrorBoundary>
);

export default ErrorBoundary;
