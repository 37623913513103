import fetch from 'utils/fetch';

export default {
    get: id => fetch.get(`organizations/${id}`).then(r => r.data),
    patch: (id, delta) =>
        fetch.patch(`organizations/${id}`, delta).then(r => r.data),
    patchSettings: (id, delta) =>
        fetch.patch(`organizations/${id}/settings`, delta).then(r => r.data),

    queryAdmins: (idOrganization, query) =>
        fetch
            .get(`organizations/${idOrganization}/admins`, { params: query })
            .then(r => r.data),
    postAdmin: (idOrganization, admin) =>
        fetch
            .post(`organizations/${idOrganization}/admins`, admin)
            .then(r => r.data),
    getAdmin: (idOrganization, idAdmin) =>
        fetch
            .get(`organizations/${idOrganization}/admins/${idAdmin}`)
            .then(r => r.data),
    patchAdmin: (idOrganization, idAdmin, delta) =>
        fetch
            .patch(`organizations/${idOrganization}/admins/${idAdmin}`, delta)
            .then(r => r.data),
    deleteAdmin: (idOrganization, idAdmin) =>
        fetch
            .delete(`organizations/${idOrganization}/admins/${idAdmin}`)
            .then(r => r.data),
};
