import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import useUI from 'contexts/useUI';
import authService from 'services/authService';

const Activate = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const { toastPush } = useUI();

    useEffect(() => {
        const { code } = match.params;
        authService
            .activate(code)
            .then(() => {
                toastPush({
                    text: 'Konto aktywowane',
                });
                history.push('/auth/sign-in');
            })
            .catch(() => {
                toastPush({
                    text: 'Aktywacja nie powiodła się',
                });
                history.push('/auth/sign-in');
            });
    }, []);

    return <div />;
};

export default Activate;
