export const NOTES_MAP = {
    ACTIVE: {
        emoji: '💪',
        label: 'Aktywny',
    },
    COMMUNICATION: {
        emoji: '📣',
        label: 'Świetna komunikacja',
    },
    LEADER: {
        emoji: '🥇',
        label: 'Lider',
    },
    PROGRESS: {
        emoji: '👍',
        label: 'Postęp',
    },
    REGRESS: {
        emoji: '👎',
        label: 'Regres',
    },
    UNINVOLVED: {
        emoji: '💤',
        label: 'Niezaangażowany',
    },
    LATE: {
        emoji: '🕐',
        label: 'Spóżniony',
    },
    UNPREPARED: {
        emoji: '⛔️',
        label: 'Nieprzygotowany',
    },
};

export const NOTES = Object.keys(NOTES_MAP);
