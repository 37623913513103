import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import YearView from './YearView';

class YearViewContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            year: props.value.getFullYear(),
            direction: 'center',
        };
        document.addEventListener('keyup', this.keyupHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.keyupHandler);
    }

    keyupHandler = e => {
        if ((e.keyCode || e.which) === 37) {
            this.prevYear();
        } else if ((e.keyCode || e.which) === 38) {
            return;
        } else if ((e.keyCode || e.which) === 39) {
            this.nextYear();
        } else if ((e.keyCode || e.which) === 40) {
            return;
        }
    };

    nextYear = () => {
        this.setState(state => ({
            year: state.year + 1,
            direction: 'left',
        }));
    };

    prevYear = () => {
        this.setState(state => ({
            year: state.year - 1,
            direction: 'right',
        }));
    };

    openDecade = () => {
        this.props.setMode('decade', new Date(this.state.year, 0));
    };

    openMonth = month => {
        this.props.setMode('month', new Date(this.state.year, month));
    };

    render() {
        const props = {
            value: this.props.value,
            year: this.state.year,
            direction: this.state.direction,
            onNext: this.nextYear,
            onPrev: this.prevYear,
            onChange: this.changeHandler,
            openDecade: this.openDecade,
            openMonth: this.openMonth,
        };

        return <YearView {...props} />;
    }
}

YearViewContainer.propTypes = {
    onChange: PropTypes.func,
};

YearViewContainer.defaultProps = {
    onChange: undefined,
};

export default YearViewContainer;
