import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import DecadeView from './DecadeView';
import MonthView from './MonthView';
import YearView from './YearView';

import style from './DatePickerContext.module.scss';

const DatePickerContext = ({ value, mode, onChange, setMode }) => {
    return (
        <div className={cx(style.context)}>
            <AnimatePresence>
                <motion.div
                    key={mode}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    {(() => {
                        switch (mode) {
                            case 'decade': {
                                return (
                                    <DecadeView
                                        value={value}
                                        onChange={onChange}
                                        setMode={setMode}
                                    />
                                );
                            }
                            case 'year': {
                                return (
                                    <YearView
                                        value={value}
                                        onChange={onChange}
                                        setMode={setMode}
                                    />
                                );
                            }
                            case 'month': {
                                return (
                                    <MonthView
                                        value={value}
                                        onChange={onChange}
                                        setMode={setMode}
                                    />
                                );
                            }
                            default: {
                                return <div />;
                            }
                        }
                    })()}
                </motion.div>
            </AnimatePresence>
        </div>
    );
};

DatePickerContext.propTypes = {
    value: PropTypes.instanceOf(Date).isRequired,
    mode: PropTypes.string.isRequired,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
    onChange: PropTypes.func,
    setMode: PropTypes.func,
};

DatePickerContext.defaultProps = {
    onNext: undefined,
    onPrev: undefined,
    onChange: undefined,
    onPickerChange: undefined,
};

export default DatePickerContext;
