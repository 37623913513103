import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import newsService from '../services/newsService.js';

export const useNewsListQuery = filters => {
    return useInfiniteQuery(
        ['news', 'list', filters],
        ({ pageParam }) => {
            return newsService.query({
                ...filters,
                limit: 10,
                offset: pageParam,
            });
        },
        {
            getNextPageParam: (a, b) => {
                if (!a.length) return undefined;
                return b.flat(1).length;
            },
        },
    );
};

export const useNewsQuery = id => {
    return useQuery(['news', 'single', id], () => newsService.get(id), {
        enabled: !!id,
        initialData: null,
    });
};
