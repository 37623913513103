import { Route, Switch } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { Logo } from 'ui';

import ViewHOC from '../ViewHOC';

import DialogAbout from './dialogs/DialogAbout.jsx';
import DialogPrivacy from './dialogs/DialogPrivacy.jsx';
import DialogTerms from './dialogs/DialogTerms.jsx';
import Activate from './Activate';
import AppInstall from './AppInstall';
import CreateSchool from './CreateSchool';
import ResetPassword from './ResetPassword';
import RoleSelection from './RoleSelection';
import SetPassword from './SetPassword';
import Signin from './Signin';
import Signup from './Signup';

import styles from './Auth.module.scss';

const Auth = ({ location }) => (
    <div className={styles.auth}>
        <Logo />
        <div className={styles.container}>
            <AnimatePresence mode={'wait'}>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}>
                    <Switch location={location}>
                        <Route exact path="/" component={Signin} />
                        <Route exact path="/auth" component={Signin} />

                        <Route exact path="/auth/signin" component={Signin} />
                        <Route
                            exact
                            path="/auth/reset-password"
                            component={ResetPassword}
                        />
                        <Route
                            exact
                            path="/auth/set-password/:code"
                            component={SetPassword}
                        />
                        <Route exact path="/auth/signup" component={Signup} />
                        <Route
                            exact
                            path="/auth/activate/:code"
                            component={Activate}
                        />
                        <Route
                            exact
                            path="/auth/create-school"
                            component={CreateSchool}
                        />
                        <Route
                            exact
                            path="/auth/role-selection"
                            component={RoleSelection}
                        />
                        <Route
                            exact
                            path="/auth/install-app"
                            component={AppInstall}
                        />

                        <Route component={Signin} />
                    </Switch>
                </motion.div>
            </AnimatePresence>
        </div>
        <DialogAbout />
        <DialogPrivacy />
        <DialogTerms />
    </div>
);

export default ViewHOC(Auth, {
    menu: false,
    topbar: false,
    mobile: true,
});
