import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ListDataCell from 'components/ListDataCell';
import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { usePlayersListQuery } from 'queries/players.queries.js';
import {
    Button,
    Column,
    Container,
    FilterTabs,
    Header,
    List,
    ListActions,
    ListCell,
} from 'ui';
import InfiniteLoader from 'ui/InfiniteLoader';

import EmptyCard from '../../../components/EmptyCard/index.js';
import ViewHOC from '../../ViewHOC';
import DialogAddPlayer from '../modals/AddPlayerDialog';

const FILTERS = [
    {
        label: 'Wszyscy',
        filters: {},
    },
    {
        label: 'Chłopcy',
        filters: {
            sex: 'male',
        },
    },
    {
        label: 'Dziewczynki',
        filters: {
            sex: 'female',
        },
    },
];

const Players = () => {
    const { modalOpen } = useUI();
    const history = useHistory();
    const [filters, setFilters] = useState({});

    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = usePlayersListQuery(filters);

    const players = data.pages.flat(1);

    const openAddPlayer = () => {
        modalOpen('addPlayer');
    };

    const openPlayer = id => {
        history.push(`/players/${id}`);
    };

    return (
        <>
            <Container>
                <Column gap={'m'}>
                    <Header title="Zawodnicy" size={'xl'}>
                        <Button onClick={openAddPlayer} color={'green'}>
                            Nowy zawodnik
                        </Button>
                    </Header>
                    <FilterTabs
                        tabs={FILTERS}
                        filters={filters}
                        setFilters={setFilters}
                    />
                    <List
                        header={
                            <>
                                <ListCell header width={300}>
                                    Imię i nazwisko
                                </ListCell>
                                <ListCell width={20} header />
                                <ListCell width={150} header>
                                    Wiek
                                </ListCell>
                                <ListCell width={200} header>
                                    Grupy
                                </ListCell>
                                <ListCell width={100} header>
                                    Rodzice
                                </ListCell>
                            </>
                        }
                        data={players}
                        renderRow={({
                            id,
                            name,
                            surname,
                            avatar,
                            sex,
                            age,
                            groups,
                            parents,
                            birthday,
                        }) => {
                            const open = () => openPlayer(id);
                            return (
                                <>
                                    <ListDataCell.User
                                        width={300}
                                        size="l"
                                        onClick={open}
                                        user={{ id, name, surname, avatar }}
                                    />
                                    <ListDataCell.Sex sex={sex} />
                                    <ListDataCell.Age
                                        date={birthday}
                                        age={age}
                                    />
                                    <ListDataCell.Groups groups={groups} />
                                    <ListDataCell.Users users={parents} />
                                    <ListActions
                                        actions={[
                                            {
                                                title: 'edit',
                                                icon: 'edit',
                                                onClick: open,
                                            },
                                        ]}
                                    />
                                </>
                            );
                        }}
                        emptyComponent={
                            <EmptyCard
                                action={openAddPlayer}
                                actionLabel="Utwórz pierwszego zawodnika"
                            />
                        }
                    />
                    {!isFetchingNextPage && hasNextPage && (
                        <InfiniteLoader onVisible={fetchNextPage} />
                    )}
                </Column>
            </Container>
            <DialogAddPlayer />
        </>
    );
};

export default ViewHOC(Players, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
