import { useHistory } from 'react-router-dom';

import EmptyCard from 'components/EmptyCard';
import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useCoachesListQuery } from 'queries/coaches.queries.js';
import {
    Button,
    Column,
    Container,
    Header,
    List,
    ListActions,
    ListCell,
    Spinner,
} from 'ui';
import InfiniteLoader from 'ui/InfiniteLoader';
import useACL from 'utils/useACL';

import ListDataCell from '../../../components/ListDataCell/index.js';
import ViewHOC from '../../ViewHOC';
import AddCoachDialog from '../modals/AddCoachDialog';

const CoachesList = () => {
    const { modalOpen } = useUI();
    const history = useHistory();
    const { isRole } = useACL();

    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetched,
        isFetching,
        isFetchingNextPage,
    } = useCoachesListQuery();

    const coaches = data.pages.flat(1);

    const openAddCoach = () => {
        modalOpen('addCoach');
    };

    const openCoach = id => {
        history.push(`/coaches/${id}`);
    };

    if (!isFetched) return <Spinner active />;

    return (
        <Container>
            <Column gap={'m'}>
                <Header title="Trenerzy" size={'xl'}>
                    {isRole([ROLES.ADMIN, ROLES.OWNER]) && (
                        <Button color={'green'} onClick={openAddCoach}>
                            Nowy trener
                        </Button>
                    )}
                </Header>
                {!isFetching && !coaches.length && (
                    <EmptyCard
                        action={openAddCoach}
                        actionLabel="Dodaj trenera"
                    />
                )}
                <List
                    header={
                        <>
                            <ListCell width={32} header></ListCell>
                            <ListCell header width={250}>
                                Imię i nazwisko
                            </ListCell>
                            <ListCell width={200}>Numer telefonu</ListCell>
                            <ListCell header>Grupy</ListCell>
                        </>
                    }
                    data={coaches}
                    renderRow={({
                        id,
                        name,
                        surname,
                        avatar,
                        groups,
                        phone,
                    }) => {
                        const open = () => openCoach(id);
                        return (
                            <>
                                <ListDataCell.User
                                    user={{ name, surname, avatar }}
                                    size="l"
                                    onClick={open}
                                />
                                <ListDataCell.Phone phone={phone} />
                                <ListDataCell.Groups groups={groups} />
                                <ListActions
                                    actions={[
                                        {
                                            title: 'edit',
                                            icon: 'edit',
                                            onClick: open,
                                        },
                                    ]}
                                />
                            </>
                        );
                    }}
                />
                {!isFetchingNextPage && hasNextPage && (
                    <InfiniteLoader onVisible={fetchNextPage} />
                )}
            </Column>
            <AddCoachDialog />
        </Container>
    );
};

export default ViewHOC(CoachesList, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
