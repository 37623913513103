import { useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import useUI from 'contexts/useUI';
import authService from 'services/authService';
import { Button, Column, Header, Input, Label } from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util';

import styles from './SetPassword.module.scss';

const SetPassword = () => {
    const [form, { isFormValid, touchForm }] = useForm(
        useMemo(() => ({
            password: {
                value: '',
                validators: [validators.isRequired(), validators.isPassword()],
            },
            repeatedPassword: {
                value: '',
                validators: [validators.isRequired(), validators.isPassword()],
            },
        })),
        {
            validators: [
                form =>
                    form.repeatedPassword.value !== form.password.value
                        ? 'Hasła nie pasują'
                        : null,
            ],
        },
    );
    const match = useRouteMatch();
    const history = useHistory();

    const { toastPush } = useUI();

    const savePassword = () => {
        const { code } = match.params;

        if (!isFormValid) return false;

        authService
            .setPassword(code, form.password.value)
            .then(() => {
                toastPush({
                    text: 'Hasło zostało zmienione',
                });
                history.push('/auth/signin');
            })
            .catch(() => {
                toastPush({
                    text: 'Zmiana hasła nie powiodła się',
                });
                history.push('/auth/signin');
            });
    };

    return (
        <div className={styles.reset}>
            <div className={styles.card}>
                <Column gap="xs">
                    <Header title={'Ustaw nowe hasło'} />
                    <div>
                        <Label>Hasło</Label>
                        <Input type="password" {...form.password} />
                        <Label>Powtórz hasło</Label>
                        <Input type="password" {...form.repeatedPassword} />
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            shape="square"
                            href="/auth/signin"
                            icon="chevron-left"
                        />
                        <Button
                            color="green"
                            onClick={savePassword}
                            disabled={!isFormValid}
                            onDisabledClick={touchForm}>
                            Ustaw nowe hasło
                        </Button>
                    </div>
                </Column>
            </div>
        </div>
    );
};

export default SetPassword;
