import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { usePrevious } from 'react-use';

import useAuth from 'contexts/useAuth';
import useUI from 'contexts/useUI';
import Mobile from 'screens/Mobile';
import MeService from 'services/meService';
import { isMobile } from 'utils/is';

export default function ViewHOC(ViewComponent, config) {
    const ViewHOCComponent = props => {
        const [isAuthorized, setIsAuthorized] = useState(false);
        const { setMenu, setTopBar, toastPush } = useUI();
        const { me, signOut } = useAuth();
        const history = useHistory();
        const match = useRouteMatch();
        const prevMe = usePrevious(me);

        useEffect(() => {
            setMenuAndTopBar();
            checkAuth();
            return () => {
                window.onFocus = undefined;
            };
        }, []);

        useEffect(() => {
            if (prevMe?.role !== me?.role) {
                checkAuth();
            }
            return () => {
                window.onFocus = undefined;
            };
        }, [me]);

        const setMenuAndTopBar = () => {
            if (!config.mobile && isMobile()) {
                setMenu(false);
                setTopBar(false);
            } else {
                setMenu(config.menu);
                setTopBar(config.topbar);
            }
        };

        const checkAuth = () => {
            const { role } = me || {};
            if (config.auth) {
                if (!role) {
                    window.localStorage.setItem(
                        'entryPoint',
                        window.location.pathname,
                    );
                    sessionStorage.setItem('redirectUrl', window.location.href);
                    history.push('/auth');
                    signOut();
                } else if (
                    config.auth.roles &&
                    !config.auth.roles.includes(role)
                ) {
                    if (match.path === '/') {
                        history.push('/dashboard');
                    } else {
                        history.goBack();
                    }
                    toastPush({
                        text: 'Nie masz uprawnień',
                    });
                } else {
                    setIsAuthorized(true);
                }

                window.onfocus = async () => {
                    try {
                        await MeService.get();
                    } catch (e) {
                        signOut();
                    }
                };
            } else {
                setIsAuthorized(true);
            }
        };

        if (!config.mobile && isMobile()) {
            return <Mobile />;
        }

        return isAuthorized ? <ViewComponent {...props} /> : <div />;
    };

    return ViewHOCComponent;
}
