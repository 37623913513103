import { Icon } from 'ui';

import style from './NotReady.module.scss';

const NotReady = () => (
    <div className={style.notReady}>
        <Icon icon="referee" color="#F6CE42" />
        <span>Funkcja w przygotowaniu</span>
    </div>
);
export default NotReady;
