import { useGroupsAllQuery } from '../../../queries/groups.queries.js';
import {
    Button,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from '../../../ui/index.js';

import i1 from './1.png';
import i2 from './2.png';

const NewVersion = ({ modalClose }) => {
    const { data: groups } = useGroupsAllQuery();
    return (
        <>
            <ModalHeader>Nowości w Goaly!</ModalHeader>
            <ModalContent>
                <p>
                    Mamy dla was ekscytujące wieści prosto z kuchni! 🎉📣
                    Chcielibyśmy was poinformować, że właśnie do naszej
                    aplikacji dołączyły dwie nowe funkcje o które od jakiegoś
                    czasu podpytywaliście.
                </p>
                <p>
                    <strong>Planery tygodniowe</strong>
                </p>
                <p>
                    <img src={i1} />
                </p>

                <p>
                    Pierwszą z tych funkcji są `&apos;Planery Tygodniowe`&apos;.
                    Teraz możecie oglądać swoje kalendarze w widoku tygodnia i
                    tworzyć treningi jeszcze szybciej i łatwiej. Zapomnijcie o
                    kombinowaniu z notatkami na kartkach i spisach na brudnych
                    serwetkach – teraz macie pełen przegląd swoich treningów, na
                    przestrzeni tygodnia.
                </p>
                <p>
                    <strong>Obecność na treningach</strong>
                </p>
                <p>
                    <img src={i2} />
                </p>

                <p>
                    Ale to nie koniec zmian! Mamy dla was drugą nowość – już
                    dziś możecie sprawdzać obecność zawodników na zajęciach i
                    bacznie monitorować frekwencje w grupach. Teraz możecie
                    śledzić, kto jest na posterunku, a kto stara się wymigać od
                    swoich piłkarskich obowiązków. Już wkrótce ta opcja pojawi w
                    także w aplikacji mobilnej.
                </p>
                <p>Do zobaczenia na boisku (i w aplikacji)! ⚽📱</p>
                <p>Sportowe pozdrowienia, Zespół Goaly</p>
            </ModalContent>
            <ModalActions>
                <Button color={'green'} href={'/calendar'} onClick={modalClose}>
                    Zobacz planer tygodniowy
                </Button>
                {groups[0] ? (
                    <Button
                        color={'green'}
                        href={`/groups/${groups[0].id}/attendance`}
                        onClick={modalClose}>
                        Zobacz frekwencje w grupie
                    </Button>
                ) : null}

                <Button color={'black'} onClick={modalClose}>
                    Zamknij
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(NewVersion, {
    id: 'newVersion',
    size: 'medium',
});
