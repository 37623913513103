import { useRouteMatch } from 'react-router-dom';

import { useCalendarEventQuery } from 'queries/events.queries.js';
import { Column, Header, Map, Spinner } from 'ui';

const MapVenue = () => {
    const {
        params: { id },
    } = useRouteMatch();
    const { data } = useCalendarEventQuery(id);

    if (!data || !data.venue || !data.venue.location) return <Spinner active />;
    return (
        <Column gap="m">
            <Header title="Lokalizacja obiektu" size="m" />
            <Map {...data.venue.location} />
        </Column>
    );
};

export default MapVenue;
