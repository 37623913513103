import { useHistory, useLocation } from 'react-router-dom';

import { useMutationGroupDelete } from 'queries/groups.mutations.js';
import { Button, ModalActions, ModalContent, ModalHeader, ModalHOC } from 'ui';

const DeleteGroupDialog = ({ data: { id }, modalClose }) => {
    const location = useLocation();
    const history = useHistory();

    const mutation = useMutationGroupDelete({
        onSuccess: () => {
            modalClose();
            if (location.pathname.includes(`/groups/${id}`)) {
                history.push('/groups');
            }
        },
    });

    const deleteGroup = () => {
        mutation.mutate(id);
    };
    return (
        <>
            <ModalHeader>Usuń grupę</ModalHeader>
            <ModalContent>Czy na pewno chcesz usunąć grupę?</ModalContent>
            <ModalActions>
                <Button color="red" onClick={deleteGroup}>
                    Usuń
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DeleteGroupDialog, {
    id: 'deleteGroup',
    size: 'small',
});
