import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from 'ui/index';

import style from './ListActions.style.module.scss';

const ListActions = ({ actions }) => {
    const buttons = actions.map(a => (
        <a
            key={a.icon}
            className={cx(style.button, style[`color-${a.color}`])}
            title={a.title}
            onClick={a.onClick}>
            <Icon size="s" icon={a.icon} color={a.color || 'black-500'} />
        </a>
    ));
    return <div className={style.listActions}>{buttons}</div>;
};

ListActions.propTypes = {
    actions: PropTypes.array,
};

ListActions.defaultProps = {
    actions: [],
};

export default ListActions;
