import { useRouteMatch } from 'react-router-dom';

import { EVENTS_TYPES_MAP, ROLES } from 'constants/logic';
import useUI from 'contexts/useUI.js';
import { useCalendarEventQuery } from 'queries/events.queries.js';
import { Card, Column, Header, LabeledValue, Row, Spinner } from 'ui';
import {
    formatAddress,
    formatDateFullWithDay,
    formatHour,
    formatTime,
} from 'utils/formatters';
import useACL from 'utils/useACL.js';

import GroupTag from '../../../../ui/GroupTag/index.js';

const Info = () => {
    const {
        params: { id },
    } = useRouteMatch();
    const { data } = useCalendarEventQuery(id);
    const { isRole, isIncludingMyGroup } = useACL();
    const { modalOpen } = useUI();

    const openEditModal =
        (isRole([ROLES.OWNER, ROLES.ADMIN]) ||
            isIncludingMyGroup(data.groups.map(g => g.id))) &&
        (() => modalOpen('editEvent', { id }));

    if (!data) {
        return <Spinner active />;
    }
    return (
        <Row gap={'m'} align="start">
            <Column>
                <Card onEdit={openEditModal}>
                    <Column gap={'xs'}>
                        <Header title="Informacje podstawowe" size="m" />
                        <LabeledValue
                            label="Tytuł wydarzenia"
                            value={data.title}
                        />
                        <Row gap={'m'}>
                            <Column>
                                <LabeledValue
                                    label="Rodzaj wydarzenia"
                                    value={EVENTS_TYPES_MAP[data.type].name}
                                />
                            </Column>
                            <Column>
                                <LabeledValue
                                    label="Grupy"
                                    value={
                                        data.groups &&
                                        data.groups.length &&
                                        data.groups.map(g => (
                                            <GroupTag
                                                group={g}
                                                size="m"
                                                key={g.id}
                                            />
                                        ))
                                    }
                                />
                            </Column>
                        </Row>
                        <LabeledValue
                            label="Opis wydarzenia"
                            value={data.desc}
                            type="longText"
                        />
                    </Column>
                </Card>
            </Column>
            <Column gap="m">
                <Card onEdit={openEditModal}>
                    <Column gap={'xs'}>
                        <Header title="Data" size="m" />
                        <Row gap="m" align="start">
                            <Column gap={'xs'}>
                                <LabeledValue
                                    label="Data rozpoczęcia"
                                    value={`${formatDateFullWithDay(
                                        data.dateStart,
                                    )}, ${formatHour(data.dateStart)}`}
                                />
                                <LabeledValue
                                    label="Data zakończenia"
                                    value={`${formatDateFullWithDay(
                                        data.dateEnd,
                                    )}, ${formatHour(data.dateEnd)}`}
                                />
                            </Column>
                            <Column gap={'xs'}>
                                <LabeledValue
                                    label="Czas trwania"
                                    value={formatTime(data.minutes)}
                                />
                            </Column>
                        </Row>
                    </Column>
                </Card>
                {data.venue && (
                    <Card onEdit={openEditModal}>
                        <Column gap={'xs'}>
                            <Header title="Obiekt" size="m" />
                            <LabeledValue
                                label="Nazwa obiektu"
                                value={data.venue.name}
                            />
                            <LabeledValue
                                label="Adres"
                                value={formatAddress(data.venue.address)}
                            />
                        </Column>
                    </Card>
                )}
            </Column>
        </Row>
    );
};

export default Info;
