import { Link } from 'react-router-dom';

import { Avatar, Column, Icon, Row, Tooltip } from 'ui';

import styles from './GroupCard.module.scss';

const GroupCard = ({ id, name, players, coaches, avatar }) => (
    <div className={styles.groupCard}>
        <Row gap="xs" align="center">
            <Link to={`/groups/${id}`}>
                <Avatar
                    text={avatar.slice(7, 9)}
                    shape="shield"
                    size="xl"
                    color={avatar.slice(0, 7)}
                />
            </Link>
            <Column gap="xxs">
                <Link className={styles.name} to={`/groups/${id}`}>
                    {name}
                </Link>
                <div className={styles.bottom}>
                    <div className={styles.players}>
                        <Icon icon="player-standing" size="m" />
                        {players.length}
                    </div>
                    <Row gap="xxxs" alignContent={'end'}>
                        {coaches.map(c => (
                            <Tooltip
                                text={`${c.name} ${c.surname}`}
                                key={c.id}
                                display="inline">
                                <Link
                                    to={`/coaches/${c.id}`}
                                    key={c.id}
                                    className={styles.parent}>
                                    <Avatar
                                        size="m"
                                        text={`${c.name} ${c.surname}`}
                                        picture={c.avatar}
                                    />
                                </Link>
                            </Tooltip>
                        ))}
                    </Row>
                </div>
            </Column>
        </Row>
    </div>
);

export default GroupCard;
