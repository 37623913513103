import { EVENTS_TYPES_ARRAY, EVENTS_TYPES_MAP } from '../../constants/logic.js';
import { HOURS } from '../../constants/time.js';

export const typeOptions = EVENTS_TYPES_ARRAY.map(e => ({
    label: EVENTS_TYPES_MAP[e].name,
    value: e,
}));

export const hoursOptions = HOURS.map(({ h, m }) => ({
    label: `${h}:${m < 10 ? `0${m}` : m}`,
    value: `${h}:${m}`,
}));

export const inviteOptions = [
    {
        label: 'Nie',
        value: 'none',
    },
    {
        label: 'Tak, z wybranych grup',
        value: 'groups',
    },
    {
        label: 'Tak, z całej szkoły',
        value: 'organization',
    },
];

const WEEKDAYS_STRINGS = [
    'w niedzielę',
    'w poniedziałek',
    'we wtorek',
    'w środę',
    'w czwartek',
    'w piątek',
    'w sobotę',
];

export const generateNiceTitle = (type, weekday, time) => {
    const [hour] = time.split(':');
    const typeString = EVENTS_TYPES_MAP[type].name;
    const weekdayString = WEEKDAYS_STRINGS[weekday];
    let timeString = '';
    if (hour < 12) {
        timeString = 'rano';
    } else if (hour < 13) {
        timeString = 'południe';
    } else if (hour < 17) {
        timeString = 'popołudnie';
    } else {
        timeString = 'wieczór';
    }

    return `${typeString} ${weekdayString} ${timeString}`;
};
