import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import PropTypes from 'prop-types';

const InfiniteLoader = ({ onVisible }) => {
    const ref = useRef();
    const [top, setTop] = useState(Infinity);

    const onScroll = () => {
        setTop(ref.current.getBoundingClientRect().top);
    };

    useDebounce(
        () => {
            if (top - 20 <= window.innerHeight) {
                if (onVisible) {
                    onVisible();
                }
            }
        },
        500,
        [top],
    );

    useEffect(() => {
        setTop(ref.current.getBoundingClientRect().top);
        window.addEventListener('scroll', onScroll, false);

        return () => {
            window.removeEventListener('scroll', onScroll, false);
        };
    }, [ref]);

    return <div ref={ref} />;
};

InfiniteLoader.propTypes = {
    onVisible: PropTypes.func.isRequired,
};

InfiniteLoader.defaultProps = {};

export default InfiniteLoader;
