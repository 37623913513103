import { useEffect, useState } from 'react';

import authService from 'services/authService';
import { Logo } from 'ui';

import style from './Mobile.module.scss';

const Mobile = () => {
    const [info, setInfo] = useState(undefined);

    useEffect(() => {
        const { pathname } = window.location;
        if (pathname.includes('auth/activate/')) {
            authService
                .activate(pathname.substr(pathname.length - 20))
                .then(() => {
                    setInfo('Konto aktywowane');
                })
                .catch(() => {
                    setInfo('Aktywacja nie powiodła się');
                });
        }
    }, []);

    return (
        <div className={style.mobile}>
            <div className={style.wrapper}>
                <Logo width={100} />
                <div className={style.title}>
                    {info || 'Prawdziwa piłka potrzebuje więcej przestrzeni'}
                </div>
                <div className={style.subtitle}>
                    Zapraszamy do pobrania naszych aplikacji mobilnych.
                </div>
                <div className={style.rows}>
                    <a
                        href="https://apps.apple.com/pl/app/goaly/id1477412576"
                        title="App Store">
                        <img
                            className={style.icon}
                            src="/img/app-store.svg"
                            alt=""
                        />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.kroppa.goaly"
                        title="Google Play">
                        <img
                            className={style.icon}
                            src="/img/google-play.svg"
                            alt=""
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Mobile;
