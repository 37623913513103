import { ROLES } from 'constants/logic.js';
import { useMutationGroupAddCoach } from 'queries/groups.mutations.js';
import { useGroupCoachesQuery } from 'queries/groups.queries.js';
import {
    Button,
    Column,
    Icon,
    Input,
    Label,
    List,
    ListActions,
    ListCell,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Spinner,
} from 'ui/index.js';

import ListDataCell from '../../../../../components/ListDataCell/index.js';
import { useSearchPeopleQuery } from '../../../../../queries/search.queries.js';
import useForm from '../../../../../utils/useForm.js';

const DialogAddCoach = ({ data: { idGroup }, modalOpen, modalClose }) => {
    const { data: groupCoaches } = useGroupCoachesQuery(idGroup);

    const [form] = useForm({
        search: {
            value: '',
        },
    });

    const { data: coaches } = useSearchPeopleQuery(form.search.value, [
        ROLES.COACH,
    ]);

    const mutationGroupAddCoach = useMutationGroupAddCoach({
        onSuccess: modalClose,
    });

    const addCoach = idCoach => {
        mutationGroupAddCoach.mutate({ idGroup, idCoach });
    };

    const addNewCoach = () => {
        const [name, surname] = form.search.value
            ? form.search.value.split(' ')
            : ['', ''];
        modalOpen('addCoach', {
            name,
            surname,
            addToGroup: idGroup,
        });
    };

    if (!coaches) return <Spinner active />;

    return (
        <>
            <ModalHeader>Zarządzaj trenerami grupy</ModalHeader>
            <ModalContent>
                <Column gap="m">
                    <Column gap="xs">
                        <Column>
                            <Label>
                                Wpisz imię lub nazwisko by znaleźć trenera
                            </Label>
                            <Input {...form.search} />
                        </Column>
                        <List
                            data={coaches.slice(0, 5)}
                            renderRow={({ id, name, surname, avatar }) => {
                                return (
                                    <>
                                        <ListDataCell.User
                                            user={{
                                                id,
                                                name,
                                                surname,
                                                avatar,
                                                role: 'coach',
                                            }}
                                            size="l"
                                        />
                                        {groupCoaches.find(
                                            pp => pp.id === id,
                                        ) ? (
                                            <ListCell width={24}>
                                                <Icon
                                                    size="m"
                                                    icon={'ok'}
                                                    color={'green-500'}
                                                />
                                            </ListCell>
                                        ) : (
                                            <ListActions
                                                actions={[
                                                    {
                                                        icon: 'plus',
                                                        onClick: () =>
                                                            addCoach(id),
                                                    },
                                                ]}
                                            />
                                        )}
                                    </>
                                );
                            }}
                        />
                    </Column>
                </Column>
            </ModalContent>
            <ModalActions>
                <Button color="black" onClick={addNewCoach}>
                    Utwórz nowego trenera
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Zakończ
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DialogAddCoach, {
    id: 'addCoachesToGroup',
    size: 'small',
});
