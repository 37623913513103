import fetch from 'utils/fetch';

export default {
    query: query => fetch.get('news', { params: query }).then(r => r.data),
    post: venue => fetch.post('news', venue).then(r => r.data),

    get: id => fetch.get(`news/${id}`).then(r => r.data),
    patch: (id, delta) => fetch.patch(`news/${id}`, delta).then(r => r.data),
    delete: id => fetch.delete(`news/${id}`).then(r => r.data),
};
