import { useEffect } from 'react';

import { useMutationPlayerPatch } from 'queries/players.mutations.js';
import { usePlayerQuery } from 'queries/players.queries.js';
import {
    Button,
    Column,
    Input,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';
import useForm from 'utils/useForm';

const DialogEditSchool = ({ data: { idPlayer }, modalClose }) => {
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        'school.name': {
            value: '',
        },
        'school.class': {
            value: '',
        },
        'school.teacher': {
            value: '',
        },
    });

    const { data } = usePlayerQuery(idPlayer);

    useEffect(() => {
        if (data) {
            form['school.name'].setValue(data.school?.name);
            form['school.class'].setValue(data.school?.class);
            form['school.teacher'].setValue(data.school?.teacher);
        }
    }, [data]);

    const mutation = useMutationPlayerPatch({
        onSuccess: modalClose,
    });

    const save = () => {
        if (!isFormValid) return;
        mutation.mutate({ id: idPlayer, delta: getFormValue() });
    };

    return (
        <>
            <ModalHeader>Edytuj informacje kontaktowe</ModalHeader>
            <ModalContent>
                <Column gap="xs">
                    <div>
                        <Label>Nazwa szkoły</Label>
                        <Input {...form['school.name']} />
                    </div>
                    <div>
                        <Label>Klasa</Label>
                        <Input {...form['school.class']} />
                    </div>
                    <div>
                        <Label>Nauczyciel / Wychowawca</Label>
                        <Input {...form['school.teacher']} />
                    </div>
                </Column>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid}
                    color="green"
                    onClick={save}
                    onDisabledClick={touchForm}>
                    Zapisz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DialogEditSchool, {
    id: 'editSchool',
    title: 'Edytuj informacje o szkole',
    size: 'small',
});
