import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Avatar from '../Avatar';
import ContextMenu from '../ContextMenu';
import Counter from '../Counter';
import Dot from '../Dot';
import Icon from '../Icon';

import styles from './Head.module.scss';

const Head = ({ title, subtitle, avatar, rightChildren, tabs, actions }) => (
    <div className={styles.head}>
        <div className={styles.top}>
            <div className={styles.avatar}>
                <Avatar {...avatar} size="xxl" />
            </div>
            <div className={styles.main}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{subtitle}</div>
            </div>
            <div className={styles.right}>{rightChildren}</div>
        </div>
        <div className={styles.bottom}>
            <div className={styles.tabs}>
                {tabs
                    .filter(a => !a.disabled)
                    .map(t => (
                        <NavLink
                            key={t.link}
                            to={t.link}
                            className={styles.tab}
                            activeClassName={styles.active}
                            exact>
                            {t.content}
                            {t.counter ? (
                                <Counter
                                    text={t.counter}
                                    styles={{ marginLeft: 5 }}
                                />
                            ) : undefined}
                            {t.warning ? (
                                <Dot color="red" style={{ marginLeft: 5 }} />
                            ) : undefined}
                        </NavLink>
                    ))}
            </div>
            {actions.length > 0 && (
                <ContextMenu actions={actions}>
                    <div className={styles.context}>
                        <Icon icon="menu" size={'s'} color={'black-300'} />
                    </div>
                </ContextMenu>
            )}
        </div>
    </div>
);

Head.propTypes = {
    tabs: PropTypes.array,
    actions: PropTypes.array,
};

Head.defaultProps = {
    tabs: [],
    actions: [],
};

export default Head;
