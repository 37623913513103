import React, { useEffect, useState } from 'react';

export const UIContext = React.createContext({
    toasts: [],
    spinners: [],
    toastPush: () => {},
    toastPop: () => {},
    spinnerPush: () => {},
    spinnerPop: () => {},
    isMenu: false,
    setMenu: () => {},
    isTopBar: false,
    setTopBar: () => {},
    modal: null,
    modalOpen: () => {},
    modalClose: () => {},
    tutorialOpen: () => {},
});

const UIProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);
    const [spinners, setSpinners] = useState([]);
    const [isMenu, setMenu] = useState(false);
    const [isTopBar, setTopBar] = useState(false);
    const [modal, setModal] = useState({
        id: null,
        data: {},
    });

    const spinnerPush = () => {
        setSpinners(currentSpinners => [...currentSpinners, true]);
    };

    const spinnerPop = () => {
        setSpinners(currentSpinners => {
            const newSpinners = [...currentSpinners];
            newSpinners.pop();
            return newSpinners;
        });
    };

    const toastPush = toast => {
        setToasts(currentToasts => {
            return [
                ...currentToasts,
                {
                    ...toast,
                    id: +new Date(),
                },
            ];
        });
    };

    const toastPop = id => {
        setToasts(currentToasts => {
            const newToasts = [];
            for (let i = 0; i < currentToasts.length; i += 1) {
                if (currentToasts[i].id !== id) {
                    newToasts.push(currentToasts[i]);
                }
            }
            return newToasts;
        });
    };

    const modalOpen = (id, data) => {
        setModal({
            id,
            data,
        });
    };

    const modalClose = () => {
        setModal({
            id: null,
            data: {},
        });
    };

    const tutorialOpen = () => {
        if (window.tutorial) {
            window.tutorial();
        } else {
            toastPush({
                title: 'Dla tego widoku nie ma jeszcze pomocy.',
                text: 'Skorzystaj z formularza pomocy w prawym dolnym rogu.',
            });
        }
    };

    useEffect(() => {
        if (modal.id) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [modal.id]);

    const contextValue = {
        toasts,
        toastPush,
        toastPop,
        isSpinner: !!spinners.length,
        spinnerPush,
        spinnerPop,
        isMenu,
        setMenu,
        isTopBar,
        setTopBar,
        modal,
        modalOpen,
        modalClose,
        tutorialOpen,
    };

    return (
        <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
    );
};

export default UIProvider;
