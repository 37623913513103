import { useEffect } from 'react';

import { ROLES } from 'constants/logic';
import { useMutationPlayerAddParent } from 'queries/players.mutations.js';
import { usePlayerQuery } from 'queries/players.queries.js';
import {
    Button,
    Column,
    Icon,
    Input,
    Label,
    List,
    ListActions,
    ListCell,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';

import ListDataCell from '../../../../../components/ListDataCell/index.js';
import { useSearchPeopleQuery } from '../../../../../queries/search.queries.js';
import useForm from '../../../../../utils/useForm.js';

const AddParents = ({ data: { idPlayer }, modalClose, modalOpen }) => {
    const { data: player } = usePlayerQuery(idPlayer);

    const [form] = useForm({
        search: {
            value: '',
        },
    });

    const { data: parents } = useSearchPeopleQuery(form.search.value, [
        ROLES.PARENT,
    ]);

    useEffect(() => {
        form.search.setValue(player.surname);
    }, [player.surname]);

    const mutationPlayerAddParent = useMutationPlayerAddParent();

    const addParent = idParent => {
        mutationPlayerAddParent.mutate({ idPlayer, idParent });
    };

    const addNewParent = () => {
        const [name, surname] = form.search.value
            ? form.search.value.split(' ')
            : ['', ''];
        modalOpen('addParent', {
            name,
            surname,
            addToPlayer: idPlayer,
        });
    };

    return (
        <>
            <ModalHeader>Zarządzaj rodzicami zawodnika</ModalHeader>
            <ModalContent>
                <Column gap="m">
                    <Column gap="xs">
                        <Column>
                            <Label>
                                Wpisz imię lub nazwisko by znaleźć rodzica
                            </Label>
                            <Input {...form.search} />
                        </Column>
                        <List
                            data={parents.slice(0, 5)}
                            renderRow={({ id, name, surname, avatar }) => {
                                return (
                                    <>
                                        <ListDataCell.User
                                            width={350}
                                            user={{ id, name, surname, avatar }}
                                        />
                                        {player.parents.find(
                                            pp => pp.id === id,
                                        ) ? (
                                            <ListCell width={24}>
                                                <Icon
                                                    size="m"
                                                    icon={'ok'}
                                                    color={'green-500'}
                                                />
                                            </ListCell>
                                        ) : (
                                            <ListActions
                                                actions={[
                                                    {
                                                        icon: 'plus',
                                                        onClick: () =>
                                                            addParent(id),
                                                    },
                                                ]}
                                            />
                                        )}
                                    </>
                                );
                            }}
                        />
                    </Column>
                </Column>
            </ModalContent>
            <ModalActions>
                <Button color="black" onClick={addNewParent}>
                    Utwórz nowego rodzica
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Zakończ
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AddParents, {
    id: 'addParentsToPlayer',
    title: 'Przypisz rodzica',
    size: 'small',
});
