import { Container, Header } from 'ui';

import styles from './Tests.module.scss';

const Tests = () => (
    <div className={styles.view}>
        <Container>
            <div>
                <Header title="Testy" size={'xl'} />
            </div>
            Sekcja niedostępna
        </Container>
    </div>
);

export default Tests;
