import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import useUI from 'contexts/useUI.js';

import { Column, Portal, Space } from '../index.js';

import style from './Modal.module.scss';

export default function ModalHOC(ModalComponent, config) {
    const ModalHOCClass = props => {
        const { modal, modalClose, modalOpen } = useUI();

        const modalProps = {
            ...props,
            data: modal.data || {},
            modalClose,
            modalOpen,
        };

        return (
            <AnimatePresence>
                {modal.id === config.id ? (
                    <Portal>
                        <div
                            className={cx([
                                style.root,
                                config.noPadding && style.noPadding,
                            ])}>
                            <motion.div
                                className={cx(
                                    style.card,
                                    style[`size-${config.size || 'small'}`],
                                )}
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{
                                    opacity: 1,
                                    scale: 1,
                                    transition: {
                                        duration: 0.3,
                                        delay: 0.2,
                                        ease: [0, 0.71, 0.2, 1.01],
                                    },
                                }}
                                exit={{
                                    opacity: 0,
                                    transition: { duration: 0.3 },
                                }}>
                                <div className={style.body}>
                                    <Column gap="m">
                                        <ModalComponent {...modalProps} />
                                    </Column>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    transition: { duration: 0.3 },
                                }}
                                exit={{
                                    opacity: 0,
                                    transition: { duration: 0.3 },
                                }}
                                className={style.overlay}
                                onClick={modalClose}
                            />
                        </div>
                    </Portal>
                ) : null}
            </AnimatePresence>
        );
    };

    return ModalHOCClass;
}

export const ModalHeader = ({ children }) => (
    <Space className={style.header}>{children}</Space>
);

ModalHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
};

export const ModalContent = ({ children }) => (
    <Space className={style.content}>{children}</Space>
);

ModalContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export const ModalActions = ({ children }) => (
    <Space className={style.actions} direction="row">
        {children}
    </Space>
);

ModalActions.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
