import { Button } from 'ui';

import iconsPng from './icons.png';

import styles from './EmptyCard.module.scss';

const EmptyCard = ({ actionLabel, action }) => {
    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.card}>
                    <div className={styles.header}>Pusto</div>
                    <div className={styles.text}>
                        <p>
                            W tym miejscu jeszcze nic nie ma.
                            <br />
                            Bądź pierwszym który coś doda
                        </p>
                    </div>
                </div>
                <img className={styles.icons} src={iconsPng} alt="" />
                {actionLabel && (
                    <div className={styles.action}>
                        <Button onClick={action} color="green">
                            {actionLabel}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmptyCard;
