import { useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';

import { Button, Column, Header, InputDate, Label, Row, Spinner } from 'ui';

import EmptyCard from '../../../../components/EmptyCard/index.js';
import {
    useGroupCoachesQuery,
    useGroupEventsQuery,
    useGroupPlayersQuery,
} from '../../../../queries/groups.queries.js';
import PercentCircle from '../../../../ui/PercentCircle/index.js';
import useForm from '../../../../utils/useForm.js';
import validators from '../../../../utils/validators.util.js';
import AddCoach from '../modals/AddCoach';

import PunchCard from './PunchCard/PunchCard.jsx';

const Attendance = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const [filter, setFilter] = useState({
        dateStart: {
            $gte: DateTime.now().set({ day: 1 }).toJSDate(),
            $lte: DateTime.now().toJSDate(),
        },
    });

    const [form, { isFormValid }] = useForm({
        dateStart: {
            value: DateTime.now().set({ day: 1 }).toJSDate(),
            validators: [validators.isRequired()],
        },
        dateEnd: {
            value: DateTime.now().toJSDate(),
            validators: [validators.isRequired()],
        },
    });

    const { data: events, isFetched } = useGroupEventsQuery(id, filter);
    const { data: players } = useGroupPlayersQuery(id);
    const { data: coaches } = useGroupCoachesQuery(id);

    const users = useMemo(() => [...players, ...coaches], [players, coaches]);

    const averageAttendance = useMemo(() => {
        return events.reduce((a, b) => a + b.attendance, 0) / events.length;
    }, [events]);

    const save = () => {
        if (!isFormValid) return;

        setFilter({
            dateStart: {
                $gte: form.dateStart.value,
                $lte: DateTime.fromJSDate(form.dateEnd.value)
                    .endOf('day')
                    .toJSDate(),
            },
        });
    };

    if (!isFetched) return <Spinner active />;

    return (
        <>
            <Column gap={'m'}>
                <Header title="Frekwencja" size="l" />
                <Row align={'center'}>
                    <Row gap="s" align={'center'} alignContent={'start'}>
                        <Row gap="xxs" size={0}>
                            <Label>Data początkowa</Label>
                            <InputDate {...form.dateStart} />
                        </Row>
                        <Row gap="xxs" size={0}>
                            <Label>Data końcowa</Label>
                            <InputDate {...form.dateEnd} />
                        </Row>
                        <Button color="green" onClick={save}>
                            Zastosuj
                        </Button>
                    </Row>
                    <PercentCircle percent={averageAttendance} size="l" />
                </Row>
                {events.length ? (
                    <PunchCard events={events} users={users} />
                ) : (
                    <EmptyCard />
                )}
            </Column>
            <AddCoach />
        </>
    );
};

export default Attendance;
