import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Dot.module.scss';

const Dot = ({ color, size, style }) => (
    <div
        className={cx(
            styles.dot,
            styles[`color-${color}`],
            styles[`size-${size}`],
        )}
        style={style}
    />
);

Dot.propTypes = {
    color: PropTypes.oneOf(['red', 'yellow', 'green']).isRequired,
    size: PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'xs']),
    style: PropTypes.shape({}),
};

Dot.defaultProps = {
    color: 'green',
    style: undefined,
    size: 's',
};

export default Dot;
