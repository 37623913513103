import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DatePickerContext from './DatePickerContext';

class DatePickerContextContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'month',
            direction: 'bottom',
        };
    }

    changeHandler = date => {
        if (this.props.onChange) {
            this.props.onChange(date);
        }
    };

    setMode = (mode, value) => {
        let direction = '';
        if (
            (this.state.mode === 'month' && mode === 'year') ||
            (this.state.mode === 'year' && mode === 'decade')
        ) {
            direction = 'bottom';
        }

        if (
            (this.state.mode === 'decade' && mode === 'year') ||
            (this.state.mode === 'year' && mode === 'month')
        ) {
            direction = 'top';
        }

        this.changeHandler(value);
        this.setState(() => ({
            mode: mode,
            direction,
        }));
    };

    render() {
        const props = {
            value: this.props.value,
            mode: this.state.mode,
            direction: this.state.direction,
            onChange: this.changeHandler,
            setMode: this.setMode,
        };

        return <DatePickerContext {...props} />;
    }
}

DatePickerContextContainer.propTypes = {
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    dateBoundary: PropTypes.object,
};

DatePickerContextContainer.defaultProps = {
    value: new Date(),
    onChange: undefined,
    dateBoundary: undefined,
};

export default DatePickerContextContainer;
