import cx from 'classnames';
import PropTypes, { oneOf } from 'prop-types';

import style from './Column.module.scss';

const Column = ({ children, size, gap }) => (
    <div
        className={cx(style.column, style[`gap-${gap}`])}
        style={{ flexGrow: size, flexBasis: 0 }}>
        {children}
    </div>
);

Column.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    gap: oneOf([null, 'xxs', 'xs', 's', 'm', 'l', 'xl']),
    size: PropTypes.number,
};

Column.defaultProps = {
    size: 1,
    children: null,
    gap: null,
};

export default Column;
