import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { useMutationParentAdd } from 'queries/parents.mutations.js';
import searchService from 'services/searchService';
import {
    Button,
    Column,
    Header,
    Input,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Row,
    Warning,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util.js';

const AddParentDialog = ({ data, modalClose }) => {
    const history = useHistory();
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        name: {
            value: '',
            validators: [validators.isRequired()],
        },
        surname: {
            value: '',
            validators: [validators.isRequired()],
        },
        phone: {
            value: '',
        },
        email: {
            value: '',
        },
        'address.street': {
            value: '',
        },
        'address.city': {
            value: '',
        },
        'address.postal': {
            value: '',
        },
    });

    useEffect(() => {
        if (data.name) {
            form.name.setValue(data.name);
        }
        if (data.surname) {
            form.surname.setValue(data.surname);
        }
    }, [data]);

    const [existingParent, setExistingParent] = useState(null);

    useDebounce(
        () => {
            if (!form.name.value || !form.surname.value) {
                setExistingParent(null);
                return;
            }

            searchService
                .get(`${form.name.value} ${form.surname.value}`)
                .then(({ parents }) => {
                    if (parents.length) {
                        setExistingParent(
                            `${parents[0].name} ${parents[0].surname}`,
                        );
                    } else {
                        setExistingParent(null);
                    }
                });
        },
        500,
        [form.name, form.surname],
    );

    const mutation = useMutationParentAdd({
        onSuccess: ({ id }) => {
            modalClose();
            history.push(`/parents/${id}`);
        },
    });

    const addParent = () => {
        if (!isFormValid) return;
        mutation.mutate({
            ...getFormValue(),
            ...(data.addToPlayer
                ? {
                      addToPlayer: data.addToPlayer,
                  }
                : null),
        });
    };

    return (
        <>
            <ModalHeader>Nowy rodzic</ModalHeader>
            <ModalContent>
                <Row gap="m">
                    <Column gap={'m'}>
                        <Column gap={'xs'}>
                            <Header title="Informacje osobiste" size="s" />
                            <div>
                                <Label>Imię</Label>
                                <Input {...form.name} />
                            </div>
                            <div>
                                <Label>Nazwisko</Label>
                                <Input {...form.surname} />
                            </div>
                            {existingParent && (
                                <div>
                                    <Warning
                                        title={`Istnieje już rodzic ${existingParent}`}
                                        content="Czy aby na pewno chcesz dodać nowego rodzica"
                                    />
                                </div>
                            )}
                        </Column>
                        <Column gap={'xs'}>
                            <Header title="Informacje kontaktowe" size="s" />
                            <div>
                                <Label>Adres email</Label>
                                <Input {...form.email} />
                            </div>
                            <div>
                                <Label>Numer telefonu</Label>
                                <Input {...form.phone} />
                            </div>
                        </Column>
                    </Column>
                    <Column gap={'xs'}>
                        <Header title="Dane adresowe" size="s" />
                        <div>
                            <Label>Ulica</Label>
                            <Input {...form['address.street']} />
                        </div>
                        <div>
                            <Label>Miasto</Label>
                            <Input {...form['address.city']} />
                        </div>
                        <div>
                            <Label>Kod pocztowy</Label>
                            <Input {...form['address.postal']} />
                        </div>
                    </Column>
                </Row>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid}
                    color="green"
                    onClick={addParent}
                    onDisabledClick={touchForm}>
                    Utwórz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AddParentDialog, {
    id: 'addParent',
    title: 'Dodaj nowego rodzica',
    size: 'medium',
});
