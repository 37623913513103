const HOURS = [];

for (let i = 5 * 60; i <= 24 * 60; i += 15) {
    HOURS.push({
        h: Math.floor(i / 60),
        m: i % 60,
    });
}

export { HOURS };
