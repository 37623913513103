import cx from 'classnames';

import styles from './DayCard.module.scss';

const DayCard = ({ date, amount, active, onClick }) => {
    const weekDay = date.weekday;
    const isWeekend = weekDay >= 6;

    return (
        <div
            className={cx(
                styles.root,
                isWeekend && styles.weekend,
                active && styles.active,
            )}
            onClick={onClick}>
            <div className={styles.card}>
                <div className={styles.number}>{date.day}</div>
                <div className={styles.separator} />
                <div className={styles.name}>
                    {date.toFormat('ccc').slice(0, -1)}
                </div>
            </div>
            {amount && <div className={styles.amount}>{amount}</div>}
        </div>
    );
};

export default DayCard;
