import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { Icon } from 'ui/index';
import { formatYear } from 'utils/formatters';

import Months from './Months';

import style from './YearView.module.scss';

const YearView = ({ year, onNext, onPrev, openMonth, openDecade }) => {
    const props = {
        year,
        openMonth,
    };
    return (
        <div className={style.root}>
            <div className={style.month}>
                <div className={style.button} onClick={onPrev}>
                    <Icon icon="circle-left" />
                </div>
                <div className={style.name} onClick={openDecade}>
                    {formatYear(new Date(year, 0))}
                </div>
                <div
                    className={style.button}
                    onClick={onNext}
                    onKeyPress={onPrev}>
                    <Icon icon="circle-right" />
                </div>
            </div>
            <div className={style.view}>
                <AnimatePresence>
                    <motion.div
                        key={year}
                        initial={{
                            opacity: 0,
                        }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{
                            opacity: 0,
                        }}>
                        <Months {...props} />
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
};

YearView.propTypes = {
    year: PropTypes.number.isRequired,
    direction: PropTypes.string.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    openMonth: PropTypes.func.isRequired,
    openDecade: PropTypes.func.isRequired,
};

export default YearView;
