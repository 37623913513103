import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { usePlayerQuery } from 'queries/players.queries.js';
import {
    AnimatedTransition,
    Column,
    Container,
    Head,
    RoleTag,
    Spinner,
} from 'ui';
import { formatAddress, formatAge } from 'utils/formatters';
import useACL from 'utils/useACL';

import ViewHOC from '../../ViewHOC';
import DeletePlayerDialog from '../modals/DeletePlayerDialog';

import AddMeasurement from './modals/AddMeasurement';
import Calendar from './Calendar';
import Info from './Info';
import Measurements from './Measurements';
import Medicals from './Medicals';

const PlayersSingle = ({ player }) => {
    const location = useLocation();
    const {
        params: { id },
    } = useRouteMatch();

    const { isRole } = useACL();

    const { modalOpen } = useUI();

    const openDeletePlayerDialog = () => {
        modalOpen('deletePlayer', { id });
    };

    const { data } = usePlayerQuery(id);

    if (!data) {
        return <Spinner active />;
    }

    return (
        <Container>
            <Column gap={'m'}>
                <Head
                    title={`${data.name} ${data.surname}`}
                    subtitle={
                        <>
                            <span>
                                {formatAge(data.age)}
                                {data.age && data.address ? ', ' : ''}
                                {formatAddress(data.address)}
                            </span>
                            <RoleTag role={ROLES.PLAYER} size="s" />
                        </>
                    }
                    avatar={{
                        text: `${data.name} ${data.surname}`,
                        picture: data.avatar,
                    }}
                    tabs={[
                        {
                            content: 'Informacje',
                            link: `/players/${data.id}/`,
                        },
                        {
                            content: 'Fizjonomia',
                            link: `/players/${data.id}/measurements`,
                        },
                        {
                            content: 'Kalendarz',
                            link: `/players/${data.id}/calendar`,
                        },
                        {
                            content: 'Badania lekarskie',
                            link: `/players/${data.id}/medicals`,
                            warning: !data.isMedicalValid,
                        },
                    ]}
                    actions={[
                        {
                            text: 'Usuń zawodnika',
                            color: 'red',
                            icon: 'delete',
                            onClick:
                                isRole([ROLES.OWNER, ROLES.ADMIN]) &&
                                openDeletePlayerDialog,
                        },
                    ]}
                />
                <AnimatedTransition k={location.pathname}>
                    <Switch location={location}>
                        <Route exact path="/players/:id/" component={Info} />
                        <Route
                            exact
                            path="/players/:id/measurements"
                            component={Measurements}
                        />
                        <Route
                            exact
                            path="/players/:id/calendar"
                            render={props => (
                                <Calendar {...props} player={player} />
                            )}
                        />
                        <Route
                            exact
                            path="/players/:id/medicals"
                            component={Medicals}
                        />
                        <Route component={Info} />
                    </Switch>
                </AnimatedTransition>
            </Column>
            <AddMeasurement />
            <DeletePlayerDialog />
        </Container>
    );
};

export default ViewHOC(PlayersSingle, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
