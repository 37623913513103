import { useRouteMatch } from 'react-router-dom';

import GroupCard from 'components/GroupCard';
import Positions from 'components/Positions';
import { SEX_MAP } from 'constants/logic';
import useUI from 'contexts/useUI';
import {
    useMutationPlayerPatch,
    useMutationPlayerRemoveParent,
} from 'queries/players.mutations.js';
import {
    usePlayerGroupsQuery,
    usePlayerQuery,
} from 'queries/players.queries.js';
import {
    Button,
    Card,
    Column,
    Header,
    LabeledValue,
    List,
    ListActions,
    Row,
    Spinner,
} from 'ui';
import { formatDateFull } from 'utils/formatters';

import ListDataCell from '../../../../components/ListDataCell/index.js';
import AddGroups from '../modals/AddGroups';
import AddParents from '../modals/AddParents';
import EditAddress from '../modals/EditAddress';
import EditContact from '../modals/EditContact';
import EditInfo from '../modals/EditInfo';
import EditSchool from '../modals/EditSchool';

const Info = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const { modalOpen } = useUI();
    const { data } = usePlayerQuery(id);
    const { data: groups } = usePlayerGroupsQuery(id);

    const mutationRemoveParent = useMutationPlayerRemoveParent();
    const removeParent = idParent => {
        mutationRemoveParent.mutate({ idPlayer: id, idParent });
    };

    const mutationPatchPositions = useMutationPlayerPatch();
    const patchPositions = positions => {
        mutationPatchPositions.mutate({ id, delta: { positions } });
    };

    if (!data) return <Spinner active />;

    return (
        <>
            <Row gap="m">
                <Column gap="m">
                    <Card
                        onEdit={() => modalOpen('editInfo', { idPlayer: id })}>
                        <Column gap="xs">
                            <Header title="Informacje podstawowe" size="m" />
                            <Row>
                                <Column>
                                    <LabeledValue
                                        label="Imię"
                                        value={data.name}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Nazwisko"
                                        value={data.surname}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Ksywka"
                                        value={data.nickname}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <LabeledValue
                                        label="Płeć"
                                        value={SEX_MAP[data.sex].name}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Data urodzenia"
                                        value={formatDateFull(data.birthday)}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="PESEL"
                                        value={data.pesel}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                    <Card
                        onEdit={() =>
                            modalOpen('addMeasurement', { idPlayer: id })
                        }>
                        <Column gap="xs">
                            <Header title="Atrybuty fizyczne" size="m" />
                            <Row>
                                <Column>
                                    <LabeledValue
                                        label="Waga"
                                        value={
                                            data?.weight &&
                                            `${data?.weight?.toFixed(1)}kg`
                                        }
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Wzrost"
                                        value={
                                            data?.height && `${data?.height}cm`
                                        }
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="BMI"
                                        value={data.bmi}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                    <Card
                        onEdit={() =>
                            modalOpen('editContact', { idPlayer: id })
                        }>
                        <Column gap="xs">
                            <Header title="Kontakt" size="m" />
                            <Row>
                                <Column>
                                    <LabeledValue
                                        label="Telefon"
                                        value={data.phone}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Adres email"
                                        value={data.email}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                    <Card
                        onEdit={() =>
                            modalOpen('editAddress', { idPlayer: id })
                        }>
                        <Column gap="xs">
                            <Header title="Adres" size="m" />
                            <Row>
                                <Column>
                                    <LabeledValue
                                        label="Ulica"
                                        value={data?.address?.street}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Miasto"
                                        value={data?.address?.city}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Kod pocztowy"
                                        value={data?.address?.postal}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                    <Card
                        onEdit={() =>
                            modalOpen('editSchool', { idPlayer: id })
                        }>
                        <Column gap="xs">
                            <Header title="Szkoła" size="m" />
                            <Row>
                                <Column>
                                    <LabeledValue
                                        label="Nazwa szkoły"
                                        value={data?.school?.name}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Klasa"
                                        value={data?.school?.class}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Wychowawca"
                                        value={data?.school?.teacher}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                </Column>
                <Column gap={'m'}>
                    <Column gap={'xs'}>
                        <Header title="Rodzice" size="m">
                            <Button
                                shape="rect"
                                icon="edit"
                                size={'l'}
                                onClick={() =>
                                    modalOpen('addParentsToPlayer', {
                                        idPlayer: id,
                                    })
                                }
                            />
                        </Header>
                        {data.parents && (
                            <List
                                data={data.parents.map(p => ({
                                    ...p,
                                    remove: () => removeParent(p.id),
                                }))}
                                renderRow={({
                                    id: idParent,
                                    name,
                                    surname,
                                    avatar,
                                    phone,
                                    remove,
                                }) => (
                                    <>
                                        <ListDataCell.User
                                            size="l"
                                            user={{
                                                id: idParent,
                                                name,
                                                surname,
                                                avatar,
                                                role: 'parent',
                                            }}
                                        />
                                        <ListDataCell.Phone phone={phone} />
                                        <ListActions
                                            actions={[
                                                {
                                                    title: 'delete',
                                                    icon: 'delete',
                                                    color: 'red',
                                                    onClick: remove,
                                                },
                                            ]}
                                        />
                                    </>
                                )}
                            />
                        )}
                    </Column>
                    <Column gap={'xs'}>
                        <Header title="Grupy" size="m">
                            <Button
                                shape="rect"
                                icon="edit"
                                size={'l'}
                                onClick={() =>
                                    modalOpen('addGroupsToPlayer', {
                                        idPlayer: id,
                                    })
                                }
                            />
                        </Header>
                        <Row gap={'xs'} wrap={'wrap'}>
                            {groups.map(group => (
                                <GroupCard
                                    {...group}
                                    key={group.id}
                                    onClick={undefined}
                                />
                            ))}
                        </Row>
                    </Column>

                    <Column gap={'xs'}>
                        <Header title="Pozycje na boisku" size="m" />
                        <Positions
                            value={data.positions}
                            onChange={patchPositions}
                        />
                    </Column>
                </Column>
            </Row>
            <EditAddress />
            <EditContact />
            <EditInfo />
            <EditSchool />
            <AddGroups />
            <AddParents />
        </>
    );
};

export default Info;
