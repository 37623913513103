import { PureComponent } from 'react';

import CookieAlert from './CookieAlert.jsx';

class CookieAlertContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
    }

    componentDidMount() {
        const ls = localStorage.getItem('cookieAlert');

        if (ls !== 'true') {
            this.setState({
                active: true,
            });
        }
    }

    accept = () => {
        localStorage.setItem('cookieAlert', 'true');
        this.setState({
            active: false,
        });
    };

    render() {
        const props = {
            active: this.state.active,
            accept: this.accept,
        };
        return <CookieAlert {...props} />;
    }
}

export default CookieAlertContainer;
