import cx from 'classnames';
import { AnimatePresence } from 'framer-motion';

import useUI from 'contexts/useUI';

import Toast from './Toast';

import style from './Toaster.style.module.scss';

const Toaster = () => {
    const { toasts } = useUI();

    return (
        <div className={cx(style.toaster, toasts.length && style.visible)}>
            <AnimatePresence>
                {toasts.slice(0, 1).map(t => (
                    <Toast key={t.id} {...t} />
                ))}
            </AnimatePresence>
        </div>
    );
};

export default Toaster;
