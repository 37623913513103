import { useRouteMatch } from 'react-router-dom';

import useUI from 'contexts/useUI';
import { useParentQuery } from 'queries/parents.queries.js';
import { Card, Column, Header, LabeledValue, Row, Spinner } from 'ui';

import EditAddress from './modals/EditAddress';
import EditContact from './modals/EditContact';
import EditInfo from './modals/EditInfo';

const Info = () => {
    const {
        params: { id },
    } = useRouteMatch();
    const { modalOpen } = useUI();

    const { data: parent } = useParentQuery(id);

    if (!parent) {
        return <Spinner active />;
    }
    return (
        <>
            <Row gap="m">
                <Column>
                    <Card
                        onEdit={() => modalOpen('editInfo', { idParent: id })}>
                        <Column gap={'xs'}>
                            <Header title="Informacje podstawowe" size="m" />
                            <Row gap="xs">
                                <Column>
                                    <LabeledValue
                                        label="Imię"
                                        value={parent.name}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Nazwisko"
                                        value={parent.surname}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                </Column>
                <Column gap="m">
                    <Card
                        onEdit={() =>
                            modalOpen('editContact', { idParent: id })
                        }>
                        <Column gap={'xs'}>
                            <Header title="Kontakt" size="m" />
                            <Row gap="xs">
                                <Column>
                                    <LabeledValue
                                        label="Telefon"
                                        value={parent.phone}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Adres email"
                                        value={parent.email}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                    <Card
                        onEdit={() =>
                            modalOpen('editAddress', { idParent: id })
                        }>
                        <Column gap={'xs'}>
                            <Header title="Adres" size="m" />
                            {parent.address && (
                                <Row gap="xs">
                                    <Column>
                                        <LabeledValue
                                            label="Ulica"
                                            value={parent.address.street}
                                        />
                                    </Column>
                                    <Column>
                                        <LabeledValue
                                            label="Miasto"
                                            value={parent.address.city}
                                        />
                                    </Column>
                                    <Column>
                                        <LabeledValue
                                            label="Kod pocztowy"
                                            value={parent.address.postal}
                                        />
                                    </Column>
                                </Row>
                            )}
                        </Column>
                    </Card>
                </Column>
            </Row>
            <EditAddress />
            <EditContact />
            <EditInfo />
        </>
    );
};

export default Info;
