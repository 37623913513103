import React, { useEffect, useMemo, useState } from 'react';
import Bugsnag from '@bugsnag/js';

import authService from 'services/authService';
import meService from 'services/meService';

import useUI from './useUI';

export const AuthContext = React.createContext({
    token: null,
    me: {
        address: {},
        school: {},
        organization: {},
    },
    roles: [],
    schools: [],
    signIn: () => {},
    signOut: () => {},
    signUp: () => {},
    selectRole: () => {},
    restoreSession: () => {},
    resetPassword: () => {},
    createSchool: () => {},
    patchMe: () => {},
});

const AuthProvider = ({ children }) => {
    const [token, setTokenState] = useState(null);
    const [me, setMe] = useState({
        address: {},
        school: {},
        organization: {},
    });
    const [roles, setRoles] = useState([]);

    const { spinnerPush, spinnerPop, toastPush } = useUI();

    const setToken = newtoken => {
        window.localStorage.setItem(
            import.meta.env.VITE_AUTHTOKEN_NAME,
            newtoken,
        );
        window.localStorage.setItem(
            import.meta.env.VITE_AUTHTOKEN_NAME,
            newtoken,
        );
        setTokenState(newtoken);
        return Promise.resolve();
    };

    useEffect(() => {
        Bugsnag.setUser(me.id, me.email, `${me.name} ${me.surname}`);
    }, [me]);

    const signIn = async (email, password) => {
        try {
            spinnerPush();
            const { data } = await authService.signIn(email, password);
            if (data.token) {
                await setToken(data.token);
            }
            toastPush({
                title: 'Autoryzacja udana',
                text: 'Witaj w Goaly',
            });
            if (
                data.users.length &&
                data.users.filter(u => u.role === 'parent').length ===
                    data.users.length
            ) {
                spinnerPop();
                return 'only-parent';
            } else if (data.users.length === 0) {
                spinnerPop();
                return 0;
            } else if (data.users.length === 1) {
                const { data: data2 } = await authService.selectUser(
                    data.users[0].id,
                );
                await setToken(data2.token);
                setMe(data2.user);
                spinnerPop();
                return 1;
            } else {
                setRoles(data.users);
                spinnerPop();
                return data.users.length;
            }
        } catch (e) {
            spinnerPop();
            toastPush({
                title: 'Autoryzacja nieudana',
                text: 'Spróbuj podobnie lub zresetuj hasło',
            });
            throw new Error(e);
        }
    };

    const signOut = () => {
        setTimeout(() => {
            setToken(null);
            setMe({
                address: {},
                school: {},
                organization: {},
            });
        }, 500);
        return Promise.resolve();
    };

    const selectRole = id => {
        spinnerPush();
        return authService.selectUser(id).then(({ data }) => {
            setToken(data.token);
            setMe(data.user);
            spinnerPop();
        });
    };

    const restoreSession = () => {
        return meService.get().then(data => {
            setMe(data);
        });
    };

    const signUp = object => {
        spinnerPush();
        return authService
            .signUp(object)
            .finally(spinnerPop)
            .then(() => {
                toastPush({
                    title: 'Aktywuj konto',
                    text: 'Wysłaliśmy Ci maila z linkiem aktywacyjnym',
                });
            })
            .catch(() => {
                toastPush({
                    title: 'Rejestracja nieudana',
                });
            });
    };

    const resetPassword = email => {
        spinnerPush();
        return authService
            .resetPassword(email)
            .finally(spinnerPop)
            .then(() => {
                toastPush({
                    title: 'Poprosiłeś o zresetowanie hasła',
                    text: 'Wysłaliśmy Ci maila z dalszymi instrukcjami',
                });
            })
            .catch(() => {
                toastPush({
                    text: 'Operacja nieudana',
                });
                return Promise.reject();
            });
    };

    const createSchool = async name => {
        spinnerPush();
        try {
            const { data } = await authService.createSchool(name);
            toastPush({
                title: 'Utworzono nową szkołę',
                text: `Witaj w ${name}`,
            });
            await setToken(data.token);
            setMe(data.u);
            spinnerPop();
        } catch (e) {
            toastPush({
                title: 'Operacja nie powiodła się',
            });
            spinnerPop();
        }
    };

    const patchMe = delta => {
        spinnerPush();
        return meService
            .patch(delta)
            .finally(spinnerPop)
            .then(({ data }) => {
                toastPush({
                    title: 'Profil zaktualizowany',
                });
                setMe(data);
            });
    };

    const schools = useMemo(() => {
        const newSchoolIds = [];
        const newSchools = {};

        roles.forEach(role => {
            if (!newSchools[role.organization._id]) {
                newSchools[role.organization._id] = {
                    ...role.organization,
                    roles: [
                        {
                            id: role.id,
                            role: role.role,
                            email: role.email,
                            name: role.name,
                            surname: role.surname,
                            avatar: role.avatar,
                        },
                    ],
                };
                newSchoolIds.push(role.organization._id);
            } else {
                newSchools[role.organization._id].roles.push({
                    id: role.id,
                    role: role.role,
                    email: role.email,
                    name: role.name,
                    surname: role.surname,
                    avatar: role.avatar,
                });
            }
        });
        return newSchoolIds.map(id => newSchools[id]);
    });

    const contextValue = {
        token,
        me,
        roles,
        schools,
        signIn,
        signOut,
        signUp,
        selectRole,
        restoreSession,
        resetPassword,
        createSchool,
        patchMe,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
