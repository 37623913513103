import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import AuthProvider from 'contexts/AuthProvider.jsx';
import UIProvider from 'contexts/UIProvider.jsx';
import App from 'screens/App';
import ErrorBoundary from 'screens/App/ErrorBoundary';

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <UIProvider>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </UIProvider>
            </BrowserRouter>
            <ReactQueryDevtools />
        </QueryClientProvider>
    </ErrorBoundary>,
);
