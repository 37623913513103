import cx from 'classnames';
import PropTypes, { oneOf } from 'prop-types';

import style from './Row.module.scss';

const Row = ({ children, gap, align, alignContent, wrap, size, className }) => (
    <div
        className={cx([
            style.row,
            style[`gap-${gap}`],
            style[`wrap-${wrap}`],
            style[`align-${align}`],
            style[`alignContent-${alignContent}`],
            className,
        ])}
        style={{
            flex: size,
        }}>
        {children}
    </div>
);

Row.propTypes = {
    size: PropTypes.number,
    gap: oneOf([null, 'xxxs', 'xxs', 'xs', 's', 'm', 'l', 'xl']),
    align: oneOf(['start', 'center', 'end']),
    alignContent: oneOf(['start', 'center', 'end', 'space-between']),
    wrap: oneOf(['nowrap', 'wrap']),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
};

Row.defaultProps = {
    size: 1,
    gap: null,
    wrap: 'nowrap',
    align: 'start',
    alignContent: 'start',
    className: null,
};

export default Row;
