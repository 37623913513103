import { useEffect } from 'react';
import cx from 'classnames';

import { useMutationGroupPatch } from 'queries/groups.mutations.js';
import { useGroupQuery } from 'queries/groups.queries.js';
import {
    Button,
    Column,
    Input,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util.js';

import styles from './AvatarGroupDialog.module.scss';

const COLORS = [
    '#00539b',
    '#007dc5',
    '#7b439a',
    '#c60c46',
    '#ee312a',
    '#231f20',
    '#ffdf00',
    '#f5842b',
    '#54b948',
    '#00853f',
    '#005953',
];

const AvatarGroupDialog = ({ data: { id }, modalClose }) => {
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        color: {
            value: '',
        },
        string: {
            value: '',
            validators: [validators.isRequired(), validators.maxLength(2)],
        },
    });

    const { data } = useGroupQuery(id);

    useEffect(() => {
        if (data) {
            form.color.setValue(data.avatar.substr(0, 7));
            form.string.setValue(data.avatar.substr(7, 2));
        }
    }, [data]);

    const mutationPatch = useMutationGroupPatch({ onSuccess: modalClose });

    const saveAvatar = () => {
        const { color, string } = getFormValue();
        mutationPatch.mutate({
            id,
            delta: {
                avatar: `${color}${string}`,
            },
        });
        modalClose();
    };

    return (
        <>
            <ModalHeader>Edytuj avatar</ModalHeader>
            <ModalContent>
                <Column gap="xs">
                    <div>
                        <Label>Wybierz kolor grupy</Label>
                        <div className={styles.colors}>
                            {COLORS.map(c => (
                                <div
                                    key={c}
                                    onClick={() => form.color.setValue(c)}
                                    className={cx([
                                        styles.color,
                                        c === form.color.value && styles.active,
                                    ])}
                                    style={{ '--color': c }}
                                />
                            ))}
                        </div>
                    </div>
                    <div>
                        <Label>Wybierz skrót grupy (1 lub 2 znaki)</Label>
                        <Input {...form.string} />
                    </div>
                </Column>
            </ModalContent>
            <ModalActions>
                <Button
                    color="green"
                    onClick={saveAvatar}
                    disabled={!isFormValid}
                    onDisabledClick={touchForm}>
                    Zapisz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AvatarGroupDialog, {
    id: 'avatarGroup',
    size: 'small',
});
