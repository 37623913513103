import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { SEX_ARRAY, SEX_MAP } from 'constants/logic';
import { useMutationPlayerAdd } from 'queries/players.mutations.js';
import searchService from 'services/searchService';
import {
    Button,
    Column,
    Header,
    Input,
    InputDate,
    InputSelect,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Row,
    Warning,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util.js';

const DialogAddPlayer = ({ data, modalClose }) => {
    const history = useHistory();
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        name: {
            value: '',
            validators: [validators.isRequired()],
        },
        surname: {
            value: '',
            validators: [validators.isRequired()],
        },
        nickname: {
            value: '',
        },
        birthday: {
            value: null,
        },
        sex: {
            value: 'male',
            validators: [validators.isRequired()],
        },
        pesel: {
            value: '',
        },
        phone: {
            value: '',
        },
        email: {
            value: '',
        },
    });

    useEffect(() => {
        if (data.name) {
            form.name.setValue(data.name);
        }
        if (data.surname) {
            form.surname.setValue(data.surname);
        }
    }, [data]);

    const [existingPlayer, setExistingPlayer] = useState(null);

    useDebounce(
        () => {
            if (!form.name.value || !form.surname.value) {
                setExistingPlayer(null);
                return;
            }

            searchService
                .get(`${form.name.value} ${form.surname.value}`)
                .then(({ players }) => {
                    if (players.length) {
                        setExistingPlayer(
                            `${players[0].name} ${players[0].surname}`,
                        );
                    } else {
                        setExistingPlayer(null);
                    }
                });
        },
        500,
        [form.name, form.surname],
    );

    const mutation = useMutationPlayerAdd({
        onSuccess: data => {
            if (location.pathname.includes(`/players`)) {
                history.push(`/players/${data.id}`);
            }
            modalClose();
        },
    });

    const add = () => {
        if (!isFormValid) return;

        const object = getFormValue();
        if (data.addToParent) {
            object.addToParent = data.addToParent;
        }
        if (data.addToGroup) {
            object.addToGroup = data.addToGroup;
        }

        mutation.mutate(object);
    };

    return (
        <>
            <ModalHeader>Nowy zawodnik</ModalHeader>
            <ModalContent>
                <Row gap="m">
                    <Column gap={'m'}>
                        <Column gap={'xs'}>
                            <Header title="Informacje osobiste" size="s" />
                            <div>
                                <Label>Imię</Label>
                                <Input {...form.name} />
                            </div>
                            <div>
                                <Label>Nazwisko</Label>
                                <Input {...form.surname} />
                            </div>
                            {existingPlayer && (
                                <div>
                                    <Warning
                                        title={`Istnieje już zawodnik ${existingPlayer}`}
                                        content="Czy aby na pewno chcesz dodać nowego zawodnika"
                                    />
                                </div>
                            )}
                            <div>
                                <Label>Ksywka</Label>
                                <Input {...form.nickname} />
                            </div>
                            <div>
                                <Label>Płeć</Label>
                                <InputSelect
                                    options={SEX_ARRAY.map(key => ({
                                        label: SEX_MAP[key].name,
                                        value: key,
                                    }))}
                                    {...form.sex}
                                />
                            </div>
                            <div>
                                <Label>Data urodzenia</Label>
                                <InputDate {...form.birthday} />
                            </div>
                            <div>
                                <Label>Pesel</Label>
                                <Input {...form.pesel} />
                            </div>
                        </Column>
                    </Column>
                    <Column gap="xs">
                        <Header title="Informacje kontaktowe" size="s" />
                        <div>
                            <Label>Adres email</Label>
                            <Input {...form.email} />
                        </div>
                        <div>
                            <Label>Numer telefonu</Label>
                            <Input {...form.phone} />
                        </div>
                    </Column>
                </Row>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid}
                    color="green"
                    onClick={add}
                    onDisabledClick={touchForm}>
                    Utwórz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DialogAddPlayer, {
    id: 'addPlayer',
    size: 'medium',
});
