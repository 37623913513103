import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './Years.module.scss';

const yearsElements = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const Years = ({ decade, openYear }) => (
    <div className={cx(style.months)}>
        <div>
            {yearsElements.map(m => (
                <div
                    className={cx(style.month)}
                    onClick={() => openYear(decade + m)}
                    key={m}>
                    {decade + m}
                </div>
            ))}
        </div>
    </div>
);

Years.propTypes = {
    decade: PropTypes.number.isRequired,
    openYear: PropTypes.func.isRequired,
};

export default Years;
