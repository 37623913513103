import { PureComponent } from 'react';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import Tutorial from './Tutorial';

const positions = [
        'topLeft',
        'top',
        'topRight',
        'bottomLeft',
        'bottom',
        'bottomRight',
    ],
    isVisible = element =>
        element.offsetTop >= window.pageYOffset &&
        element.offsetTop + element.clientHeight <=
            window.pageYOffset + window.innerHeight,
    elementPOG = element => {
        const { width, height, left, top } = element.getBoundingClientRect();
        return {
            x: left + width / 2,
            y: top + height / 2,
        };
    },
    screenSection = element => {
        let tmp = 0;
        const pog = elementPOG(element);

        if (pog.y > window.innerHeight / 2) {
            tmp += 3;
        }

        if (pog.x > window.innerWidth * (2 / 3)) {
            tmp += 2;
        } else if (pog.x > window.innerWidth / 3) {
            tmp += 1;
        }

        return positions[tmp];
    },
    scrollTo = element =>
        new Promise(resolve => {
            element.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
                resolve();
            }, 300);
        });

class TutorialContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            step: 0,
            element: {
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
                width: 0,
                height: 0,
            },
        };
    }

    componentDidMount() {
        const { id } = this.props;

        const tutorial = JSON.parse(localStorage.getItem('tutorial')) || [];

        if (!tutorial[id]) {
            setTimeout(this.open, 1000);
        }

        window.tutorial = this.open;
    }

    componentWillUnmount() {
        window.tutorial = undefined;
    }

    open = () => {
        this.setState({
            active: true,
        });

        if (this.props.onStart) {
            this.props.onStart();
        }

        document.body.style.overflow = 'hidden';
    };

    close = () => {
        const { id, onFinish } = this.props;
        const tmp = localStorage.getItem('tutorial');
        const tutorial = tmp ? JSON.parse(tmp) : {};

        tutorial[id] = true;

        localStorage.setItem('tutorial', JSON.stringify(tutorial));

        this.setState({
            active: false,
        });

        if (onFinish) {
            onFinish();
        }

        document.body.style.overflow = 'auto';
    };

    next = () => {
        const { steps } = this.props,
            { step } = this.state;

        if (step < steps.length - 1) {
            this.openStep(step + 1);
        } else {
            this.close();
        }
    };

    openStep = id => {
        const step = this.props.steps[id];
        if (step.element) {
            const elementDOM = document.querySelector(step.element);
            if (elementDOM) {
                if (isVisible(elementDOM)) {
                    const { width, height, left, top } =
                        elementDOM.getBoundingClientRect();
                    this.setState({
                        step: id,
                        element: {
                            x: left,
                            y: top - window.pageYOffset,
                            width,
                            height,
                            position: screenSection(elementDOM),
                        },
                    });
                } else {
                    scrollTo(elementDOM).then(() => {
                        this.setState({
                            step: id,
                            element: {
                                x: elementDOM.offsetLeft,
                                y: elementDOM.offsetTop - window.pageYOffset,
                                width: elementDOM.offsetWidth,
                                height: elementDOM.offsetHeight,
                                position: screenSection(elementDOM),
                            },
                        });
                    });
                }
            } else if (this.props.steps[id + 1]) {
                this.openStep(id + 1);
            } else {
                this.close();
            }
        } else if (this.props.steps[this.state.step + 1]) {
            this.setState({
                step: this.state.step + 1,
                element: {
                    x: window.innerWidth / 2,
                    y: window.innerHeight / 2,
                    width: 0,
                    height: 0,
                },
            });
        } else {
            this.close();
        }
    };

    render() {
        const props = {
            steps: this.props.steps,
            step: this.state.step,
            element: this.state.element,
            next:
                this.state.step < this.props.steps.length - 1
                    ? this.next
                    : undefined,
            close:
                this.state.step < this.props.steps.length - 1
                    ? this.close
                    : undefined,
            finish:
                this.state.step === this.props.steps.length - 1
                    ? this.close
                    : undefined,
        };

        return (
            <AnimatePresence>
                {this.state.active && <Tutorial {...props} />}
            </AnimatePresence>
        );
    }
}

TutorialContainer.propTypes = {
    id: PropTypes.string.isRequired,
    steps: PropTypes.array,
    onStart: PropTypes.func,
    onFinish: PropTypes.func,
};

TutorialContainer.defaultProps = {
    steps: [],
    onStart: undefined,
    onFinish: undefined,
};

export default TutorialContainer;
