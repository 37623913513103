import { useEffect, useMemo } from 'react';

import { useMutationEventPatch } from 'queries/events.mutations.js';
import { useCalendarEventQuery } from 'queries/events.queries.js';
import { useGroupsAllQuery } from 'queries/groups.queries.js';
import { useVenuesAllQuery } from 'queries/venues.queries.js';
import {
    Button,
    Column,
    Header,
    Input,
    InputDate,
    InputSelect,
    InputText,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Row,
    Warning,
} from 'ui';
import useForm from 'utils/useForm';
import validatorsUtil from 'utils/validators.util.js';

import useUI from '../../../../contexts/useUI.js';
import {
    hoursOptions,
    inviteOptions,
    typeOptions,
} from '../../calendar.utils.js';

const EditEvent = ({ data: { id }, modalClose }) => {
    const { toastPush } = useUI();
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        title: {
            value: 'Trening',
            validators: [validatorsUtil.isRequired()],
        },
        desc: {
            value: '',
        },
        date: {
            value: new Date(),
        },
        type: {
            value: 'training',
        },
        timeStart: {
            value: '15:0',
        },
        timeEnd: {
            value: '16:30',
        },
        venue: {
            value: null,
        },
        groups: {
            value: [],
        },
        'invite.players': {
            value: 'groups',
        },
        'invite.parents': {
            value: 'none',
        },
        'invite.coaches': {
            value: 'groups',
        },
    });

    const { data: groupsFetched } = useGroupsAllQuery();
    const { data: venuesFetched } = useVenuesAllQuery();

    const options = useMemo(
        () => ({
            groups: groupsFetched
                ? groupsFetched.map(v => ({ label: v.name, value: v.id }))
                : [],
            venues: venuesFetched
                ? venuesFetched.map(v => ({ label: v.name, value: v.id }))
                : [],
        }),
        [groupsFetched, venuesFetched],
    );

    const { data: event } = useCalendarEventQuery(id);

    const timeValidator = () => {
        const timeStart = Number(form.timeStart.value.replace(':', '.'));
        const timeEnd = Number(form.timeEnd.value.replace(':', '.'));

        if (timeStart > timeEnd) {
            toastPush({
                text: 'Godzina rozpoczęcia nie może być większa niż godzina zakończenia',
            });
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (event) {
            const dateStart = new Date(event.dateStart);
            const dateEnd = new Date(event.dateEnd);

            form.title.setValue(event.title);
            form.desc.setValue(event.desc);
            form.date.setValue(dateStart);
            form.type.setValue(event.type);
            form.timeStart.setValue(
                `${dateStart.getHours()}:${dateStart.getMinutes()}`,
            );
            form.timeEnd.setValue(
                `${dateEnd.getHours()}:${dateEnd.getMinutes()}`,
            );
            form.venue.setValue(event.venue?.id);
            form.groups.setValue((event.groups || []).map(g => g.id));
        }
    }, [event]);

    const mutation = useMutationEventPatch({
        onSuccess: modalClose,
    });

    const editEvent = () => {
        if (!isFormValid) return;

        const form = getFormValue();

        if (!timeValidator()) return;

        const dateStart = new Date(form.date.getTime());
        const dateEnd = new Date(form.date.getTime());

        const timeStartArray = form.timeStart.split(':');
        const timeEndArray = form.timeEnd.split(':');

        dateStart.setHours(Number(timeStartArray[0]));
        dateStart.setMinutes(Number(timeStartArray[1]));

        dateEnd.setHours(Number(timeEndArray[0]));
        dateEnd.setMinutes(Number(timeEndArray[1]));

        mutation.mutate({
            id: id,
            delta: {
                ...form,
                dateStart,
                dateEnd,
            },
        });
    };

    return (
        <>
            <ModalHeader>Edytuj wydarzenie</ModalHeader>
            <ModalContent>
                <Row gap="m" align="start">
                    <Column gap="m">
                        <Column gap="xs">
                            <Header title="Informacje o wydarzeniu" size="s" />
                            <div>
                                <Label>Tytuł wydarzenia</Label>
                                <Input {...form.title} />
                            </div>
                            <div>
                                <Label>Rodzaj wydarzenia</Label>
                                <InputSelect
                                    options={typeOptions}
                                    {...form.type}
                                />
                            </div>
                        </Column>
                        <Column gap="xs">
                            <Header title="Data i miejsce" size="s" />
                            <div>
                                <Label>Data</Label>
                                <InputDate {...form.date} />
                            </div>
                            <Row gap={'m'}>
                                <Column>
                                    <Label>Godzina rozpoczęcia</Label>
                                    <InputSelect
                                        options={hoursOptions}
                                        {...form.timeStart}
                                    />
                                </Column>
                                <Column>
                                    <Label>Godzina zakończenia</Label>
                                    <InputSelect
                                        options={hoursOptions}
                                        {...form.timeEnd}
                                    />
                                </Column>
                            </Row>
                            <div>
                                <Label>Obiekt</Label>
                                <InputSelect
                                    options={options.venues}
                                    {...form.venue}
                                />
                            </div>
                            {!form.venue.value && (
                                <div>
                                    <Warning
                                        title="Nie wybrano miejsca wydarzenia"
                                        content="Czy aby na pewno chcesz dodać takie
                                            wydarzenie?"
                                    />
                                </div>
                            )}
                        </Column>
                    </Column>
                    <Column gap="m">
                        <Column gap="xs">
                            <Header title="Grupy" size="s" />
                            <div>
                                <Label>Dodaj do kalendarzy grup</Label>
                                <InputSelect
                                    options={options.groups}
                                    {...form.groups}
                                    multiselect
                                />
                            </div>

                            {!form.groups.value.length && (
                                <div>
                                    <Warning
                                        title="Nie wybrano żadnej grupy"
                                        content=" Czy aby na pewno chcesz dodać takie
                                            wydarzenie"
                                    />
                                </div>
                            )}
                        </Column>
                        <Column gap="xs">
                            <Header title="Uczestnicy" size="s" />
                            <div>
                                <Label>Zaproszeni zawodnicy</Label>
                                <InputSelect
                                    options={inviteOptions}
                                    {...form['invite.players']}
                                />
                            </div>
                            <div>
                                <Label>Zaproszeni rodzice</Label>
                                <InputSelect
                                    options={inviteOptions}
                                    {...form['invite.parents']}
                                />
                            </div>
                            <div>
                                <Label>Zaproszeni trenerzy</Label>
                                <InputSelect
                                    options={inviteOptions}
                                    {...form['invite.coaches']}
                                />
                            </div>
                        </Column>
                        <Column gap="xs">
                            <Header title="Dodatkowe informacje" size="s" />
                            <div>
                                <Label>Opis wydarzenia</Label>
                                <InputText {...form.desc} />
                            </div>
                        </Column>
                    </Column>
                </Row>
            </ModalContent>
            <ModalActions>
                <Button
                    color="green"
                    onClick={editEvent}
                    onDisabledClick={touchForm}
                    disabled={!isFormValid}>
                    Zapisz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(EditEvent, {
    id: 'editEvent',
    size: 'large',
});
