import { useMutation, useQueryClient } from '@tanstack/react-query';

import useUI from 'contexts/useUI';
import coachesService from 'services/coachesService.js';
import groupsService from 'services/groupsService.js';

export const useMutationCoachAdd = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(coachesService.post, {
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['coaches']);

            toastPush({
                title: 'Dodano trenera',
                text: `${data.name} ${data.surname}`,
            });

            if (variables.addToGroup) {
                queryClient.invalidateQueries(['groups', 'single']);
            }

            if (onSuccess) {
                onSuccess(data);
            }
        },
    });
};

export const useMutationCoachPatch = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    return useMutation(({ id, delta }) => coachesService.patch(id, delta), {
        onSuccess: data => {
            toastPush({
                title: 'Wyedytowano trenera',
                text: `${data.name} ${data.surname}`,
            });

            queryClient.invalidateQueries(['coaches']);
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationCoachDelete = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(coachesService.delete, {
        onSuccess: () => {
            toastPush({
                title: 'Usunięto trenera',
            });

            queryClient.invalidateQueries(['coaches', 'list']);
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationCoachAddGroup = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idCoach, idGroup }) => groupsService.addCoach(idGroup, idCoach),
        {
            onSuccess: (data, { idGroup, idCoach }) => {
                toastPush({
                    title: 'Dodano trenera do grupy',
                });

                queryClient.invalidateQueries(['groups', 'single', idGroup]);
                queryClient.invalidateQueries(['coaches', 'single', idCoach]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};

export const useMutationCoachRemoveGroup = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(
        ({ idCoach, idGroup }) => groupsService.removeCoach(idGroup, idCoach),
        {
            onSuccess: (data, { idCoach, idGroup }) => {
                toastPush({
                    title: 'Usunięto trenera z grupy',
                });

                queryClient.invalidateQueries(['groups', 'single', idGroup]);
                queryClient.invalidateQueries(['coaches', 'single', idCoach]);
                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
};
