import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useVenueQuery } from 'queries/venues.queries.js';
import { AnimatedTransition, Column, Container, Head, Spinner } from 'ui';
import useACL from 'utils/useACL';

import ViewHOC from '../../ViewHOC';
import AddEditVenueDialog from '../modals/AddEditVenueModal';
import DeleteVenueDialog from '../modals/DeleteVenueModal';

import Calendar from './Calendar';
import Info from './Info';

import styles from './VenuesSingle.module.scss';

const VenuesSingle = () => {
    const location = useLocation();
    const {
        params: { id },
    } = useRouteMatch();

    const { isRole } = useACL();

    const { modalOpen } = useUI();

    const { data: venue, isFetched } = useVenueQuery(id);

    if (!isFetched) {
        return <Spinner active />;
    }
    return (
        <div className={styles.view}>
            <Container>
                <Column gap={'m'}>
                    <Head
                        title={`${venue.name}`}
                        subtitle={`${venue.address.city}`}
                        avatar={{
                            text: `${venue.name[0]}${venue.name[1]}`,
                            picture: venue.avatar,
                        }}
                        tabs={[
                            {
                                content: 'Informacje',
                                link: `/venues/${venue.id}`,
                            },
                            {
                                content: 'Kalendarz',
                                link: `/venues/${venue.id}/calendar`,
                            },
                        ]}
                        actions={[
                            {
                                text: 'Usuń obiekt',
                                color: 'red',
                                icon: 'delete',
                                onClick:
                                    isRole([ROLES.OWNER, ROLES.ADMIN]) &&
                                    (() => modalOpen('deleteVenue', { id })),
                            },
                        ]}
                    />
                    <AnimatedTransition k={location.pathname}>
                        <Switch location={location}>
                            <Route exact path="/venues/:id/" component={Info} />
                            <Route
                                exact
                                path="/venues/:id/calendar"
                                render={props => (
                                    <Calendar {...props} venue={venue} />
                                )}
                            />
                            <Route component={Info} />
                        </Switch>
                    </AnimatedTransition>
                </Column>
            </Container>
            <AddEditVenueDialog />
            <DeleteVenueDialog />
        </div>
    );
};

export default ViewHOC(VenuesSingle, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
