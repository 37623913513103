import { ROLES } from 'constants/logic';
import useAuth from 'contexts/useAuth';
import useUI from 'contexts/useUI';
import { useOrganizationAdministratorsQuery } from 'queries/organization.queries.js';
import {
    Button,
    Column,
    Header,
    List,
    ListActions,
    ListCell,
    RoleTag,
    Tag,
} from 'ui';
import InfiniteLoader from 'ui/InfiniteLoader';
import useACL from 'utils/useACL';

import ListDataCell from '../../../components/ListDataCell/index.js';
import AddAdmin from '../modals/AddAdmin';
import DeleteAdmin from '../modals/DeleteAdmin';

import styles from './Administrators.module.scss';

const Administrators = () => {
    const { modalOpen } = useUI();
    const { me } = useAuth();
    const { isRole } = useACL();

    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useOrganizationAdministratorsQuery();

    const administrators = data.pages.flat(1);

    const openAddAdministrator = () => {
        modalOpen('addAdministrator');
    };

    const openDeleteAdministrator = idAdmin => {
        modalOpen('deleteAdministrator', {
            idOrganization: me.organization._id,
            idAdmin,
        });
    };

    return (
        <>
            <Column gap={'m'}>
                <Header title="Lista administratorów" size="l">
                    {isRole([ROLES.OWNER]) && (
                        <Button
                            size="l"
                            color={'green'}
                            onClick={openAddAdministrator}>
                            Nowy administrator
                        </Button>
                    )}
                </Header>
                <div className={styles.text}>
                    <p>
                        Poniżej znajduje się lista administratorów szkoły. W
                        każdej chwili możesz dodać nowego lub
                        zablokować/odblokować jednego z nich
                    </p>
                    <p>
                        Konto administracyjne jest odrębnym od konta
                        trenerskiego/rodzica i będzie widoczne przy logowaniu
                        jako jedna z dostępnych opcji
                    </p>
                </div>
                <List
                    header={
                        <>
                            <ListCell width={300} header>
                                Imię i nazwisko
                            </ListCell>
                            <ListCell width={150} header>
                                Adres email
                            </ListCell>
                            <ListCell width={120} header>
                                Rola
                            </ListCell>
                            <ListCell width={100} header>
                                Status
                            </ListCell>
                        </>
                    }
                    data={administrators}
                    renderRow={({
                        id,
                        name,
                        surname,
                        avatar,
                        email,
                        role,
                        active,
                    }) => {
                        let actions = [];
                        if (isRole([ROLES.OWNER])) {
                            actions.push({
                                title: 'Usuń',
                                icon: 'delete',
                                color: 'red',
                                onClick: () => {
                                    openDeleteAdministrator(id);
                                },
                            });
                        }
                        return (
                            <>
                                <ListDataCell.User
                                    user={{ name, surname, avatar }}
                                    size={'l'}
                                />
                                <ListDataCell.Email email={email} />
                                <ListCell width={120}>
                                    <RoleTag role={role} size="s" />
                                </ListCell>
                                <ListCell width={100}>
                                    {active ? (
                                        <Tag
                                            text="AKTYWNY"
                                            color="green"
                                            size="s"
                                        />
                                    ) : (
                                        <Tag
                                            text="NIEAKTYWNY"
                                            color="green"
                                            size="s"
                                        />
                                    )}
                                </ListCell>
                                <ListActions actions={actions} />
                            </>
                        );
                    }}
                />
                {!isFetchingNextPage && hasNextPage && (
                    <InfiniteLoader onVisible={fetchNextPage} />
                )}
            </Column>
            <AddAdmin />
            <DeleteAdmin />
        </>
    );
};

export default Administrators;
