export default {
    'black-300': '#535353',
    'black-500': '#3D3D3D',
    white: '#FFFFFF',
    'grey-50': '#F8F8F8',
    'grey-100': '#F1F1F1',
    'grey-200': '#EAEBEE',
    'grey-300': '#CBCCD3',
    'grey-400': '#B0B1BB',
    'grey-500': '#9899A6',
    'green-200': '#B1EBC8',
    'green-500': '#47D07C',
    'avocado-500': '#04664F',
    'yellow-500': '#F3C969',
    yellow: '#f6ce42',
    red: '#d20000',
};
