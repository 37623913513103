import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import style from './OptionsView.module.scss';

const OptionsView = ({
    options,
    onSelect,
    onSelectAll,
    setOptionsRef,
    setOptionActiveRef,
    coordinates,
}) => (
    <div className={style.root} style={coordinates} ref={setOptionsRef}>
        {options.length > 0 && onSelectAll && (
            <div className={cx(style.option)} onClick={onSelectAll}>
                Wybierz wszystko
            </div>
        )}
        {options.map(o => (
            <div
                className={cx(style.option, o.selected && style.selected)}
                onClick={() => {
                    onSelect(o.value);
                }}
                ref={o.selected ? setOptionActiveRef : undefined}
                key={o.label}>
                <div>{o.label}</div>
                <AnimatePresence>
                    {o.selected && (
                        <motion.div
                            className={style.icon}
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.5 }}>
                            <Icon icon="ok" color="green" />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        ))}
        {options.length === 0 && (
            <div className={cx(style.option, style.noOptions)}>Brak opcji</div>
        )}
    </div>
);

OptionsView.propTypes = {
    onSelect: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func,
    options: PropTypes.array.isRequired,
    active: PropTypes.bool,
    multiselect: PropTypes.bool,
};

OptionsView.defaultProps = {
    active: false,
    onSelectAll: undefined,
    multiselect: undefined,
};

export default OptionsView;
