import { useMemo } from 'react';
import { DateTime } from 'luxon';

import CalendarAgendaView from 'components/CalendarAgendaView';
import { useCalendarListQuery } from 'queries/events.queries.js';
import { Column, Header, Spinner } from 'ui';

const CalendarListAgenda = () => {
    const now = useMemo(() => DateTime.now(), []);

    const { data, isFetched } = useCalendarListQuery({
        dateStart: { $gte: now.toString() },
    });

    if (!isFetched) return <Spinner active />;

    return (
        <Column gap={'m'}>
            <Header title="Najbliższe wydarzenia" size="l" />
            <CalendarAgendaView events={data} />
        </Column>
    );
};

export default CalendarListAgenda;
