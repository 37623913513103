import { ReactComponent as BallSvg } from './svg/Ball.svg';
import { ReactComponent as CheerSvg } from './svg/Cheer.svg';
import { ReactComponent as PlayerSvg } from './svg/Player.svg';
import { ReactComponent as TrophySvg } from './svg/Trophy.svg';

import styles from './WelcomeCard.module.scss';

const WelcomeCard = ({ data: { name } }) => (
    <div className={styles.card}>
        <div className={styles.content}>
            <div className={styles.title}>Hej {name}, witaj w szkole!</div>
            <div className={styles.text}>
                <p>
                    Goaly to nowa <strong>aplikacja</strong> do współpracy w
                    ramach <strong>szkoły piłkarskiej</strong>, która pomaga
                    organizować pracę zespołu i prowadzić w jednym miejscu
                    wszystkie dotyczące jego aktywności .
                </p>
                <p>
                    Łączymy <strong>trenerów, rodziców i zawodników</strong> w
                    jednym miejscu pozwalając na wspólną pracę nad przyszłością
                    młodych adeptów futbolu.
                </p>
            </div>
        </div>
        <BallSvg
            style={{
                width: 64,
                height: 64,
                position: 'absolute',
                top: -32,
                right: 10,
            }}
        />
        <CheerSvg
            style={{
                width: 64,
                height: 64,
                position: 'absolute',
                top: '50%',
                right: -20,
            }}
        />
        <PlayerSvg
            style={{
                width: 84,
                height: 84,
                position: 'absolute',
                top: '20%',
                right: 70,
            }}
        />
        <TrophySvg
            style={{
                width: 84,
                height: 84,
                position: 'absolute',
                bottom: -10,
                right: 40,
            }}
        />
    </div>
);

export default WelcomeCard;
