import { useHistory, useLocation } from 'react-router-dom';

import { useMutationParentDelete } from 'queries/parents.mutations.js';
import { Button, ModalActions, ModalContent, ModalHeader, ModalHOC } from 'ui';

const DeleteParentDialog = ({ data: { id }, modalClose }) => {
    const location = useLocation();
    const history = useHistory();

    const mutation = useMutationParentDelete({
        onSuccess: () => {
            modalClose();
            if (location.pathname.includes(`/parents/${id}`)) {
                history.push('/parents');
            }
        },
    });

    const deleteParent = () => {
        mutation.mutate(id);
    };

    return (
        <>
            <ModalHeader>Usuń rodzica</ModalHeader>
            <ModalContent>Czy na pewno chcesz usunąć rodzica?</ModalContent>
            <ModalActions>
                <Button color="red" onClick={deleteParent}>
                    Usuń
                </Button>
                <Button color="black" onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DeleteParentDialog, {
    id: 'deleteParent',
    title: 'Usuwasz rodzica',
    size: 'small',
});
