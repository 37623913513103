import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import useUI from 'contexts/useUI';
import DeleteEventCyclicalDialog from 'screens/Calendar/modals/DeleteEventCyclicalDialog';
import EditEventCyclicalDialog from 'screens/Calendar/modals/EditEventCyclicalDialog';
import { Button, Column } from 'ui';

import PillBar from '../../../../ui/PillBar/index.js';

import CalendarAgenda from './CalendarAgenda.jsx';
import CalendarWeek from './CalendarWeek.jsx';

const Calendar = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const [mode, setMode] = useState('week');

    const { modalOpen } = useUI();

    return (
        <Column gap={'m'}>
            <Column gap={'m'}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <PillBar
                        fieldId={'group-calendar-mode'}
                        value={mode}
                        onChange={setMode}
                        options={[
                            {
                                label: 'Tydzień',
                                value: 'week',
                            },
                            {
                                label: 'Agenda',
                                value: 'agenda',
                            },
                        ]}
                    />
                    <Button
                        size="xl"
                        color={'green'}
                        onClick={() =>
                            modalOpen('addEvent', { groupIds: [id] })
                        }>
                        Dodaj wydarzenie
                    </Button>
                </div>
                {mode === 'week' && <CalendarWeek />}
                {mode === 'agenda' && <CalendarAgenda />}
            </Column>
            <DeleteEventCyclicalDialog />
            <EditEventCyclicalDialog />
        </Column>
    );
};

export default Calendar;
