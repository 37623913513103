import {
    useMutationCoachAddGroup,
    useMutationCoachRemoveGroup,
} from 'queries/coaches.mutations.js';
import { useCoachGroupsQuery } from 'queries/coaches.queries.js';
import { useGroupsAllQuery } from 'queries/groups.queries.js';
import {
    Button,
    List,
    ListActions,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';

import ListDataCell from '../../../../../../components/ListDataCell/index.js';

const AddGroups = ({ data: { idCoach }, modalClose }) => {
    const { data: coachGroups } = useCoachGroupsQuery(idCoach);
    const { data: groups } = useGroupsAllQuery();

    const mutationCoachAddGroup = useMutationCoachAddGroup();
    const mutationCoachRemoveGroup = useMutationCoachRemoveGroup();

    const addGroup = idGroup => {
        mutationCoachAddGroup.mutate({ idCoach, idGroup });
    };

    const removeGroup = idGroup => {
        mutationCoachRemoveGroup.mutate({ idCoach, idGroup });
    };

    if (!coachGroups || !groups) return <div />;

    return (
        <>
            <ModalHeader>Zarządzaj grupami trenera</ModalHeader>
            <ModalContent>
                <List
                    data={groups}
                    renderRow={({ id, name, avatar }) => {
                        return (
                            <>
                                <ListDataCell.Group
                                    group={{ id, name, avatar }}
                                />
                                {coachGroups.find(cg => cg.id === id) ? (
                                    <ListActions
                                        actions={[
                                            {
                                                icon: 'minus',
                                                onClick: () => removeGroup(id),
                                            },
                                        ]}
                                    />
                                ) : (
                                    <ListActions
                                        actions={[
                                            {
                                                icon: 'plus',
                                                onClick: () => addGroup(id),
                                            },
                                        ]}
                                    />
                                )}
                            </>
                        );
                    }}
                />
            </ModalContent>
            <ModalActions>
                <Button color={'black'} onClick={modalClose}>
                    Utwórz
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AddGroups, {
    id: 'addGroupsToCoach',
    size: 'small',
});
