import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useCoachQuery } from 'queries/coaches.queries.js';
import ViewHOC from 'screens/ViewHOC';
import {
    AnimatedTransition,
    Column,
    Container,
    Head,
    RoleTag,
    Spinner,
} from 'ui';
import useACL from 'utils/useACL';

import { formatAddress, formatAge } from '../../../utils/formatters';
import DeleteCoachDialog from '../modals/DeleteCoachDialog';

import Calendar from './Calendar';
import Groups from './Groups';
import Info from './Info';

const CoachesSingle = () => {
    const location = useLocation();
    const {
        params: { id },
    } = useRouteMatch();

    const { isRole } = useACL();

    const { modalOpen } = useUI();

    const openDeleteCoachDialog = () => {
        modalOpen('deleteCoach', { id });
    };

    const { data: coach } = useCoachQuery(id);

    if (!coach) {
        return <Spinner active />;
    }
    return (
        <>
            <Container>
                <Column gap={'m'}>
                    <Head
                        title={`${coach.name} ${coach.surname}`}
                        subtitle={
                            <>
                                <span>
                                    {formatAge(coach.age)}
                                    {coach.age && coach.address ? ', ' : ''}
                                    {formatAddress(coach.address)}
                                </span>
                                <RoleTag role={ROLES.COACH} size="s" />
                            </>
                        }
                        avatar={{
                            text: `${coach.name} ${coach.surname}`,
                            picture: coach.avatar,
                        }}
                        tabs={[
                            {
                                content: 'Informacje',
                                link: `/coaches/${coach.id}`,
                            },
                            {
                                content: 'Grupy',
                                link: `/coaches/${coach.id}/groups`,
                                counter: coach.groups
                                    ? coach.groups.length
                                    : undefined,
                            },
                            {
                                content: 'Kalendarz',
                                link: `/coaches/${coach.id}/calendar`,
                            },
                        ]}
                        actions={[
                            {
                                text: 'Usuń trenera',
                                color: 'red',
                                icon: 'delete',
                                onClick:
                                    isRole([ROLES.OWNER, ROLES.ADMIN]) &&
                                    openDeleteCoachDialog,
                            },
                        ]}
                    />
                    <AnimatedTransition k={location}>
                        <Switch location={location}>
                            <Route
                                exact
                                path="/coaches/:id/"
                                component={Info}
                            />
                            <Route
                                exact
                                path="/coaches/:id/groups"
                                component={Groups}
                            />
                            <Route
                                exact
                                path="/coaches/:id/calendar"
                                render={props => (
                                    <Calendar {...props} coach={coach} />
                                )}
                            />
                            <Route component={Info} />
                        </Switch>
                    </AnimatedTransition>
                </Column>
            </Container>
            <DeleteCoachDialog />
        </>
    );
};

export default ViewHOC(CoachesSingle, {
    menu: true,
    topbar: true,
    auth: {
        roles: [ROLES.OWNER, ROLES.ADMIN, ROLES.COACH],
    },
});
