import cx from 'classnames';
import PropTypes from 'prop-types';

import Row from '../grid/Row.jsx';

import { ReactComponent as MailIcon } from './svg/mail.svg';
import { ReactComponent as PhoneIcon } from './svg/phone.svg';

import style from './ContactTag.module.scss';

const ContactTag = ({ children, type, size }) => {
    return (
        <div
            className={cx(
                style.contacttag,
                style[`size-${size}`],
                style[`type-${type}`],
            )}>
            <Row gap="xxxs" align="center">
                {type === 'phone' ? <PhoneIcon /> : <MailIcon />}
                <span>{children}</span>
            </Row>
        </div>
    );
};

ContactTag.propTypes = {
    children: PropTypes.any.isRequired,
    type: PropTypes.string,
    size: PropTypes.oneOf(['m', 'l']),
};

ContactTag.defaultProps = {
    type: 'phone',
    size: 'm',
};

export default ContactTag;
