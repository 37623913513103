import { useEffect, useState } from 'react';

import EmptyCard from 'components/EmptyCard';
import useUI from 'contexts/useUI';
import { Column, Row } from 'ui';
import { formatDateFullWithDay, formatDay } from 'utils/formatters';

import DaysRibbon from './DaysRibbon';
import EventCard from './EventCard';

import styles from './CalendarAgendaView.module.scss';

const Day = (day, array, highlightedId) => {
    return (
        <div className={styles.day} key={day} data-scroll-to={day}>
            <Column gap="xs">
                <div className={styles.dayName}>
                    {formatDateFullWithDay(day)}
                </div>
                {array.map(t => (
                    <EventCard
                        {...t}
                        highlighted={highlightedId === t.id}
                        key={t.id}
                    />
                ))}
            </Column>
        </div>
    );
};

const CalendarAgendaView = ({ events, isFetching }) => {
    const { modalOpen } = useUI();

    const [days, setDays] = useState({});
    const [selectedEvent, setSelectedEvent] = useState(null);

    useEffect(() => {
        if (events.length) {
            const daysNew = {};
            events.forEach(e => {
                const day = formatDay(e.dateStart);

                if (!daysNew[day]) {
                    daysNew[day] = [];
                }

                daysNew[day].push({
                    ...e,
                    onClick: () => setSelectedEvent(e),
                });
            });

            setSelectedEvent(events[0]);
            setDays(daysNew);
        } else {
            setDays({});
        }
    }, [events]);

    if (!isFetching && !events.length) {
        return (
            <EmptyCard
                action={() => modalOpen('addEvent')}
                actionLabel="Dodaj nowe wydarzenie"
            />
        );
    }

    return (
        <div className={styles.root}>
            <Row>
                <Column>
                    <DaysRibbon days={days} />
                </Column>
            </Row>
            <Row>
                <Column gap="l">
                    {Object.keys(days).map(day => {
                        if (days[day]) {
                            return Day(day, days[day], selectedEvent.id);
                        }
                        return undefined;
                    })}
                </Column>
                <Column />
            </Row>
        </div>
    );
};

export default CalendarAgendaView;
