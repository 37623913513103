import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import parentsService from '../services/parentsService.js';

export const useParentQuery = id => {
    return useQuery(['parents', 'single', id], () => parentsService.get(id), {
        initialData: null,
    });
};

export const useParentEventsQuery = id => {
    return useQuery(
        ['parents', 'single', id, 'events'],
        () => parentsService.getEvents(id),
        {
            initialData: [],
        },
    );
};

export const useParentGroupsQuery = id => {
    return useQuery(
        ['parents', 'single', id, 'groups'],
        () => parentsService.getGroups(id),
        {
            initialData: [],
        },
    );
};

export const useParentPlayersQuery = id => {
    return useQuery(
        ['parents', 'single', id, 'players'],
        () => parentsService.getPlayers(id),
        {
            initialData: [],
        },
    );
};

export const useParentsListQuery = () => {
    return useInfiniteQuery(
        ['parents', 'list'],
        ({ pageParam }) => {
            return parentsService.query({
                limit: 10,
                offset: pageParam,
            });
        },
        {
            getNextPageParam: (a, b) => {
                if (!a.length) return undefined;
                return b.flat(1).length;
            },
        },
    );
};
