import { useMemo } from 'react';
import ReactQuill from 'react-quill';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './InputText.module.scss';

const InputText = ({
    value,
    error,
    onChange,
    onBlur,
    onFocus,
    isTouched,
    isFocused,
    disabled,
}) => {
    const isErrorVisible = useMemo(
        () => error && isTouched && !isFocused,
        [error, isTouched, isFocused],
    );

    return (
        <div
            className={cx(
                styles.inputText,
                isFocused && styles.focused,
                isErrorVisible && styles.error,
                disabled && styles.disabled,
            )}>
            <ReactQuill
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            {isErrorVisible && <div className={styles.error}>{error}</div>}
        </div>
    );
};

InputText.propTypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

InputText.defaultProps = {
    value: '',
    placeholder: undefined,
    error: undefined,
    onChange: undefined,
    onBlur: undefined,
    onFocus: undefined,
};

export default InputText;
