import { Button } from 'ui';

import styles from './AppInstall.module.scss';

const AppInstall = () => (
    <div className={styles.reset}>
        <div className={styles.card}>
            <div className={styles.header}>Zainstaluj aplikacje mobilną</div>
            <div className={styles.text}>
                By korzystać z Goaly jako rodzic powienieneś zainstalowć
                aplikacę mobilną. Wybierz odpowiednią wersję dla swojego modelu
                telefonu
            </div>
            <div className={styles.rows}>
                <a
                    href="https://apps.apple.com/pl/app/goaly/id1477412576"
                    title="App Store">
                    <img
                        className={styles.icon}
                        src="/img/app-store-badge.png"
                        alt=""
                    />
                </a>
                <a
                    href="https://play.google.com/store/apps/details?id=com.kroppa.goaly"
                    title="Google Play">
                    <img
                        className={styles.icon}
                        src="/img/google-play-badge.png"
                        alt=""
                    />
                </a>
            </div>
            <div className={styles.buttons}>
                <Button
                    shape="square"
                    href="/auth/signin"
                    icon="chevron-left"
                />
            </div>
        </div>
    </div>
);

export default AppInstall;
