import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import {
    useMutationGroupAdd,
    useMutationGroupPatch,
} from 'queries/groups.mutations.js';
import { useGroupQuery } from 'queries/groups.queries.js';
import searchService from 'services/searchService';
import {
    Button,
    Column,
    Header,
    Input,
    InputText,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Warning,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util.js';

const AddEditGroupDialog = ({ data: { id }, modalClose }) => {
    const [existingGroup, setExistingGroup] = useState(null);

    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        name: {
            value: '',
            validators: [validators.isRequired()],
        },
        subname: {
            value: '',
        },
        desc: {
            value: '',
        },
    });

    const { data } = useGroupQuery(id);

    useEffect(() => {
        if (data) {
            form.name.setValue(data.name);
            form.desc.setValue(data.desc);
            form.subname.setValue(data.subname);
        }
    }, [data]);

    useDebounce(
        () => {
            if (!form.name.value || id) {
                setExistingGroup(null);
                return;
            }

            searchService.get(form.name.value).then(({ groups }) => {
                if (groups.length) {
                    setExistingGroup(groups[0].name);
                } else {
                    setExistingGroup(null);
                }
            });
        },
        500,
        [name],
    );

    const mutationAdd = useMutationGroupAdd({ onSuccess: modalClose });
    const mutationPatch = useMutationGroupPatch({ onSuccess: modalClose });

    const add = () => {
        if (!isFormValid) return;
        mutationAdd.mutate(getFormValue());
    };

    const edit = () => {
        if (!isFormValid) return;
        mutationPatch.mutate({ id, delta: getFormValue() });
    };

    return (
        <>
            <ModalHeader>{id ? 'Edytuj grupę' : 'Nowa grupa'}</ModalHeader>
            <ModalContent>
                <Column gap={'xs'}>
                    <Header title="Informacje podstawowe" size="s" />
                    <div>
                        <Label>Nazwa grupy</Label>
                        <Input {...form.name} />
                    </div>
                    {existingGroup && (
                        <div>
                            <Warning
                                title={`Istnieje już grupa ${existingGroup}`}
                                content="Czy aby na pewno chcesz dodać nową grupę"
                            />
                        </div>
                    )}
                    <div>
                        <Label>Podnazwa grupy</Label>
                        <Input {...form.subname} />
                    </div>
                    <div>
                        <Label>Opis grupy</Label>
                        <InputText {...form.desc} />
                    </div>
                </Column>
            </ModalContent>
            <ModalActions>
                {id ? (
                    <Button
                        disabled={!isFormValid}
                        color="green"
                        onClick={edit}>
                        Zapisz
                    </Button>
                ) : (
                    <Button
                        disabled={!isFormValid}
                        color="green"
                        onClick={add}
                        onDisabledClick={touchForm}>
                        Utwórz
                    </Button>
                )}
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AddEditGroupDialog, {
    id: 'addEditGroup',
    size: 'medium',
});
