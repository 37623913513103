import { useEffect } from 'react';

import { ROLES } from 'constants/logic';
import useAuth from 'contexts/useAuth';
import {
    useMutationOrganizationPatch,
    useMutationOrganizationPatchSettings,
} from 'queries/organization.mutations.js';
import { useOrganizationInfoQuery } from 'queries/organization.queries.js';
import {
    Button,
    Column,
    Header,
    Input,
    InputFile,
    InputSwitch,
    Label,
    Row,
    Spinner,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util';

import ViewHOC from '../../ViewHOC';

import styles from './SchoolSettings.module.scss';

const SchoolSettings = () => {
    const { me } = useAuth();
    const { data } = useOrganizationInfoQuery();

    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        name: {
            value: '',
            validators: [validators.isRequired()],
        },
        avatar: {
            value: null,
        },
        email: {
            value: '',
            validators: [validators.isRequired(), validators.isEmail()],
        },
        phone: {
            value: '',
        },
        'address.street': {
            value: '',
        },
        'address.city': {
            value: '',
        },
        'address.postal': {
            value: '',
        },
    });

    useEffect(() => {
        if (data) {
            form.name.setValue(data.name);
            form.avatar.setValue(data.avatar);
            form.email.setValue(data.email);
            form.phone.setValue(data.phone);
            form['address.street'].setValue(data.address?.street);
            form['address.city'].setValue(data.address?.city);
            form['address.postal'].setValue(data.address?.postal);
        }
    }, [data]);

    const mutationSave = useMutationOrganizationPatch();
    const mutationSettingsSave = useMutationOrganizationPatchSettings();

    const save = () => {
        if (!isFormValid) return;
        mutationSave.mutate({ id: me.organization._id, delta: getFormValue() });
    };

    const setSettings = key => value => {
        mutationSettingsSave.mutate({
            id: me.organization._id,
            delta: { [key]: value },
        });
    };

    if (!data) return <Spinner active />;

    return (
        <Row gap={'m'}>
            <Column gap={'m'}>
                <Header title="Dane szkoły" size="l" />
                <Column gap={'xs'}>
                    <Header title="Dane podstawowe" size="s" />
                    <div>
                        <Label>Nazwa szkoły</Label>
                        <Input type="text" {...form.name} />
                    </div>
                    <div>
                        <Label>Avatar szkoły</Label>
                        <InputFile type="avatar" {...form.avatar} />
                    </div>
                    <div>
                        <Label>Adres email</Label>
                        <Input type="text" {...form.email} />
                    </div>
                    <div>
                        <Label>Numer telefonu</Label>
                        <Input type="text" {...form.phone} />
                    </div>
                </Column>
                <Column gap={'xs'}>
                    <Header title="Dane adresowe" size="s" />
                    <div>
                        <Label>Ulica</Label>
                        <Input type="text" {...form['address.street']} />
                    </div>
                    <div>
                        <Label>Miasto</Label>
                        <Input type="text" {...form['address.city']} />
                    </div>
                    <div>
                        <Label>Kod pocztowy</Label>
                        <Input type="text" {...form['address.postal']} />
                    </div>
                </Column>
                <div>
                    <Button
                        color={'green'}
                        onClick={save}
                        disabled={!isFormValid}
                        onDisabledClick={touchForm}>
                        Zapisz zmiany
                    </Button>
                </div>
            </Column>
            <Column gap={'m'}>
                <Header title="Ustawienia organizacji" size="l" />
                <Column gap={'xs'} size={0}>
                    <Header title="Kontrola dostępu" size="s" />
                    <div className={styles.text}>
                        Dokonaj ustawień kto powinien mieć dostęp do panelu
                        Goaly w ramach Twojej organizacji
                    </div>
                    <InputSwitch
                        value={data?.settings?.accessCoach}
                        onChange={setSettings('accessCoach')}>
                        <strong>Trenerzy </strong>
                        posiadają dostęp do Goaly
                    </InputSwitch>
                    <InputSwitch
                        value={data?.settings?.accessParent}
                        onChange={setSettings('accessParent')}>
                        <strong>Rodzice </strong>
                        posiadają dostęp do Goaly
                    </InputSwitch>
                    <InputSwitch
                        value={data?.settings?.accessPlayer}
                        onChange={setSettings('accessPlayer')}>
                        <strong>Zawodnicy </strong>
                        posiadają dostęp do Goaly
                    </InputSwitch>
                </Column>
                <Header title="Integracje" size="l" />
                <Column gap={'xs'} size={0}>
                    <Header
                        title="REST API (Integracja ze stroną WWW)"
                        size="s"
                    />
                    <div className={styles.text}>
                        Usługa RESTowego API pozwoli na zintegrowanie danych z
                        Goaly ze stroną internetową. Po uruchomieniu
                        funckjonalności na adres email otrzymasz kod
                        autoryzacyjny oraz dalsze informacje na temat
                        korzystania z API. Proces korzystania z API i integracji
                        ze stroną będziemy wymagał doświadczonego programisty.
                    </div>
                    <InputSwitch
                        value={data?.settings?.webAPI}
                        onChange={setSettings('webAPI')}>
                        Webowe API włączone
                    </InputSwitch>
                </Column>
            </Column>
        </Row>
    );
};

export default ViewHOC(SchoolSettings, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
