import PropTypes from 'prop-types';

import { ROLES, ROLES_MAP } from 'constants/logic';

import Tag from '../Tag';

const COLOR_ROLES_MAP = {
    [ROLES.OWNER]: 'black',
    [ROLES.ADMIN]: 'black',
    [ROLES.COACH]: 'green',
    [ROLES.PLAYER]: 'grey',
    [ROLES.PARENT]: 'grey',
};

const RoleTag = ({ role, size }) => {
    if (!role) return null;
    return (
        <Tag
            color={COLOR_ROLES_MAP[role]}
            text={ROLES_MAP[role].name}
            size={size}
        />
    );
};

RoleTag.propTypes = {
    role: PropTypes.string,
};
RoleTag.defaultProps = {
    role: '',
};

export default RoleTag;
