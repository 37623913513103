import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { Button, Card, Column, Html, Row, Tag } from 'ui';
import useACL from 'utils/useACL';

import GroupTag from '../../../ui/GroupTag/index.js';

import styles from './GalleryCard.module.scss';

const GalleryCard = ({
    id,
    data: { title, text, photos = [] },
    author: { id: authorId },
    groups,
    editable,
}) => {
    const { isMe, isRole } = useACL();
    const { modalOpen } = useUI();
    const open = () => {
        modalOpen('gallery', { id });
    };

    const openEdit = () => modalOpen('addGallery', { id });

    const isEditable =
        editable && (isRole([ROLES.OWNER, ROLES.ADMIN]) || isMe(authorId));

    return (
        <Card onEdit={isEditable ? openEdit : null} padding={false}>
            <div className={styles.pictures} onClick={open}>
                {photos[0] && (
                    <div
                        className={styles.pic}
                        style={{ background: `url(${photos[0]})` }}
                    />
                )}
                {photos[1] && (
                    <div
                        className={styles.pic}
                        style={{ background: `url(${photos[1]})` }}
                    />
                )}
            </div>
            <div className={styles.content}>
                <Column gap="xs">
                    <Row>
                        <Tag size="m" color="green">
                            Galeria
                        </Tag>
                    </Row>
                    {title && (
                        <Row>
                            <div className={styles.title}>{title}</div>
                        </Row>
                    )}
                    {groups.length ? (
                        <Row gap="xs">
                            {groups.map(g => (
                                <GroupTag group={g} size="s" key={g.id} />
                            ))}
                        </Row>
                    ) : null}
                    {text && (
                        <Row>
                            <Html className={styles.text}>{text}</Html>
                        </Row>
                    )}

                    <Row alignContent="end">
                        <Button size="l" onClick={open} color="green">
                            Zobacz galerię
                        </Button>
                    </Row>
                </Column>
            </div>

            {/*<div className={styles.label}>GALERIA</div>*/}
            {/*{title && (*/}
            {/*    <div className={styles.title} onClick={open}>*/}
            {/*        {title}*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{groups.length ? (*/}
            {/*    <div className={styles.groups}>*/}
            {/*        {groups.map(g => (*/}
            {/*            <Link*/}
            {/*                to={`/groups/${g.id}`}*/}
            {/*                key={g.id}*/}
            {/*                className={styles.group}>*/}
            {/*                <Tag text={g.name} color="green" size="s" />*/}
            {/*            </Link>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*) : null}*/}
        </Card>
    );
};

export default GalleryCard;
