import cx from 'classnames';
import PropTypes from 'prop-types';

import { addDays, setWeekday } from 'utils/date';

import style from './Days.module.scss';

const Days = ({ value, year, month, onChange }) => {
    const firstDay = setWeekday(new Date(year, month, 1), 1),
        lastDay = setWeekday(new Date(year, month + 1, 0), 7),
        days = [];

    let day = firstDay,
        daysElements = [];

    while (day <= lastDay) {
        days.push(day);
        day = addDays(day, 1);
    }

    daysElements = days.map(d => {
        const isTransparent = d.getMonth() !== month,
            isSelected =
                value && d.toLocaleDateString() === value.toLocaleDateString(),
            isToday =
                d.toLocaleDateString() === new Date().toLocaleDateString();

        return (
            <div
                className={cx(
                    style.day,
                    isTransparent && style.transparent,
                    isSelected && style.selected,
                    isToday && style.today,
                )}
                key={d.toLocaleString()}
                onClick={() => {
                    onChange(d);
                }}
                onKeyPress={undefined}>
                <span>{d.getDate()}</span>
            </div>
        );
    });

    return (
        <div className={cx(style.days)}>
            <div>{daysElements}</div>
        </div>
    );
};

Days.propTypes = {
    value: PropTypes.instanceOf(Date),
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    onChange: PropTypes.func,
};

Days.defaultProps = {
    value: undefined,
    onChange: undefined,
};

export default Days;
