import AddEventChoose from './AddEventChoose';
import AddEventCyclical from './AddEventCyclical';
import AddEventSingle from './AddEventSingle';

const AddEvent = () => (
    <>
        <AddEventChoose />
        <AddEventSingle />
        <AddEventCyclical />
    </>
);

export default AddEvent;
