import { useRouteMatch } from 'react-router-dom';

import EmptyCard from 'components/EmptyCard';
import useUI from 'contexts/useUI';
import { usePlayerMedicalsQuery } from 'queries/players.queries.js';
import {
    Button,
    Column,
    Header,
    List,
    ListActions,
    ListCell,
    Spinner,
    Tag,
} from 'ui';

import ListDataCell from '../../../../components/ListDataCell/index.js';
import AddMedical from '../modals/AddMedical';

const Medicals = () => {
    const { modalOpen } = useUI();
    const {
        params: { id },
    } = useRouteMatch();

    const { data, isFetched } = usePlayerMedicalsQuery(id);

    if (!isFetched) return <Spinner active />;

    return (
        <>
            <Column gap={'m'}>
                <Header title="Badania lekarskie" size="l">
                    <Button
                        size={'l'}
                        color={'green'}
                        onClick={() => {
                            modalOpen('addMedical', { idPlayer: id });
                        }}>
                        Wprowadź badanie
                    </Button>
                </Header>
                {!data.length ? (
                    <EmptyCard
                        action={() => modalOpen('addMedical', { idPlayer: id })}
                        actionLabel="Dodaj pierwsze badanie"
                    />
                ) : (
                    <List
                        header={
                            <>
                                <ListCell width={120} header>
                                    Wprowadzono
                                </ListCell>
                                <ListCell width={120} header>
                                    Wazne do
                                </ListCell>
                                <ListCell width={100} header>
                                    Dokument
                                </ListCell>
                                <ListCell header>Autor</ListCell>
                            </>
                        }
                        data={data}
                        renderRow={({ createdAt, dateEnd, author }) => {
                            return (
                                <>
                                    <ListDataCell.Date date={createdAt} />
                                    <ListDataCell.Date date={dateEnd} />
                                    <ListCell width={100}>
                                        <Tag text="BRAK" size="s" />
                                    </ListCell>
                                    <ListDataCell.User user={author} />
                                    <ListActions actions={[]} />
                                </>
                            );
                        }}
                    />
                )}
            </Column>
            <AddMedical />
        </>
    );
};
export default Medicals;
