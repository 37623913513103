import { NEWS_TYPES } from 'constants/logic';
import { Avatar, Column, RoleTag, Row } from 'ui';

import GalleryCard from './GalleryCard';
import InfoCard from './InfoCard';
import NewsCard from './NewsCard';
import WelcomeCard from './WelcomeCard';

import styles from './Feed.module.scss';

const renderCard = item => {
    switch (item.type) {
        case NEWS_TYPES.NEWS: {
            return <NewsCard {...item} />;
        }
        case NEWS_TYPES.INFO: {
            return <InfoCard {...item} />;
        }
        case NEWS_TYPES.GALLERY: {
            return <GalleryCard {...item} />;
        }
        case 'auto': {
            switch (item.subtype) {
                case 'welcome': {
                    return <WelcomeCard {...item} />;
                }
                default: {
                    return null;
                }
            }
        }
        default: {
            return null;
        }
    }
};

const FeedCard = item => {
    return (
        <div className={styles.card}>
            <Column gap="xxs">
                <div className={styles.head}>
                    <Row gap="xs" align={'center'}>
                        <Avatar
                            size="m"
                            text={`${item.author.name} ${item.author.surname}`}
                            picture={item.author.avatar}
                        />
                        <Row gap="xxxs" className={styles.author}>
                            <span>{item.author.name}</span>
                            <strong>{item.author.surname}</strong>
                        </Row>

                        <RoleTag role={item.author.role} size="s" />
                    </Row>
                </div>
                {renderCard(item)}
            </Column>
        </div>
    );
};

export default FeedCard;
