import { useRouteMatch } from 'react-router-dom';

import Gallery from 'components/Gallery';
import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useMutationCoachPatch } from 'queries/coaches.mutations.js';
import { useCoachQuery } from 'queries/coaches.queries.js';
import { Card, Column, Header, LabeledValue, Row, Spinner } from 'ui';
import { formatDateFull } from 'utils/formatters';
import useACL from 'utils/useACL';

import EditAddress from './modals/EditAddress';
import EditBio from './modals/EditBio';
import EditContact from './modals/EditContact';
import EditInfo from './modals/EditInfo';

const Info = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const { modalOpen } = useUI();
    const { data: coach, isFetched } = useCoachQuery(id);

    const { isRole, isMe } = useACL();

    const canEdit = isRole([ROLES.ADMIN, ROLES.OWNER]) || isMe(id);

    const mutation = useMutationCoachPatch();

    const patch = delta => {
        mutation.mutate({ id, delta });
    };

    if (!isFetched) return <Spinner active />;
    return (
        <>
            <Row gap={'m'}>
                <Column gap={'m'}>
                    <Card
                        onEdit={
                            canEdit
                                ? () => modalOpen('editInfo', { idCoach: id })
                                : null
                        }>
                        <Column gap={'xs'}>
                            <Header title="Informacje podstawowe" size="m" />
                            <Row gap={'xs'}>
                                <Column>
                                    <LabeledValue
                                        label="Imię"
                                        value={coach.name}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label={'Nazwisko'}
                                        value={coach.surname}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label={'Data urodzenia'}
                                        value={formatDateFull(coach.birthday)}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                    <Card
                        onEdit={
                            canEdit
                                ? () =>
                                      modalOpen('editContact', { idCoach: id })
                                : null
                        }>
                        <Column gap={'xs'}>
                            <Header title="Kontakt" size="m" />
                            <Row gap={'xs'}>
                                <Column>
                                    <LabeledValue
                                        label="Telefon"
                                        value={coach.phone}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label={'Adres email'}
                                        value={coach.email}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                    <Card
                        onEdit={
                            canEdit
                                ? () =>
                                      modalOpen('editAddress', { idCoach: id })
                                : null
                        }>
                        <Column gap={'xs'}>
                            <Header title="Adres" size="m" />
                            <Row gap={'xs'}>
                                <Column>
                                    <LabeledValue
                                        label="Ulica"
                                        value={coach.address.street}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label={'Miasto'}
                                        value={coach.address.city}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label={'Kod pocztowy'}
                                        value={coach.address.postal}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                </Column>
                <Column gap={'m'}>
                    <Card
                        onEdit={
                            canEdit
                                ? () => modalOpen('editBio', { idCoach: id })
                                : null
                        }>
                        <Column gap={'xs'}>
                            <Header title="Biografia" size="m" />
                            <Row gap={'xs'}>
                                <Column>
                                    <LabeledValue
                                        label="Biografia"
                                        value={coach.address.bio}
                                        type={'longText'}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                    <Card>
                        <Column gap={'xs'}>
                            <Header title="Zdjęcia trenera" size="m" />
                            <Gallery
                                photos={coach.photos}
                                starredPhoto={coach.photo}
                                onChange={photos => patch({ photos })}
                                onStarChange={photo => patch({ photo })}
                                disabled={!canEdit}
                            />
                        </Column>
                    </Card>
                </Column>
            </Row>
            <EditAddress />
            <EditContact />
            <EditInfo />
            <EditBio />
        </>
    );
};

export default Info;
