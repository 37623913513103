import cx from 'classnames';

import style from './Html.module.scss';

const Html = ({ className, children }) => {
    if (children) {
        return (
            <div
                className={cx([style.html, className])}
                dangerouslySetInnerHTML={{
                    __html: children.replace(/\n/g, '<br />'),
                }}
            />
        );
    }
    return null;
};

export default Html;
