import cx from 'classnames';
import PropTypes from 'prop-types';

import { formatMonth } from 'utils/formatters';

import style from './Months.module.scss';

const monthsElements = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const Months = ({ year, openMonth }) => (
    <div className={cx(style.months)}>
        <div>
            {monthsElements.map(m => (
                <div
                    className={cx(style.month)}
                    onClick={() => openMonth(m)}
                    key={m}>
                    {formatMonth(new Date(year, m))}
                </div>
            ))}
        </div>
    </div>
);

Months.propTypes = {
    year: PropTypes.number.isRequired,
    openMonth: PropTypes.func.isRequired,
};

export default Months;
