import fetch from 'utils/fetch';

export default {
    getFeed: params =>
        fetch.get('dashboard/feed', { params }).then(r => r.data),
    getGroups: params =>
        fetch.get('dashboard/groups', { params }).then(r => r.data),
    getCoaches: () => fetch.get('dashboard/coaches').then(r => r.data),
    getPlayers: () => fetch.get('dashboard/players').then(r => r.data),
    getEvents: () => fetch.get('dashboard/events').then(r => r.data),
    getVenues: () => fetch.get('dashboard/venues').then(r => r.data),
};
