import { useEffect, useRef, useState } from 'react';
import animateScrollTo from 'animated-scroll-to';
import cx from 'classnames';
import { DateTime } from 'luxon';

import { isToday } from 'utils/date';
import { formatDay } from 'utils/formatters';

import DayCard from './DayCard';
import DaysRibbonScroll from './DaysRibbonScroll';

import styles from './CalendarAgendaView.module.scss';

const DaysRibbon = ({ days }) => {
    const [isPinned, setIsPinned] = useState(false);
    const ref = useRef();
    useEffect(() => {
        window.addEventListener('scroll', onScroll, false);

        return () => {
            window.removeEventListener('scroll', onScroll, false);
        };
    }, [ref]);
    const onScroll = () => {
        if (ref.current) {
            const { top } = ref.current.getBoundingClientRect();
            if (top < 71) {
                setIsPinned(true);
            } else if (top > 71) {
                setIsPinned(false);
            }
        }
    };

    const daysComponents = [];
    const today = DateTime.now();
    for (let i = 0; i < 28; i++) {
        const day = today.plus({ days: i });
        daysComponents.push(
            <DayCard
                key={formatDay(day)}
                date={day}
                amount={days[formatDay(day)]?.length}
                active={isToday(day)}
                onClick={() => {
                    const el = document.querySelector(
                        `[data-scroll-to='${formatDay(day)}']`,
                    );
                    if (el) {
                        animateScrollTo(el, { verticalOffset: -180 });
                    }
                }}
            />,
        );
    }

    return (
        <div ref={ref}>
            <div className={cx([styles.daysRibbon, isPinned && styles.pinned])}>
                <DaysRibbonScroll>{daysComponents}</DaysRibbonScroll>
            </div>
        </div>
    );
};

export default DaysRibbon;
