import { useMutation, useQueryClient } from '@tanstack/react-query';

import useUI from 'contexts/useUI';
import newsService from 'services/newsService.js';

export const useMutationNewsAdd = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(newsService.post, {
        onSuccess: data => {
            toastPush({
                title: 'Dodano aktualność',
            });

            queryClient.invalidateQueries(['news', 'list']);
            queryClient.invalidateQueries(['news', 'single', data.id]);
            (data.groups || []).map(id => {
                queryClient.invalidateQueries(['group', id, 'feed']);
                queryClient.invalidateQueries(['group', id, 'galleries']);
            });

            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationNewsPatch = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(({ id, delta }) => newsService.patch(id, delta), {
        onSuccess: (data, { id }) => {
            toastPush({
                title: 'Wyedytowano',
            });

            queryClient.invalidateQueries(['news', 'list']);
            queryClient.invalidateQueries(['news', 'single', id]);
            (data.groups || []).map(id => {
                queryClient.invalidateQueries(['group', id, 'feed']);
                queryClient.invalidateQueries(['group', id, 'galleries']);
            });

            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useMutationNewsDelete = ({ onSuccess } = {}) => {
    const queryClient = useQueryClient();
    const { toastPush } = useUI();

    return useMutation(newsService.delete, {
        onSuccess: (data, { id }) => {
            toastPush({
                title: 'Usunięto',
            });

            queryClient.invalidateQueries(['news', 'list']);
            queryClient.invalidateQueries(['news', 'single', id]);
            (data.groups || []).map(id => {
                queryClient.invalidateQueries(['group', id, 'feed']);
                queryClient.invalidateQueries(['group', id, 'galleries']);
            });

            if (onSuccess) {
                onSuccess();
            }
        },
    });
};
