import cx from 'classnames';
import PropTypes from 'prop-types';

import { Html } from '../index.js';

import style from './LabeledValue.module.scss';

const LabeledValue = ({ label, value, type }) => {
    return (
        <div className={cx([style.labeledValue, style[`type-${type}`]])}>
            <div className={style.label}>{label}</div>
            {type === 'longText' && (
                <div className={style.value}>
                    <Html>{value}</Html>
                </div>
            )}
            {type === 'text' && <div className={style.value}>{value}</div>}
        </div>
    );
};

LabeledValue.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    type: PropTypes.oneOf(['text', 'longText']),
};

LabeledValue.defaultProps = {
    type: 'text',
    value: null,
};

export default LabeledValue;
