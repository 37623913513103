import fetch from 'utils/fetch';

export default {
    query: query => fetch.get('groups', { params: query }).then(r => r.data),
    post: group => fetch.post('groups', group).then(r => r.data),

    get: id => fetch.get(`groups/${id}`).then(r => r.data),
    patch: (id, delta) => fetch.patch(`groups/${id}`, delta).then(r => r.data),
    delete: id => fetch.delete(`groups/${id}`).then(r => r.data),

    getPlayers: id => fetch.get(`groups/${id}/players`).then(r => r.data),
    addPlayer: (idGroup, idPlayer) =>
        fetch.put(`groups/${idGroup}/players/${idPlayer}`).then(r => r.data),
    removePlayer: (idGroup, idPlayer) =>
        fetch.delete(`groups/${idGroup}/players/${idPlayer}`).then(r => r.data),

    getCoaches: id => fetch.get(`groups/${id}/coaches`).then(r => r.data),
    addCoach: (idGroup, idCoach) =>
        fetch.put(`groups/${idGroup}/coaches/${idCoach}`).then(r => r.data),
    removeCoach: (idGroup, idCoach) =>
        fetch.delete(`groups/${idGroup}/coaches/${idCoach}`).then(r => r.data),

    getEvents: (id, query) =>
        fetch.get(`groups/${id}/events`, { params: query }).then(r => r.data),
    getFeed: (id, query) =>
        fetch.get(`groups/${id}/feed`, { params: query }).then(r => r.data),
    getGalleries: (id, query) =>
        fetch
            .get(`groups/${id}/galleries`, { params: query })
            .then(r => r.data),

    getEventsCyclical: (id, query) =>
        fetch
            .get(`groups/${id}/events-cyclical`, { params: query })
            .then(r => r.data),
};
