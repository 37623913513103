import { AnimatePresence, motion } from 'framer-motion';

const AnimatedTransition = ({ children, k, ...props }) => (
    <AnimatePresence mode={'wait'}>
        <motion.div
            key={k}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            {...props}>
            {children}
        </motion.div>
    </AnimatePresence>
);

export default AnimatedTransition;
