import { useMutationPlayerMedicalAdd } from 'queries/players.mutations.js';
import {
    Button,
    InputDate,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util.js';

const DialogAddMedical = ({ data: { idPlayer }, modalClose }) => {
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        dateEnd: {
            value: '',
            validators: [validators.isRequired()],
        },
    });

    const mutation = useMutationPlayerMedicalAdd({
        onSuccess: modalClose,
    });

    const addMedical = () => {
        if (!isFormValid) return;
        mutation.mutate({ idPlayer: idPlayer, medical: getFormValue() });
    };

    return (
        <>
            <ModalHeader>Nowe badanie lekarskie</ModalHeader>
            <ModalContent>
                <Label>Data ważności</Label>
                <InputDate {...form.dateEnd} />
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid || mutation.isLoading}
                    color="green"
                    onClick={addMedical}
                    onDisabledClick={touchForm}>
                    Utwórz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DialogAddMedical, {
    id: 'addMedical',
    title: 'Dodaj nowe badanie lekarskie',
    size: 'small',
});
