import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Button } from 'ui';

import Image from './Image';

import styles from './Gallery.module.scss';

const Gallery = ({
    photos,
    starredPhoto,
    onDelete,
    onStar,
    onDrop,
    size,
    multiple,
    disabled,
}) => {
    const onDropHandler = useCallback(onDrop, []);
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: onDropHandler,
        accept: ['image/png', 'image/jpg', 'image/jpeg'],
        maxFiles: multiple ? 20 : 1,
        noDragEventsBubbling: true,
        noClick: true,
    });
    return (
        <div className={styles.gallery} {...getRootProps()}>
            <div className={styles.images}>
                {photos.map(p => (
                    <Image
                        key={p}
                        url={p}
                        onStar={onStar}
                        onDelete={onDelete}
                        isStarred={starredPhoto === p}
                        size={size}
                    />
                ))}
            </div>
            {!disabled && (
                <div className={styles.buttons}>
                    <Button
                        color={'green'}
                        size={'l'}
                        onClick={open}>{`Dodaj nowe ${
                        multiple ? 'zdjęcia' : 'zdjęcie'
                    }`}</Button>
                </div>
            )}
            <input {...getInputProps()} />
        </div>
    );
};

export default Gallery;
