export const EVENTS_TYPES_ARRAY = [
    'training',
    'match',
    'tournament',
    'test',
    'meeting',
    'other',
];

export const EVENTS_TYPES_MAP = {
    [EVENTS_TYPES_ARRAY[0]]: {
        name: 'Trening',
        icon: 'whistle',
    },
    [EVENTS_TYPES_ARRAY[1]]: {
        name: 'Mecz',
        icon: 'match',
    },
    [EVENTS_TYPES_ARRAY[2]]: {
        name: 'Turniej',
        icon: 'tournament',
    },
    [EVENTS_TYPES_ARRAY[3]]: {
        name: 'Testy',
        icon: 'clock',
    },
    [EVENTS_TYPES_ARRAY[4]]: {
        name: 'Spotkanie',
        icon: 'chat',
    },
    [EVENTS_TYPES_ARRAY[5]]: {
        name: 'Inny',
        icon: '',
    },
};

export const NEWS_TYPES = {
    INFO: 'info',
    NEWS: 'news',
    GALLERY: 'gallery',
};

export const NEWS_TYPES_ARRAY = ['info', 'news', 'gallery'];

export const NEWS_TYPES_MAP = {
    [NEWS_TYPES_ARRAY[0]]: {
        name: 'Ogłoszenie',
        icon: 'whistle',
    },
    [NEWS_TYPES_ARRAY[1]]: {
        name: 'Aktualność',
        icon: 'news',
    },
    [NEWS_TYPES_ARRAY[2]]: {
        name: 'Galeria',
        icon: 'camera',
    },
};

export const SEX_ARRAY = ['male', 'female'];

export const SEX_MAP = {
    male: {
        name: 'Mężczyzna',
    },
    female: {
        name: 'Kobieta',
    },
};

export const ROLES = {
    OWNER: 'owner',
    ADMIN: 'admin',
    COACH: 'coach',
    PARENT: 'parent',
    PLAYER: 'player',
};

export const ROLES_MAP = {
    owner: {
        name: 'Właściciel',
        color: '#9B51E0',
    },
    admin: {
        name: 'Administrator',
        color: '#EB5757',
    },
    coach: {
        name: 'Trener',
        color: '#F2994A',
    },
    parent: {
        name: 'Rodzic',
        color: '#2F80ED',
    },
    player: {
        name: 'Zawodnik',
        color: '#56CCF2',
    },
};
