import { useHistory } from 'react-router-dom';

import EmptyCard from 'components/EmptyCard';
import ListDataCell from 'components/ListDataCell';
import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import { useParentsListQuery } from 'queries/parents.queries.js';
import {
    Button,
    Column,
    Container,
    Header,
    List,
    ListActions,
    ListCell,
    Spinner,
} from 'ui';
import InfiniteLoader from 'ui/InfiniteLoader';

import ViewHOC from '../../ViewHOC';

const Parents = () => {
    const { modalOpen } = useUI();
    const history = useHistory();

    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetching,
        isFetched,
    } = useParentsListQuery();

    const parents = data.pages.flat(1);

    const openAddParent = () => {
        modalOpen('addParent');
    };

    const openParent = id => {
        history.push(`/parents/${id}`);
    };

    if (!isFetched) return <Spinner active />;

    return (
        <Container>
            <Column gap={'m'}>
                <Header title="Rodzice" size="xl">
                    <Button color={'green'} onClick={openAddParent}>
                        Nowy rodzic
                    </Button>
                </Header>
                {!isFetching && !parents.length && (
                    <EmptyCard
                        action={openAddParent}
                        actionLabel="Dodaj rodzica"
                    />
                )}
                <List
                    header={
                        <>
                            <ListCell width={300} header>
                                Imię i nazwisko
                            </ListCell>
                            <ListCell width={200}>Numer telefonu</ListCell>
                            <ListCell header>Dzieci</ListCell>
                        </>
                    }
                    data={parents}
                    renderRow={({
                        id,
                        name,
                        surname,
                        avatar,
                        players,
                        phone,
                    }) => {
                        const open = () => openParent(id);
                        return (
                            <>
                                <ListDataCell.User
                                    onClick={open}
                                    size="l"
                                    user={{ id, name, surname, avatar }}
                                />
                                <ListDataCell.Phone phone={phone} />
                                <ListDataCell.Users users={players} />
                                <ListActions
                                    actions={[
                                        {
                                            title: 'edit',
                                            icon: 'edit',
                                            onClick: open,
                                        },
                                    ]}
                                />
                            </>
                        );
                    }}
                />
                {!isFetchingNextPage && hasNextPage && (
                    <InfiniteLoader onVisible={fetchNextPage} />
                )}
            </Column>
        </Container>
    );
};

export default ViewHOC(Parents, {
    menu: true,
    topbar: true,
    auth: {
        roles: [
            ROLES.OWNER,
            ROLES.ADMIN,
            ROLES.COACH,
            ROLES.PARENT,
            ROLES.PLAYER,
        ],
    },
});
