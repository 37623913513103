import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import style from './Button.module.scss';

const ICON_SIZE_MAP = {
    l: 's',
    xl: 'm',
};

const ICON_COLOR_MAP = {
    white: 'black-500',
    green: 'white',
    black: 'white',
    red: 'white',
    grey: 'grey-500',
};

const Button = ({
    children,
    icon,
    type,
    shape,
    size,
    color,
    disabled,
    href,
    onClick,
    onDisabledClick,
    styles,
}) => {
    const content = (
        <>
            {icon && (
                <Icon
                    icon={icon}
                    size={ICON_SIZE_MAP[size]}
                    color={ICON_COLOR_MAP[color]}
                />
            )}
            {children && (
                <span style={{ marginLeft: icon ? '8px' : 0 }}>{children}</span>
            )}
        </>
    );

    if (href) {
        return (
            <Link
                className={cx(
                    style.button,
                    style[`size-${size}`],
                    style[`shape-${shape}`],
                    style[`type-${type}`],
                    style[`color-${color}`],
                    disabled && style.disabled,
                )}
                style={styles}
                to={href}>
                {content}
            </Link>
        );
    }
    return (
        <button
            className={cx(
                style.button,
                style[`size-${size}`],
                style[`shape-${shape}`],
                style[`type-${type}`],
                style[`color-${color}`],
                disabled && style.disabled,
            )}
            style={styles}
            onClick={disabled ? onDisabledClick : onClick}>
            {content}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.oneOf(['fill', 'border']),
    shape: PropTypes.oneOf(['rect', 'square']),
    size: PropTypes.oneOf(['l', 'xl']),
    color: PropTypes.oneOf(['white', 'green', 'black', 'red']),
    disabled: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
    onDisabledClick: PropTypes.func,
    styles: PropTypes.shape({}),
};

Button.defaultProps = {
    type: 'fill',
    icon: undefined,
    color: 'white',
    size: 'xl',
    shape: 'rect',
    disabled: false,
    children: '',
    href: undefined,
    onClick: undefined,
    onDisabledClick: undefined,
    styles: {},
};

export default Button;
