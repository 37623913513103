import { Link } from 'react-router-dom';
import cx from 'classnames';

import { Avatar } from 'ui';

import style from './Search.module.scss';

const Search = ({ players = [], parents = [], coaches = [], groups = [] }) => (
    <div
        className={cx(
            style.search,
            (players.length ||
                parents.length ||
                coaches.length ||
                groups.length) &&
                style.active,
        )}>
        <div className={style.group}>
            <div className={style.header}>ZAWODNICY</div>
            {players.map(r => (
                <Link className={style.item} to={`/players/${r.id}`} key={r.id}>
                    <Avatar
                        text={`${r.name} ${r.surname}`}
                        size="m"
                        picture={r.avatar}
                    />
                    <div className={style.name}>
                        {r.name} <strong>{r.surname}</strong>
                    </div>
                </Link>
            ))}
        </div>
        <div className={style.group}>
            <div className={style.header}>RODZICE</div>
            {parents.map(r => (
                <Link className={style.item} to={`/parents/${r.id}`} key={r.id}>
                    <Avatar
                        text={`${r.name} ${r.surname}`}
                        size="m"
                        picture={r.avatar}
                    />
                    <div className={style.name}>
                        {r.name} <strong>{r.surname}</strong>
                    </div>
                </Link>
            ))}
        </div>
        <div className={style.group}>
            <div className={style.header}>TRENERZY</div>
            {coaches.map(r => (
                <Link className={style.item} to={`/coaches/${r.id}`} key={r.id}>
                    <Avatar
                        text={`${r.name} ${r.surname}`}
                        size="m"
                        picture={r.avatar}
                    />
                    <div className={style.name}>
                        {r.name} <strong>{r.surname}</strong>
                    </div>
                </Link>
            ))}
        </div>
        <div className={style.group}>
            <div className={style.header}>GRUPY</div>
            {groups.map(r => (
                <Link className={style.item} to={`/groups/${r.id}`} key={r.id}>
                    <Avatar
                        text={r.avatar.slice(7, 9)}
                        size="m"
                        shape="shield"
                        color={r.avatar.slice(0, 7)}
                    />
                    <div className={style.name}>
                        <strong>{r.name}</strong>
                    </div>
                </Link>
            ))}
        </div>
    </div>
);

export default Search;
