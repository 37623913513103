import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import playersService from '../services/playersService.js';

export const usePlayerQuery = id => {
    return useQuery(['players', 'single', id], () => playersService.get(id), {
        enabled: !!id,
        initialData: null,
    });
};

export const usePlayerEventsQuery = id => {
    return useQuery(
        ['players', 'single', id, 'events'],
        () => playersService.getEvents(id),
        {
            enabled: !!id,
            initialData: [],
        },
    );
};

export const usePlayerGroupsQuery = id => {
    return useQuery(
        ['players', 'single', id, 'groups'],
        () => playersService.getGroups(id),
        {
            enabled: !!id,
            initialData: [],
        },
    );
};

export const usePlayerParentsQuery = id => {
    return useQuery(
        ['players', 'single', id, 'parents'],
        () => playersService.getPlayers(id),
        {
            enabled: !!id,
            initialData: [],
        },
    );
};

export const usePlayerMeasurementsQuery = id => {
    return useQuery(
        ['players', 'single', id, 'measurements'],
        () => playersService.getMeasurements(id),
        {
            enabled: !!id,
            initialData: [],
        },
    );
};

export const usePlayerMedicalsQuery = id => {
    return useQuery(
        ['players', 'single', id, 'medicals'],
        () => playersService.getMedicals(id),
        {
            enabled: !!id,
            initialData: [],
        },
    );
};

export const usePlayersListQuery = filters => {
    return useInfiniteQuery(
        ['players', 'list', filters],
        ({ pageParam }) => {
            return playersService.query({
                ...filters,
                limit: 10,
                offset: pageParam,
            });
        },
        {
            getNextPageParam: (a, b) => {
                if (!a.length) return undefined;
                return b.flat(1).length;
            },
        },
    );
};
