import { useEffect } from 'react';

import { useMutationCoachPatch } from 'queries/coaches.mutations.js';
import { useCoachQuery } from 'queries/coaches.queries.js';
import {
    Button,
    InputText,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';
import useForm from 'utils/useForm';

const DialogEditBio = ({ data: { idCoach }, modalClose }) => {
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        bio: {
            value: '',
        },
    });

    const { data } = useCoachQuery(idCoach);

    useEffect(() => {
        if (data) {
            form.bio.setValue(data.bio);
        }
    }, [data]);

    const mutation = useMutationCoachPatch({
        onSuccess: modalClose,
    });

    const save = () => {
        if (!isFormValid) return;
        mutation.mutate({ id: idCoach, delta: getFormValue() });
    };
    return (
        <>
            <ModalHeader>Edytuj biografię</ModalHeader>
            <ModalContent>
                <div>
                    <Label>Biografia</Label>
                    <InputText {...form.bio} />
                </div>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid}
                    color="green"
                    onClick={save}
                    onDisabledClick={touchForm}>
                    Zapisz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DialogEditBio, {
    id: 'editBio',
    size: 'medium',
});
