import { useEffect } from 'react';

import useAuth from 'contexts/useAuth';
import useUI from 'contexts/useUI';
import {
    Button,
    Column,
    Header,
    Input,
    InputFile,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Row,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util.js';

const DialogProfile = () => {
    const { modalClose } = useUI();
    const { me, patchMe } = useAuth();

    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        name: {
            value: '',
            validators: [validators.isRequired()],
        },
        surname: {
            value: '',
            validators: [validators.isRequired()],
        },
        avatar: {
            value: null,
        },
        phone: {
            value: '',
        },
        pesel: {
            value: '',
        },
        email: {
            value: '',
            validators: [validators.isRequired(), validators.isEmail()],
        },
        'address.street': {
            value: '',
        },
        'address.city': {
            value: '',
        },
        'address.postal': {
            value: '',
        },
    });

    const save = () => {
        if (!isFormValid) return;
        patchMe(getFormValue()).then(modalClose);
    };

    useEffect(() => {
        if (me) {
            form.name.setValue(me.name);
            form.surname.setValue(me.surname);
            form.avatar.setValue(me.avatar);
            form.phone.setValue(me.phone);
            form.pesel.setValue(me.pesel);
            form.email.setValue(me.email);
            form['address.street'].setValue(me.address?.street);
            form['address.city'].setValue(me.address?.city);
            form['address.postal'].setValue(me.address?.postal);
        }
    }, [me]);

    return (
        <>
            <ModalHeader>Twój profil</ModalHeader>
            <ModalContent>
                <Row gap={'m'}>
                    <Column gap={'xs'}>
                        <Header title="Informacje osobiste" size="s" />
                        <div>
                            <Label>Imię</Label>
                            <Input {...form.name} />
                        </div>
                        <div>
                            <Label>Nazwisko</Label>
                            <Input {...form.surname} />
                        </div>
                        <div>
                            <Label>Pesel</Label>
                            <Input {...form.pesel} />
                        </div>
                        <div>
                            <Label>Avatar</Label>
                            <InputFile {...form.avatar} />
                        </div>
                    </Column>
                    <Column gap={'m'}>
                        <Column gap={'xs'}>
                            <Header title="Informacje kontaktowe" size="s" />
                            <div>
                                <Label>Adres email</Label>
                                <Input {...form.email} />
                            </div>
                            <div>
                                <Label>Numer telefonu</Label>
                                <Input {...form.phone} />
                            </div>
                        </Column>
                        <Column gap={'xs'}>
                            <Header title="Dane adresowe" size="s" />
                            <div>
                                <Label>Ulica</Label>
                                <Input {...form['address.street']} />
                            </div>
                            <div>
                                <Label>Miasto</Label>
                                <Input {...form['address.city']} />
                            </div>
                            <div>
                                <Label>Kod pocztowy</Label>
                                <Input {...form['address.postal']} />
                            </div>
                        </Column>
                    </Column>
                </Row>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid}
                    color="green"
                    onClick={save}
                    onDisabledClick={touchForm}>
                    Zapisz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DialogProfile, {
    id: 'profile',
    size: 'medium',
});
