import { AnimatePresence, motion } from 'framer-motion';

import { Button } from 'ui';

import style from './CookieAlert.module.scss';

const CookieAlert = ({ active, accept }) => (
    <AnimatePresence>
        {active && (
            <motion.div className={style.cookieAlert}>
                <p className={style.header}>Polityka Cookies</p>
                <p className={style.text}>
                    Używamy cookies, aby zapewnić największą wygodę korzystania
                    z tego serwisu i stale podnosić jego jakość.
                </p>
                <Button color="green" onClick={accept}>
                    Rozumiem
                </Button>
            </motion.div>
        )}
    </AnimatePresence>
);

export default CookieAlert;
