import PropTypes from 'prop-types';

import style from './Container.module.scss';

const Container = ({ children }) => (
    <div className={style.container}>
        <div>{children}</div>
    </div>
);

Container.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

Container.defaultProps = {};

export default Container;
