import { useEffect } from 'react';

import { useMutationParentPatch } from 'queries/parents.mutations.js';
import { useParentQuery } from 'queries/parents.queries.js';
import {
    Button,
    Column,
    Input,
    InputDate,
    InputFile,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util';

const DialogEditInfo = ({ data: { idParent }, modalClose }) => {
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        name: {
            value: '',
            validators: [validators.isRequired('Imię jest wymagane')],
        },
        surname: {
            value: '',
            validators: [validators.isRequired('Nazwisko jest wymagane')],
        },
        birthday: {
            value: null,
        },
        avatar: {
            value: null,
        },
    });

    const { data } = useParentQuery(idParent);

    useEffect(() => {
        if (data) {
            form.name.setValue(data.name);
            form.surname.setValue(data.surname);
            form.birthday.setValue(new Date(data.birthday));
            form.avatar.setValue(data.avatar);
        }
    }, [data]);

    const mutation = useMutationParentPatch({
        onSuccess: modalClose,
    });

    const save = () => {
        if (!isFormValid) return;
        mutation.mutate({ id: idParent, delta: getFormValue() });
    };
    return (
        <>
            <ModalHeader>Edytuj informacje podstawowe</ModalHeader>
            <ModalContent>
                <Column gap={'xs'}>
                    <div>
                        <Label>Imię</Label>
                        <Input {...form.name} />
                    </div>
                    <div>
                        <Label>Nazwisko</Label>
                        <Input {...form.surname} />
                    </div>
                    <div>
                        <Label>Data urodzenia</Label>
                        <InputDate {...form.birthday} />
                    </div>
                    <div>
                        <Label>Avatar</Label>
                        <InputFile type="avatar" {...form.avatar} />
                    </div>
                </Column>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid || mutation.isLoading}
                    color="green"
                    onClick={save}
                    onDisabledClick={touchForm}>
                    Zapisz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DialogEditInfo, {
    id: 'editInfo',
    title: 'Edytuj informacje podstawowe',
    size: 'small',
});
