import { useNewsQuery } from 'queries/news.queries.js';
import { ModalContent, ModalHOC, Row, Spinner } from 'ui';
import { formatDateFullWithDay } from 'utils/formatters.js';

import GroupTag from '../../../../ui/GroupTag/index.js';

import styles from './Gallery.module.scss';

const Gallery = ({ data }) => {
    const { data: gallery } = useNewsQuery(data.id);

    if (!gallery) {
        return <Spinner active />;
    }

    const {
        data: { title, photos },
        date,
        groups,
    } = gallery;

    return (
        <>
            <ModalContent>
                <div className={styles.root}>
                    <div className={styles.top}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.info}>
                            <div className={styles.date}>
                                {formatDateFullWithDay(date)}
                            </div>
                            <div className={styles.groups}>
                                <Row gap={'s'}>
                                    {groups.map(g => (
                                        <GroupTag
                                            group={g}
                                            size="m"
                                            key={g.id}
                                        />
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className={styles.photos}>
                        {photos.map(p => {
                            const path = !p.includes('gorniklibiaz.pl')
                                ? p.replace('.jpg', '.large.jpg')
                                : p;
                            return (
                                <img
                                    key={path}
                                    className={styles.photo}
                                    src={path}
                                    alt="title"
                                />
                            );
                        })}
                    </div>
                </div>
            </ModalContent>
        </>
    );
};

export default ModalHOC(Gallery, {
    id: 'gallery',
    size: 'large',
    noPadding: true,
});
