import fetch from 'utils/fetch';

export default {
    get: () =>
        new Promise((resolve, reject) => {
            fetch
                .get('me', { headers: { silent: true } })
                .then(({ data }) => {
                    if (!data.id) {
                        reject();
                    }
                    resolve(data);
                })
                .catch(reject);
        }),
    patch: delta => fetch.patch('me', delta),

    getNotifications: () => fetch.get('me/notifications'),
    getEvents: () => fetch.get('me/events'),
    getPlayers: () => fetch.get('me/players'),
    getGroups: () => fetch.get('me/groups'),
    getNews: () => fetch.get('me/news'),
};
