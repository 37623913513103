import { useMutationPlayerMeasurementAdd } from 'queries/players.mutations.js';
import { usePlayerQuery } from 'queries/players.queries.js';
import {
    Button,
    Column,
    Input,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util.js';

const DialogAddMeasurement = ({ data, modalClose }) => {
    const { data: player } = usePlayerQuery(data.idPlayer);

    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        height: {
            value: player?.height,
            validators: [validators.isRequired(), validators.isNumber()],
        },
        weight: {
            value: player?.weight,
            validators: [validators.isRequired(), validators.isNumber()],
        },
    });

    const mutationPatchPositions = useMutationPlayerMeasurementAdd({
        onSuccess: modalClose,
    });
    const add = () => {
        if (!isFormValid) return;
        mutationPatchPositions.mutate({
            idPlayer: data.idPlayer,
            measurement: getFormValue(),
        });
    };

    return (
        <>
            <ModalHeader>Nowy pomiar</ModalHeader>
            <ModalContent>
                <Column gap="xs">
                    <div>
                        <Label>Wzrost (cm)</Label>
                        <Input {...form.height} type="number" />
                    </div>
                    <div>
                        <Label>Waga (kg)</Label>
                        <Input {...form.weight} type="number" />
                    </div>
                </Column>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid}
                    color="green"
                    onClick={add}
                    onDisabledClick={touchForm}>
                    Utwórz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DialogAddMeasurement, {
    id: 'addMeasurement',
    size: 'small',
});
