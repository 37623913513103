import { useEffect } from 'react';

import { useMutationPlayerPatch } from 'queries/players.mutations.js';
import { usePlayerQuery } from 'queries/players.queries.js';
import {
    Button,
    Column,
    Input,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';
import useForm from 'utils/useForm';

const DialogEditContact = ({ data: { idPlayer }, modalClose }) => {
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        phone: {
            value: '',
        },
        email: {
            value: '',
        },
    });

    const { data } = usePlayerQuery(idPlayer);

    useEffect(() => {
        if (data) {
            form.phone.setValue(data.phone);
            form.email.setValue(data.email);
        }
    }, [data]);

    const mutation = useMutationPlayerPatch({
        onSuccess: modalClose,
    });

    const save = () => {
        if (!isFormValid) return;
        mutation.mutate({ id: idPlayer, delta: getFormValue() });
    };

    return (
        <>
            <ModalHeader>Edytuj informacje kontaktowe</ModalHeader>
            <ModalContent>
                <Column gap="xs">
                    <div>
                        <Label>Numer telefonu</Label>
                        <Input {...form.phone} />
                    </div>
                    <div>
                        <Label>Adres email</Label>
                        <Input {...form.email} />
                    </div>
                </Column>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid}
                    color="green"
                    onClick={save}
                    onDisabledClick={touchForm}>
                    Zapisz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};
export default ModalHOC(DialogEditContact, {
    id: 'editContact',
    title: 'Edytuj informacje kontaktowe',
    size: 'small',
});
