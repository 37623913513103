import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '../index.js';

import style from './Card.module.scss';

const Card = ({ children, padding, onEdit }) => {
    return (
        <div className={cx([style.card, padding && style.padding])}>
            {children}
            {onEdit && (
                <a className={style.button} onClick={onEdit}>
                    <Icon size="s" icon="edit" color={'black-500'} />
                </a>
            )}
        </div>
    );
};

Card.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    padding: PropTypes.bool,
    onEdit: PropTypes.func,
};

Card.defaultProps = {
    onEdit: null,
    padding: true,
};

export default Card;
