import { useCallback, useMemo, useState } from 'react';

const useForm = (fields, options) => {
    const [state, setState] = useState(
        Object.keys(fields).reduce((agg, key) => {
            return {
                ...agg,
                [key]: {
                    value: fields[key].value,
                    defaultValue: fields[key].value,
                    error: (fields[key].validators || []).reduce(
                        (agg, validator) => agg || validator(fields[key].value),
                        null,
                    ),
                    isFocused: false,
                    isTouched: false,
                    isDirty: false,
                },
            };
        }, {}),
    );

    const form = useMemo(() => {
        return Object.keys(fields).reduce(
            (agg, key) => ({
                ...agg,
                [key]: {
                    ...state[key],
                    error:
                        state[key].isTouched && !state[key].isFocused
                            ? state[key].error
                            : null,
                    onChange: val => {
                        setState(fs => ({
                            ...fs,
                            [key]: {
                                ...fs[key],
                                value: val,
                                error: (fields[key].validators || []).reduce(
                                    (agg, validator) => agg || validator(val),
                                    null,
                                ),
                                isDirty: val !== fs[key].defaultValue,
                            },
                        }));
                    },
                    onBlur: () =>
                        setState(fs => ({
                            ...fs,
                            [key]: {
                                ...fs[key],
                                isFocused: false,
                            },
                        })),
                    onFocus: () =>
                        setState(fs => ({
                            ...fs,
                            [key]: {
                                ...fs[key],
                                isFocused: true,
                                isTouched: true,
                            },
                        })),
                    setValue: val =>
                        setState(fs => ({
                            ...fs,
                            [key]: {
                                ...fs[key],
                                value: val,
                                defaultValue: val,
                                error: (fields[key].validators || []).reduce(
                                    (agg, validator) => agg || validator(val),
                                    null,
                                ),
                                isDirty: false,
                                isTouched: false,
                            },
                        })),
                    setError: error =>
                        setState(fs => ({
                            ...fs,
                            [key]: {
                                ...fs[key],
                                value: fs[key]?.value,
                                error: error,
                            },
                        })),
                },
            }),
            {},
        );
    }, [state]);

    const formError = useMemo(() => {
        if (options?.validators?.length) {
            return options.validators.reduce(
                (agg, validator) => agg || validator(state),
                null,
            );
        }
    }, [state]);

    const isFormValid = useMemo(() => {
        return !Object.keys(state).find(key => state[key]?.error) && !formError;
    }, [state]);

    const isFormDirty = useMemo(() => {
        return !!Object.keys(state).find(key => state[key]?.isDirty);
    }, [state]);

    const cleanForm = useCallback(() => {
        setState(
            Object.keys(fields).reduce((agg, key) => {
                return {
                    ...agg,
                    [key]: {
                        value: fields[key].value,
                        error: (fields[key].validators || []).reduce(
                            (agg, validator) =>
                                agg || validator(fields[key].value),
                            null,
                        ),
                        isFocused: false,
                        isTouched: false,
                        isDirty: false,
                    },
                };
            }, {}),
        );
    }, []);

    const getFormValue = useCallback(() => {
        const formValue = {};
        Object.keys(fields).forEach(key => {
            if (key.includes('.')) {
                const [k1, k2] = key.split('.');
                if (!formValue[k1]) {
                    formValue[k1] = {};
                }
                formValue[k1][k2] = state[key].value;
            } else {
                formValue[key] = state[key].value;
            }
        });
        return formValue;
    }, [form]);

    const formValue = useMemo(() => {
        const formValue = {};
        Object.keys(fields).forEach(key => {
            formValue[key] = state[key].value;
        });
        return formValue;
    }, [form]);

    const touchForm = useCallback(() => {
        setState(state =>
            Object.keys(fields).reduce((agg, key) => {
                return {
                    ...agg,
                    [key]: {
                        ...state[key],
                        isTouched: true,
                    },
                };
            }, {}),
        );
    }, []);

    return [
        form,
        {
            formError,
            isFormValid,
            isFormDirty,
            cleanForm,
            touchForm,
            getFormValue,
            formValue,
        },
    ];
};

export default useForm;
