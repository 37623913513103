import { useRouteMatch } from 'react-router-dom';

import Gallery from 'components/Gallery';
import { ROLES } from 'constants/logic';
import useUI from 'contexts/useUI';
import {
    useMutationGroupPatch,
    useMutationGroupRemoveCoach,
} from 'queries/groups.mutations.js';
import { useGroupCoachesQuery, useGroupQuery } from 'queries/groups.queries.js';
import {
    Button,
    Card,
    Column,
    Header,
    Html,
    LabeledValue,
    List,
    ListActions,
    Row,
    Spinner,
} from 'ui';
import useACL from 'utils/useACL';

import EmptyCard from '../../../../components/EmptyCard/index.js';
import ListDataCell from '../../../../components/ListDataCell/index.js';
import AddCoach from '../modals/AddCoach/AddCoach.jsx';

const Info = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const { modalOpen, modalClose } = useUI();
    const { isRole, isMyGroup } = useACL();
    const { data: group } = useGroupQuery(id);
    const { data: coaches } = useGroupCoachesQuery(id);

    const canEdit = isMyGroup(id) || isRole([ROLES.ADMIN, ROLES.OWNER]);

    const mutationPatch = useMutationGroupPatch({ onSuccess: modalClose });
    const mutationRemoveCoach = useMutationGroupRemoveCoach();
    const patch = delta => {
        mutationPatch.mutate({ id, delta });
    };

    const addCoachesToGroup = () =>
        modalOpen('addCoachesToGroup', { idGroup: id });

    const removeCoach = idCoach => {
        mutationRemoveCoach.mutate({ idGroup: id, idCoach });
    };

    if (!group || !group.id) return <Spinner active />;

    return (
        <>
            <Row gap="m">
                <Column gap="m">
                    <Card
                        onEdit={
                            canEdit
                                ? () => modalOpen('addEditGroup', { id })
                                : null
                        }>
                        <Column gap="xs">
                            <Header title="Informacje podstawowe" size="m" />
                            <Row gap="xs">
                                <Column>
                                    <LabeledValue
                                        label="Nazwa"
                                        value={group.name}
                                    />
                                </Column>
                                <Column>
                                    <LabeledValue
                                        label="Dodatkowa nazwa"
                                        value={group.subname}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Card>
                    <Card>
                        <Column gap="xs">
                            <Header title="Zdjęcia grupy" size="m" />
                            <Gallery
                                photos={group.photos}
                                starredPhoto={group.photo}
                                onChange={photos => patch({ photos })}
                                onStarChange={photo => patch({ photo })}
                                disabled={!canEdit}
                            />
                        </Column>
                    </Card>
                    <Card
                        onEdit={
                            canEdit
                                ? () => modalOpen('addEditGroup', { id })
                                : null
                        }>
                        <Column gap="xs">
                            <Header title="Opis grupy" size="m" />
                            <Html>{group.desc}</Html>
                        </Column>
                    </Card>
                </Column>
                <Column gap="s">
                    <Header title="Trenerzy" size="m">
                        {canEdit && (
                            <Button
                                shape="rect"
                                icon="edit"
                                size={'l'}
                                onClick={addCoachesToGroup}
                            />
                        )}
                    </Header>
                    {!coaches.length ? (
                        <EmptyCard
                            action={addCoachesToGroup}
                            actionLabel="Dodaj pierwszego trenera"
                        />
                    ) : (
                        <List
                            data={coaches || []}
                            renderRow={({
                                id: idCoach,
                                name,
                                surname,
                                avatar,
                            }) => {
                                return (
                                    <>
                                        <ListDataCell.User
                                            user={{
                                                id: idCoach,
                                                name,
                                                surname,
                                                avatar,
                                                role: 'coach',
                                            }}
                                            size="l"
                                        />
                                        <ListActions
                                            actions={[
                                                {
                                                    title: 'delete',
                                                    icon: 'delete',
                                                    color: 'red',
                                                    onClick: () => {
                                                        removeCoach(idCoach);
                                                    },
                                                },
                                            ]}
                                        />
                                    </>
                                );
                            }}
                        />
                    )}
                </Column>
            </Row>
            <AddCoach />
        </>
    );
};

export default Info;
