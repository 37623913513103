import { useContext } from 'react';

import { AuthContext } from './AuthProvider';

const useAuth = () => {
    const {
        token,
        me,
        roles,
        schools,
        signIn,
        signOut,
        signUp,
        selectRole,
        restoreSession,
        resetPassword,
        createSchool,
        patchMe,
    } = useContext(AuthContext);

    return {
        token,
        me,
        roles,
        schools,
        signIn,
        signOut,
        signUp,
        selectRole,
        restoreSession,
        resetPassword,
        createSchool,
        patchMe,
    };
};

export default useAuth;
