import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { useMutationCoachAdd } from 'queries/coaches.mutations.js';
import searchService from 'services/searchService';
import {
    Button,
    Column,
    Header,
    Input,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    Row,
    Warning,
} from 'ui';
import useForm from 'utils/useForm';

import ModalHOC from '../../../../ui/Modal/index.js';
import validators from '../../../../utils/validators.util.js';

import styles from './AddCoachDialog.module.scss';

const AddCoachDialog = ({ data, modalClose }) => {
    const history = useHistory();
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        name: {
            value: '',
            validators: [validators.isRequired()],
        },
        surname: {
            value: '',
            validators: [validators.isRequired()],
        },
        phone: {
            value: '',
        },
        email: {
            value: '',
        },
        'address.street': {
            value: '',
        },
        'address.city': {
            value: '',
        },
        'address.postal': {
            value: '',
        },
    });

    useEffect(() => {
        if (data.name) {
            form.name.setValue(data.name);
        }
        if (data.surname) {
            form.surname.setValue(data.surname);
        }
    }, [data]);

    const [existingCoach, setExistingCoach] = useState(null);

    useDebounce(
        () => {
            if (!form.name.value || !form.surname.value) {
                setExistingCoach(null);
                return;
            }

            searchService
                .get(`${form.name.value} ${form.surname.value}`)
                .then(({ coaches }) => {
                    if (coaches.length) {
                        setExistingCoach(
                            `${coaches[0].name} ${coaches[0].surname}`,
                        );
                    } else {
                        setExistingCoach(null);
                    }
                });
        },
        500,
        [form.name, form.surname],
    );

    const mutation = useMutationCoachAdd({
        onSuccess: ({ id }) => {
            modalClose();
            history.push(`/coaches/${id}`);
        },
    });

    const add = () => {
        if (!isFormValid) return;
        const object = getFormValue();
        if (data.addToGroup) {
            object.addToGroup = data.addToGroup;
        }

        mutation.mutate(object);
    };

    return (
        <>
            <ModalHeader>Nowy trener</ModalHeader>
            <ModalContent>
                <Row gap="m">
                    <Column gap={'m'}>
                        <Column gap={'xs'}>
                            <Header title="Informacje osobiste" size="s" />
                            <div>
                                <Label>Imię</Label>
                                <Input {...form.name} />
                            </div>
                            <div>
                                <Label>Nazwisko</Label>
                                <Input {...form.surname} />
                            </div>
                            {existingCoach && (
                                <div>
                                    <Warning
                                        title={`Istnieje już trener ${existingCoach}`}
                                        content="Czy aby na pewno chcesz dodać nowego trenera?"
                                    />
                                </div>
                            )}
                        </Column>
                        <Column gap={'xs'}>
                            <Header title="Informacje kontaktowe" size="s" />
                            <div className={styles.field}>
                                <Label>Adres email</Label>
                                <Input {...form.email} />
                            </div>
                            <div className={styles.field}>
                                <Label>Numer telefonu</Label>
                                <Input {...form.phone} />
                            </div>
                        </Column>
                    </Column>
                    <Column gap={'xs'}>
                        <Header title="Dane adresowe" size="s" />
                        <div>
                            <Label>Ulica</Label>
                            <Input {...form['address.street']} />
                        </div>
                        <div>
                            <Label>Miasto</Label>
                            <Input {...form['address.city']} />
                        </div>
                        <div>
                            <Label>Kod pocztowy</Label>
                            <Input {...form['address.postal']} />
                        </div>
                    </Column>
                </Row>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid}
                    color="green"
                    onClick={add}
                    onDisabledClick={touchForm}>
                    Utwórz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AddCoachDialog, {
    id: 'addCoach',
    size: 'medium',
});
