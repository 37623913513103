import { ModalContent, ModalHeader, ModalHOC } from 'ui';

const DialogPrivacy = () => (
    <>
        <ModalHeader>
            <h2>Polityka prywatności Goaly</h2>
        </ModalHeader>
        <ModalContent>
            <div>
                <p>
                    Polityka prywatności opisuje zasady przetwarzania przez nas
                    informacji na Twój temat, w tym danych osobowych oraz
                    ciasteczek, czyli tzw. cookies.
                </p>
                <p> 1. Informacje ogólne</p>
                <p>
                    Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod
                    adresem url: www.goaly.pl Operatorem serwisu oraz
                    Administratorem danych osobowych jest: Piotr Polus ul.
                    Konopnickiej, 32-590 Libiąż Adres kontaktowy poczty
                    elektronicznej operatora: piotrek@goaly.pl. Operator jest
                    Administratorem Twoich danych osobowych w odniesieniu do
                    danych podanych dobrowolnie w Serwisie.
                </p>
                <p>Serwis wykorzystuje dane osobowe w następujących celach:</p>
                <ul>
                    <li>Prezentacja profil użytkownika innym użytkownikom</li>
                    <li>Prowadzenie newslettera</li>
                    <li>Obsługa zapytań przez formularz</li>
                    <li>Prezentacja oferty lub informacji</li>
                </ul>
                <p>
                    Serwis realizuje funkcje pozyskiwania informacji o
                    użytkownikach i ich zachowaniu w następujący sposób:
                </p>
                <ul>
                    <li>
                        Poprzez dobrowolnie wprowadzone w formularzach dane,
                        które zostają wprowadzone do systemów Operatora.
                    </li>
                    <li>
                        Poprzez zapisywanie w urządzeniach końcowych plików
                        cookie (tzw. „ciasteczka”).
                    </li>
                </ul>
                <p>
                    2. Wybrane metody ochrony danych stosowane przez Operatora
                </p>
                <p>
                    Obiekty logowania i wprowadzania danych osobowych są
                    chronione w warstwie transmisji (certyfikat SSL). Dzięki
                    temu dane osobowe i dane logowania, wprowadzone na stronie,
                    zostają zaszyfrowane w komputerze użytkownika i mogą być
                    odczytane jedynie na docelowym serwerze. Hasła użytkowników
                    są przechowywane w postaci hashowanej. Funkcja hashująca
                    działa jednokierunkowo - nie jest możliwe odwrócenie jej
                    działania, co stanowi obecnie współczesny standard w
                    zakresie przechowywania haseł użytkowników. Operator
                    okresowo zmienia swoje hasła administracyjne. W celu ochrony
                    danych Operator regularnie wykonuje kopie bezpieczeństwa.
                    Istotnym elementem ochrony danych jest regularna
                    aktualizacja wszelkiego oprogramowania, wykorzystywanego
                    przez Operatora do przetwarzania danych osobowych, co w
                    szczególności oznacza regularne aktualizacje komponentów
                    programistycznych.
                </p>
                <p>
                    3. Twoje prawa i dodatkowe informacje o sposobie
                    wykorzystania danych
                </p>
                <p>
                    W niektórych sytuacjach Administrator ma prawo przekazywać
                    Twoje dane osobowe innym odbiorcom, jeśli będzie to
                    niezbędne do wykonania zawartej z Tobą umowy lub do
                    zrealizowania obowiązków ciążących na Administratorze.
                    Dotyczy to takich grup odbiorców: operatorzy płatności
                    upoważnieni pracownicy i współpracownicy, którzy korzystają
                    z danych w celu realizacji celu działania strony Twoje dane
                    osobowe przetwarzane przez Administratora nie dłużej, niż
                    jest to konieczne do wykonania związanych z nimi czynności
                    określonych osobnymi przepisami (np. o prowadzeniu
                    rachunkowości). W odniesieniu do danych marketingowych dane
                    nie będą przetwarzane dłużej niż przez 3 lata.
                </p>
                <p>Przysługuje Ci prawo żądania od Administratora:</p>
                <ul>
                    <li>dostępu do danych osobowych Ciebie dotyczących,</li>
                    <li>ich sprostowania,</li>
                    <li>usunięcia,</li>
                    <li>ograniczenia przetwarzania,</li>
                    <li>oraz przenoszenia danych.</li>
                </ul>
                <p>
                    Przysługuje Ci prawo do złożenia sprzeciwu w zakresie
                    przetwarzania wskazanego w pkt 3.3 c) wobec przetwarzania
                    danych osobowych w celu wykonania prawnie uzasadnionych
                    interesów realizowanych przez Administratora, w tym
                    profilowania, przy czym prawo sprzeciwu nie będzie mogło być
                    wykonane w przypadku istnienia ważnych prawnie uzasadnionych
                    podstaw do przetwarzania, nadrzędnych wobec Ciebie
                    interesów, praw i wolności, w szczególności ustalenia,
                    dochodzenia lub obrony roszczeń. Na działania Administratora
                    przysługuje skarga do Prezesa Urzędu Ochrony Danych
                    Osobowych, ul. Stawki 2, 00-193 Warszawa.
                </p>
                <p>
                    Podanie danych osobowych jest dobrowolne, lecz niezbędne do
                    obsługi Serwisu. W stosunku do Ciebie mogą być podejmowane
                    czynności polegające na zautomatyzowanym podejmowaniu
                    decyzji, w tym profilowaniu w celu świadczenia usług w
                    ramach zawartej umowy oraz w celu prowadzenia przez
                    Administratora marketingu bezpośredniego. Dane osobowe nie
                    są przekazywane od krajów trzecich w rozumieniu przepisów o
                    ochronie danych osobowych. Oznacza to, że nie przesyłamy ich
                    poza teren Unii Europejskiej.
                </p>
                <p>4. Informacje w formularzach</p>
                <p>
                    Serwis zbiera informacje podane dobrowolnie przez
                    użytkownika, w tym dane osobowe, o ile zostaną one podane.
                    Serwis może zapisać informacje o parametrach połączenia
                    (oznaczenie czasu, adres IP). Serwis, w niektórych
                    wypadkach, może zapisać informację ułatwiającą powiązanie
                    danych w formularzu z adresem e-mail użytkownika
                    wypełniającego formularz. W takim wypadku adres e-mail
                    użytkownika pojawia się wewnątrz adresu url strony
                    zawierającej formularz. Dane podane w formularzu są
                    przetwarzane w celu wynikającym z funkcji konkretnego
                    formularza, np. w celu dokonania procesu obsługi zgłoszenia
                    serwisowego lub kontaktu handlowego, rejestracji usług itp.
                    Każdorazowo kontekst i opis formularza w czytelny sposób
                    informuje, do czego on służy.
                </p>
                <p>5. Istotne techniki marketingowe</p>
                <p>
                    Operator stosuje analizę statystyczną ruchu na stronie,
                    poprzez Google Analytics (Google Inc. z siedzibą w USA).
                    Operator nie przekazuje do operatora tej usługi danych
                    osobowych, a jedynie zanonimizowane informacje. Usługa
                    bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym
                    użytkownika. W zakresie informacji o preferencjach
                    użytkownika gromadzonych przez sieć reklamową Google
                    użytkownik może przeglądać i edytować informacje wynikające
                    z plików cookies przy pomocy narzędzia:
                    https://www.google.com/ads/preferences/ Operator stosuje
                    rozwiązanie automatyzujące działanie Serwisu w odniesieniu
                    do użytkowników, np. mogące przesłać maila do użytkownika po
                    odwiedzeniu konkretnej podstrony, o ile wyraził on zgodę na
                    otrzymywanie korespondencji handlowej od Operatora.
                </p>
                <p>6. Informacja o plikach cookies</p>
                <p>
                    Serwis korzysta z plików cookies. Pliki cookies (tzw.
                    „ciasteczka”) stanowią dane informatyczne, w szczególności
                    pliki tekstowe, które przechowywane są w urządzeniu końcowym
                    Użytkownika Serwisu i przeznaczone są do korzystania ze
                    stron internetowych Serwisu. Cookies zazwyczaj zawierają
                    nazwę strony internetowej, z której pochodzą, czas
                    przechowywania ich na urządzeniu końcowym oraz unikalny
                    numer. Podmiotem zamieszczającym na urządzeniu końcowym
                    Użytkownika Serwisu pliki cookies oraz uzyskującym do nich
                    dostęp jest operator Serwisu. Pliki cookies wykorzystywane
                    są w następujących celach: utrzymanie sesji użytkownika
                    Serwisu (po zalogowaniu), dzięki której użytkownik nie musi
                    na każdej podstronie Serwisu ponownie wpisywać loginu i
                    hasła; realizacji celów określonych powyżej w części
                    &quot;Istotne techniki marketingowe&quot;; W ramach Serwisu
                    stosowane są dwa zasadnicze rodzaje plików cookies:
                    „sesyjne” (session cookies) oraz „stałe” (persistent
                    cookies). Cookies „sesyjne” są plikami tymczasowymi, które
                    przechowywane są w urządzeniu końcowym Użytkownika do czasu
                    wylogowania, opuszczenia strony internetowej lub wyłączenia
                    oprogramowania (przeglądarki internetowej). „Stałe” pliki
                    cookies przechowywane są w urządzeniu końcowym Użytkownika
                    przez czas określony w parametrach plików cookies lub do
                    czasu ich usunięcia przez Użytkownika. Oprogramowanie do
                    przeglądania stron internetowych (przeglądarka internetowa)
                    zazwyczaj domyślnie dopuszcza przechowywanie plików cookies
                    w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą
                    dokonać zmiany ustawień w tym zakresie. Przeglądarka
                    internetowa umożliwia usunięcie plików cookies. Możliwe jest
                    także automatyczne blokowanie plików cookies Szczegółowe
                    informacje na ten temat zawiera pomoc lub dokumentacja
                    przeglądarki internetowej. Ograniczenia stosowania plików
                    cookies mogą wpłynąć na niektóre funkcjonalności dostępne na
                    stronach internetowych Serwisu. Pliki cookies zamieszczane w
                    urządzeniu końcowym Użytkownika Serwisu wykorzystywane mogą
                    być również przez współpracujące z operatorem Serwisu
                    podmioty, w szczególności dotyczy to firm: Google (Google
                    Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w
                    USA), Twitter (Twitter Inc. z siedzibą w USA). 6.
                    Subprocesorzy informacji
                </p>
                <ul>
                    <li>
                        Amazon Web Services, Inc., Cloud Service Provider (EU
                        region), Ireland & United States
                    </li>
                    <li>
                        Salesforce Heroku, Cloud-based Managed Application
                        Provider (EU region), United States
                    </li>
                    <li>
                        mLab, Cloud-based Managed Database Provider (EU region),
                        United States
                    </li>
                    <li>
                        Mailgun Technologies, Inc., Cloud-based Email
                        Notification Services, United States
                    </li>
                    <li>Bugsnag, Inc., Error monitoring service (EU region)</li>
                    <li>
                        H88 S.A. Hosting service (EU region), Poznan, Poland
                    </li>
                </ul>
            </div>
        </ModalContent>
    </>
);

export default ModalHOC(DialogPrivacy, {
    id: 'privacypolicy',
    size: 'medium',
});
