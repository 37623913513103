import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';

import uploadService from 'services/uploadService';

import useUI from '../../contexts/useUI.js';
import { Spinner } from '../index.js';

import styles from './InputFile.module.scss';

const InputFile = ({
    value,
    onChange,
    error,
    isTouched,
    isFocused,
    disabled,
    type,
}) => {
    const { spinnerPush, spinnerPop } = useUI();

    const [uploading, setUploading] = useState(false);

    const onDrop = el => {
        setUploading(true);
        spinnerPush();
        uploadService
            .upload(el[0], type)
            .then(({ data: { url } }) => {
                spinnerPop();
                onChange(url);
                setUploading(false);
            })
            .catch(() => {
                spinnerPop();
                setUploading(false);
            });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: ['image/png', 'image/jpg', 'image/jpeg'],
        maxFiles: 1,
        noDragEventsBubbling: true,
    });

    const isErrorVisible = useMemo(
        () => error && isTouched && !isFocused,
        [error, isTouched, isFocused],
    );

    return (
        <div
            {...getRootProps()}
            className={cx(
                styles.input,
                isFocused && styles.focused,
                isErrorVisible && styles.error,
                disabled && styles.disabled,
                uploading && styles.uploading,
            )}>
            {uploading && <Spinner />}
            {value && !uploading && (
                <div className={styles.value}>
                    <img className={styles.valueImg} src={value} alt="" />
                    <div className={styles.valueText}>Plik został wybrany</div>
                </div>
            )}
            <input {...getInputProps()} />
        </div>
    );
};

InputFile.defaultProps = {
    type: 'photo',
};

export default InputFile;
