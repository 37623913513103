import fetch from 'utils/fetch';

export default {
    signIn: (email, password) => fetch.post('auth/login', { email, password }),

    signOut: () => fetch.post('auth/logout'),

    signUp: object => fetch.post('auth/signup', object),

    activate: activationCode => fetch.get(`auth/activate/${activationCode}`),

    resetPassword: email => fetch.post('auth/reset-password', { email }),

    setPassword: (code, password) =>
        fetch.post(`auth/set-password/${code}`, { password }),

    createSchool: name => fetch.post('auth/create-organization', { name }),

    selectUser: userId => fetch.post('auth/select-user', { userId }),
};
