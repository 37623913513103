import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './Avatar.module.scss';

const Avatar = ({ text, picture, shape, size, color, styles }) => {
    if (shape === 'shield') {
        return (
            <div
                className={cx(style.avatarShield, style[`size-${size}`])}
                style={styles}>
                {size !== 's' && <span>{text.slice(0, 2)}</span>}
                <svg
                    width="72"
                    height="100"
                    viewBox="0 0 72 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 66.2835V8C0 3.58172 3.58172 0 8 0H64C68.4183 0 72 3.58172 72 8V66.2228C72 68.6144 70.9301 70.8805 69.0834 72.4001L40.6925 95.7634C37.7103 98.2175 33.4006 98.1907 30.4492 95.6997L2.84008 72.3971C1.03912 70.877 0 68.6402 0 66.2835Z"
                        fill={color || '#dddddd'}
                    />
                </svg>
            </div>
        );
    }

    if (!picture) {
        const string = text
            .split(' ')
            .slice(0, 2)
            .map(s => s[0] || ' ')
            .join('')
            .toUpperCase();

        return (
            <div
                className={cx(
                    style.avatar,
                    style[`shape-${shape}`],
                    style[`size-${size}`],
                )}
                style={styles}>
                {string}
            </div>
        );
    }
    return (
        <div
            className={cx(
                style.avatar,
                style.avatarPic,
                style[`shape-${shape}`],
                style[`size-${size}`],
            )}
            style={{ background: `url(${picture})`, ...styles }}>
            &nbsp;
        </div>
    );
};

Avatar.propTypes = {
    text: PropTypes.string.isRequired,
    shape: PropTypes.oneOf(['round', 'square', 'shield']),
    size: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'xxl']),
    color: PropTypes.string,
};

Avatar.defaultProps = {
    shape: 'round',
    size: 'm',
    text: '',
    color: '#dddddd',
};

export default Avatar;
