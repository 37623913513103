import { useHistory } from 'react-router-dom';

import useAuth from 'contexts/useAuth';
import { Button, Column, Header, Input, Label } from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util.js';

import styles from './ResetPassword.module.scss';

const ResetPassword = () => {
    const [form, { isFormValid, touchForm }] = useForm({
        email: {
            value: '',
            validators: [validators.isRequired(), validators.isEmail()],
        },
    });
    const history = useHistory();
    const { resetPassword } = useAuth();

    const resetPasswordFunc = () => {
        if (!isFormValid) return false;

        resetPassword(form.email.value)
            .then(() => {
                history.push('/auth/signin');
            })
            .catch(() => {});
    };

    return (
        <div className={styles.reset}>
            <div className={styles.card}>
                <Column gap="s">
                    <Header title={'Zresetuj hasło'} />
                    <div>
                        <Label>Adres email</Label>
                        <Input {...form.email} />
                    </div>
                    <div className={styles.text}>
                        Wprowadz adres email przypisany do Twoijego konta na
                        który wyślemy link do zresetowania hasła.
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            shape="square"
                            href="/auth/signin"
                            icon="chevron-left"
                        />
                        <Button
                            color="green"
                            onClick={resetPasswordFunc}
                            disabled={!isFormValid}
                            onDisabledClick={touchForm}>
                            Zresetuj
                        </Button>
                    </div>
                </Column>
            </div>
        </div>
    );
};

export default ResetPassword;
