import { useRouteMatch } from 'react-router-dom';

import EmptyCard from 'components/EmptyCard';
import Feed from 'components/Feed';
import useUI from 'contexts/useUI';
import { useGroupFeedQuery } from 'queries/groups.queries.js';
import AddInfo from 'screens/News/modals/AddInfo';
import AddNews from 'screens/News/modals/AddNews';
import { Button, Column, ContextMenu, Header, Spinner } from 'ui';

const FeedGroup = () => {
    const {
        params: { id },
    } = useRouteMatch();

    const { modalOpen } = useUI();

    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetched,
    } = useGroupFeedQuery(id);

    const feed = data.pages.flat(1);

    const contextActions = [
        {
            text: 'Nowa aktualność',
            icon: 'news',
            main: true,
            onClick: () => modalOpen('addNews', { groups: [id] }),
        },
        {
            text: 'Nowe ogłoszenie',
            icon: 'whistle',
            onClick: () => modalOpen('addInfo', { groups: [id] }),
        },
    ];

    if (!isFetched) return <Spinner active />;

    return (
        <Column gap={'m'}>
            <Header title="Komunikacja" size="l">
                <ContextMenu actions={contextActions} large>
                    <Button size="l" color={'green'}>
                        Dodaj nowe
                    </Button>
                </ContextMenu>
            </Header>
            {!feed.length && (
                <EmptyCard
                    action={() => modalOpen('addInfo', { groups: [id] })}
                    actionLabel="Dodaj pierwsze ogłoszenie"
                />
            )}
            <Feed
                items={feed}
                load={fetchNextPage}
                isMore={!isFetchingNextPage && hasNextPage}
                editable
            />
            <AddNews />
            <AddInfo />
        </Column>
    );
};

export default FeedGroup;
