import { useEffect } from 'react';

import { useMutationCoachPatch } from 'queries/coaches.mutations.js';
import { useCoachQuery } from 'queries/coaches.queries.js';
import {
    Button,
    Column,
    Input,
    InputDate,
    InputFile,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
} from 'ui';
import useForm from 'utils/useForm';

import validators from '../../../../../../utils/validators.util.js';

const DialogEditInfo = ({ data: { idCoach }, modalClose }) => {
    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        name: {
            value: '',
            validators: [validators.isRequired()],
        },
        surname: {
            value: '',
            validators: [validators.isRequired()],
        },
        birthday: {
            value: null,
        },
        avatar: {
            value: null,
        },
    });

    const { data } = useCoachQuery(idCoach);

    useEffect(() => {
        if (data) {
            form.name.setValue(data.name);
            form.surname.setValue(data.surname);
            form.birthday.setValue(new Date(data.birthday));
            form.avatar.setValue(data.avatar);
        }
    }, [data]);

    const mutation = useMutationCoachPatch({
        onSuccess: modalClose,
    });

    const save = () => {
        if (!isFormValid) return;
        mutation.mutate({ id: idCoach, delta: getFormValue() });
    };

    return (
        <>
            <ModalHeader>Edytuj informacje podstawowe</ModalHeader>
            <ModalContent>
                <Column gap={'xs'}>
                    <div>
                        <Label>Imię</Label>
                        <Input {...form.name} />
                    </div>
                    <div>
                        <Label>Nazwisko</Label>
                        <Input {...form.surname} />
                    </div>
                    <div>
                        <Label>Data urodzenia</Label>
                        <InputDate {...form.birthday} />
                    </div>
                    <div>
                        <Label>Avatar</Label>
                        <InputFile type="avatar" {...form.avatar} />
                    </div>
                </Column>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!isFormValid}
                    color="green"
                    onClick={save}
                    onDisabledClick={touchForm}>
                    Zapisz
                </Button>
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(DialogEditInfo, {
    id: 'editInfo',
    size: 'small',
});
