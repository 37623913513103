import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { addDays } from 'utils/date';

import MonthView from './MonthView';

const keydownHandler = e => {
    if ((e.keyCode || e.which) >= 37 && (e.keyCode || e.which) <= 40) {
        e.preventDefault();
    }
};

class MonthViewContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            year: props.value.getFullYear(),
            month: props.value.getMonth(),
            direction: 'center',
        };

        document.addEventListener('keyup', this.keyupHandler);
        document.addEventListener('keydown', keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.keyupHandler);
        document.removeEventListener('keydown', keydownHandler);
    }

    changeHandler = date => {
        this.props.onChange(date);
    };

    keyupHandler = e => {
        if ((e.keyCode || e.which) === 37) {
            this.prevDay();
        } else if ((e.keyCode || e.which) === 38) {
            this.prevWeek();
        } else if ((e.keyCode || e.which) === 39) {
            this.nextDay();
        } else if ((e.keyCode || e.which) === 40) {
            this.nextWeek();
        }
    };

    nextMonth = () => {
        const { month } = this.state;

        if (month !== 11) {
            this.setState(state => ({
                month: state.month + 1,
                direction: 'left',
            }));
        } else {
            this.setState(state => ({
                year: state.year + 1,
                month: 0,
                direction: 'left',
            }));
        }
    };

    prevMonth = () => {
        const { month } = this.state;

        if (month !== 0) {
            this.setState(state => ({
                month: state.month - 1,
                direction: 'right',
            }));
        } else {
            this.setState(state => ({
                year: state.year - 1,
                month: 11,
                direction: 'right',
            }));
        }
    };

    nextWeek = () => {
        const { date } = this.props,
            newDate = addDays(date, 7);
        this.changeHandler(newDate);
        if (date.getMonth() !== newDate.getMonth()) {
            this.nextMonth();
        }
    };

    prevWeek = () => {
        const { date } = this.props,
            newDate = addDays(date, -7);
        this.changeHandler(newDate);
        if (date.getMonth() !== newDate.getMonth()) {
            this.prevMonth();
        }
    };

    nextDay = () => {
        const { date } = this.props,
            newDate = addDays(date, 1);
        this.changeHandler(newDate);
        if (date.getMonth() !== newDate.getMonth()) {
            this.nextMonth();
        }
    };

    prevDay = () => {
        const { date } = this.props,
            newDate = addDays(date, -1);
        this.changeHandler(newDate);
        if (date.getMonth() !== newDate.getMonth()) {
            this.prevMonth();
        }
    };

    openYear = () => {
        this.props.setMode('year', new Date(this.state.year, this.state.month));
    };

    render() {
        const props = {
            value: this.props.value,
            month: this.state.month,
            year: this.state.year,
            direction: this.state.direction,
            onNext: this.nextMonth,
            onPrev: this.prevMonth,
            onChange: this.changeHandler,
            openYear: this.openYear,
        };

        return <MonthView {...props} />;
    }
}

MonthViewContainer.propTypes = {
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
};

MonthViewContainer.defaultProps = {
    value: new Date(),
    onChange: undefined,
};

export default MonthViewContainer;
