import PropTypes from 'prop-types';

import styles from './Label.module.scss';

const Label = ({ children }) => <div className={styles.label}>{children}</div>;

Label.propTypes = {
    children: PropTypes.string.isRequired,
};

export default Label;
