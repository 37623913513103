import { Link } from 'react-router-dom';

import { Avatar, Icon, Row, Tooltip } from 'ui';

import styles from './GroupCard.module.scss';

const GroupCard = ({ id, name, subname, players, coaches, avatar }) => (
    <Link to={`/groups/${id}`} key={id} className={styles.groupCard}>
        <div className={styles.image}>
            <Avatar
                text={avatar.slice(7, 9)}
                shape="shield"
                size="xxl"
                color={avatar.slice(0, 7)}
            />
        </div>
        <div className={styles.name}>{name}</div>
        <div className={styles.subname}>{subname || 'Grupa szkolna'}</div>
        <div className={styles.bottom}>
            <div className={styles.players}>
                {players && (
                    <>
                        <Icon icon="player-standing" />
                        {players.length}
                    </>
                )}
            </div>
            <div className={styles.coaches}>
                <Row gap="xxxs">
                    {coaches &&
                        coaches.map(c => (
                            <Tooltip
                                text={`${c.name} ${c.surname}`}
                                key={c.id}
                                display="inline">
                                <Avatar
                                    size="m"
                                    text={`${c.name} ${c.surname}`}
                                    picture={c.avatar}
                                />
                            </Tooltip>
                        ))}
                </Row>
            </div>
        </div>
    </Link>
);

export default GroupCard;
