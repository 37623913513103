import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import style from './ListRow.style.module.scss';

const animation = {
    initial: {
        opacity: 0,
        scaleY: 0.9,
    },
    animate: {
        opacity: 1,
        scaleY: 1,
    },
    exit: {
        opacity: 0,
        scaleY: 1,
    },
};

const ListRow = ({ data, renderRow, ...props }) => (
    <motion.div {...animation} {...props} className={style.listRow} layout>
        {renderRow(data)}
    </motion.div>
);

ListRow.propTypes = {
    data: PropTypes.object.isRequired,
    renderRow: PropTypes.func.isRequired,
};

export default ListRow;
