import { useEffect, useMemo } from 'react';

import { useGroupsAllQuery } from 'queries/groups.queries.js';
import {
    useMutationNewsAdd,
    useMutationNewsPatch,
} from 'queries/news.mutations.js';
import { useNewsQuery } from 'queries/news.queries.js';
import {
    Button,
    Column,
    Header,
    Input,
    InputFile,
    InputSelect,
    InputSwitch,
    InputText,
    Label,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHOC,
    Warning,
} from 'ui';
import useForm from 'utils/useForm';
import validators from 'utils/validators.util.js';

const AddNews = ({ data: { groups, id }, modalClose }) => {
    const { data: groupsFetched } = useGroupsAllQuery();

    const groupOptions = useMemo(
        () => groupsFetched?.map(v => ({ label: v.name, value: v.id })) || [],
        [groupsFetched],
    );

    const [form, { isFormValid, getFormValue, touchForm }] = useForm({
        title: {
            value: '',
            validators: [validators.isRequired()],
        },
        picture: {
            value: '',
        },
        shortText: {
            value: '',
            validators: [validators.isRequired()],
        },
        longText: {
            value: '',
        },
        isWholeOrganization: {
            value: false,
        },
        groups: {
            value: groups || [],
        },
        isWeb: {
            value: false,
        },
    });

    const { data } = useNewsQuery(id);

    useEffect(() => {
        if (data) {
            form.title.setValue(data.data?.title);
            form.picture.setValue(data.data?.picture);
            form.shortText.setValue(data.data?.shortText);
            form.longText.setValue(data.data?.longText);
            form.groups.setValue(data.groups?.map(g => g.id) || []);
            form.isWeb.setValue(data.isWeb);
            form.isWholeOrganization.setValue(!data.groupsRule);
        }
    }, [data]);

    const mutationAdd = useMutationNewsAdd({ onSuccess: modalClose });
    const mutationPatch = useMutationNewsPatch({ onSuccess: modalClose });

    const add = () => {
        if (!isFormValid) return;
        mutationAdd.mutate({
            type: 'news',
            data: {
                ...getFormValue(),
            },
            ...getFormValue(),
            groupsRule: !form.isWholeOrganization.value,
            groups: form.isWholeOrganization.value ? [] : form.groups.value,
        });
    };

    const edit = () => {
        if (!isFormValid) return;
        mutationPatch.mutate({
            id,
            delta: {
                type: 'news',
                data: {
                    ...getFormValue(),
                },
                ...getFormValue(),
                groupsRule: !form.isWholeOrganization.value,
                groups: form.isWholeOrganization.value ? [] : form.groups.value,
            },
        });
    };

    return (
        <>
            <ModalHeader>
                {id ? 'Edytuj wiadomość' : 'Nowa wiadomość'}
            </ModalHeader>
            <ModalContent>
                <Column gap="m">
                    <Column gap="xs">
                        <Header title="Treśc aktualności" size="s" />
                        <div>
                            <Label>Tytuł</Label>
                            <Input {...form.title} />
                        </div>
                        <div>
                            <Label>Zdjęcie</Label>
                            <InputFile type="photo" {...form.picture} />
                        </div>
                        <div>
                            <Label>Krótka treść</Label>
                            <InputText {...form.shortText} />
                        </div>
                        <div>
                            <Label>Długa treść</Label>
                            <InputText {...form.longText} />
                        </div>
                    </Column>
                    <Column gap="xs">
                        <Header title="Informacje dodatkowe" size="s" />
                        <div>
                            <InputSwitch {...form.isWholeOrganization}>
                                Aktualność dotyczy całej organizacji
                            </InputSwitch>
                        </div>
                        {!form.isWholeOrganization.value && (
                            <div>
                                <Label>Przypisz grupy do aktualności</Label>
                                <InputSelect
                                    options={groupOptions}
                                    {...form.groups}
                                    multiselect
                                />
                            </div>
                        )}
                        <div>
                            <InputSwitch {...form.isWeb}>
                                Publikacja na stronie internetowej
                            </InputSwitch>
                            {!form.isWeb.value && (
                                <Warning
                                    title={`Aktualność nie zostanie upubliczniona na stronie www szkoły`}
                                    content="Będzie widoczna tylko wewnątrz Goaly"
                                />
                            )}
                        </div>
                    </Column>
                </Column>
            </ModalContent>
            <ModalActions>
                {!id && (
                    <Button
                        disabled={!isFormValid}
                        color="green"
                        onClick={add}
                        onDisabledClick={touchForm}>
                        Utwórz
                    </Button>
                )}
                {id && (
                    <Button
                        disabled={!isFormValid}
                        color="green"
                        onClick={edit}>
                        Zapisz
                    </Button>
                )}
                <Button color={'black'} onClick={modalClose}>
                    Anuluj
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalHOC(AddNews, {
    id: 'addNews',
    size: 'medium',
});
